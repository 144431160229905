<template>
  <ConfirmModal wrapper-class="p-6 w-11/12" frame-class="cursor-default text-black" @close-dialog="closePopup">
    <template #title>
      <h1 class="text-headline-m" data-test-id="prospect-row-popup">
        {{ t('ProspectsCatalog.popupHeadline') }}
      </h1>
    </template>

    <template #content>
      <AlertBox v-if="!prospect.available" type="info" class="mt-5">
        <template #header>
          {{ t('ProspectsCatalog.prospectNotAvailableTitle') }}
        </template>
        {{ t('ProspectsCatalog.prospectNotAvailableDescription') }}
      </AlertBox>
      <TableLight class="mt-6 w-full">
        <thead>
          <tr>
            <th class="text-left">{{ t('ProspectsCatalog.visitedEvents') }}</th>
            <th class="text-left">{{ t('ProspectsCatalog.companyName') }}</th>
            <th class="text-left">{{ t('ProspectsCatalog.position') }}</th>
            <th class="text-left">{{ t('ProspectsCatalog.companyDepartment') }}</th>
            <th class="text-left">{{ t('ProspectsCatalog.companyJobLevel') }}</th>
            <th class="text-left">{{ t('ProspectsCatalog.companyField') }}</th>
            <th class="text-left">{{ t('ProspectsCatalog.companyIndustry') }}</th>
            <th class="text-left">{{ t('ProspectsCatalog.companySize') }}</th>
            <th class="text-left">{{ t('ProspectsCatalog.companyCountry') }}</th>
          </tr>
        </thead>
        <tbody>
          <ProspectRowPopupRow
            v-for="(company, i) in props.prospect.companies"
            :key="`${props.prospect.id}-company-${i}`"
            :company="company"
            :facet-items="props.facetItems"
            :prospect="props.prospect"
          />
        </tbody>
      </TableLight>
    </template>

    <template #actions>
      <div class="flex justify-end mt-8">
        <button class="btn-text-purple-m" @click.stop="closePopup">{{ t('close') }}</button>
        <DropDownSelect v-if="canAdd" inner-class="w-fit" direction="right" position="bottom">
          <template #activator="{ open }">
            <button class="ml-4 btn-primary-purple-m" @click.stop="open">
              <SvgIcon :path="mdiPlaylistPlus" class="text-white mr-3" />
              {{ t('ProspectsCatalog.addToList') }}
            </button>
          </template>

          <template #default="{ close }">
            <ul class="list-none p-0 m-0">
              <template v-if="prospectLists.length">
                <li v-for="list in props.prospectLists" :key="`list_${list.id}`" class="separate-rows">
                  <label
                    class="block cursor-pointer hover:bg-purple-100 peer-checked:bg-purple-100 py-2 px-3"
                    @click.stop="addProspectToList(list.id, close)"
                  >
                    {{ list.title }}
                  </label>
                </li>
              </template>
              <template v-else>
                <li class="separate-rows">
                  <label class="block cursor-pointer hover:bg-purple-100 peer-checked:bg-purple-100 py-2 px-3">
                    {{ $t('ProspectsCatalog.addToListNoListFound') }}
                  </label>
                </li>
              </template>
            </ul>
          </template>
        </DropDownSelect>
        <button v-if="props.prospectListId && isChangeable" class="ml-4 btn-primary-purple-m" @click.stop="deleteProspectItem">
          <SvgIcon :path="mdiMinusCircleOutline" class="text-white mr-3" />
          {{ t('ProspectsCatalog.removeFromList') }}
        </button>
      </div>
    </template>
  </ConfirmModal>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import { mdiMinusCircleOutline, mdiPlaylistPlus } from '@mdi/js'
import ProspectRowPopupRow from './ProspectRowPopupRow.vue'
import { useI18n } from 'vue-i18n'
import type { ProspectFacetItems } from '@/components/Prospects/prospectFacetItems'
import { ConfirmModal } from '@/components'
import { TableLight } from '@/ui/TableLight'
const { t } = useI18n()
import type { ProspectItem, ProspectList } from './types'
import { findActiveList, isListChangeable } from './helpers'

const props = defineProps<{
  facetItems: ProspectFacetItems
  prospect: ProspectItem
  prospectListId?: string
  prospectLists: ProspectList[]
  disableAdd?: boolean
}>()

const canAdd = computed(() => !props.prospectListId && props.prospect.available && !props.disableAdd)

const emit = defineEmits<{
  (e: 'createProspectItem', value: { prospectId: string; prospectListId: string }): void
  (e: 'deleteProspectItem', value: { prospectId: string; prospectListId: string }): void
  (e: 'hideDetails'): void
}>()

const activeList = findActiveList(props.prospectLists, props.prospectListId)
const isChangeable = computed(() => isListChangeable(activeList.value))

function closePopup() {
  emit('hideDetails')
}

function addProspectToList(prospectListId: string, callback: () => void) {
  emit('createProspectItem', { prospectId: props.prospect.id, prospectListId: prospectListId })
  callback()
  emit('hideDetails')
}

function deleteProspectItem() {
  if (props.prospectListId) {
    emit('deleteProspectItem', { prospectId: props.prospect.id, prospectListId: props.prospectListId })
  }
  emit('hideDetails')
}
</script>
