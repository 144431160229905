<template>
  <ContentCardSubheader :back-to="{ name: ReviewsRouteName.ReviewsProducts }">
    {{ t('Reviews.product.view.assetsTitle', { productName: product.title }) }}
    <template #actions>
      <RouterLink
        class="btn-primary-purple-m btn-disabled text-white"
        :to="{ name: ReviewsRouteName.ReviewsProductEditAssets, params: { productId: product.id } }"
      >
        {{ t('Reviews.product.action.edit') }}
      </RouterLink>
    </template>
  </ContentCardSubheader>
</template>
<script setup lang="ts">
import type { ProductData } from '@/gql/reviews'
import { useI18n } from 'vue-i18n'
import { ReviewsRouteName } from '@/router/types'
import ContentCardSubheader from '@/ui/ContentCardSubheader.vue'

defineProps<{
  product: Pick<ProductData, 'id' | 'title'>
}>()

const { t } = useI18n()
</script>
