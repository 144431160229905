import type { Ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { ReviewsRouteName } from '@/router/types.ts'
import { useBreadcrumb } from './useBreadcrumb.ts'
import type { BreadcrumbItem } from '@/components/PageBreadcrumbs.vue'

export const useReviewsRootBreadcrumb = (): Ref<BreadcrumbItem> => {
  const { t } = useI18n()

  return useBreadcrumb({ name: ReviewsRouteName.ReviewsProducts }, t('breadcrumbs.reviews.root'))
}
