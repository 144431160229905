import { computed } from 'vue'
import type { SurveyResponse } from '@/components/ReviewsManagement/Reviews/types.ts'
import { SurveyResponseStateEnum } from '@/gql/reviews'

export enum SurveyResponseState {
  Drafted = 'drafted',
  ReDrafted = 'reDrafted',
  Proposed = 'proposed',
  Published = 'published',
  Rejected = 'rejected',
  Stale = 'stale',
}

export const useSurveyResponseState = (surveyResponse: Pick<SurveyResponse, 'aasmState' | 'review'>) =>
  computed<SurveyResponseState>(() => {
    if (surveyResponse.review && surveyResponse.aasmState === SurveyResponseStateEnum.Drafted) {
      return SurveyResponseState.ReDrafted
    }

    return surveyResponse.aasmState as SurveyResponseState
  })
