import { gql } from '@/helpers/gql'
import * as VueApolloComposable from '@vue/apollo-composable'
import type * as VueCompositionApi from 'vue'
export type Maybe<T> = T | null
export type InputMaybe<T> = Maybe<T>
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] }
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> }
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> }
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never }
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never }
export type ReactiveFunction<TParam> = () => TParam
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string }
  String: { input: string; output: string }
  Boolean: { input: boolean; output: boolean }
  Int: { input: number; output: number }
  Float: { input: number; output: number }
  CentsAmount: { input: any; output: any }
  ISO8601Date: { input: any; output: any }
  ISO8601DateTime: { input: string; output: string }
  JSON: { input: any; output: any }
  Upload: { input: File | File[]; output: File | File[] }
}

/** Autogenerated input type of Accept */
export type AcceptInput = {
  attendeeId: Scalars['ID']['input']
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>
}

/** Autogenerated return type of Accept. */
export type AcceptPayload = {
  __typename?: 'AcceptPayload'
  attendee?: Maybe<Attendee>
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>
  errors: Array<Scalars['String']['output']>
}

/** Autogenerated input type of AcceptTicketRequest */
export type AcceptTicketRequestInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>
  ticketId: Scalars['ID']['input']
}

/** Autogenerated return type of AcceptTicketRequest. */
export type AcceptTicketRequestPayload = {
  __typename?: 'AcceptTicketRequestPayload'
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>
  errors: Array<Scalars['String']['output']>
  ticket?: Maybe<Ticket>
}

/** Autogenerated input type of ActivateTicket */
export type ActivateTicketInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>
  ticketId: Scalars['ID']['input']
}

/** Autogenerated return type of ActivateTicket. */
export type ActivateTicketPayload = {
  __typename?: 'ActivateTicketPayload'
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>
  errors: Array<Scalars['String']['output']>
  ticket?: Maybe<Ticket>
}

export type ActsAsTaggableOn = Node & {
  __typename?: 'ActsAsTaggableOn'
  id: Scalars['ID']['output']
  name: Scalars['String']['output']
  slug: Scalars['String']['output']
  text: Scalars['String']['output']
}

/** Autogenerated input type of AddAttendeesByTicketIdentifier */
export type AddAttendeesByTicketIdentifierInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>
  eventId: Scalars['ID']['input']
  ticketIdentifiers: Array<Scalars['ID']['input']>
}

/** Autogenerated return type of AddAttendeesByTicketIdentifier. */
export type AddAttendeesByTicketIdentifierPayload = {
  __typename?: 'AddAttendeesByTicketIdentifierPayload'
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>
  errors: Array<Scalars['String']['output']>
  results?: Maybe<Array<AttendeeByTicketIdentifierResult>>
}

export type AgencyMatch = {
  __typename?: 'AgencyMatch'
  highlight?: Maybe<Scalars['String']['output']>
  id: Scalars['ID']['output']
  logo?: Maybe<ReviewsAsset>
  slug: Scalars['String']['output']
  title: Scalars['String']['output']
  url: Scalars['String']['output']
}

export type AgencyMatchPaginatedCollection = {
  __typename?: 'AgencyMatchPaginatedCollection'
  nodes: Array<AgencyMatch>
  pageInfo: OffsetPaginationInfo
}

export type AirtableAttachment = {
  __typename?: 'AirtableAttachment'
  filename: Scalars['String']['output']
  id: Scalars['ID']['output']
  url: Scalars['String']['output']
}

export type AirtableAttachmentAttributes = {
  creations?: InputMaybe<Array<Scalars['Upload']['input']>>
  deletions?: InputMaybe<Array<Scalars['ID']['input']>>
}

/** Autogenerated input type of Apply */
export type ApplyInput = {
  acceptedSpecialTerms?: InputMaybe<Scalars['Boolean']['input']>
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>
  eventIds: Array<Scalars['ID']['input']>
  ticketId: Scalars['ID']['input']
}

/** Autogenerated return type of Apply. */
export type ApplyPayload = {
  __typename?: 'ApplyPayload'
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>
  errors?: Maybe<Array<Scalars['String']['output']>>
  success: Scalars['Boolean']['output']
}

export type ArticleMatch = {
  __typename?: 'ArticleMatch'
  authors: Array<HygraphAuthor>
  highlight?: Maybe<Scalars['String']['output']>
  id: Scalars['ID']['output']
  publishDateTime: Scalars['ISO8601DateTime']['output']
  slug: Scalars['String']['output']
  title: Scalars['String']['output']
  url: Scalars['String']['output']
}

export type ArticleMatchPaginatedCollection = {
  __typename?: 'ArticleMatchPaginatedCollection'
  nodes: Array<ArticleMatch>
  pageInfo: OffsetPaginationInfo
}

export type Attendee = Node & {
  __typename?: 'Attendee'
  aasmState: Scalars['String']['output']
  addedViaManage: Scalars['Boolean']['output']
  checkedInAt?: Maybe<Scalars['ISO8601DateTime']['output']>
  event: PublishedEvent
  forcedAllocation?: Maybe<Scalars['Boolean']['output']>
  id: Scalars['ID']['output']
  tagList?: Maybe<Array<Scalars['String']['output']>>
  ticket?: Maybe<Ticket>
}

export enum AttendeeAasmStateEnum {
  Accepted = 'accepted',
  Denied = 'denied',
  Requested = 'requested',
}

export type AttendeeByTicketIdentifierResult = {
  __typename?: 'AttendeeByTicketIdentifierResult'
  companyName?: Maybe<Scalars['String']['output']>
  errorMessage?: Maybe<Scalars['String']['output']>
  position?: Maybe<Scalars['String']['output']>
  success: Scalars['Boolean']['output']
  ticketIdentifier: Scalars['String']['output']
}

/** The connection type for Attendee. */
export type AttendeeConnection = {
  __typename?: 'AttendeeConnection'
  acceptedCount: Scalars['Int']['output']
  acceptedSuccessorCount: Scalars['Int']['output']
  deniedCount: Scalars['Int']['output']
  /** A list of edges. */
  edges: Array<AttendeeEdge>
  /** A list of nodes. */
  nodes: Array<Attendee>
  /** Information to aid in pagination. */
  pageInfo: PageInfo
  requestedCount: Scalars['Int']['output']
  totalCount: Scalars['Int']['output']
}

/** An edge in a connection. */
export type AttendeeEdge = {
  __typename?: 'AttendeeEdge'
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output']
  /** The item at the end of the edge. */
  node?: Maybe<Attendee>
}

export enum AttendeeOrderAttributesEnum {
  AasmState = 'aasm_state',
  CheckedInAt = 'checked_in_at',
  CompanyName = 'company_name',
  CompanyPosition = 'company_position',
  CreatedAt = 'created_at',
  EventId = 'event_id',
  FinishedNotificationSentAt = 'finished_notification_sent_at',
  Id = 'id',
  Rating = 'rating',
  TicketId = 'ticket_id',
  UpdatedAt = 'updated_at',
}

export type BadgeTransaction = Node & {
  __typename?: 'BadgeTransaction'
  badgeTransactionItems: Array<BadgeTransactionItem>
  /** Company name */
  company: Scalars['String']['output']
  /** Created At */
  createdAt: Scalars['ISO8601DateTime']['output']
  /** External Badge ID from GET */
  externalBadgeId: Scalars['String']['output']
  /** External ID from GET */
  externalId: Scalars['String']['output']
  /** Transaction Type */
  getType: Scalars['String']['output']
  /** Sum of gifted credits */
  giftCreditsGross: Scalars['Float']['output']
  id: Scalars['ID']['output']
  /** Invoice PDF URL */
  invoicePdfUrl: Scalars['String']['output']
  /** Invoice Url landingpage. Use invoice_pdf_url for the pdf */
  invoiceUrl: Scalars['String']['output']
  /** Notification Send At */
  notificationSentAt?: Maybe<Scalars['ISO8601DateTime']['output']>
  /** Order the Receipt is part of */
  order?: Maybe<Order>
  /** JSON API response */
  payload: Scalars['String']['output']
  /** Real credit sum */
  realCreditsGross: Scalars['Float']['output']
  /** Receipt */
  receipt: Scalars['String']['output']
  /** Receipt Number */
  receiptNumber: Scalars['String']['output']
  /** Server Receive Date (from GET) */
  serverReceiveDate: Scalars['ISO8601DateTime']['output']
  /** Badge Session. Always 1 */
  sessionCounter: Scalars['Int']['output']
  /** If true, the transaction was skipped or taken out of the order */
  skip: Scalars['Boolean']['output']
  /** Status of the Badge Transaction. Only if true is the badge_transaction relevant */
  status: Scalars['String']['output']
  /** Total credit sum */
  totalCreditsGross: Scalars['Float']['output']
  /** Transaction Date */
  transactionDate: Scalars['ISO8601DateTime']['output']
  /** Updated At */
  updatedAt: Scalars['ISO8601DateTime']['output']
}

/** The connection type for BadgeTransaction. */
export type BadgeTransactionConnection = {
  __typename?: 'BadgeTransactionConnection'
  /** A list of edges. */
  edges: Array<BadgeTransactionEdge>
  /** A list of nodes. */
  nodes: Array<BadgeTransaction>
  /** Information to aid in pagination. */
  pageInfo: PageInfo
  totalCount: Scalars['Int']['output']
}

/** An edge in a connection. */
export type BadgeTransactionEdge = {
  __typename?: 'BadgeTransactionEdge'
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output']
  /** The item at the end of the edge. */
  node?: Maybe<BadgeTransaction>
}

export type BadgeTransactionItem = Node & {
  __typename?: 'BadgeTransactionItem'
  /** BadgeTransaction */
  badgeTransaction: BadgeTransaction
  /** Count */
  count?: Maybe<Scalars['Int']['output']>
  /** Created At */
  createdAt: Scalars['ISO8601DateTime']['output']
  /** Gift Credits Gross */
  giftCreditsGross?: Maybe<Scalars['Float']['output']>
  id: Scalars['ID']['output']
  /** Item Type */
  itemType?: Maybe<Scalars['String']['output']>
  /** Items Real Credits Gross */
  itemsRealCreditsGross?: Maybe<Scalars['Float']['output']>
  /** Name */
  name?: Maybe<Scalars['String']['output']>
  /** Real Credits Gross */
  realCreditsGross?: Maybe<Scalars['Float']['output']>
  /** Skip */
  skip: Scalars['Boolean']['output']
  /** Tax Rate */
  taxRate: Scalars['Float']['output']
  /** Tax Rate Name */
  taxRateName?: Maybe<Scalars['String']['output']>
  /** Total Credits Gross */
  totalCreditsGross: Scalars['Float']['output']
  /** Updated At */
  transactionDate: Scalars['ISO8601DateTime']['output']
  /** Updated At */
  updatedAt: Scalars['ISO8601DateTime']['output']
}

export type BillingAddress = {
  __typename?: 'BillingAddress'
  PartnerCompany: PartnerCompany
  careOf?: Maybe<Scalars['String']['output']>
  country?: Maybe<Scalars['String']['output']>
  houseNumber?: Maybe<Scalars['String']['output']>
  id: Scalars['ID']['output']
  name?: Maybe<Scalars['String']['output']>
  postalCode?: Maybe<Scalars['String']['output']>
  street?: Maybe<Scalars['String']['output']>
}

/** Attributes for creating or updating a billing_address */
export type BillingAddressAttributes = {
  /** C/O */
  careOf?: InputMaybe<Scalars['String']['input']>
  /** City */
  city?: InputMaybe<Scalars['String']['input']>
  /** Country Code */
  country?: InputMaybe<Scalars['String']['input']>
  /** House Number */
  houseNumber?: InputMaybe<Scalars['String']['input']>
  /** Name */
  name?: InputMaybe<Scalars['String']['input']>
  /** Postal Code */
  postalCode?: InputMaybe<Scalars['String']['input']>
  /** Street */
  street?: InputMaybe<Scalars['String']['input']>
}

export type Booth = Node & {
  __typename?: 'Booth'
  backCoverDesignUrl?: Maybe<Scalars['String']['output']>
  boost: Scalars['Int']['output']
  boothNumber?: Maybe<Scalars['String']['output']>
  counterBrandingFrontUrl?: Maybe<Scalars['String']['output']>
  counterBrandingSideAUrl?: Maybe<Scalars['String']['output']>
  counterBrandingSideBUrl?: Maybe<Scalars['String']['output']>
  createdAt?: Maybe<Scalars['ISO8601DateTime']['output']>
  descriptionDe?: Maybe<Scalars['String']['output']>
  descriptionEn?: Maybe<Scalars['String']['output']>
  event: Event
  extras?: Maybe<Array<Extra>>
  extrasDueDate?: Maybe<Scalars['ISO8601DateTime']['output']>
  foodTypes?: Maybe<Array<BoothFoodTypeEnum>>
  id: Scalars['ID']['output']
  latitude?: Maybe<Scalars['String']['output']>
  location?: Maybe<Location>
  longitude?: Maybe<Scalars['String']['output']>
  oscPortalUrl?: Maybe<Scalars['String']['output']>
  partnerCompany?: Maybe<PartnerCompany>
  premium: Scalars['Boolean']['output']
  products: Array<Product>
  showOnMap: Scalars['Boolean']['output']
  titleDe?: Maybe<Scalars['String']['output']>
  titleEn?: Maybe<Scalars['String']['output']>
  type?: Maybe<BoothTypeEnum>
  typeDisplayName: Scalars['String']['output']
}

/** Attributes for creating or updating a booth */
export type BoothAttributes = {
  backCoverDesign?: InputMaybe<Scalars['Upload']['input']>
  counterBrandingFront?: InputMaybe<Scalars['Upload']['input']>
  counterBrandingSideA?: InputMaybe<Scalars['Upload']['input']>
  counterBrandingSideB?: InputMaybe<Scalars['Upload']['input']>
  /** Description DE */
  descriptionDe?: InputMaybe<Scalars['String']['input']>
  /** Description EN */
  descriptionEn?: InputMaybe<Scalars['String']['input']>
  foodTypes?: InputMaybe<Array<BoothFoodTypeEnum>>
  /** Title DE */
  titleDe?: InputMaybe<Scalars['String']['input']>
  /** Title EN */
  titleEn?: InputMaybe<Scalars['String']['input']>
}

/** The connection type for Booth. */
export type BoothConnection = {
  __typename?: 'BoothConnection'
  /** A list of edges. */
  edges: Array<BoothEdge>
  /** A list of nodes. */
  nodes: Array<Booth>
  /** Information to aid in pagination. */
  pageInfo: PageInfo
  totalCount: Scalars['Int']['output']
}

/** An edge in a connection. */
export type BoothEdge = {
  __typename?: 'BoothEdge'
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output']
  /** The item at the end of the edge. */
  node?: Maybe<Booth>
}

export enum BoothFoodTypeEnum {
  Fish = 'fish',
  Meat = 'meat',
  Vegan = 'vegan',
  Veggie = 'veggie',
}

export enum BoothTypeEnum {
  BrandedRoomBooth = 'BrandedRoomBooth',
  CoExhibitorBooth = 'CoExhibitorBooth',
  GastroBooth = 'GastroBooth',
  PremiumBooth = 'PremiumBooth',
  PremiumLoungeBooth = 'PremiumLoungeBooth',
  PremiumOutdoorBooth = 'PremiumOutdoorBooth',
  SamplingBooth = 'SamplingBooth',
  SeacontainerBooth = 'SeacontainerBooth',
  StartupBooth = 'StartupBooth',
  SystemBooth = 'SystemBooth',
}

export enum BucketEventTypeEnum {
  DigitalMasterclass = 'DigitalMasterclass',
  GuidedTour = 'GuidedTour',
  Masterclass = 'Masterclass',
  SideEvent = 'SideEvent',
}

export type BulkDiscount = {
  __typename?: 'BulkDiscount'
  net: Scalars['Int']['output']
  quantity: Scalars['Int']['output']
  ticketCategoryId: Scalars['String']['output']
}

/** Attributes for friendly captcha */
export type CaptchaAttributes = {
  /** sitekey */
  sitekey: Scalars['String']['input']
  /** solution */
  solution: Scalars['String']['input']
}

export type CheckoutConfig = {
  __typename?: 'CheckoutConfig'
  additionalAddress?: Maybe<Scalars['String']['output']>
  agbUrl?: Maybe<Scalars['String']['output']>
  bankaccountHash?: Maybe<Scalars['String']['output']>
  creditcardHash?: Maybe<Scalars['String']['output']>
  payoneAccountId?: Maybe<Scalars['String']['output']>
  payoneMerchantId?: Maybe<Scalars['String']['output']>
  payoneMode?: Maybe<Scalars['String']['output']>
  payonePortalId?: Maybe<Scalars['String']['output']>
  privacyUrl?: Maybe<Scalars['String']['output']>
}

export type CheckoutLineItem = {
  __typename?: 'CheckoutLineItem'
  externalId?: Maybe<Scalars['String']['output']>
  invoiceUrl?: Maybe<Scalars['String']['output']>
  isGross?: Maybe<Scalars['Boolean']['output']>
  quantity?: Maybe<Scalars['Int']['output']>
  singleAmount?: Maybe<Scalars['Int']['output']>
  ticketCategoryId?: Maybe<Scalars['ID']['output']>
  title?: Maybe<Scalars['String']['output']>
  type?: Maybe<LineItemTypeEnum>
  vat?: Maybe<Scalars['Int']['output']>
}

export type CheckoutOrder = {
  __typename?: 'CheckoutOrder'
  /** Status of the order. requested = only our system / link sent, appointed = sent to payone */
  aasmState: OrderAasmStateEnum
  /** Additional Address */
  additionalAddress?: Maybe<Scalars['String']['output']>
  /** Checkout Token */
  afterPaymentToken?: Maybe<Scalars['String']['output']>
  amountInCentsGross: Scalars['Int']['output']
  business?: Maybe<Scalars['Boolean']['output']>
  /** City */
  city?: Maybe<Scalars['String']['output']>
  /** Company name */
  company?: Maybe<Scalars['String']['output']>
  config?: Maybe<CheckoutConfig>
  /** Country */
  country?: Maybe<Scalars['String']['output']>
  /** Customer reference */
  customerReference?: Maybe<Scalars['String']['output']>
  /** E-Mail */
  email?: Maybe<Scalars['String']['output']>
  /** Event the order is for */
  event?: Maybe<Event>
  /** First name */
  firstName?: Maybe<Scalars['String']['output']>
  /** invoice_appendix */
  invoiceAppendix?: Maybe<Scalars['String']['output']>
  /** invoice_number */
  invoiceNumber?: Maybe<Scalars['String']['output']>
  /** Last Name */
  lastName?: Maybe<Scalars['String']['output']>
  lastOrder?: Maybe<CheckoutOrder>
  lineItems?: Maybe<Array<CheckoutLineItem>>
  number?: Maybe<Scalars['String']['output']>
  /** Payment method */
  paymentMethod?: Maybe<Scalars['String']['output']>
  redirectUrl?: Maybe<Scalars['String']['output']>
  /** ??? */
  reference?: Maybe<Scalars['String']['output']>
  /** Salutation */
  salutation?: Maybe<Scalars['String']['output']>
  /** Street */
  street?: Maybe<Scalars['String']['output']>
  summary?: Maybe<Summary>
  /** Title */
  title?: Maybe<Scalars['String']['output']>
  /** Order type */
  type?: Maybe<OrderTypeEnum>
  /** VAT */
  vat?: Maybe<Scalars['Int']['output']>
  /** VAT Number */
  vatNumber?: Maybe<Scalars['String']['output']>
  /** Postal code */
  zip?: Maybe<Scalars['Int']['output']>
}

export type CheckoutQuery = {
  __typename?: 'CheckoutQuery'
  /** Order details and config */
  order: CheckoutOrder
}

export type CheckoutQueryOrderArgs = {
  afterPaymentToken: Scalars['String']['input']
}

/** Autogenerated input type of CloseAttendeeSelection */
export type CloseAttendeeSelectionInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>
  eventId: Scalars['ID']['input']
}

/** Autogenerated return type of CloseAttendeeSelection. */
export type CloseAttendeeSelectionPayload = {
  __typename?: 'CloseAttendeeSelectionPayload'
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>
  errors: Array<Scalars['String']['output']>
  event?: Maybe<Event>
}

export enum CollectionUpdateEnum {
  Add = 'add',
  Delete = 'delete',
}

export enum CompanyDepartmentEnum {
  Finance = 'finance',
  Hr = 'hr',
  It = 'it',
  Logistic = 'logistic',
  Management = 'management',
  Marketing = 'marketing',
  Other = 'other',
  Production = 'production',
  Research = 'research',
  Sales = 'sales',
  Service = 'service',
}

export type CompanyDepartments = {
  __typename?: 'CompanyDepartments'
  enum: CompanyDepartmentEnum
  name: Scalars['String']['output']
}

export type CompanyFields = {
  __typename?: 'CompanyFields'
  companyIndustryId: Scalars['Int']['output']
  id: Scalars['Int']['output']
  /** @deprecated Use company_industry_id */
  parentId: Scalars['Int']['output']
  translation: Scalars['String']['output']
}

export type CompanyIndustries = {
  __typename?: 'CompanyIndustries'
  id: Scalars['Int']['output']
  translation: Scalars['String']['output']
}

export enum CompanySizeEnum {
  MoreThan_0 = 'more_than_0',
  MoreThan_10_001 = 'more_than_10_001',
  MoreThan_11 = 'more_than_11',
  MoreThan_51 = 'more_than_51',
  MoreThan_100_000 = 'more_than_100_000',
  MoreThan_101 = 'more_than_101',
  MoreThan_201 = 'more_than_201',
  MoreThan_501 = 'more_than_501',
  MoreThan_1001 = 'more_than_1001',
  MoreThan_2001 = 'more_than_2001',
  MoreThan_5001 = 'more_than_5001',
}

export type CompanySizes = {
  __typename?: 'CompanySizes'
  enum: CompanySizeEnum
  name: Scalars['String']['output']
}

export enum CompanyTypeEnum {
  Advertiser = 'advertiser',
  Agency = 'agency',
  Brand = 'brand',
  Consulting = 'consulting',
  Influencer = 'influencer',
  Investor = 'investor',
  Other = 'other',
  PlatformNetwork = 'platform_network',
  Publisher = 'publisher',
  ServiceProvider = 'service_provider',
  SoftwareTool = 'software_tool',
  Vendor = 'vendor',
}

export type CompanyTypes = {
  __typename?: 'CompanyTypes'
  enum: CompanyTypeEnum
  name: Scalars['String']['output']
}

export type Contact = {
  __typename?: 'Contact'
  email?: Maybe<Scalars['String']['output']>
  firstname?: Maybe<Scalars['String']['output']>
  id: Scalars['ID']['output']
  name?: Maybe<Scalars['String']['output']>
  partnerCompany: PartnerCompany
  phone?: Maybe<Scalars['String']['output']>
  salutation?: Maybe<Scalars['String']['output']>
  title?: Maybe<Scalars['String']['output']>
  type: ContactTypeEnum
}

/** Attributes for creating or updating a contact */
export type ContactAttributes = {
  /** E-Mail */
  email?: InputMaybe<Scalars['String']['input']>
  /** First name */
  firstname?: InputMaybe<Scalars['String']['input']>
  /** Last name */
  name?: InputMaybe<Scalars['String']['input']>
  /** Phone */
  phone?: InputMaybe<Scalars['String']['input']>
  /** Salutation */
  salutation?: InputMaybe<Scalars['String']['input']>
  /** Title */
  title?: InputMaybe<Scalars['String']['input']>
  /** EventContact or SalesContact */
  type?: InputMaybe<Scalars['String']['input']>
}

/** Attributes for creating a contact transaction */
export type ContactTransactionAttributes = {
  /** Contact Transaction Type */
  contactTransactionType?: InputMaybe<ContactTransactionTypeEnum>
  /** Message */
  message?: InputMaybe<Scalars['String']['input']>
  /** Reviews product slug */
  reviewsProductSlug?: InputMaybe<Scalars['String']['input']>
  /** Sender Company */
  senderCompany?: InputMaybe<Scalars['String']['input']>
  /** Sender Company Position */
  senderCompanyPosition?: InputMaybe<Scalars['String']['input']>
  /** Sender Email */
  senderEmail?: InputMaybe<Scalars['String']['input']>
  /** Sender First Name */
  senderFirstname?: InputMaybe<Scalars['String']['input']>
  /** Sender Last Name */
  senderLastname?: InputMaybe<Scalars['String']['input']>
  /** Sender First Name */
  senderName?: InputMaybe<Scalars['String']['input']>
  /** Sender Phone */
  senderPhone?: InputMaybe<Scalars['String']['input']>
  /** Sender Website */
  senderWebsite?: InputMaybe<Scalars['String']['input']>
}

export enum ContactTransactionTypeEnum {
  ContactForm = 'contact_form',
  CustomQuote = 'custom_quote',
}

export enum ContactTypeEnum {
  EventContact = 'EventContact',
  SalesContact = 'SalesContact',
}

export type ContentStream = Node & {
  __typename?: 'ContentStream'
  descriptionDe?: Maybe<Scalars['String']['output']>
  descriptionEn?: Maybe<Scalars['String']['output']>
  event: Event
  id: Scalars['ID']['output']
  interests?: Maybe<Array<ActsAsTaggableOn>>
  name: Scalars['String']['output']
  slug?: Maybe<Scalars['String']['output']>
  titleDe?: Maybe<Scalars['String']['output']>
  titleEn?: Maybe<Scalars['String']['output']>
}

/** The connection type for ContentStream. */
export type ContentStreamConnection = {
  __typename?: 'ContentStreamConnection'
  /** A list of edges. */
  edges: Array<ContentStreamEdge>
  /** A list of nodes. */
  nodes: Array<ContentStream>
  /** Information to aid in pagination. */
  pageInfo: PageInfo
  totalCount: Scalars['Int']['output']
}

/** An edge in a connection. */
export type ContentStreamEdge = {
  __typename?: 'ContentStreamEdge'
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output']
  /** The item at the end of the edge. */
  node?: Maybe<ContentStream>
}

export type Country = {
  __typename?: 'Country'
  code: Scalars['String']['output']
  translation: Scalars['String']['output']
  translations: CountryTranslations
}

export type CountryTranslations = {
  __typename?: 'CountryTranslations'
  de: Scalars['String']['output']
  en: Scalars['String']['output']
}

/** Autogenerated input type of CreateAcknowledgement */
export type CreateAcknowledgementInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>
  documentId: Scalars['ID']['input']
  resourceId: Scalars['ID']['input']
}

/** Autogenerated return type of CreateAcknowledgement. */
export type CreateAcknowledgementPayload = {
  __typename?: 'CreateAcknowledgementPayload'
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>
  docAcknowledgement?: Maybe<DocAcknowledgement>
  errors?: Maybe<Array<Scalars['String']['output']>>
}

/** Autogenerated input type of CreateContact */
export type CreateContactInput = {
  attributes: ContactAttributes
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>
  partnerCompanyId: Scalars['ID']['input']
}

/** Autogenerated return type of CreateContact. */
export type CreateContactPayload = {
  __typename?: 'CreateContactPayload'
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>
  contact?: Maybe<Contact>
  errors: Array<Scalars['String']['output']>
}

/** Autogenerated input type of CreateContactTransaction */
export type CreateContactTransactionInput = {
  attributes: ContactTransactionAttributes
  captcha?: InputMaybe<CaptchaAttributes>
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>
  companySlug?: InputMaybe<Scalars['String']['input']>
  contactId?: InputMaybe<Scalars['String']['input']>
}

/** Autogenerated return type of CreateContactTransaction. */
export type CreateContactTransactionPayload = {
  __typename?: 'CreateContactTransactionPayload'
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>
  errors: Array<Scalars['String']['output']>
  success: Scalars['Boolean']['output']
}

/** Autogenerated input type of CreateDirectTicket */
export type CreateDirectTicketInput = {
  attributes: DirectTicketAttributes
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>
  invitationQuotaId: Scalars['ID']['input']
}

/** Autogenerated return type of CreateDirectTicket. */
export type CreateDirectTicketPayload = {
  __typename?: 'CreateDirectTicketPayload'
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>
  errors: Array<Scalars['String']['output']>
  ticket?: Maybe<Ticket>
}

/** Autogenerated input type of CreateEventSpeaker */
export type CreateEventSpeakerInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>
  eventId: Scalars['ID']['input']
  speakerId: Scalars['ID']['input']
}

/** Autogenerated return type of CreateEventSpeaker. */
export type CreateEventSpeakerPayload = {
  __typename?: 'CreateEventSpeakerPayload'
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>
  errors: Array<Scalars['String']['output']>
  eventSpeaker?: Maybe<EventSpeaker>
}

/** Autogenerated input type of CreateInboundContact */
export type CreateInboundContactInput = {
  attributes: InboundContactAttributes
  captcha?: InputMaybe<CaptchaAttributes>
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>
}

/** Autogenerated return type of CreateInboundContact. */
export type CreateInboundContactPayload = {
  __typename?: 'CreateInboundContactPayload'
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>
  errors: Array<Scalars['String']['output']>
  success: Scalars['Boolean']['output']
}

/** Autogenerated input type of CreateIndividualInvitees */
export type CreateIndividualInviteesInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>
  invitationQuotaId: Scalars['ID']['input']
  invitees: Array<InviteeInput>
}

/** Autogenerated return type of CreateIndividualInvitees. */
export type CreateIndividualInviteesPayload = {
  __typename?: 'CreateIndividualInviteesPayload'
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>
  errors?: Maybe<Array<Scalars['String']['output']>>
  invitationQuota?: Maybe<InvitationQuota>
}

/** Autogenerated input type of Create */
export type CreateInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>
  dueDateItemId: Scalars['ID']['input']
}

/** Autogenerated input type of CreateInternalAccessCheck */
export type CreateInternalAccessCheckInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>
  eventId: Scalars['ID']['input']
  ticketId: Scalars['ID']['input']
  viewingSessionId: Scalars['ID']['input']
}

/** Autogenerated return type of CreateInternalAccessCheck. */
export type CreateInternalAccessCheckPayload = {
  __typename?: 'CreateInternalAccessCheckPayload'
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>
  errors?: Maybe<Array<Scalars['String']['output']>>
  internalAccessCheck: InternalAccessCheck
}

/** Autogenerated input type of CreateLead */
export type CreateLeadInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>
  note?: InputMaybe<Scalars['String']['input']>
  scannedTicket: Scalars['String']['input']
  tags?: InputMaybe<Scalars['String']['input']>
}

/** Autogenerated return type of CreateLead. */
export type CreateLeadPayload = {
  __typename?: 'CreateLeadPayload'
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>
  errors: Array<Scalars['String']['output']>
  lead?: Maybe<Lead>
}

/** Autogenerated input type of CreateOrder */
export type CreateOrderInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>
  inviteeToken?: InputMaybe<Scalars['String']['input']>
  origin?: InputMaybe<Scalars['String']['input']>
  promoCode?: InputMaybe<Scalars['String']['input']>
  ticketCategories: Array<TicketCategoryInput>
  utmParams?: InputMaybe<Scalars['String']['input']>
}

/** Autogenerated return type of CreateOrder. */
export type CreateOrderPayload = {
  __typename?: 'CreateOrderPayload'
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>
  errors?: Maybe<Array<Scalars['String']['output']>>
  order?: Maybe<CheckoutOrder>
}

/** Autogenerated input type of CreatePartnerCompanyWithHubspotId */
export type CreatePartnerCompanyWithHubspotIdInput = {
  city?: InputMaybe<Scalars['String']['input']>
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>
  description?: InputMaybe<Scalars['String']['input']>
  hubspotId: Scalars['String']['input']
  name: Scalars['String']['input']
  slug?: InputMaybe<Scalars['String']['input']>
  street?: InputMaybe<Scalars['String']['input']>
  website?: InputMaybe<Scalars['String']['input']>
  zipcode?: InputMaybe<Scalars['String']['input']>
}

/** Autogenerated return type of CreatePartnerCompanyWithHubspotId. */
export type CreatePartnerCompanyWithHubspotIdPayload = {
  __typename?: 'CreatePartnerCompanyWithHubspotIdPayload'
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>
  errors: Array<Scalars['String']['output']>
  partnerCompany?: Maybe<PartnerCompany>
}

/** Autogenerated return type of Create. */
export type CreatePayload = {
  __typename?: 'CreatePayload'
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>
  dueDateItems: Array<DueDateItem>
  errors?: Maybe<Array<Scalars['String']['output']>>
  success: Scalars['Boolean']['output']
}

/** Autogenerated input type of CreateProspectItem */
export type CreateProspectItemInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>
  prospectListId: Scalars['ID']['input']
  token: Scalars['ID']['input']
}

/** Autogenerated return type of CreateProspectItem. */
export type CreateProspectItemPayload = {
  __typename?: 'CreateProspectItemPayload'
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>
  errors: Array<Scalars['String']['output']>
  success: Scalars['Boolean']['output']
}

/** Autogenerated input type of CreateProspectList */
export type CreateProspectListInput = {
  attributes: ProspectListAttributes
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>
  eventId: Scalars['ID']['input']
  partnerCompanyId: Scalars['ID']['input']
}

/** Autogenerated return type of CreateProspectList. */
export type CreateProspectListPayload = {
  __typename?: 'CreateProspectListPayload'
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>
  errors: Array<Scalars['String']['output']>
  prospectList?: Maybe<ProspectList>
}

/** Autogenerated input type of CreateReviewsManager */
export type CreateReviewsManagerInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>
  email: Scalars['String']['input']
  firstName?: InputMaybe<Scalars['String']['input']>
  lastName?: InputMaybe<Scalars['String']['input']>
  partnerCompanySlug: Scalars['String']['input']
  personalMessage?: InputMaybe<Scalars['String']['input']>
}

/** Autogenerated return type of CreateReviewsManager. */
export type CreateReviewsManagerPayload = {
  __typename?: 'CreateReviewsManagerPayload'
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>
  errors: Array<Scalars['String']['output']>
  user?: Maybe<User>
}

/** Autogenerated input type of CreateSpeaker */
export type CreateSpeakerInput = {
  attributes: SpeakerAttributes
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>
  partnerCompanyId: Scalars['ID']['input']
}

/** Autogenerated return type of CreateSpeaker. */
export type CreateSpeakerPayload = {
  __typename?: 'CreateSpeakerPayload'
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>
  errors: Array<Scalars['String']['output']>
  speaker?: Maybe<Speaker>
}

/** Autogenerated input type of CreateUser */
export type CreateUserInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>
  /** Email */
  email: Scalars['String']['input']
  personalMessage?: InputMaybe<Scalars['String']['input']>
  /** Role names */
  roleNames?: InputMaybe<Array<RoleNameEnum>>
}

/** Autogenerated return type of CreateUser. */
export type CreateUserPayload = {
  __typename?: 'CreateUserPayload'
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>
  errors: Array<Scalars['String']['output']>
  user?: Maybe<User>
}

/** Attributes for defining csv column to invitee attribute mapping */
export type CsvMappingAttributes = {
  cc?: InputMaybe<Scalars['Int']['input']>
  company?: InputMaybe<Scalars['Int']['input']>
  dateOfBirth?: InputMaybe<Scalars['Int']['input']>
  email: Scalars['Int']['input']
  externalId?: InputMaybe<Scalars['Int']['input']>
  firstName?: InputMaybe<Scalars['Int']['input']>
  gender?: InputMaybe<Scalars['Int']['input']>
  lastName?: InputMaybe<Scalars['Int']['input']>
  partnerName?: InputMaybe<Scalars['Int']['input']>
  partnerRole?: InputMaybe<Scalars['Int']['input']>
  phone?: InputMaybe<Scalars['Int']['input']>
  position?: InputMaybe<Scalars['Int']['input']>
  title?: InputMaybe<Scalars['Int']['input']>
}

export type CsvPreviewData = {
  __typename?: 'CsvPreviewData'
  index: Scalars['Int']['output']
  name: Scalars['String']['output']
  sample: Array<Scalars['String']['output']>
}

/** Autogenerated input type of DeactivateTicket */
export type DeactivateTicketInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>
  ticketId: Scalars['ID']['input']
}

/** Autogenerated return type of DeactivateTicket. */
export type DeactivateTicketPayload = {
  __typename?: 'DeactivateTicketPayload'
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>
  errors: Array<Scalars['String']['output']>
  ticket?: Maybe<Ticket>
}

/** Autogenerated input type of DeleteAcknowledgementUpload */
export type DeleteAcknowledgementUploadInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>
  docAcknowledgementId: Scalars['ID']['input']
  uploadUrl: Scalars['String']['input']
}

/** Autogenerated return type of DeleteAcknowledgementUpload. */
export type DeleteAcknowledgementUploadPayload = {
  __typename?: 'DeleteAcknowledgementUploadPayload'
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>
  docAcknowledgement?: Maybe<DocAcknowledgement>
  errors: Array<Scalars['String']['output']>
}

/** Autogenerated input type of DeleteEventSpeaker */
export type DeleteEventSpeakerInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>
  eventSpeakerId: Scalars['ID']['input']
}

/** Autogenerated return type of DeleteEventSpeaker. */
export type DeleteEventSpeakerPayload = {
  __typename?: 'DeleteEventSpeakerPayload'
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>
  errors?: Maybe<Array<Scalars['String']['output']>>
  success: Scalars['Boolean']['output']
}

/** Autogenerated input type of DeleteIndividualInvitees */
export type DeleteIndividualInviteesInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>
  emails: Array<Scalars['String']['input']>
  invitationQuotaId: Scalars['ID']['input']
}

/** Autogenerated return type of DeleteIndividualInvitees. */
export type DeleteIndividualInviteesPayload = {
  __typename?: 'DeleteIndividualInviteesPayload'
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>
  errors?: Maybe<Array<Scalars['String']['output']>>
  success: Scalars['Boolean']['output']
}

/** Autogenerated input type of Delete */
export type DeleteInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>
  dueDateItemId: Scalars['ID']['input']
}

/** Autogenerated return type of Delete. */
export type DeletePayload = {
  __typename?: 'DeletePayload'
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>
  dueDateItems: Array<DueDateItem>
  errors?: Maybe<Array<Scalars['String']['output']>>
  success: Scalars['Boolean']['output']
}

/** Autogenerated input type of DeleteProspectItem */
export type DeleteProspectItemInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>
  prospectListId: Scalars['ID']['input']
  token: Scalars['ID']['input']
}

/** Autogenerated return type of DeleteProspectItem. */
export type DeleteProspectItemPayload = {
  __typename?: 'DeleteProspectItemPayload'
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>
  errors: Array<Scalars['String']['output']>
  success: Scalars['Boolean']['output']
}

/** Autogenerated input type of DeleteProspectList */
export type DeleteProspectListInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>
  prospectListId: Scalars['ID']['input']
}

/** Autogenerated return type of DeleteProspectList. */
export type DeleteProspectListPayload = {
  __typename?: 'DeleteProspectListPayload'
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>
  errors: Array<Scalars['String']['output']>
}

/** Autogenerated input type of DeleteTicket */
export type DeleteTicketInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>
  ticketId: Scalars['ID']['input']
}

/** Autogenerated return type of DeleteTicket. */
export type DeleteTicketPayload = {
  __typename?: 'DeleteTicketPayload'
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>
  errors: Array<Scalars['String']['output']>
}

/** Autogenerated input type of DeleteUnsentInvitees */
export type DeleteUnsentInviteesInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>
  invitationQuotaId: Scalars['ID']['input']
}

/** Autogenerated return type of DeleteUnsentInvitees. */
export type DeleteUnsentInviteesPayload = {
  __typename?: 'DeleteUnsentInviteesPayload'
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>
  errors: Array<Scalars['String']['output']>
  success: Scalars['Boolean']['output']
}

/** Autogenerated input type of Deny */
export type DenyInput = {
  attendeeId: Scalars['ID']['input']
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>
}

/** Autogenerated return type of Deny. */
export type DenyPayload = {
  __typename?: 'DenyPayload'
  attendee?: Maybe<Attendee>
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>
  errors: Array<Scalars['String']['output']>
}

/** Autogenerated input type of DenyTicketRequest */
export type DenyTicketRequestInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>
  ticketId: Scalars['ID']['input']
}

/** Autogenerated return type of DenyTicketRequest. */
export type DenyTicketRequestPayload = {
  __typename?: 'DenyTicketRequestPayload'
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>
  errors: Array<Scalars['String']['output']>
}

export type DigitalMasterclass = Node & {
  __typename?: 'DigitalMasterclass'
  agendaFormatted?: Maybe<Scalars['String']['output']>
  attendeeCount: Scalars['Int']['output']
  attendeeSelectionClosed: Scalars['Boolean']['output']
  attendeeSelectionPaused: Scalars['Boolean']['output']
  attendees?: Maybe<AttendeeConnection>
  booths?: Maybe<BoothConnection>
  companyEmail?: Maybe<Scalars['String']['output']>
  companyLogoUrl?: Maybe<Scalars['String']['output']>
  companyName?: Maybe<Scalars['String']['output']>
  companyWebsite?: Maybe<Scalars['String']['output']>
  ctaButtonLink?: Maybe<Scalars['String']['output']>
  ctaButtonText?: Maybe<Scalars['String']['output']>
  ctaButtonTextDe?: Maybe<Scalars['String']['output']>
  ctaButtonTextEn?: Maybe<Scalars['String']['output']>
  description: Scalars['String']['output']
  descriptionDe?: Maybe<Scalars['String']['output']>
  descriptionEn?: Maybe<Scalars['String']['output']>
  duration?: Maybe<Scalars['String']['output']>
  durationInMinutes?: Maybe<Scalars['Int']['output']>
  enableCheckin: Scalars['Boolean']['output']
  endsAt: Scalars['ISO8601DateTime']['output']
  eventFormat?: Maybe<Scalars['String']['output']>
  featured: Scalars['Boolean']['output']
  id: Scalars['ID']['output']
  idHash: Scalars['String']['output']
  interests: Array<Interest>
  invitationQuotas?: Maybe<Array<InvitationQuota>>
  invitationQuotasCount: Scalars['Int']['output']
  language?: Maybe<LanguageEnum>
  leadCount: Scalars['Int']['output']
  livestreamUrl?: Maybe<Scalars['String']['output']>
  livestreamUrlWithToken?: Maybe<Scalars['String']['output']>
  location?: Maybe<Location>
  locationAddress?: Maybe<Scalars['String']['output']>
  locationName?: Maybe<Scalars['String']['output']>
  /** @deprecated Use website_background instead */
  logoUrl?: Maybe<Scalars['String']['output']>
  masterclassApplicationOpenUntil?: Maybe<Scalars['String']['output']>
  maxAttendees?: Maybe<Scalars['Int']['output']>
  minAttendees?: Maybe<Scalars['Int']['output']>
  name: Scalars['String']['output']
  nameDe?: Maybe<Scalars['String']['output']>
  nameEn?: Maybe<Scalars['String']['output']>
  partnerCompanies: Array<PartnerCompany>
  partnerCompanyId?: Maybe<Scalars['ID']['output']>
  partnerCompanyListing?: Maybe<Array<PartnerCompany>>
  presentationFormatted?: Maybe<Scalars['String']['output']>
  presentationRaw?: Maybe<Scalars['String']['output']>
  previewCardBackgroundUrl?: Maybe<Scalars['String']['output']>
  prospectSelectionUntil?: Maybe<Scalars['ISO8601DateTime']['output']>
  relatedOmrReviewsSlug?: Maybe<Scalars['String']['output']>
  reservedSeats: Scalars['Int']['output']
  shortName?: Maybe<Scalars['String']['output']>
  shuttleService?: Maybe<Scalars['String']['output']>
  skipBillingInfo?: Maybe<Scalars['Boolean']['output']>
  slug: Scalars['String']['output']
  speakers: Array<Speaker>
  stage?: Maybe<Stage>
  stageId?: Maybe<Scalars['ID']['output']>
  startsAt: Scalars['ISO8601DateTime']['output']
  streamingKey?: Maybe<Scalars['String']['output']>
  subtitle?: Maybe<Scalars['String']['output']>
  subtitleDe?: Maybe<Scalars['String']['output']>
  subtitleEn?: Maybe<Scalars['String']['output']>
  ticketCreationDeadlineHint?: Maybe<Scalars['ISO8601DateTime']['output']>
  ticketId: Scalars['ID']['output']
  ticketShop: Scalars['Boolean']['output']
  /** @deprecated title is deprecated, use name instead */
  title?: Maybe<Scalars['String']['output']>
  /** @deprecated title_de is deprecated, use name_de instead */
  titleDe?: Maybe<Scalars['String']['output']>
  /** @deprecated title_en is deprecated, use name_en instead */
  titleEn?: Maybe<Scalars['String']['output']>
  type?: Maybe<EventTypeEnum>
  videoFinalDuration: Scalars['Int']['output']
  videoFinalUrl: Scalars['String']['output']
  videoPresentationUrl?: Maybe<Scalars['String']['output']>
  videoRecordingUrl?: Maybe<Scalars['String']['output']>
  website?: Maybe<Scalars['String']['output']>
  websiteBackgroundUrl?: Maybe<Scalars['String']['output']>
}

export type DigitalMasterclassAttendeesArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
}

export type DigitalMasterclassBoothsArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
}

export type DigitalMasterclassPartnerCompanyListingArgs = {
  interests?: InputMaybe<Array<Scalars['String']['input']>>
  limit?: InputMaybe<Scalars['Int']['input']>
}

/** Attributes for creating or updating ticket from Crew or Exh Quotas */
export type DirectTicketAttributes = {
  birthdate?: InputMaybe<Scalars['ISO8601Date']['input']>
  companyDepartment?: InputMaybe<CompanyDepartmentEnum>
  companyFieldId?: InputMaybe<Scalars['Int']['input']>
  companyIndustryId?: InputMaybe<Scalars['Int']['input']>
  companyName: Scalars['String']['input']
  companySize?: InputMaybe<CompanySizeEnum>
  companyType?: InputMaybe<CompanyTypeEnum>
  country?: InputMaybe<Scalars['String']['input']>
  email: Scalars['String']['input']
  firstName: Scalars['String']['input']
  jobLevel?: InputMaybe<Scalars['String']['input']>
  lastName: Scalars['String']['input']
  partnerName?: InputMaybe<Scalars['String']['input']>
  partnerRole?: InputMaybe<Scalars['String']['input']>
  phone?: InputMaybe<Scalars['String']['input']>
  position: Scalars['String']['input']
  salutation: SalutationTypeEnum
  title?: InputMaybe<Scalars['String']['input']>
}

/** Autogenerated input type of Disconnect */
export type DisconnectInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>
  provider: OauthProviderEnum
}

/** Autogenerated return type of Disconnect. */
export type DisconnectPayload = {
  __typename?: 'DisconnectPayload'
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>
  errors?: Maybe<Array<Scalars['String']['output']>>
  me: User
  success: Scalars['Boolean']['output']
}

export type DocAcknowledgement = {
  __typename?: 'DocAcknowledgement'
  acknowledgedAt?: Maybe<Scalars['String']['output']>
  acknowledgedBy?: Maybe<Scalars['ID']['output']>
  document: Document
  id: Scalars['ID']['output']
  signableId: Scalars['ID']['output']
  signableType: Scalars['String']['output']
  uploadedAt?: Maybe<Scalars['String']['output']>
  uploadedBy?: Maybe<Scalars['ID']['output']>
  uploadsUrls: Array<Scalars['String']['output']>
}

/** The connection type for DocAcknowledgement. */
export type DocAcknowledgementConnection = {
  __typename?: 'DocAcknowledgementConnection'
  /** A list of edges. */
  edges: Array<DocAcknowledgementEdge>
  /** A list of nodes. */
  nodes: Array<DocAcknowledgement>
  /** Information to aid in pagination. */
  pageInfo: PageInfo
  totalCount: Scalars['Int']['output']
}

/** An edge in a connection. */
export type DocAcknowledgementEdge = {
  __typename?: 'DocAcknowledgementEdge'
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output']
  /** The item at the end of the edge. */
  node?: Maybe<DocAcknowledgement>
}

export type Document = Node & {
  __typename?: 'Document'
  description?: Maybe<Scalars['String']['output']>
  descriptionDe?: Maybe<Scalars['String']['output']>
  descriptionEn?: Maybe<Scalars['String']['output']>
  docAcknowledgements?: Maybe<Array<DocAcknowledgement>>
  documentUrl?: Maybe<Scalars['String']['output']>
  eventId: Scalars['ID']['output']
  id: Scalars['ID']['output']
  requireAcknowledge: Scalars['Boolean']['output']
  requireUpload: Scalars['Boolean']['output']
  title?: Maybe<Scalars['String']['output']>
  titleDe?: Maybe<Scalars['String']['output']>
  titleEn?: Maybe<Scalars['String']['output']>
}

/** The connection type for Document. */
export type DocumentConnection = {
  __typename?: 'DocumentConnection'
  /** A list of edges. */
  edges: Array<DocumentEdge>
  /** A list of nodes. */
  nodes: Array<Document>
  /** Information to aid in pagination. */
  pageInfo: PageInfo
  totalCount: Scalars['Int']['output']
}

/** An edge in a connection. */
export type DocumentEdge = {
  __typename?: 'DocumentEdge'
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output']
  /** The item at the end of the edge. */
  node?: Maybe<Document>
}

export type DueDateItem = Node & {
  __typename?: 'DueDateItem'
  /** Due date was marked as completed by partner company */
  completed: Scalars['Boolean']['output']
  /** Due At */
  dueAt?: Maybe<Scalars['ISO8601DateTime']['output']>
  id: Scalars['ID']['output']
  /** ID of the resource to update */
  resourceId?: Maybe<Scalars['ID']['output']>
  /** Resource type. Indicate what kind of action is required. */
  resourceType: Scalars['String']['output']
  /** German title */
  titleDe: Scalars['String']['output']
  /** English title */
  titleEn: Scalars['String']['output']
  /** Indicates if due date requirements were fulfilled */
  valid: Scalars['Boolean']['output']
}

/** Autogenerated input type of EditEmailTemplate */
export type EditEmailTemplateInput = {
  body: Scalars['String']['input']
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>
  emailCc?: InputMaybe<Scalars['String']['input']>
  invitationQuotaId: Scalars['ID']['input']
  replyTo: Scalars['String']['input']
  subject: Scalars['String']['input']
}

/** Autogenerated return type of EditEmailTemplate. */
export type EditEmailTemplatePayload = {
  __typename?: 'EditEmailTemplatePayload'
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>
  errors: Array<Scalars['String']['output']>
  invitationQuota?: Maybe<InvitationQuota>
}

export type EducationReportMatch = {
  __typename?: 'EducationReportMatch'
  description: Scalars['String']['output']
  highlight?: Maybe<Scalars['String']['output']>
  id: Scalars['String']['output']
  imageUrl?: Maybe<Scalars['String']['output']>
  maxPrice?: Maybe<Scalars['String']['output']>
  minPrice?: Maybe<Scalars['String']['output']>
  priceCurrency?: Maybe<Scalars['String']['output']>
  productType?: Maybe<Scalars['String']['output']>
  tags?: Maybe<Array<Scalars['String']['output']>>
  title: Scalars['String']['output']
  url: Scalars['String']['output']
}

export type EducationReportMatchPaginatedCollection = {
  __typename?: 'EducationReportMatchPaginatedCollection'
  nodes: Array<EducationReportMatch>
  pageInfo: OffsetPaginationInfo
}

export type EducationSeminarMatch = {
  __typename?: 'EducationSeminarMatch'
  description: Scalars['String']['output']
  highlight?: Maybe<Scalars['String']['output']>
  id: Scalars['String']['output']
  imageUrl?: Maybe<Scalars['String']['output']>
  maxPrice?: Maybe<Scalars['String']['output']>
  minPrice?: Maybe<Scalars['String']['output']>
  priceCurrency?: Maybe<Scalars['String']['output']>
  productType?: Maybe<Scalars['String']['output']>
  tags?: Maybe<Array<Scalars['String']['output']>>
  title: Scalars['String']['output']
  url: Scalars['String']['output']
}

export type EducationSeminarMatchPaginatedCollection = {
  __typename?: 'EducationSeminarMatchPaginatedCollection'
  nodes: Array<EducationSeminarMatch>
  pageInfo: OffsetPaginationInfo
}

export type Error = {
  __typename?: 'Error'
  ticketCategoryId?: Maybe<Scalars['String']['output']>
  type?: Maybe<Scalars['String']['output']>
}

export type Event = Node & {
  __typename?: 'Event'
  agendaFormatted?: Maybe<Scalars['String']['output']>
  attendeeCount: Scalars['Int']['output']
  attendeeSelectionClosed: Scalars['Boolean']['output']
  attendeeSelectionPaused: Scalars['Boolean']['output']
  attendees?: Maybe<AttendeeConnection>
  booths?: Maybe<BoothConnection>
  companyEmail?: Maybe<Scalars['String']['output']>
  companyLogoUrl?: Maybe<Scalars['String']['output']>
  companyName?: Maybe<Scalars['String']['output']>
  companyWebsite?: Maybe<Scalars['String']['output']>
  ctaButtonLink?: Maybe<Scalars['String']['output']>
  ctaButtonText?: Maybe<Scalars['String']['output']>
  ctaButtonTextDe?: Maybe<Scalars['String']['output']>
  ctaButtonTextEn?: Maybe<Scalars['String']['output']>
  description?: Maybe<Scalars['String']['output']>
  descriptionDe?: Maybe<Scalars['String']['output']>
  descriptionEn?: Maybe<Scalars['String']['output']>
  duration?: Maybe<Scalars['String']['output']>
  durationInMinutes?: Maybe<Scalars['Int']['output']>
  enableCheckin: Scalars['Boolean']['output']
  endsAt?: Maybe<Scalars['ISO8601DateTime']['output']>
  eventFormat?: Maybe<Scalars['String']['output']>
  featured: Scalars['Boolean']['output']
  id: Scalars['ID']['output']
  interests: Array<Interest>
  invitationQuotas?: Maybe<Array<InvitationQuota>>
  invitationQuotasCount: Scalars['Int']['output']
  language?: Maybe<LanguageEnum>
  leadCount: Scalars['Int']['output']
  livestreamUrl?: Maybe<Scalars['String']['output']>
  livestreamUrlWithToken?: Maybe<Scalars['String']['output']>
  location?: Maybe<Location>
  locationAddress?: Maybe<Scalars['String']['output']>
  locationName?: Maybe<Scalars['String']['output']>
  /** @deprecated Use website_background instead */
  logoUrl?: Maybe<Scalars['String']['output']>
  masterclassApplicationOpenUntil?: Maybe<Scalars['String']['output']>
  maxAttendees?: Maybe<Scalars['Int']['output']>
  minAttendees?: Maybe<Scalars['Int']['output']>
  name?: Maybe<Scalars['String']['output']>
  nameDe?: Maybe<Scalars['String']['output']>
  nameEn?: Maybe<Scalars['String']['output']>
  partnerCompanies: Array<PartnerCompany>
  partnerCompanyId?: Maybe<Scalars['ID']['output']>
  partnerCompanyListing?: Maybe<Array<PartnerCompany>>
  presentationFormatted?: Maybe<Scalars['String']['output']>
  presentationRaw?: Maybe<Scalars['String']['output']>
  previewCardBackgroundUrl?: Maybe<Scalars['String']['output']>
  prospectSelectionUntil?: Maybe<Scalars['ISO8601DateTime']['output']>
  relatedOmrReviewsSlug?: Maybe<Scalars['String']['output']>
  reservedSeats: Scalars['Int']['output']
  shortName?: Maybe<Scalars['String']['output']>
  shuttleService?: Maybe<Scalars['String']['output']>
  skipBillingInfo?: Maybe<Scalars['Boolean']['output']>
  slug?: Maybe<Scalars['String']['output']>
  speakers: Array<Speaker>
  stage?: Maybe<Stage>
  stageId?: Maybe<Scalars['ID']['output']>
  startsAt?: Maybe<Scalars['ISO8601DateTime']['output']>
  streamingKey?: Maybe<Scalars['String']['output']>
  subtitle?: Maybe<Scalars['String']['output']>
  subtitleDe?: Maybe<Scalars['String']['output']>
  subtitleEn?: Maybe<Scalars['String']['output']>
  ticketCreationDeadlineHint?: Maybe<Scalars['ISO8601DateTime']['output']>
  ticketShop: Scalars['Boolean']['output']
  /** @deprecated title is deprecated, use name instead */
  title?: Maybe<Scalars['String']['output']>
  /** @deprecated title_de is deprecated, use name_de instead */
  titleDe?: Maybe<Scalars['String']['output']>
  /** @deprecated title_en is deprecated, use name_en instead */
  titleEn?: Maybe<Scalars['String']['output']>
  type?: Maybe<EventTypeEnum>
  videoPresentationUrl?: Maybe<Scalars['String']['output']>
  videoRecordingUrl?: Maybe<Scalars['String']['output']>
  website?: Maybe<Scalars['String']['output']>
  websiteBackgroundUrl?: Maybe<Scalars['String']['output']>
}

export type EventAttendeesArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
}

export type EventBoothsArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
}

export type EventPartnerCompanyListingArgs = {
  interests?: InputMaybe<Array<Scalars['String']['input']>>
  limit?: InputMaybe<Scalars['Int']['input']>
}

export type EventApplication = {
  __typename?: 'EventApplication'
  aasmState: AttendeeAasmStateEnum
  addedViaManage: Scalars['Boolean']['output']
  event: PublishedEvent
  id: Scalars['ID']['output']
}

export enum EventApplicationPhaseEnum {
  Application = 'application',
  Fcfs = 'fcfs',
  Finished = 'finished',
  Lead = 'lead',
}

/** Attributes for creating or updating an event */
export type EventAttributes = {
  /** agenda timestamps json */
  agendaFormatted?: InputMaybe<Scalars['String']['input']>
  /** agenda timestamps for topics */
  agendaRaw?: InputMaybe<Scalars['String']['input']>
  /** Events company email */
  companyEmail?: InputMaybe<Scalars['String']['input']>
  /** Events company name */
  companyName?: InputMaybe<Scalars['String']['input']>
  /** Events company website */
  companyWebsite?: InputMaybe<Scalars['String']['input']>
  /** Event cta link */
  ctaButtonLink?: InputMaybe<Scalars['String']['input']>
  /** Event cta text */
  ctaButtonText?: InputMaybe<Scalars['String']['input']>
  /** Event description (DE) */
  descriptionDe?: InputMaybe<Scalars['String']['input']>
  /** Event description (EN) */
  descriptionEn?: InputMaybe<Scalars['String']['input']>
  /** Event duration */
  duration?: InputMaybe<Scalars['String']['input']>
  /** Event ends at */
  endsAt?: InputMaybe<Scalars['ISO8601DateTime']['input']>
  /** Event format */
  eventFormat?: InputMaybe<Scalars['String']['input']>
  /** Interests */
  interests?: InputMaybe<Array<Scalars['ID']['input']>>
  /** Event language */
  language?: InputMaybe<Scalars['String']['input']>
  /** Event location address override */
  locationAddress?: InputMaybe<Scalars['String']['input']>
  /** Event location override */
  locationName?: InputMaybe<Scalars['String']['input']>
  /** Event max attendees */
  maxAttendees?: InputMaybe<Scalars['Int']['input']>
  /** Event name */
  nameDe?: InputMaybe<Scalars['String']['input']>
  /** Event name */
  nameEn?: InputMaybe<Scalars['String']['input']>
  /** presentation timestamps json */
  presentationFormatted?: InputMaybe<Scalars['String']['input']>
  /** presentation timestamps */
  presentationRaw?: InputMaybe<Scalars['String']['input']>
  /** Shuttle Service */
  shuttleService?: InputMaybe<Scalars['Boolean']['input']>
  /** Event starts at */
  startsAt?: InputMaybe<Scalars['ISO8601DateTime']['input']>
  /** Streaming key */
  streamingKey?: InputMaybe<Scalars['String']['input']>
  /** Event subtitle (DE) */
  subtitleDe?: InputMaybe<Scalars['String']['input']>
  /** Event subtitle (EN) */
  subtitleEn?: InputMaybe<Scalars['String']['input']>
  /** processed video file */
  videoFinal?: InputMaybe<Scalars['String']['input']>
  /** video file for presentation */
  videoPresentation?: InputMaybe<Scalars['Upload']['input']>
  /** video file for speaker recording */
  videoRecording?: InputMaybe<Scalars['Upload']['input']>
  websiteBackground?: InputMaybe<Scalars['Upload']['input']>
}

/** The connection type for Event. */
export type EventConnectionWithTotalCount = {
  __typename?: 'EventConnectionWithTotalCount'
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<EventEdge>>>
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<Event>>>
  /** Information to aid in pagination. */
  pageInfo: PageInfo
  totalCount: Scalars['Int']['output']
}

/** An edge in a connection. */
export type EventEdge = {
  __typename?: 'EventEdge'
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output']
  /** The item at the end of the edge. */
  node?: Maybe<Event>
}

export type EventNotification = {
  __typename?: 'EventNotification'
  id: Scalars['ID']['output']
  message: Scalars['String']['output']
  title: Scalars['String']['output']
}

export enum EventPeriodEnum {
  Past = 'past',
  Upcoming = 'upcoming',
}

export type EventSpeaker = Node & {
  __typename?: 'EventSpeaker'
  event: Event
  id: Scalars['ID']['output']
  speaker: Speaker
}

export type EventStarringsUpdate = {
  eventId: Scalars['ID']['input']
  type: CollectionUpdateEnum
}

export enum EventTypeEnum {
  BreakEvent = 'BreakEvent',
  CareerMeetup = 'CareerMeetup',
  DeepDive = 'DeepDive',
  DigitalMasterclass = 'DigitalMasterclass',
  Event = 'Event',
  GuidedTour = 'GuidedTour',
  Masterclass = 'Masterclass',
  SideEvent = 'SideEvent',
  StageSlot = 'StageSlot',
}

export type EventsForApplication = {
  __typename?: 'EventsForApplication'
  applicationPhase?: Maybe<EventApplicationPhaseEnum>
  eventTypes: Array<BucketEventTypeEnum>
  events: Array<PublishedEvent>
  maxApplications: Scalars['Int']['output']
  withinPeriod: Scalars['Boolean']['output']
}

/** Autogenerated input type of ExportLeads */
export type ExportLeadsInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>
  email: Scalars['String']['input']
  eventSlug: Scalars['String']['input']
}

/** Autogenerated return type of ExportLeads. */
export type ExportLeadsPayload = {
  __typename?: 'ExportLeadsPayload'
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>
  errors: Array<Scalars['String']['output']>
  success: Scalars['Boolean']['output']
}

export type Extra = {
  __typename?: 'Extra'
  comment?: Maybe<Scalars['String']['output']>
  count: Scalars['Int']['output']
  deliveryFee?: Maybe<Scalars['Float']['output']>
  fileName?: Maybe<Scalars['String']['output']>
  fileUrl?: Maybe<Scalars['String']['output']>
  price: Scalars['Float']['output']
  productId: Scalars['ID']['output']
  selectedVariantId: Scalars['ID']['output']
}

/** Attributes for creating or updating extras */
export type ExtraAttributes = {
  comment?: InputMaybe<Scalars['String']['input']>
  count: Scalars['Int']['input']
  file?: InputMaybe<Scalars['Upload']['input']>
  productId: Scalars['ID']['input']
  variantId: Scalars['ID']['input']
}

export type Facet = {
  __typename?: 'Facet'
  id: Scalars['ID']['output']
  name: Scalars['String']['output']
}

export type Field = {
  __typename?: 'Field'
  description?: Maybe<Scalars['String']['output']>
  descriptionDe?: Maybe<Scalars['String']['output']>
  descriptionEn?: Maybe<Scalars['String']['output']>
  getId?: Maybe<Scalars['String']['output']>
  id: Scalars['ID']['output']
  key?: Maybe<Scalars['String']['output']>
  name?: Maybe<Scalars['String']['output']>
}

export type FieldCategory = {
  __typename?: 'FieldCategory'
  getBadgeColor?: Maybe<Scalars['String']['output']>
  getWristbandColor?: Maybe<Scalars['String']['output']>
  id: Scalars['ID']['output']
  key: FieldCategoryEnum
  name: Scalars['String']['output']
}

export enum FieldCategoryEnum {
  Construction = 'construction',
  Crew = 'crew',
  Exhibitor = 'exhibitor',
  Guest = 'guest',
  GuestFfwd = 'guest_ffwd',
  Press = 'press',
  PressFfwd = 'press_ffwd',
  Speaker = 'speaker',
}

export enum GenderEnum {
  Female = 'female',
  Male = 'male',
  Other = 'other',
}

export type GeojsonMaps = {
  __typename?: 'GeojsonMaps'
  eventSlug: Scalars['String']['output']
  features: Scalars['JSON']['output']
  id: Scalars['ID']['output']
  revisionId: Scalars['ID']['output']
}

export type HygraphAuthor = {
  __typename?: 'HygraphAuthor'
  name: Scalars['String']['output']
}

/** Autogenerated input type of ImportInvitees */
export type ImportInviteesInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>
  csvUploadId: Scalars['ID']['input']
  hasHeaders: Scalars['Boolean']['input']
  invitationQuotaId: Scalars['ID']['input']
  mapping: CsvMappingAttributes
}

/** Autogenerated return type of ImportInvitees. */
export type ImportInviteesPayload = {
  __typename?: 'ImportInviteesPayload'
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>
  errors: Array<Scalars['String']['output']>
  success: Scalars['Boolean']['output']
  withErrorsCount: Scalars['Int']['output']
  withoutErrorsCount: Scalars['Int']['output']
}

/** Attributes for creating an inbound contact */
export type InboundContactAttributes = {
  /** Company */
  company: Scalars['String']['input']
  /** Email */
  email: Scalars['String']['input']
  /** First Name */
  firstname: Scalars['String']['input']
  /** How did you hear about us? */
  howDidYouHearAboutUs?: InputMaybe<SourceEnum>
  /** Last Name */
  lastname: Scalars['String']['input']
  /** Message */
  message?: InputMaybe<Scalars['String']['input']>
  /** Phone */
  phone?: InputMaybe<Scalars['String']['input']>
  /** Website */
  position?: InputMaybe<Scalars['String']['input']>
  /** What would you like to talk to us about? */
  whatWouldYouLikeToTalkToUsAbout: InterestEnum
}

export type Interest = Node & {
  __typename?: 'Interest'
  id: Scalars['ID']['output']
  name: Scalars['String']['output']
  slug: Scalars['String']['output']
  /** @deprecated deprecated, use name instead */
  text: Scalars['String']['output']
}

/** The connection type for Interest. */
export type InterestConnection = {
  __typename?: 'InterestConnection'
  /** A list of edges. */
  edges: Array<InterestEdge>
  /** A list of nodes. */
  nodes: Array<Interest>
  /** Information to aid in pagination. */
  pageInfo: PageInfo
  totalCount: Scalars['Int']['output']
}

/** An edge in a connection. */
export type InterestEdge = {
  __typename?: 'InterestEdge'
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output']
  /** The item at the end of the edge. */
  node?: Maybe<Interest>
}

export enum InterestEnum {
  Branding = 'branding',
  ContentPartnership = 'content_partnership',
  DigitalMasterclass = 'digital_masterclass',
  FestivalBooth = 'festival_booth',
  IndividualRequest = 'individual_request',
  Masterclass = 'masterclass',
  PartnershipInGeneral = 'partnership_in_general',
}

export type InterestsUpdate = {
  interestId: Scalars['ID']['input']
  type: CollectionUpdateEnum
}

export type InternalAccessCheck = {
  __typename?: 'InternalAccessCheck'
  id: Scalars['ID']['output']
  ticket: Ticket
  user: User
  viewingSessionId: Scalars['ID']['output']
}

export type InvitationQuota = Node & {
  __typename?: 'InvitationQuota'
  activatedTicketsCount?: Maybe<Scalars['Int']['output']>
  /** @deprecated deprecated */
  adminDescription?: Maybe<Scalars['String']['output']>
  allowUsageOfSameAddress: Scalars['Boolean']['output']
  cc?: Maybe<Scalars['String']['output']>
  createdAt: Scalars['String']['output']
  descriptionDe?: Maybe<Scalars['String']['output']>
  descriptionEn?: Maybe<Scalars['String']['output']>
  discount: Scalars['Int']['output']
  doNotSendOutTicketEmail: Scalars['Boolean']['output']
  event?: Maybe<Event>
  expirationDate?: Maybe<Scalars['ISO8601DateTime']['output']>
  fields: Array<Field>
  id: Scalars['ID']['output']
  inviteeTracking?: Maybe<InviteeTracking>
  invitees?: Maybe<InviteeConnection>
  kind: InvitationQuotaTypeEnum
  maxUsable: Scalars['Int']['output']
  messageBody?: Maybe<Scalars['String']['output']>
  messageSubject?: Maybe<Scalars['String']['output']>
  partnerCompany?: Maybe<PartnerCompany>
  promoCodes?: Maybe<Array<PromoCode>>
  replyTo?: Maybe<Scalars['String']['output']>
  showFormLink: Scalars['Boolean']['output']
  ticketCategory?: Maybe<TicketCategory>
  ticketsCount?: Maybe<Scalars['Int']['output']>
  title?: Maybe<Scalars['String']['output']>
  usableTicketsCount?: Maybe<Scalars['Int']['output']>
}

export type InvitationQuotaInviteesArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
}

/** The connection type for InvitationQuota. */
export type InvitationQuotaConnection = {
  __typename?: 'InvitationQuotaConnection'
  /** A list of edges. */
  edges: Array<InvitationQuotaEdge>
  /** A list of nodes. */
  nodes: Array<InvitationQuota>
  /** Information to aid in pagination. */
  pageInfo: PageInfo
  totalCount: Scalars['Int']['output']
}

/** An edge in a connection. */
export type InvitationQuotaEdge = {
  __typename?: 'InvitationQuotaEdge'
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output']
  /** The item at the end of the edge. */
  node?: Maybe<InvitationQuota>
}

export enum InvitationQuotaTypeEnum {
  CodeQuota = 'CodeQuota',
  CrewQuota = 'CrewQuota',
  ExhibitorQuota = 'ExhibitorQuota',
  GuestQuota = 'GuestQuota',
  PressQuota = 'PressQuota',
}

export type Invitee = {
  __typename?: 'Invitee'
  acceptedAt?: Maybe<Scalars['ISO8601DateTime']['output']>
  activated: Scalars['Boolean']['output']
  bounceDescription?: Maybe<Scalars['String']['output']>
  bouncedAt?: Maybe<Scalars['ISO8601DateTime']['output']>
  cc?: Maybe<Scalars['String']['output']>
  company?: Maybe<Scalars['String']['output']>
  createdAt: Scalars['ISO8601DateTime']['output']
  declinedAt?: Maybe<Scalars['ISO8601DateTime']['output']>
  email: Scalars['String']['output']
  externalId?: Maybe<Scalars['String']['output']>
  firstName?: Maybe<Scalars['String']['output']>
  gender?: Maybe<GenderEnum>
  lastName?: Maybe<Scalars['String']['output']>
  openedAt?: Maybe<Scalars['ISO8601DateTime']['output']>
  sentAt?: Maybe<Scalars['ISO8601DateTime']['output']>
  sentCount: Scalars['Int']['output']
  status: InviteeStatusEnum
  title?: Maybe<Scalars['String']['output']>
  token: Scalars['ID']['output']
}

/** The connection type for Invitee. */
export type InviteeConnection = {
  __typename?: 'InviteeConnection'
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<InviteeEdge>>>
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<Invitee>>>
  /** Information to aid in pagination. */
  pageInfo: PageInfo
  totalCount: Scalars['Int']['output']
}

/** An edge in a connection. */
export type InviteeEdge = {
  __typename?: 'InviteeEdge'
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output']
  /** The item at the end of the edge. */
  node?: Maybe<Invitee>
}

/** Input type for creating manual invitees */
export type InviteeInput = {
  /** Company */
  company?: InputMaybe<Scalars['String']['input']>
  /** Email */
  email: Scalars['String']['input']
  /** First name */
  firstName: Scalars['String']['input']
  /** Gender */
  gender?: InputMaybe<GenderEnum>
  /** Last name */
  lastName: Scalars['String']['input']
  /** Title */
  title?: InputMaybe<Scalars['String']['input']>
}

export type InviteePaginatedCollection = {
  __typename?: 'InviteePaginatedCollection'
  nodes: Array<Invitee>
  pageInfo: OffsetPaginationInfo
}

export enum InviteeStatusEnum {
  Accepted = 'accepted',
  Bounced = 'bounced',
  Declined = 'declined',
  Draft = 'draft',
  Opened = 'opened',
  Purchased = 'purchased',
  Sending = 'sending',
  Sent = 'sent',
}

export type InviteeTracking = {
  __typename?: 'InviteeTracking'
  acceptedCount: Scalars['Int']['output']
  declinedCount: Scalars['Int']['output']
  openedCount: Scalars['Int']['output']
  purchasedCount: Scalars['Int']['output']
  sentCount: Scalars['Int']['output']
  totalCount: Scalars['Int']['output']
}

export type Invoice = {
  __typename?: 'Invoice'
  createdAt: Scalars['ISO8601DateTime']['output']
  id: Scalars['ID']['output']
  invoiceUrl: Scalars['String']['output']
  number: Scalars['String']['output']
  transactionType: TransactionTypeEnum
}

export enum JobLevelEnum {
  BoardMember = 'board_member',
  CLevel = 'c_level',
  Ceo = 'ceo',
  Cfo = 'cfo',
  Cmo = 'cmo',
  Coo = 'coo',
  Cto = 'cto',
  Director = 'director',
  Entry = 'entry',
  HeadOf = 'head_of',
  Manager = 'manager',
  Other = 'other',
  Senior = 'senior',
  Student = 'student',
}

export type JobLevels = {
  __typename?: 'JobLevels'
  enum: JobLevelEnum
  name: Scalars['String']['output']
}

export type JobMatch = {
  __typename?: 'JobMatch'
  companyLogoThumb?: Maybe<Scalars['String']['output']>
  companyName: Scalars['String']['output']
  highlight?: Maybe<Scalars['String']['output']>
  id: Scalars['ID']['output']
  locations: Array<Scalars['String']['output']>
  publishedAt: Scalars['ISO8601DateTime']['output']
  slug: Scalars['String']['output']
  title: Scalars['String']['output']
  url: Scalars['String']['output']
}

export type JobMatchPaginatedCollection = {
  __typename?: 'JobMatchPaginatedCollection'
  nodes: Array<JobMatch>
  pageInfo: OffsetPaginationInfo
}

export enum JobsCrewProfessionalEnum {
  BakerAndPattesian = 'baker_and_pattesian',
  Barista = 'barista',
  Concierge = 'concierge',
  ContentCreation = 'content_creation',
  Cooking = 'cooking',
  FoodtruckManagement = 'foodtruck_management',
  ForkliftDriver = 'forklift_driver',
  InitiativeApplication = 'initiative_application',
  KitchenAssist = 'kitchen_assist',
  Logistics = 'logistics',
  ShuttleDriver = 'shuttle_driver',
  TruckDriver = 'truck_driver',
}

export type JsonLd = {
  __typename?: 'JsonLd'
  id: Scalars['ID']['output']
  json: Scalars['String']['output']
}

export type JwtMasterclass = {
  __typename?: 'JwtMasterclass'
  masterclass: DigitalMasterclass
  ticket: Ticket
}

export enum LanguageEnum {
  De = 'de',
  En = 'en',
}

export type Lead = {
  __typename?: 'Lead'
  id: Scalars['ID']['output']
  leadLinkId: Scalars['ID']['output']
  leadLinkType: Scalars['String']['output']
  note?: Maybe<Scalars['String']['output']>
  scannedCompanyName?: Maybe<Scalars['String']['output']>
  scannedEmail?: Maybe<Scalars['String']['output']>
  scannedFirstName?: Maybe<Scalars['String']['output']>
  scannedLastName?: Maybe<Scalars['String']['output']>
  scannedTicketIdentifier: Scalars['String']['output']
  tags?: Maybe<Array<Scalars['String']['output']>>
}

/** Attributes for creating or updating leads through syncing */
export type LeadAttributes = {
  note?: InputMaybe<Scalars['String']['input']>
  scannedTicket: Scalars['String']['input']
  tags?: InputMaybe<Scalars['String']['input']>
}

/** The connection type for Lead. */
export type LeadConnection = {
  __typename?: 'LeadConnection'
  /** A list of edges. */
  edges: Array<LeadEdge>
  /** A list of nodes. */
  nodes: Array<Lead>
  /** Information to aid in pagination. */
  pageInfo: PageInfo
  totalCount: Scalars['Int']['output']
}

/** An edge in a connection. */
export type LeadEdge = {
  __typename?: 'LeadEdge'
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output']
  /** The item at the end of the edge. */
  node?: Maybe<Lead>
}

export type LeadGenLimitConfig = Node & {
  __typename?: 'LeadGenLimitConfig'
  event: Event
  id: Scalars['ID']['output']
  limit?: Maybe<Scalars['Int']['output']>
  partnerCompany: PartnerCompany
}

export type LineItem = {
  __typename?: 'LineItem'
  net: Scalars['Int']['output']
  quantity: Scalars['Int']['output']
  ticketCategoryId: Scalars['String']['output']
}

export enum LineItemTypeEnum {
  Goods = 'goods',
  Voucher = 'voucher',
}

export enum LocaleEnum {
  De = 'de',
  En = 'en',
}

export type Location = Node & {
  __typename?: 'Location'
  city?: Maybe<Scalars['String']['output']>
  country?: Maybe<Scalars['String']['output']>
  id: Scalars['ID']['output']
  mapsUrl?: Maybe<Scalars['String']['output']>
  name?: Maybe<Scalars['String']['output']>
  slug?: Maybe<Scalars['String']['output']>
  street?: Maybe<Scalars['String']['output']>
  zipcode?: Maybe<Scalars['String']['output']>
}

/** Autogenerated input type of ManuallyMarkAsSent */
export type ManuallyMarkAsSentInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>
  promoCodeId: Scalars['ID']['input']
}

/** Autogenerated return type of ManuallyMarkAsSent. */
export type ManuallyMarkAsSentPayload = {
  __typename?: 'ManuallyMarkAsSentPayload'
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>
  errors: Array<Scalars['String']['output']>
  promoCode?: Maybe<PromoCode>
}

/** Autogenerated input type of ManuallyMarkAsUnsent */
export type ManuallyMarkAsUnsentInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>
  promoCodeId: Scalars['ID']['input']
}

/** Autogenerated return type of ManuallyMarkAsUnsent. */
export type ManuallyMarkAsUnsentPayload = {
  __typename?: 'ManuallyMarkAsUnsentPayload'
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>
  errors: Array<Scalars['String']['output']>
  promoCode?: Maybe<PromoCode>
}

export enum MerchSizeEnum {
  L = 'L',
  M = 'M',
  S = 'S',
  Xl = 'XL',
  Xs = 'XS',
  Xxl = 'XXL',
  Xxxl = 'XXXL',
  Xxxxl = 'XXXXL',
}

export type Mutation = {
  __typename?: 'Mutation'
  acceptAttendee?: Maybe<AcceptPayload>
  acceptTicketRequest?: Maybe<AcceptTicketRequestPayload>
  activateTicket?: Maybe<ActivateTicketPayload>
  addAttendeesByTicketIdentifier?: Maybe<AddAttendeesByTicketIdentifierPayload>
  closeAttendeeSelection?: Maybe<CloseAttendeeSelectionPayload>
  createAcknowledgement?: Maybe<CreateAcknowledgementPayload>
  createContact?: Maybe<CreateContactPayload>
  createContactTransaction?: Maybe<CreateContactTransactionPayload>
  createDirectTicket?: Maybe<CreateDirectTicketPayload>
  createDueDateCompletion?: Maybe<CreatePayload>
  createEventApplications?: Maybe<ApplyPayload>
  createEventSpeaker?: Maybe<CreateEventSpeakerPayload>
  createIndividualInvitees?: Maybe<CreateIndividualInviteesPayload>
  createInternalAccessCheck?: Maybe<CreateInternalAccessCheckPayload>
  createLead?: Maybe<CreateLeadPayload>
  createOrder?: Maybe<CreateOrderPayload>
  createPartnerCompanyWithHubspotId?: Maybe<CreatePartnerCompanyWithHubspotIdPayload>
  createProspectItem?: Maybe<CreateProspectItemPayload>
  createProspectList?: Maybe<CreateProspectListPayload>
  createReviewsManager?: Maybe<CreateReviewsManagerPayload>
  createSpeaker?: Maybe<CreateSpeakerPayload>
  createUser?: Maybe<CreateUserPayload>
  deactivateTicket?: Maybe<DeactivateTicketPayload>
  deleteAcknowledgementUpload?: Maybe<DeleteAcknowledgementUploadPayload>
  deleteDueDateCompletion?: Maybe<DeletePayload>
  deleteEventSpeaker?: Maybe<DeleteEventSpeakerPayload>
  deleteIndividualInvitees?: Maybe<DeleteIndividualInviteesPayload>
  deleteProspectItem?: Maybe<DeleteProspectItemPayload>
  deleteProspectList?: Maybe<DeleteProspectListPayload>
  deleteTicket?: Maybe<DeleteTicketPayload>
  deleteUnsentInvitees?: Maybe<DeleteUnsentInviteesPayload>
  denyAttendee?: Maybe<DenyPayload>
  denyTicketRequest?: Maybe<DenyTicketRequestPayload>
  disconnectOauthProvider?: Maybe<DisconnectPayload>
  editEmailTemplate?: Maybe<EditEmailTemplatePayload>
  exportLeads?: Maybe<ExportLeadsPayload>
  importInvitees?: Maybe<ImportInviteesPayload>
  inboundContact?: Maybe<CreateInboundContactPayload>
  manuallyMarkAsSent?: Maybe<ManuallyMarkAsSentPayload>
  manuallyMarkAsUnsent?: Maybe<ManuallyMarkAsUnsentPayload>
  placeCheckoutOrder?: Maybe<PlaceOrderPayload>
  resendConfirmationInstructions?: Maybe<ResendConfirmationInstructionsPayload>
  resendTicketMail?: Maybe<ResendTicketMailPayload>
  retractVolunteer: Volunteer
  sendIndividualInvitations?: Maybe<SendIndividualInvitationsPayload>
  sendInvitations?: Maybe<SendInvitationsPayload>
  sendProspectListInvitations?: Maybe<SendProspectListInvitationsPayload>
  sendTestEmail?: Maybe<SendTestEmailPayload>
  sendTestProspectInvitation?: Maybe<SendTestInvitationPayload>
  setPaymentInfo?: Maybe<SetPaymentInfoPayload>
  submitProspectList?: Maybe<SubmitProspectListPayload>
  submitVolunteer: Volunteer
  syncLeads?: Maybe<UpsertLeadsPayload>
  updateAcknowledgement?: Maybe<UpdateAcknowledgementPayload>
  updateAttendeesStateFromCsv?: Maybe<UpdateAttendeesStateFromCsvPayload>
  updateBillingAddress?: Maybe<UpdateBillingAddressPayload>
  updateBooth?: Maybe<UpdateBoothPayload>
  updateBoothExtras?: Maybe<UpdateExtrasPayload>
  updateCheckoutOrder?: Maybe<UpdateOrderPayload>
  updateContact?: Maybe<UpdateContactPayload>
  updateDirectTicket?: Maybe<UpdateDirectTicketPayload>
  updateEvent?: Maybe<UpdateEventPayload>
  updateEventStarrings?: Maybe<UpdateEventStarringsPayload>
  updateInterests?: Maybe<UpdateInterestsPayload>
  updateLead?: Maybe<UpdateLeadPayload>
  updateMe?: Maybe<UpdateMePayload>
  updatePartnerCompany?: Maybe<UpdatePartnerCompanyPayload>
  updateProspectList?: Maybe<UpdateProspectListPayload>
  updateSpeaker?: Maybe<UpdateSpeakerPayload>
  updateTicket?: Maybe<UpdateTicketPayload>
  updateUser?: Maybe<UpdateUserPayload>
  updateUserRoles?: Maybe<UpdateUserRolesPayload>
  uploadInvitees?: Maybe<UploadInviteesPayload>
  upsertLeads?: Maybe<UpsertLeadsPayload>
  upsertVolunteer: Volunteer
}

export type MutationAcceptAttendeeArgs = {
  input: AcceptInput
}

export type MutationAcceptTicketRequestArgs = {
  input: AcceptTicketRequestInput
}

export type MutationActivateTicketArgs = {
  input: ActivateTicketInput
}

export type MutationAddAttendeesByTicketIdentifierArgs = {
  input: AddAttendeesByTicketIdentifierInput
}

export type MutationCloseAttendeeSelectionArgs = {
  input: CloseAttendeeSelectionInput
}

export type MutationCreateAcknowledgementArgs = {
  input: CreateAcknowledgementInput
}

export type MutationCreateContactArgs = {
  input: CreateContactInput
}

export type MutationCreateContactTransactionArgs = {
  input: CreateContactTransactionInput
}

export type MutationCreateDirectTicketArgs = {
  input: CreateDirectTicketInput
}

export type MutationCreateDueDateCompletionArgs = {
  input: CreateInput
}

export type MutationCreateEventApplicationsArgs = {
  input: ApplyInput
}

export type MutationCreateEventSpeakerArgs = {
  input: CreateEventSpeakerInput
}

export type MutationCreateIndividualInviteesArgs = {
  input: CreateIndividualInviteesInput
}

export type MutationCreateInternalAccessCheckArgs = {
  input: CreateInternalAccessCheckInput
}

export type MutationCreateLeadArgs = {
  input: CreateLeadInput
}

export type MutationCreateOrderArgs = {
  input: CreateOrderInput
}

export type MutationCreatePartnerCompanyWithHubspotIdArgs = {
  input: CreatePartnerCompanyWithHubspotIdInput
}

export type MutationCreateProspectItemArgs = {
  input: CreateProspectItemInput
}

export type MutationCreateProspectListArgs = {
  input: CreateProspectListInput
}

export type MutationCreateReviewsManagerArgs = {
  input: CreateReviewsManagerInput
}

export type MutationCreateSpeakerArgs = {
  input: CreateSpeakerInput
}

export type MutationCreateUserArgs = {
  input: CreateUserInput
}

export type MutationDeactivateTicketArgs = {
  input: DeactivateTicketInput
}

export type MutationDeleteAcknowledgementUploadArgs = {
  input: DeleteAcknowledgementUploadInput
}

export type MutationDeleteDueDateCompletionArgs = {
  input: DeleteInput
}

export type MutationDeleteEventSpeakerArgs = {
  input: DeleteEventSpeakerInput
}

export type MutationDeleteIndividualInviteesArgs = {
  input: DeleteIndividualInviteesInput
}

export type MutationDeleteProspectItemArgs = {
  input: DeleteProspectItemInput
}

export type MutationDeleteProspectListArgs = {
  input: DeleteProspectListInput
}

export type MutationDeleteTicketArgs = {
  input: DeleteTicketInput
}

export type MutationDeleteUnsentInviteesArgs = {
  input: DeleteUnsentInviteesInput
}

export type MutationDenyAttendeeArgs = {
  input: DenyInput
}

export type MutationDenyTicketRequestArgs = {
  input: DenyTicketRequestInput
}

export type MutationDisconnectOauthProviderArgs = {
  input: DisconnectInput
}

export type MutationEditEmailTemplateArgs = {
  input: EditEmailTemplateInput
}

export type MutationExportLeadsArgs = {
  input: ExportLeadsInput
}

export type MutationImportInviteesArgs = {
  input: ImportInviteesInput
}

export type MutationInboundContactArgs = {
  input: CreateInboundContactInput
}

export type MutationManuallyMarkAsSentArgs = {
  input: ManuallyMarkAsSentInput
}

export type MutationManuallyMarkAsUnsentArgs = {
  input: ManuallyMarkAsUnsentInput
}

export type MutationPlaceCheckoutOrderArgs = {
  input: PlaceOrderInput
}

export type MutationResendConfirmationInstructionsArgs = {
  input: ResendConfirmationInstructionsInput
}

export type MutationResendTicketMailArgs = {
  input: ResendTicketMailInput
}

export type MutationRetractVolunteerArgs = {
  input: RetractVolunteerInput
}

export type MutationSendIndividualInvitationsArgs = {
  input: SendIndividualInvitationsInput
}

export type MutationSendInvitationsArgs = {
  input: SendInvitationsInput
}

export type MutationSendProspectListInvitationsArgs = {
  input: SendProspectListInvitationsInput
}

export type MutationSendTestEmailArgs = {
  input: SendTestEmailInput
}

export type MutationSendTestProspectInvitationArgs = {
  input: SendTestInvitationInput
}

export type MutationSetPaymentInfoArgs = {
  input: SetPaymentInfoInput
}

export type MutationSubmitProspectListArgs = {
  input: SubmitProspectListInput
}

export type MutationSubmitVolunteerArgs = {
  input: SubmitVolunteerInput
}

export type MutationSyncLeadsArgs = {
  input: UpsertLeadsInput
}

export type MutationUpdateAcknowledgementArgs = {
  input: UpdateAcknowledgementInput
}

export type MutationUpdateAttendeesStateFromCsvArgs = {
  input: UpdateAttendeesStateFromCsvInput
}

export type MutationUpdateBillingAddressArgs = {
  input: UpdateBillingAddressInput
}

export type MutationUpdateBoothArgs = {
  input: UpdateBoothInput
}

export type MutationUpdateBoothExtrasArgs = {
  input: UpdateExtrasInput
}

export type MutationUpdateCheckoutOrderArgs = {
  input: UpdateOrderInput
}

export type MutationUpdateContactArgs = {
  input: UpdateContactInput
}

export type MutationUpdateDirectTicketArgs = {
  input: UpdateDirectTicketInput
}

export type MutationUpdateEventArgs = {
  input: UpdateEventInput
}

export type MutationUpdateEventStarringsArgs = {
  input: UpdateEventStarringsInput
}

export type MutationUpdateInterestsArgs = {
  input: UpdateInterestsInput
}

export type MutationUpdateLeadArgs = {
  input: UpdateLeadInput
}

export type MutationUpdateMeArgs = {
  input: UpdateMeInput
}

export type MutationUpdatePartnerCompanyArgs = {
  input: UpdatePartnerCompanyInput
}

export type MutationUpdateProspectListArgs = {
  input: UpdateProspectListInput
}

export type MutationUpdateSpeakerArgs = {
  input: UpdateSpeakerInput
}

export type MutationUpdateTicketArgs = {
  input: UpdateTicketInput
}

export type MutationUpdateUserArgs = {
  input: UpdateUserInput
}

export type MutationUpdateUserRolesArgs = {
  input: UpdateUserRolesInput
}

export type MutationUploadInviteesArgs = {
  input: UploadInviteesInput
}

export type MutationUpsertLeadsArgs = {
  input: UpsertLeadsInput
}

export type MutationUpsertVolunteerArgs = {
  input: UpsertVolunteerInput
}

export type News = {
  __typename?: 'News'
  bodyDe?: Maybe<Scalars['String']['output']>
  bodyEn?: Maybe<Scalars['String']['output']>
  bodyHtmlDe?: Maybe<Scalars['String']['output']>
  bodyHtmlEn?: Maybe<Scalars['String']['output']>
  ctaButtonLink?: Maybe<Scalars['String']['output']>
  ctaButtonTextDe?: Maybe<Scalars['String']['output']>
  ctaButtonTextEn?: Maybe<Scalars['String']['output']>
  force: Scalars['Boolean']['output']
  id: Scalars['ID']['output']
  imageUrl?: Maybe<Scalars['String']['output']>
  tags?: Maybe<Array<Scalars['String']['output']>>
  titleDe: Scalars['String']['output']
  titleEn: Scalars['String']['output']
}

/** The connection type for News. */
export type NewsConnection = {
  __typename?: 'NewsConnection'
  /** A list of edges. */
  edges: Array<NewsEdge>
  /** A list of nodes. */
  nodes: Array<News>
  /** Information to aid in pagination. */
  pageInfo: PageInfo
  totalCount: Scalars['Int']['output']
}

/** An edge in a connection. */
export type NewsEdge = {
  __typename?: 'NewsEdge'
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output']
  /** The item at the end of the edge. */
  node?: Maybe<News>
}

/** An object with an ID. */
export type Node = {
  /** ID of the object. */
  id: Scalars['ID']['output']
}

export enum OauthProviderEnum {
  GoogleOauth2 = 'google_oauth2',
  Linkedin = 'linkedin',
}

export type OffsetPaginationInfo = {
  __typename?: 'OffsetPaginationInfo'
  hasNextPage: Scalars['Boolean']['output']
  hasPreviousPage: Scalars['Boolean']['output']
  limit: Scalars['Int']['output']
  offset: Scalars['Int']['output']
  pagesCount: Scalars['Int']['output']
  /** Total items count */
  totalCount: Scalars['Int']['output']
}

export type Onsite = {
  __typename?: 'Onsite'
  badgeColor?: Maybe<Scalars['String']['output']>
  postPayLimit: Scalars['Int']['output']
  primaryClassification?: Maybe<FieldCategoryEnum>
  wristbandColor?: Maybe<Scalars['String']['output']>
}

export type Order = Node & {
  __typename?: 'Order'
  /** Status of the order. requested = only our system / link sent, appointed = sent to payone */
  aasmState: OrderAasmStateEnum
  /** Additional Address */
  additionalAddress?: Maybe<Scalars['String']['output']>
  /** Checkout Token */
  afterPaymentToken?: Maybe<Scalars['String']['output']>
  /** @deprecated Please use amount_in_cents_gross */
  amountInCents: Scalars['Int']['output']
  /** Gross amount in cents */
  amountInCentsGross: Scalars['Int']['output']
  /** Net amount in cents */
  amountInCentsNet: Scalars['Int']['output']
  /** @deprecated Please use amount_in_euros_gross */
  amountInEuros: Scalars['Float']['output']
  /** Gross amount in euros */
  amountInEurosGross: Scalars['Float']['output']
  /** Net amount in euros */
  amountInEurosNet: Scalars['Float']['output']
  /** Appointed At */
  appointedAt: Scalars['ISO8601DateTime']['output']
  /** Orders badge transactions */
  badgeTransactions?: Maybe<BadgeTransactionConnection>
  /** Canceled At */
  canceledAt?: Maybe<Scalars['ISO8601DateTime']['output']>
  /** City */
  city?: Maybe<Scalars['String']['output']>
  /** Company name */
  company?: Maybe<Scalars['String']['output']>
  /** Country */
  country?: Maybe<Scalars['String']['output']>
  /** Created At */
  createdAt: Scalars['ISO8601DateTime']['output']
  /** Customer reference */
  customerReference?: Maybe<Scalars['String']['output']>
  /** E-Mail */
  email?: Maybe<Scalars['String']['output']>
  /** Event the order is for */
  event?: Maybe<Event>
  /** First name */
  firstName?: Maybe<Scalars['String']['output']>
  id: Scalars['ID']['output']
  /** Invoice Number */
  invoiceNumber?: Maybe<Scalars['String']['output']>
  invoices: Array<Invoice>
  /** Last Name */
  lastName?: Maybe<Scalars['String']['output']>
  /** House number */
  number?: Maybe<Scalars['String']['output']>
  /** Order paid? */
  paid?: Maybe<Scalars['Boolean']['output']>
  /** Paid At */
  paidAt?: Maybe<Scalars['ISO8601DateTime']['output']>
  /** Payment method */
  paymentMethod?: Maybe<Scalars['String']['output']>
  /** PayOne ID */
  payoneId?: Maybe<Scalars['String']['output']>
  /** Period of performance ends at */
  periodOfPerformanceEndsAt?: Maybe<Scalars['ISO8601DateTime']['output']>
  /** Period of performance starts at */
  periodOfPerformanceStartsAt?: Maybe<Scalars['ISO8601DateTime']['output']>
  /** ??? */
  reference?: Maybe<Scalars['String']['output']>
  /** Salutation */
  salutation?: Maybe<Scalars['String']['output']>
  /** Street */
  street?: Maybe<Scalars['String']['output']>
  /** Title */
  title?: Maybe<Scalars['String']['output']>
  /** Order type */
  type?: Maybe<OrderTypeEnum>
  /** Updated At */
  updatedAt: Scalars['ISO8601DateTime']['output']
  /** VAT */
  vat?: Maybe<Scalars['Int']['output']>
  /** VAT Number */
  vatNumber?: Maybe<Scalars['String']['output']>
  /** Postal code */
  zip?: Maybe<Scalars['Int']['output']>
}

export type OrderBadgeTransactionsArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
}

export enum OrderAasmStateEnum {
  Appointed = 'appointed',
  Canceled = 'canceled',
  CheckedOut = 'checked_out',
  CheckoutFailed = 'checkout_failed',
  Paid = 'paid',
  Requested = 'requested',
}

/** The connection type for Order. */
export type OrderConnection = {
  __typename?: 'OrderConnection'
  /** A list of edges. */
  edges: Array<OrderEdge>
  /** A list of nodes. */
  nodes: Array<Order>
  /** Information to aid in pagination. */
  pageInfo: PageInfo
  totalCount: Scalars['Int']['output']
}

export enum OrderDirectionEnum {
  Asc = 'asc',
  Desc = 'desc',
}

/** An edge in a connection. */
export type OrderEdge = {
  __typename?: 'OrderEdge'
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output']
  /** The item at the end of the edge. */
  node?: Maybe<Order>
}

export enum OrderTypeEnum {
  BadgeOrder = 'BadgeOrder',
  JobOrder = 'JobOrder',
  ReportOrder = 'ReportOrder',
  TicketOrder = 'TicketOrder',
}

/** Information about pagination in a connection. */
export type PageInfo = {
  __typename?: 'PageInfo'
  /** When paginating forwards, the cursor to continue. */
  endCursor?: Maybe<Scalars['String']['output']>
  /** When paginating forwards, are there more items? */
  hasNextPage: Scalars['Boolean']['output']
  /** When paginating backwards, are there more items? */
  hasPreviousPage: Scalars['Boolean']['output']
  /** When paginating backwards, the cursor to continue. */
  startCursor?: Maybe<Scalars['String']['output']>
}

export type PageVisit = {
  __typename?: 'PageVisit'
  address?: Maybe<Scalars['String']['output']>
  alexaRank?: Maybe<Scalars['Int']['output']>
  categorySlugs: Array<Scalars['String']['output']>
  city?: Maybe<Scalars['String']['output']>
  companyName?: Maybe<Scalars['String']['output']>
  companyWebsite?: Maybe<Scalars['String']['output']>
  country?: Maybe<Scalars['String']['output']>
  createdAt: Scalars['ISO8601DateTime']['output']
  email?: Maybe<Scalars['String']['output']>
  /** @deprecated Use emploees_from and employees_to instead */
  employees?: Maybe<Scalars['String']['output']>
  employeesFrom?: Maybe<Scalars['String']['output']>
  employeesTo?: Maybe<Scalars['String']['output']>
  /** @deprecated Use financial_report_from, financial_report_to and financial_report_currency instead */
  financialReport?: Maybe<Scalars['String']['output']>
  financialReportCurrency?: Maybe<Scalars['String']['output']>
  financialReportFrom?: Maybe<Scalars['String']['output']>
  financialReportTo?: Maybe<Scalars['String']['output']>
  foundedYear?: Maybe<Scalars['Int']['output']>
  id: Scalars['ID']['output']
  identifier?: Maybe<Scalars['String']['output']>
  linkedinIndustryCategory?: Maybe<Scalars['String']['output']>
  linkedinIndustryCode?: Maybe<Scalars['String']['output']>
  linkedinUrl?: Maybe<Scalars['String']['output']>
  phone?: Maybe<Scalars['String']['output']>
  productSlugs: Array<Scalars['String']['output']>
  project?: Maybe<Scalars['String']['output']>
  recurring?: Maybe<Scalars['Boolean']['output']>
  sector?: Maybe<Scalars['String']['output']>
  subjectId?: Maybe<Scalars['String']['output']>
  subjectType?: Maybe<Scalars['String']['output']>
  url: Scalars['String']['output']
  zipCode?: Maybe<Scalars['String']['output']>
}

export type PageVisitCompanyWebsiteArgs = {
  formatted?: InputMaybe<Scalars['Boolean']['input']>
}

export type PageVisitEmployeesArgs = {
  formatted?: InputMaybe<Scalars['Boolean']['input']>
}

export type PageVisitFinancialReportArgs = {
  formatted?: InputMaybe<Scalars['Boolean']['input']>
}

export type PageVisitLinkedinIndustryCodeArgs = {
  formatted?: InputMaybe<Scalars['Boolean']['input']>
}

/** The connection type for PageVisit. */
export type PageVisitConnection = {
  __typename?: 'PageVisitConnection'
  /** A list of edges. */
  edges: Array<PageVisitEdge>
  /** A list of nodes. */
  nodes: Array<PageVisit>
  /** Information to aid in pagination. */
  pageInfo: PageInfo
  totalCount: Scalars['Int']['output']
}

/** An edge in a connection. */
export type PageVisitEdge = {
  __typename?: 'PageVisitEdge'
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output']
  /** The item at the end of the edge. */
  node?: Maybe<PageVisit>
}

export type PartnerCompany = Node & {
  __typename?: 'PartnerCompany'
  billingAddress?: Maybe<BillingAddress>
  booths: Array<Booth>
  city?: Maybe<Scalars['String']['output']>
  contacts: Array<Contact>
  countryCode?: Maybe<Scalars['String']['output']>
  descriptionDe?: Maybe<Scalars['String']['output']>
  descriptionEn?: Maybe<Scalars['String']['output']>
  displayName?: Maybe<Scalars['String']['output']>
  facebook?: Maybe<Scalars['String']['output']>
  familyMember: Scalars['Boolean']['output']
  foodTypes: Array<BoothFoodTypeEnum>
  guidedTours?: Maybe<Array<Event>>
  hasChildEvents: Scalars['Boolean']['output']
  hasFoodBooths: Scalars['Boolean']['output']
  hasMasterclass?: Maybe<Scalars['Boolean']['output']>
  hasQuotas: Scalars['Boolean']['output']
  hasSpeaker?: Maybe<Scalars['Boolean']['output']>
  hasStageSlot?: Maybe<Scalars['Boolean']['output']>
  id: Scalars['ID']['output']
  instagram?: Maybe<Scalars['String']['output']>
  interests?: Maybe<Array<Interest>>
  leadGenLimitConfigs: Array<LeadGenLimitConfig>
  linkedinUrl?: Maybe<Scalars['String']['output']>
  logoUrl?: Maybe<Scalars['String']['output']>
  masterclasses?: Maybe<Array<Event>>
  name?: Maybe<Scalars['String']['output']>
  omrJobsUrl?: Maybe<Scalars['String']['output']>
  publicEmail?: Maybe<Scalars['String']['output']>
  sideEvents?: Maybe<Array<Event>>
  slug?: Maybe<Scalars['String']['output']>
  stageSlots?: Maybe<Array<Event>>
  street?: Maybe<Scalars['String']['output']>
  tagList?: Maybe<Array<TagListTypeEnum>>
  twitter?: Maybe<Scalars['String']['output']>
  webLogoUrl?: Maybe<Scalars['String']['output']>
  website?: Maybe<Scalars['String']['output']>
  youtube?: Maybe<Scalars['String']['output']>
  zipcode?: Maybe<Scalars['String']['output']>
}

/** Attributes for creating or updating a partner_company */
export type PartnerCompanyAttributes = {
  /** Description DE */
  descriptionDe?: InputMaybe<Scalars['String']['input']>
  /** Description EN */
  descriptionEn?: InputMaybe<Scalars['String']['input']>
  /** Company display name */
  displayName?: InputMaybe<Scalars['String']['input']>
  /** Facebook */
  facebook?: InputMaybe<Scalars['String']['input']>
  /** Instagram */
  instagram?: InputMaybe<Scalars['String']['input']>
  /** Interests */
  interests?: InputMaybe<Array<Scalars['ID']['input']>>
  /** Linkedin */
  linkedinUrl?: InputMaybe<Scalars['String']['input']>
  logo?: InputMaybe<Scalars['Upload']['input']>
  /** Company name */
  name?: InputMaybe<Scalars['String']['input']>
  /** OMRJobs URL */
  omrJobsUrl?: InputMaybe<Scalars['String']['input']>
  /** Twitter */
  twitter?: InputMaybe<Scalars['String']['input']>
  webLogo?: InputMaybe<Scalars['Upload']['input']>
  /** Website */
  website?: InputMaybe<Scalars['String']['input']>
  /** Youtube */
  youtube?: InputMaybe<Scalars['String']['input']>
}

/** The connection type for PartnerCompany. */
export type PartnerCompanyConnection = {
  __typename?: 'PartnerCompanyConnection'
  /** A list of edges. */
  edges: Array<PartnerCompanyEdge>
  /** A list of nodes. */
  nodes: Array<PartnerCompany>
  /** Information to aid in pagination. */
  pageInfo: PageInfo
  totalCount: Scalars['Int']['output']
}

/** An edge in a connection. */
export type PartnerCompanyEdge = {
  __typename?: 'PartnerCompanyEdge'
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output']
  /** The item at the end of the edge. */
  node?: Maybe<PartnerCompany>
}

export type PartnerCompanyMatch = {
  __typename?: 'PartnerCompanyMatch'
  highlight?: Maybe<Scalars['String']['output']>
  id: Scalars['ID']['output']
  name: Scalars['String']['output']
  slug: Scalars['String']['output']
  url: Scalars['String']['output']
  webLogoUrl: Scalars['String']['output']
}

export type PartnerCompanyMatchPaginatedCollection = {
  __typename?: 'PartnerCompanyMatchPaginatedCollection'
  nodes: Array<PartnerCompanyMatch>
  pageInfo: OffsetPaginationInfo
}

export type PaymentInfo = {
  __typename?: 'PaymentInfo'
  additionalAddress?: Maybe<Scalars['String']['output']>
  address: Scalars['String']['output']
  business: Scalars['Boolean']['output']
  city: Scalars['String']['output']
  company?: Maybe<Scalars['String']['output']>
  country: Scalars['String']['output']
  customerReference?: Maybe<Scalars['String']['output']>
  email: Scalars['String']['output']
  firstName: Scalars['String']['output']
  id: Scalars['ID']['output']
  lastName: Scalars['String']['output']
  salutation: Scalars['String']['output']
  vatNumber?: Maybe<Scalars['String']['output']>
  zip: Scalars['String']['output']
}

export enum PaymentMethodEnum {
  Amex = 'amex',
  Debit = 'debit',
  Invoice = 'invoice',
  Mastercard = 'mastercard',
  Paypal = 'paypal',
  Visa = 'visa',
}

/** Attributes for placing orders */
export type PlaceOrderAttributes = {
  bankAccount?: InputMaybe<Scalars['String']['input']>
  bankAccountHolder?: InputMaybe<Scalars['String']['input']>
  bankCode?: InputMaybe<Scalars['String']['input']>
  bankCountry?: InputMaybe<Scalars['String']['input']>
  bic?: InputMaybe<Scalars['String']['input']>
  cardExpireDate?: InputMaybe<Scalars['String']['input']>
  cardHolder?: InputMaybe<Scalars['String']['input']>
  cardType?: InputMaybe<Scalars['String']['input']>
  clearingType?: InputMaybe<Scalars['String']['input']>
  iban?: InputMaybe<Scalars['String']['input']>
  method: PaymentMethodEnum
  pseudoCardpan?: InputMaybe<Scalars['String']['input']>
  truncatedCardpan?: InputMaybe<Scalars['String']['input']>
}

/** Autogenerated input type of PlaceOrder */
export type PlaceOrderInput = {
  afterPaymentToken: Scalars['String']['input']
  attributes: PlaceOrderAttributes
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>
}

/** Autogenerated return type of PlaceOrder. */
export type PlaceOrderPayload = {
  __typename?: 'PlaceOrderPayload'
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>
  errors?: Maybe<Array<Scalars['String']['output']>>
  order?: Maybe<CheckoutOrder>
  redirectUrl?: Maybe<Scalars['String']['output']>
}

export type PodcastMatch = {
  __typename?: 'PodcastMatch'
  authors: Scalars['String']['output']
  coverImage?: Maybe<Scalars['String']['output']>
  description?: Maybe<Scalars['String']['output']>
  episodesCount: Scalars['Int']['output']
  highlight?: Maybe<Scalars['String']['output']>
  id: Scalars['ID']['output']
  keywords: Array<Scalars['String']['output']>
  lastEpisodePublicationDate?: Maybe<Scalars['ISO8601DateTime']['output']>
  publicationType: Scalars['String']['output']
  publishedAt?: Maybe<Scalars['ISO8601DateTime']['output']>
  slug: Scalars['String']['output']
  subtitle?: Maybe<Scalars['String']['output']>
  title: Scalars['String']['output']
  url: Scalars['String']['output']
}

export type PodcastMatchPaginatedCollection = {
  __typename?: 'PodcastMatchPaginatedCollection'
  nodes: Array<PodcastMatch>
  pageInfo: OffsetPaginationInfo
}

export type Product = {
  __typename?: 'Product'
  attachmentUrl?: Maybe<Scalars['String']['output']>
  commentRequired: Scalars['Boolean']['output']
  deliveryFee?: Maybe<Scalars['Float']['output']>
  description: Scalars['String']['output']
  fileUploadRequired: Scalars['Boolean']['output']
  id: Scalars['ID']['output']
  maximum?: Maybe<Scalars['Int']['output']>
  name: Scalars['String']['output']
  productCategory?: Maybe<ProductCategory>
  variants: Array<Variant>
}

export type ProductCategory = {
  __typename?: 'ProductCategory'
  cancellationDate?: Maybe<Scalars['ISO8601DateTime']['output']>
  expiresAt?: Maybe<Scalars['ISO8601DateTime']['output']>
  id: Scalars['ID']['output']
  name: Scalars['String']['output']
  position: Scalars['Int']['output']
}

export type ProductMatch = {
  __typename?: 'ProductMatch'
  averageRecommendationRating?: Maybe<Scalars['Float']['output']>
  highlight?: Maybe<Scalars['String']['output']>
  id: Scalars['ID']['output']
  logo?: Maybe<ReviewsAsset>
  reviewCount: Scalars['Int']['output']
  slug: Scalars['String']['output']
  title: Scalars['String']['output']
  url: Scalars['String']['output']
}

export type ProductMatchPaginatedCollection = {
  __typename?: 'ProductMatchPaginatedCollection'
  nodes: Array<ProductMatch>
  pageInfo: OffsetPaginationInfo
}

export enum ProfileScopeEnum {
  Basic = 'basic',
  Detailed = 'detailed',
  NetworkingTerms = 'networking_terms',
  ReviewsTerms = 'reviews_terms',
}

export type PromoCode = Node & {
  __typename?: 'PromoCode'
  code: Scalars['String']['output']
  companyName?: Maybe<Scalars['String']['output']>
  id: Scalars['ID']['output']
  manuallyMarkedAsSentAt?: Maybe<Scalars['ISO8601DateTime']['output']>
  ticketCount: Scalars['Int']['output']
  tickets?: Maybe<Array<Ticket>>
  timesUsableRemaining: Scalars['Int']['output']
  usable: Scalars['Boolean']['output']
  userName?: Maybe<Scalars['String']['output']>
}

export type PromoCodeDiscount = {
  __typename?: 'PromoCodeDiscount'
  code?: Maybe<Scalars['String']['output']>
  discountAmount?: Maybe<Scalars['Int']['output']>
  ticketCategoryId?: Maybe<Scalars['String']['output']>
  timesApplied?: Maybe<Scalars['Int']['output']>
}

export type Prospect = {
  __typename?: 'Prospect'
  _score?: Maybe<Scalars['Float']['output']>
  available: Scalars['Boolean']['output']
  companies: Array<ProspectCompany>
  highlight?: Maybe<SearchResultHighlight>
  id: Scalars['ID']['output']
  visitedEvents: Array<VisitedEvent>
}

export type ProspectCompany = {
  __typename?: 'ProspectCompany'
  country?: Maybe<Scalars['String']['output']>
  department?: Maybe<CompanyDepartmentEnum>
  eventSlug: Scalars['String']['output']
  field?: Maybe<Scalars['ID']['output']>
  industry?: Maybe<Scalars['ID']['output']>
  jobLevel?: Maybe<JobLevelEnum>
  name?: Maybe<Scalars['String']['output']>
  position?: Maybe<Scalars['String']['output']>
  size?: Maybe<Scalars['ID']['output']>
}

export type ProspectList = Node & {
  __typename?: 'ProspectList'
  contactEmail?: Maybe<Scalars['String']['output']>
  contactName?: Maybe<Scalars['String']['output']>
  contactReason?: Maybe<Scalars['String']['output']>
  createdAt: Scalars['ISO8601DateTime']['output']
  event: Event
  id: Scalars['ID']['output']
  partnerCompany: PartnerCompany
  prospects: Array<Prospect>
  submittedAt?: Maybe<Scalars['ISO8601DateTime']['output']>
  title: Scalars['String']['output']
  updatedAt?: Maybe<Scalars['ISO8601DateTime']['output']>
}

/** Attributes for creating or updating prospect list */
export type ProspectListAttributes = {
  contactEmail: Scalars['String']['input']
  contactName: Scalars['String']['input']
  contactReason?: InputMaybe<Scalars['String']['input']>
  title: Scalars['String']['input']
}

/** The connection type for ProspectList. */
export type ProspectListConnection = {
  __typename?: 'ProspectListConnection'
  /** A list of edges. */
  edges: Array<ProspectListEdge>
  /** A list of nodes. */
  nodes: Array<ProspectList>
  /** Information to aid in pagination. */
  pageInfo: PageInfo
  totalCount: Scalars['Int']['output']
}

/** An edge in a connection. */
export type ProspectListEdge = {
  __typename?: 'ProspectListEdge'
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output']
  /** The item at the end of the edge. */
  node?: Maybe<ProspectList>
}

export type ProspectsCount = {
  __typename?: 'ProspectsCount'
  relation: Scalars['String']['output']
  total: Scalars['Int']['output']
}

export type ProspectsFilter = {
  __typename?: 'ProspectsFilter'
  id: Scalars['ID']['output']
  name: Scalars['String']['output']
}

export type ProspectsSearchResult = {
  __typename?: 'ProspectsSearchResult'
  companyCountryFacets: Array<Facet>
  companyDepartmentFacets: Array<Facet>
  companyFieldFacets: Array<Facet>
  companyIndustryFacets: Array<Facet>
  companyJobLevelFacets: Array<Facet>
  companySizeFacets: Array<Facet>
  perPage: Scalars['Int']['output']
  prospects: Array<Prospect>
  prospectsCount: ProspectsCount
  visitedEventsFilter: Array<ProspectsFilter>
}

export type PublishedBooth = {
  __typename?: 'PublishedBooth'
  boost: Scalars['Int']['output']
  boothNumber: Scalars['String']['output']
  description?: Maybe<Scalars['String']['output']>
  foodTypes?: Maybe<Array<BoothFoodTypeEnum>>
  id: Scalars['ID']['output']
  partnerCompany: PublishedPartnerCompany
  premium: Scalars['Boolean']['output']
  priceIndicator?: Maybe<Scalars['Int']['output']>
  showBoothNumberAsMapLabel: Scalars['Boolean']['output']
  showOnMap: Scalars['Boolean']['output']
  title?: Maybe<Scalars['String']['output']>
  type: BoothTypeEnum
}

export type PublishedBoothPaginatedCollection = {
  __typename?: 'PublishedBoothPaginatedCollection'
  nodes: Array<PublishedBooth>
  pageInfo: OffsetPaginationInfo
}

export type PublishedEvent = {
  __typename?: 'PublishedEvent'
  applicationStop: Scalars['Boolean']['output']
  description?: Maybe<Scalars['String']['output']>
  endsAt: Scalars['ISO8601DateTime']['output']
  id: Scalars['ID']['output']
  idHash: Scalars['String']['output']
  interests: Array<Interest>
  language?: Maybe<LanguageEnum>
  name: Scalars['String']['output']
  partnerCompanies: Array<PublishedPartnerCompany>
  slug: Scalars['String']['output']
  speakers: Array<PublishedSpeaker>
  stage: Stage
  stageId: Scalars['ID']['output']
  startsAt: Scalars['ISO8601DateTime']['output']
  type: EventTypeEnum
}

export type PublishedPartnerCompany = {
  __typename?: 'PublishedPartnerCompany'
  boothNumbers: Array<Scalars['String']['output']>
  contactId?: Maybe<Scalars['ID']['output']>
  description: Scalars['String']['output']
  displayName: Scalars['String']['output']
  eventIds: Array<Scalars['ID']['output']>
  facebook?: Maybe<Scalars['String']['output']>
  id: Scalars['ID']['output']
  instagram?: Maybe<Scalars['String']['output']>
  interests: Array<Interest>
  linkedIn?: Maybe<Scalars['String']['output']>
  omrJobsUrl?: Maybe<Scalars['String']['output']>
  premium?: Maybe<Scalars['Boolean']['output']>
  slug: Scalars['String']['output']
  twitter?: Maybe<Scalars['String']['output']>
  webLogoUrl: Scalars['String']['output']
  website?: Maybe<Scalars['String']['output']>
  youtube?: Maybe<Scalars['String']['output']>
}

export type PublishedPartnerCompanyBoothNumbersArgs = {
  rootEventSlug: Scalars['String']['input']
}

export type PublishedPartnerCompanyEventIdsArgs = {
  rootEventSlug: Scalars['String']['input']
}

export type PublishedPartnerCompanyPaginatedCollection = {
  __typename?: 'PublishedPartnerCompanyPaginatedCollection'
  nodes: Array<PublishedPartnerCompany>
  pageInfo: OffsetPaginationInfo
}

export type PublishedQuery = {
  __typename?: 'PublishedQuery'
  /**
   * Published booths
   * @deprecated deprecated use boothsPaginated instead
   */
  booths: Array<PublishedBooth>
  /** Published booths */
  boothsPaginated: PublishedBoothPaginatedCollection
  eventsForApplication: Array<EventsForApplication>
  /** JSON-LD for the published event */
  jsonLd: JsonLd
  /**
   * Published exhibitors
   * @deprecated deprecated use partnerCompaniesPaginated instead
   */
  partnerCompanies: Array<PublishedPartnerCompany>
  /** Published exhibitors */
  partnerCompaniesPaginated: PublishedPartnerCompanyPaginatedCollection
  /** Published partner company by ID */
  partnerCompany: PublishedPartnerCompany
  /** Published event */
  publishedEvent: PublishedEvent
  /** Published events */
  publishedEvents: Array<PublishedEvent>
  /** Published speaker by ID */
  speaker: PublishedSpeaker
  /** Published speakers and artists by event slug */
  speakersAndArtists: PublishedSpeakerPaginatedCollection
  /** Timetable for event */
  timetable: Timetable
}

export type PublishedQueryBoothsArgs = {
  rootEventSlug: Scalars['String']['input']
  types?: InputMaybe<Array<BoothTypeEnum>>
}

export type PublishedQueryBoothsPaginatedArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  rootEventSlug: Scalars['String']['input']
  types?: InputMaybe<Array<BoothTypeEnum>>
}

export type PublishedQueryEventsForApplicationArgs = {
  ticketId: Scalars['ID']['input']
}

export type PublishedQueryJsonLdArgs = {
  eventSlug: Scalars['String']['input']
}

export type PublishedQueryPartnerCompaniesArgs = {
  rootEventSlug: Scalars['String']['input']
}

export type PublishedQueryPartnerCompaniesPaginatedArgs = {
  days?: InputMaybe<Array<Scalars['ISO8601DateTime']['input']>>
  eventType?: InputMaybe<EventTypeEnum>
  interests?: InputMaybe<Array<Scalars['String']['input']>>
  limit?: InputMaybe<Scalars['Int']['input']>
  limitToList?: InputMaybe<Scalars['String']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  premium?: InputMaybe<Scalars['Boolean']['input']>
  rootEventSlug: Scalars['String']['input']
  searchTerm?: InputMaybe<Scalars['String']['input']>
  sortByList?: InputMaybe<Scalars['String']['input']>
}

export type PublishedQueryPartnerCompanyArgs = {
  id?: InputMaybe<Scalars['ID']['input']>
  slug?: InputMaybe<Scalars['String']['input']>
}

export type PublishedQueryPublishedEventArgs = {
  eventSlug: Scalars['String']['input']
  rootEventSlug?: InputMaybe<Scalars['String']['input']>
}

export type PublishedQueryPublishedEventsArgs = {
  eventIds: Array<Scalars['ID']['input']>
}

export type PublishedQuerySpeakerArgs = {
  id?: InputMaybe<Scalars['ID']['input']>
  rootEventSlug?: InputMaybe<Scalars['String']['input']>
  slug?: InputMaybe<Scalars['String']['input']>
}

export type PublishedQuerySpeakersAndArtistsArgs = {
  days?: InputMaybe<Array<Scalars['ISO8601DateTime']['input']>>
  eventType?: InputMaybe<EventTypeEnum>
  ffwd?: InputMaybe<Scalars['Boolean']['input']>
  interests?: InputMaybe<Array<Scalars['String']['input']>>
  limit?: InputMaybe<Scalars['Int']['input']>
  limitToList?: InputMaybe<Scalars['String']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  role?: InputMaybe<SpeakerRoleEnum>
  rootEventSlug: Scalars['String']['input']
  searchTerm?: InputMaybe<Scalars['String']['input']>
  sortByList?: InputMaybe<Scalars['String']['input']>
  stageSlug?: InputMaybe<Scalars['String']['input']>
}

export type PublishedQueryTimetableArgs = {
  eventSlug: Scalars['String']['input']
  stageNames?: InputMaybe<Array<Scalars['String']['input']>>
  types?: InputMaybe<Array<EventTypeEnum>>
}

export type PublishedSpeaker = {
  __typename?: 'PublishedSpeaker'
  createdAt: Scalars['ISO8601DateTime']['output']
  description?: Maybe<Scalars['String']['output']>
  eventIds: Array<Scalars['ID']['output']>
  facebookUrl?: Maybe<Scalars['String']['output']>
  ffwd: Scalars['Boolean']['output']
  id: Scalars['ID']['output']
  instagramUrl?: Maybe<Scalars['String']['output']>
  interests: Array<Interest>
  linkedinUrl?: Maybe<Scalars['String']['output']>
  name: Scalars['String']['output']
  partnerCompanyName?: Maybe<Scalars['String']['output']>
  photoUrl: Scalars['String']['output']
  /** @deprecated position is deprecated, use title instead */
  position?: Maybe<Scalars['String']['output']>
  roles?: Maybe<Array<SpeakerRoleEnum>>
  slug: Scalars['String']['output']
  title?: Maybe<Scalars['String']['output']>
  twitterUrl?: Maybe<Scalars['String']['output']>
  websiteUrl?: Maybe<Scalars['String']['output']>
  youtubeUrl?: Maybe<Scalars['String']['output']>
}

export type PublishedSpeakerPaginatedCollection = {
  __typename?: 'PublishedSpeakerPaginatedCollection'
  nodes: Array<PublishedSpeaker>
  pageInfo: OffsetPaginationInfo
}

export type Query = {
  __typename?: 'Query'
  /** Find attendees by event_id */
  attendees: AttendeeConnection
  /** Booths */
  booths: BoothConnection
  checkout: CheckoutQuery
  /** Content streams */
  contentStreams: ContentStreamConnection
  /** CSV Preview Data */
  csvPreviewData?: Maybe<Array<CsvPreviewData>>
  currentTime: Scalars['ISO8601DateTime']['output']
  dashboardTickets: Array<Ticket>
  /** Document Acknowledgements */
  docAcknowledgements: DocAcknowledgementConnection
  /** Documents */
  documents: DocumentConnection
  /** DueDates */
  dueDateItems: Array<DueDateItem>
  /** event */
  event: Event
  /** event */
  eventById: Event
  eventNotifications: Array<EventNotification>
  /** events */
  events: EventConnectionWithTotalCount
  /** geojson_maps */
  geojsonMaps: GeojsonMaps
  /** Interests */
  interests: InterestConnection
  invitationQuota: InvitationQuota
  /** Invitation Quotas */
  invitationQuotas: InvitationQuotaConnection
  /** Invitees */
  invitees: InviteePaginatedCollection
  jwtMasterclass: JwtMasterclass
  /** leads */
  leads: LeadConnection
  /** your own user */
  me?: Maybe<User>
  /** My orders */
  myOrders: OrderConnection
  /** News */
  news: NewsConnection
  /** Fetches an object given its ID. */
  node?: Maybe<Node>
  /** Fetches a list of objects given a list of IDs. */
  nodes: Array<Maybe<Node>>
  /** orders */
  orders: OrderConnection
  /** PageVisits */
  pageVisits?: Maybe<PageVisitConnection>
  /** partner_companies */
  partnerCompanies: PartnerCompanyConnection
  /** ProspectLists */
  prospectLists?: Maybe<ProspectListConnection>
  prospects?: Maybe<ProspectsSearchResult>
  published: PublishedQuery
  search: SearchResult
  /** speakers */
  speakers: SpeakerConnection
  /** stage */
  stage?: Maybe<Stage>
  /** ticket */
  ticket?: Maybe<Ticket>
  ticketShop: TicketShopQuery
  /** tickets */
  tickets: TicketConnection
  /** user */
  user?: Maybe<User>
  userPropertiesPreferences: UserPropertiesPreferences
  /** Users */
  users: UserConnection
  volunteer?: Maybe<Volunteer>
}

export type QueryAttendeesArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  eventId: Scalars['ID']['input']
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  orderBy?: InputMaybe<AttendeeOrderAttributesEnum>
  orderDirection?: InputMaybe<OrderDirectionEnum>
  state?: InputMaybe<Scalars['String']['input']>
}

export type QueryBoothsArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  boothNumber?: InputMaybe<Scalars['String']['input']>
  eventId?: InputMaybe<Scalars['ID']['input']>
  eventPeriod?: InputMaybe<EventPeriodEnum>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  type?: InputMaybe<BoothTypeEnum>
}

export type QueryContentStreamsArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  eventId?: InputMaybe<Scalars['ID']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  interests?: InputMaybe<Array<Scalars['String']['input']>>
  last?: InputMaybe<Scalars['Int']['input']>
}

export type QueryCsvPreviewDataArgs = {
  csvUploadId: Scalars['ID']['input']
  invitationQuotaId: Scalars['ID']['input']
}

export type QueryDocAcknowledgementsArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
}

export type QueryDocumentsArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  eventId?: InputMaybe<Scalars['ID']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  signable?: InputMaybe<Scalars['ID']['input']>
  tags: Array<Scalars['String']['input']>
}

export type QueryEventArgs = {
  slug: Scalars['String']['input']
}

export type QueryEventByIdArgs = {
  id: Scalars['ID']['input']
}

export type QueryEventNotificationsArgs = {
  eventSlug: Scalars['String']['input']
}

export type QueryEventsArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  interests?: InputMaybe<Array<Scalars['String']['input']>>
  last?: InputMaybe<Scalars['Int']['input']>
  noParent?: InputMaybe<Scalars['Boolean']['input']>
  parentEventsFirst?: InputMaybe<Scalars['Boolean']['input']>
  period?: InputMaybe<EventPeriodEnum>
  rootEvent?: InputMaybe<Scalars['String']['input']>
  slug?: InputMaybe<Scalars['String']['input']>
  startsAtMonth?: InputMaybe<Scalars['Int']['input']>
  startsAtYear?: InputMaybe<Scalars['Int']['input']>
  type?: InputMaybe<Array<EventTypeEnum>>
}

export type QueryGeojsonMapsArgs = {
  eventSlug: Scalars['String']['input']
}

export type QueryInterestsArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
}

export type QueryInvitationQuotaArgs = {
  id: Scalars['ID']['input']
}

export type QueryInvitationQuotasArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  partnerCompanyId?: InputMaybe<Scalars['ID']['input']>
}

export type QueryInviteesArgs = {
  activated?: InputMaybe<Scalars['Boolean']['input']>
  invitationQuotaId: Scalars['ID']['input']
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  searchQuery?: InputMaybe<Scalars['String']['input']>
  status?: InputMaybe<InviteeStatusEnum>
}

export type QueryJwtMasterclassArgs = {
  accessToken: Scalars['String']['input']
  slug: Scalars['String']['input']
}

export type QueryLeadsArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  mainEventId: Scalars['ID']['input']
  partnerCompanyId?: InputMaybe<Scalars['ID']['input']>
}

export type QueryMyOrdersArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  eventSlug?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  type?: InputMaybe<Array<OrderTypeEnum>>
}

export type QueryNewsArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
}

export type QueryNodeArgs = {
  id: Scalars['ID']['input']
}

export type QueryNodesArgs = {
  ids: Array<Scalars['ID']['input']>
}

export type QueryOrdersArgs = {
  aasmState?: InputMaybe<Array<OrderAasmStateEnum>>
  after?: InputMaybe<Scalars['String']['input']>
  badgeIds?: InputMaybe<Array<Scalars['String']['input']>>
  before?: InputMaybe<Scalars['String']['input']>
  canceledAtMonth?: InputMaybe<Scalars['Int']['input']>
  canceledAtYear?: InputMaybe<Scalars['Int']['input']>
  createdAtMonth?: InputMaybe<Scalars['Int']['input']>
  createdAtYear?: InputMaybe<Scalars['Int']['input']>
  eventSlug?: InputMaybe<Scalars['String']['input']>
  eventStartsAtMonth?: InputMaybe<Scalars['Int']['input']>
  eventStartsAtYear?: InputMaybe<Scalars['Int']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  paidAtMonth?: InputMaybe<Scalars['Int']['input']>
  paidAtYear?: InputMaybe<Scalars['Int']['input']>
  paymentMethod?: InputMaybe<Array<PaymentMethodEnum>>
  payoneIds?: InputMaybe<Array<Scalars['String']['input']>>
  type?: InputMaybe<Array<OrderTypeEnum>>
}

export type QueryPageVisitsArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  categorySlugs?: InputMaybe<Array<Scalars['String']['input']>>
  createdDaysAgo?: InputMaybe<Scalars['Int']['input']>
  excludeCompanyNames?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  excludeCompanyWebsites?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  first?: InputMaybe<Scalars['Int']['input']>
  from?: InputMaybe<Scalars['ISO8601DateTime']['input']>
  identifier?: InputMaybe<Scalars['String']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  productSlugs?: InputMaybe<Array<Scalars['String']['input']>>
  project?: InputMaybe<Scalars['String']['input']>
  recurring?: InputMaybe<Scalars['Boolean']['input']>
  subjectId?: InputMaybe<Array<Scalars['String']['input']>>
  subjectType?: InputMaybe<Scalars['String']['input']>
  to?: InputMaybe<Scalars['ISO8601DateTime']['input']>
}

export type QueryPartnerCompaniesArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  interests?: InputMaybe<Array<Scalars['String']['input']>>
  last?: InputMaybe<Scalars['Int']['input']>
  onlyFamilyMembers?: InputMaybe<Scalars['Boolean']['input']>
  stageId?: InputMaybe<Scalars['String']['input']>
}

export type QueryProspectListsArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  eventSlug?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  partnerCompanyId: Scalars['ID']['input']
  period?: InputMaybe<EventPeriodEnum>
}

export type QueryProspectsArgs = {
  available?: InputMaybe<Scalars['Boolean']['input']>
  companyCountries?: InputMaybe<Array<Scalars['ID']['input']>>
  companyDepartments?: InputMaybe<Array<Scalars['ID']['input']>>
  companyFields?: InputMaybe<Array<Scalars['ID']['input']>>
  companyIndustries?: InputMaybe<Array<Scalars['ID']['input']>>
  companyJobLevels?: InputMaybe<Array<Scalars['ID']['input']>>
  companyName?: InputMaybe<Scalars['String']['input']>
  companySizes?: InputMaybe<Array<Scalars['ID']['input']>>
  eventSlug: Scalars['String']['input']
  page?: InputMaybe<Scalars['Int']['input']>
  perPage?: InputMaybe<Scalars['Int']['input']>
  position?: InputMaybe<Scalars['String']['input']>
  visitedEvents?: InputMaybe<Array<Scalars['ID']['input']>>
}

export type QuerySearchArgs = {
  term: Scalars['String']['input']
}

export type QuerySpeakersArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  forCurrentPartnerCompany?: InputMaybe<Scalars['Boolean']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  searchTerm?: InputMaybe<Scalars['String']['input']>
}

export type QueryStageArgs = {
  slug: Scalars['String']['input']
}

export type QueryTicketArgs = {
  identifier: Scalars['String']['input']
}

export type QueryTicketsArgs = {
  aasmState?: InputMaybe<Array<TicketAasmStateEnum>>
  activated?: InputMaybe<Scalars['Boolean']['input']>
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  eventId?: InputMaybe<Scalars['ID']['input']>
  eventSlug?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  invitationQuotaId?: InputMaybe<Scalars['ID']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  orderBy?: InputMaybe<TicketOrderAttributesEnum>
  orderDirection?: InputMaybe<OrderDirectionEnum>
  search?: InputMaybe<Scalars['String']['input']>
  source?: InputMaybe<Array<TicketSourceEnum>>
  swapped?: InputMaybe<Scalars['Boolean']['input']>
  updatedAt?: InputMaybe<Scalars['ISO8601DateTime']['input']>
}

export type QueryUserArgs = {
  email?: InputMaybe<Scalars['String']['input']>
  globalId?: InputMaybe<Scalars['ID']['input']>
  id?: InputMaybe<Scalars['String']['input']>
}

export type QueryUsersArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
}

/** Autogenerated input type of ResendConfirmationInstructions */
export type ResendConfirmationInstructionsInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>
}

/** Autogenerated return type of ResendConfirmationInstructions. */
export type ResendConfirmationInstructionsPayload = {
  __typename?: 'ResendConfirmationInstructionsPayload'
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>
  errors?: Maybe<Array<Scalars['String']['output']>>
  me: User
  success: Scalars['Boolean']['output']
}

/** Autogenerated input type of ResendTicketMail */
export type ResendTicketMailInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>
  ticketId: Scalars['ID']['input']
}

/** Autogenerated return type of ResendTicketMail. */
export type ResendTicketMailPayload = {
  __typename?: 'ResendTicketMailPayload'
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>
  errors: Array<Scalars['String']['output']>
  success: Scalars['Boolean']['output']
}

export enum ResourceTypeEnum {
  CodeQuota = 'CodeQuota',
  CrewQuota = 'CrewQuota',
  Event = 'Event',
  ExhibitorQuota = 'ExhibitorQuota',
  GuestQuota = 'GuestQuota',
  PartnerCompany = 'PartnerCompany',
}

/** Autogenerated input type of RetractVolunteer */
export type RetractVolunteerInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>
}

export type ReviewsAsset = {
  __typename?: 'ReviewsAsset'
  contentType: Scalars['String']['output']
  url: Scalars['String']['output']
}

export type Role = {
  __typename?: 'Role'
  id: Scalars['ID']['output']
  name: RoleNameEnum
  resourceId?: Maybe<Scalars['String']['output']>
  resourceType?: Maybe<ResourceTypeEnum>
}

export enum RoleNameEnum {
  AccessesAdmin = 'accesses_admin',
  /** @deprecated deprecated */
  Admin = 'admin',
  AdminActsAsTaggableOnTags = 'admin__acts_as_taggable_on_tags',
  AdminAdminUserAccesses = 'admin__admin_user_accesses',
  AdminAdminUserGroups = 'admin__admin_user_groups',
  AdminAllocations = 'admin__allocations',
  AdminBadgeOrders = 'admin__badge_orders',
  AdminBadgeTransactionItems = 'admin__badge_transaction_items',
  AdminBadgeTransactions = 'admin__badge_transactions',
  AdminBadges = 'admin__badges',
  AdminBooths = 'admin__booths',
  AdminComments = 'admin__comments',
  AdminCompanies = 'admin__companies',
  AdminCompanyFields = 'admin__company_fields',
  AdminCompanyIndustries = 'admin__company_industries',
  AdminContacts = 'admin__contacts',
  AdminContentStreams = 'admin__content_streams',
  AdminDashboard = 'admin__dashboard',
  AdminDeals = 'admin__deals',
  AdminDigitalMasterclasses = 'admin__digital_masterclasses',
  AdminDocAcknowledgements = 'admin__doc_acknowledgements',
  AdminDocuments = 'admin__documents',
  AdminDueDates = 'admin__due_dates',
  AdminEventApplications = 'admin__event_applications',
  AdminEventNotifications = 'admin__event_notifications',
  AdminEventPartnerCompanies = 'admin__event_partner_companies',
  AdminEventSpeakers = 'admin__event_speakers',
  AdminEvents = 'admin__events',
  AdminFieldCategories = 'admin__field_categories',
  AdminFields = 'admin__fields',
  AdminInterests = 'admin__interests',
  AdminInvitationQuotas = 'admin__invitation_quotas',
  AdminInvitees = 'admin__invitees',
  AdminJobOrders = 'admin__job_orders',
  AdminLeads = 'admin__leads',
  AdminLocations = 'admin__locations',
  AdminMasterclasses = 'admin__masterclasses',
  AdminNews = 'admin__news',
  AdminOutgoingWebhooks = 'admin__outgoing_webhooks',
  AdminPartnerCompanies = 'admin__partner_companies',
  AdminPartnerPages = 'admin__partner_pages',
  AdminPassbookConfigs = 'admin__passbook_configs',
  AdminPaymentInfos = 'admin__payment_infos',
  AdminPipedriveConfigs = 'admin__pipedrive_configs',
  AdminProductCategories = 'admin__product_categories',
  AdminProducts = 'admin__products',
  AdminPromoCodeConfigs = 'admin__promo_code_configs',
  AdminPromoCodes = 'admin__promo_codes',
  AdminProspectItems = 'admin__prospect_items',
  AdminProspectLists = 'admin__prospect_lists',
  AdminRedirects = 'admin__redirects',
  AdminReportOrders = 'admin__report_orders',
  AdminSearchTracks = 'admin__search_tracks',
  AdminSpeakers = 'admin__speakers',
  AdminStages = 'admin__stages',
  AdminTicketCategories = 'admin__ticket_categories',
  AdminTicketOrders = 'admin__ticket_orders',
  AdminTickets = 'admin__tickets',
  AdminUsers = 'admin__users',
  AdminUsersRoles = 'admin__users_roles',
  AfterPaymentAdmin = 'after_payment_admin',
  AttendeeAllocationAdmin = 'attendee_allocation_admin',
  AttendeeAllocationSuperAdmin = 'attendee_allocation_super_admin',
  /** @deprecated deprecated */
  BoothBuilder = 'booth_builder',
  DeleteAdmin = 'delete_admin',
  EditTicketFieldsAdmin = 'edit_ticket_fields_admin',
  /** @deprecated deprecated */
  Employee = 'employee',
  GeojsonAdmin = 'geojson_admin',
  InternalApiUser = 'internal_api_user',
  InvalidateQuotaAdmin = 'invalidate_quota_admin',
  /** @deprecated deprecated */
  LeadGen = 'lead_gen',
  /** @deprecated deprecated */
  LeadScanner = 'lead_scanner',
  ManageBooths = 'manage__booths',
  ManageCompanyProfile = 'manage__company_profile',
  ManageContentPages = 'manage__content_pages',
  ManageDashboard = 'manage__dashboard',
  ManageEvents = 'manage__events',
  ManageLeadGeneration = 'manage__lead_generation',
  ManageLeadScanner = 'manage__lead_scanner',
  ManageMyLeads = 'manage__my_leads',
  ManageReviewsManagement = 'manage__reviews_management',
  ManageSpeakers = 'manage__speakers',
  ManageTickets = 'manage__tickets',
  ManageUserManagement = 'manage__user_management',
  MergeAdmin = 'merge_admin',
  MobileTester = 'mobile_tester',
  /** @deprecated deprecated */
  ProjectManager = 'project_manager',
  QuotaUser = 'quota_user',
  SlugsAdmin = 'slugs_admin',
  SyncTicketFieldsAdmin = 'sync_ticket_fields_admin',
  /** @deprecated deprecated */
  TicketAdmin = 'ticket_admin',
  UnlockTicketAdmin = 'unlock_ticket_admin',
}

export enum SalutationTypeEnum {
  Female = 'female',
  Male = 'male',
  Other = 'other',
}

export type Salutations = {
  __typename?: 'Salutations'
  enum: SalutationTypeEnum
  name: Scalars['String']['output']
}

export type SearchResult = {
  __typename?: 'SearchResult'
  agencies: AgencyMatchPaginatedCollection
  dailyArticles: ArticleMatchPaginatedCollection
  educationReports: EducationReportMatchPaginatedCollection
  educationSeminars: EducationSeminarMatchPaginatedCollection
  jobs: JobMatchPaginatedCollection
  partnerCompanies: PartnerCompanyMatchPaginatedCollection
  podcasts: PodcastMatchPaginatedCollection
  products: ProductMatchPaginatedCollection
  speakers: SpeakerMatchPaginatedCollection
}

export type SearchResultDailyArticlesArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
}

export type SearchResultEducationReportsArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
}

export type SearchResultEducationSeminarsArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
}

export type SearchResultJobsArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
}

export type SearchResultPartnerCompaniesArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
}

export type SearchResultPodcastsArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
}

export type SearchResultSpeakersArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
}

export type SearchResultHighlight = {
  __typename?: 'SearchResultHighlight'
  companyName?: Maybe<Scalars['String']['output']>
  companyPosition?: Maybe<Scalars['String']['output']>
}

/** Autogenerated input type of SendIndividualInvitations */
export type SendIndividualInvitationsInput = {
  cc?: InputMaybe<Scalars['String']['input']>
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>
  emails: Array<Scalars['String']['input']>
  invitationQuotaId: Scalars['ID']['input']
  messageBody: Scalars['String']['input']
  messageSubject: Scalars['String']['input']
  replyTo: Scalars['String']['input']
}

/** Autogenerated return type of SendIndividualInvitations. */
export type SendIndividualInvitationsPayload = {
  __typename?: 'SendIndividualInvitationsPayload'
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>
  errors: Array<Scalars['String']['output']>
  success: Scalars['Boolean']['output']
}

/** Autogenerated input type of SendInvitations */
export type SendInvitationsInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>
  invitationQuotaId: Scalars['ID']['input']
}

/** Autogenerated return type of SendInvitations. */
export type SendInvitationsPayload = {
  __typename?: 'SendInvitationsPayload'
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>
  errors: Array<Scalars['String']['output']>
  success: Scalars['Boolean']['output']
}

/** Autogenerated input type of SendProspectListInvitations */
export type SendProspectListInvitationsInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>
  prospectListId: Scalars['ID']['input']
}

/** Autogenerated return type of SendProspectListInvitations. */
export type SendProspectListInvitationsPayload = {
  __typename?: 'SendProspectListInvitationsPayload'
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>
  errors: Array<Scalars['String']['output']>
  success: Scalars['Boolean']['output']
}

/** Autogenerated input type of SendTestEmail */
export type SendTestEmailInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>
  invitationQuotaId: Scalars['ID']['input']
}

/** Autogenerated return type of SendTestEmail. */
export type SendTestEmailPayload = {
  __typename?: 'SendTestEmailPayload'
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>
  errors: Array<Scalars['String']['output']>
  success: Scalars['Boolean']['output']
}

/** Autogenerated input type of SendTestInvitation */
export type SendTestInvitationInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>
  email: Scalars['String']['input']
  prospectListId: Scalars['ID']['input']
}

/** Autogenerated return type of SendTestInvitation. */
export type SendTestInvitationPayload = {
  __typename?: 'SendTestInvitationPayload'
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>
  errors: Array<Scalars['String']['output']>
  success: Scalars['Boolean']['output']
}

/** Autogenerated input type of SetPaymentInfo */
export type SetPaymentInfoInput = {
  attributes: TicketPaymentInfoAttributes
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>
  ticketId: Scalars['ID']['input']
}

/** Autogenerated return type of SetPaymentInfo. */
export type SetPaymentInfoPayload = {
  __typename?: 'SetPaymentInfoPayload'
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>
  errors: Array<Scalars['String']['output']>
  ticket?: Maybe<Ticket>
}

export enum SourceEnum {
  FreeOnlineSeminars = 'free_online_seminars',
  Instagram = 'instagram',
  LinkedIn = 'linkedIn',
  MentionInAPodcast = 'mention_in_a_podcast',
  OmrDeepDive = 'omr_deep_dive',
  OmrFestival = 'omr_festival',
  Other = 'other',
  PersonalRecommendation = 'personal_recommendation',
  Press = 'press',
  SearchEngine = 'search_engine',
}

export type Speaker = Node & {
  __typename?: 'Speaker'
  createdAt: Scalars['ISO8601DateTime']['output']
  description?: Maybe<Scalars['String']['output']>
  descriptionDe?: Maybe<Scalars['String']['output']>
  descriptionEn?: Maybe<Scalars['String']['output']>
  editorialFlag?: Maybe<Scalars['Boolean']['output']>
  email?: Maybe<Scalars['String']['output']>
  eventSpeakers?: Maybe<Array<EventSpeaker>>
  events?: Maybe<EventConnectionWithTotalCount>
  facebookUrl?: Maybe<Scalars['String']['output']>
  firstname?: Maybe<Scalars['String']['output']>
  id: Scalars['ID']['output']
  instagramUrl?: Maybe<Scalars['String']['output']>
  interests?: Maybe<Array<Interest>>
  lastname?: Maybe<Scalars['String']['output']>
  linkedinUrl?: Maybe<Scalars['String']['output']>
  name?: Maybe<Scalars['String']['output']>
  omrSpeakerProfileUrl?: Maybe<Scalars['String']['output']>
  partnerCompany?: Maybe<PartnerCompany>
  partnerCompanyLogoUrl?: Maybe<Scalars['String']['output']>
  partnerCompanyName?: Maybe<Scalars['String']['output']>
  photoUrl?: Maybe<Scalars['String']['output']>
  /** @deprecated position is deprecated, use title instead */
  position?: Maybe<Scalars['String']['output']>
  seoDescription?: Maybe<Scalars['String']['output']>
  seoTitle?: Maybe<Scalars['String']['output']>
  slug?: Maybe<Scalars['String']['output']>
  title?: Maybe<Scalars['String']['output']>
  twitterUrl?: Maybe<Scalars['String']['output']>
  websiteUrl?: Maybe<Scalars['String']['output']>
  youtubeUrl?: Maybe<Scalars['String']['output']>
}

export type SpeakerEventsArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
}

/** Attributes for creating or updating an speaker */
export type SpeakerAttributes = {
  /** german description */
  description?: InputMaybe<Scalars['String']['input']>
  /** german description */
  descriptionDe?: InputMaybe<Scalars['String']['input']>
  /** englisch description */
  descriptionEn?: InputMaybe<Scalars['String']['input']>
  /** email */
  email?: InputMaybe<Scalars['String']['input']>
  /** facebook url */
  facebookUrl?: InputMaybe<Scalars['String']['input']>
  /** first name */
  firstname?: InputMaybe<Scalars['String']['input']>
  /** insagram url */
  instagramUrl?: InputMaybe<Scalars['String']['input']>
  /** Interests */
  interests?: InputMaybe<Array<Scalars['ID']['input']>>
  /** lastname */
  lastname?: InputMaybe<Scalars['String']['input']>
  /** linkedin url */
  linkedinUrl?: InputMaybe<Scalars['String']['input']>
  /** name */
  name?: InputMaybe<Scalars['String']['input']>
  /** partner company name */
  partnerCompanyName?: InputMaybe<Scalars['String']['input']>
  photo?: InputMaybe<Scalars['Upload']['input']>
  /** seo description */
  seoDescription?: InputMaybe<Scalars['String']['input']>
  /** seo title */
  seoTitle?: InputMaybe<Scalars['String']['input']>
  /** title */
  title?: InputMaybe<Scalars['String']['input']>
  /** twitter url */
  twitterUrl?: InputMaybe<Scalars['String']['input']>
  /** website url */
  websiteUrl?: InputMaybe<Scalars['String']['input']>
  /** youtube url */
  youtubeUrl?: InputMaybe<Scalars['String']['input']>
}

/** The connection type for Speaker. */
export type SpeakerConnection = {
  __typename?: 'SpeakerConnection'
  /** A list of edges. */
  edges: Array<SpeakerEdge>
  /** A list of nodes. */
  nodes: Array<Speaker>
  /** Information to aid in pagination. */
  pageInfo: PageInfo
  totalCount: Scalars['Int']['output']
}

/** An edge in a connection. */
export type SpeakerEdge = {
  __typename?: 'SpeakerEdge'
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output']
  /** The item at the end of the edge. */
  node?: Maybe<Speaker>
}

export type SpeakerMatch = {
  __typename?: 'SpeakerMatch'
  highlight?: Maybe<Scalars['String']['output']>
  id: Scalars['ID']['output']
  name: Scalars['String']['output']
  photoUrl: Scalars['String']['output']
  slug: Scalars['String']['output']
  title?: Maybe<Scalars['String']['output']>
  url: Scalars['String']['output']
}

export type SpeakerMatchPaginatedCollection = {
  __typename?: 'SpeakerMatchPaginatedCollection'
  nodes: Array<SpeakerMatch>
  pageInfo: OffsetPaginationInfo
}

export enum SpeakerRoleEnum {
  Artist = 'Artist',
  Speaker = 'Speaker',
}

export type Stage = Node & {
  __typename?: 'Stage'
  events: Array<Event>
  id: Scalars['ID']['output']
  name: Scalars['String']['output']
  /** @deprecated deprecated, use name instead */
  nameDe?: Maybe<Scalars['String']['output']>
  /** @deprecated deprecated, use name instead */
  nameEn?: Maybe<Scalars['String']['output']>
  partnerCompanies?: Maybe<Array<PartnerCompany>>
  slug: Scalars['String']['output']
  speakers: Array<Speaker>
  subtitleDe?: Maybe<Scalars['String']['output']>
  subtitleEn?: Maybe<Scalars['String']['output']>
  timetablePosition?: Maybe<Scalars['Int']['output']>
}

/** Autogenerated input type of SubmitProspectList */
export type SubmitProspectListInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>
  prospectListId: Scalars['ID']['input']
}

/** Autogenerated return type of SubmitProspectList. */
export type SubmitProspectListPayload = {
  __typename?: 'SubmitProspectListPayload'
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>
  errors: Array<Scalars['String']['output']>
  prospectList?: Maybe<ProspectList>
}

/** Autogenerated input type of SubmitVolunteer */
export type SubmitVolunteerInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>
}

export type Summary = {
  __typename?: 'Summary'
  bulkDiscounts: Array<BulkDiscount>
  errors?: Maybe<Array<Error>>
  lineItems: Array<LineItem>
  promoCodeDiscount?: Maybe<PromoCodeDiscount>
  totals: SummaryTotals
}

export type SummaryTotals = {
  __typename?: 'SummaryTotals'
  gross: Scalars['Int']['output']
  net: Scalars['Int']['output']
  taxes: Scalars['Int']['output']
}

export enum TagListTypeEnum {
  BrandedRoomBooth = 'BrandedRoomBooth',
  BreakEvent = 'BreakEvent',
  CareerMeetup = 'CareerMeetup',
  CoExhibitorBooth = 'CoExhibitorBooth',
  DeepDive = 'DeepDive',
  DigitalMasterclass = 'DigitalMasterclass',
  Event = 'Event',
  GastroBooth = 'GastroBooth',
  GuidedTour = 'GuidedTour',
  Masterclass = 'Masterclass',
  PremiumBooth = 'PremiumBooth',
  PremiumLoungeBooth = 'PremiumLoungeBooth',
  PremiumOutdoorBooth = 'PremiumOutdoorBooth',
  SamplingBooth = 'SamplingBooth',
  SeacontainerBooth = 'SeacontainerBooth',
  SideEvent = 'SideEvent',
  StageSlot = 'StageSlot',
  StartupBooth = 'StartupBooth',
  SystemBooth = 'SystemBooth',
}

export type Ticket = {
  __typename?: 'Ticket'
  aasmState: TicketAasmStateEnum
  ableToCheckIn: Scalars['Boolean']['output']
  accreditationMessage?: Maybe<Scalars['String']['output']>
  birthdate?: Maybe<Scalars['ISO8601Date']['output']>
  companyDepartment?: Maybe<CompanyDepartmentEnum>
  companyFieldId?: Maybe<Scalars['Int']['output']>
  companyIndustryId?: Maybe<Scalars['Int']['output']>
  companyName?: Maybe<Scalars['String']['output']>
  companySize?: Maybe<CompanySizeEnum>
  companyType?: Maybe<CompanyTypeEnum>
  country?: Maybe<Scalars['String']['output']>
  createdAt: Scalars['String']['output']
  deactivatedAt?: Maybe<Scalars['ISO8601DateTime']['output']>
  email?: Maybe<Scalars['String']['output']>
  ffwdConsent?: Maybe<Scalars['Boolean']['output']>
  fields?: Maybe<Array<Field>>
  firstName?: Maybe<Scalars['String']['output']>
  id: Scalars['ID']['output']
  identifier: Scalars['String']['output']
  isDataComplete: Scalars['Boolean']['output']
  isEditable: Scalars['Boolean']['output']
  isPaymentInfoEnabled: Scalars['Boolean']['output']
  isTicketCategoryEnded: Scalars['Boolean']['output']
  jobLevel?: Maybe<JobLevelEnum>
  lastName?: Maybe<Scalars['String']['output']>
  merchSize?: Maybe<MerchSizeEnum>
  omrMatchConsent?: Maybe<Scalars['Boolean']['output']>
  onsite: Onsite
  ownership?: Maybe<TicketOwnershipTypeEnum>
  partnerName?: Maybe<Scalars['String']['output']>
  partnerRole?: Maybe<Scalars['String']['output']>
  passbookDownloadUrl?: Maybe<Scalars['String']['output']>
  paymentInfo?: Maybe<PaymentInfo>
  pdfDownloadUrl?: Maybe<Scalars['String']['output']>
  phone?: Maybe<Scalars['String']['output']>
  position?: Maybe<Scalars['String']['output']>
  primaryFieldCategory?: Maybe<FieldCategory>
  qrCodePayload: Scalars['String']['output']
  qrCodeUrl?: Maybe<Scalars['String']['output']>
  salutation?: Maybe<SalutationTypeEnum>
  source: TicketSourceEnum
  /** Is true if user has swapped the ticket for a badge */
  swapped: Scalars['Boolean']['output']
  ticketCategory: TicketCategory
  title?: Maybe<Scalars['String']['output']>
  updatedAt: Scalars['String']['output']
  user?: Maybe<User>
}

export enum TicketAasmStateEnum {
  Canceled = 'canceled',
  Completed = 'completed',
  Locked = 'locked',
  Unlocked = 'unlocked',
}

export type TicketCategory = {
  __typename?: 'TicketCategory'
  descriptionDe?: Maybe<Scalars['String']['output']>
  descriptionEn?: Maybe<Scalars['String']['output']>
  endTime?: Maybe<Scalars['String']['output']>
  event: Event
  getBackgroundColor?: Maybe<Scalars['String']['output']>
  getTextColor?: Maybe<Scalars['String']['output']>
  hashId?: Maybe<Scalars['String']['output']>
  id: Scalars['ID']['output']
  location?: Maybe<Scalars['String']['output']>
  offerEndTime?: Maybe<Scalars['String']['output']>
  price?: Maybe<Scalars['Float']['output']>
  public: Scalars['Boolean']['output']
  requiresFfwdConsent: Scalars['Boolean']['output']
  startTime?: Maybe<Scalars['String']['output']>
  title: Scalars['String']['output']
  titleDe?: Maybe<Scalars['String']['output']>
  titleEn?: Maybe<Scalars['String']['output']>
}

/** Input type for a single ticket category */
export type TicketCategoryInput = {
  count: Scalars['Int']['input']
  ticketCategoryId: Scalars['String']['input']
}

/** The connection type for Ticket. */
export type TicketConnection = {
  __typename?: 'TicketConnection'
  canceledCount: Scalars['Int']['output']
  completedCount: Scalars['Int']['output']
  /** A list of edges. */
  edges: Array<TicketEdge>
  lockedCount: Scalars['Int']['output']
  /** A list of nodes. */
  nodes: Array<Ticket>
  /** Information to aid in pagination. */
  pageInfo: PageInfo
  totalCount: Scalars['Int']['output']
  unlockedCount: Scalars['Int']['output']
}

/** An edge in a connection. */
export type TicketEdge = {
  __typename?: 'TicketEdge'
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output']
  /** The item at the end of the edge. */
  node?: Maybe<Ticket>
}

export enum TicketEvent {
  Cancel = 'cancel',
  Complete = 'complete',
  Unlock = 'unlock',
}

export enum TicketOrderAttributesEnum {
  AasmState = 'aasm_state',
  CreatedAt = 'created_at',
  Id = 'id',
  UpdatedAt = 'updated_at',
}

export enum TicketOwnershipTypeEnum {
  Me = 'me',
  Other = 'other',
}

export type TicketPaymentInfoAttributes = {
  additionalAddress?: InputMaybe<Scalars['String']['input']>
  address: Scalars['String']['input']
  business: Scalars['Boolean']['input']
  city: Scalars['String']['input']
  company?: InputMaybe<Scalars['String']['input']>
  country: Scalars['String']['input']
  customerReference?: InputMaybe<Scalars['String']['input']>
  email: Scalars['String']['input']
  firstName: Scalars['String']['input']
  lastName: Scalars['String']['input']
  salutation: Scalars['String']['input']
  vatNumber?: InputMaybe<Scalars['String']['input']>
  zip: Scalars['String']['input']
}

export type TicketShopQuery = {
  __typename?: 'TicketShopQuery'
  /** Ticket shop compute */
  compute: Summary
  /** Ticket Categories for the ticket shop */
  ticketCategories: Array<TicketShopTicketCategory>
  /** tickets */
  tickets: Array<Ticket>
}

export type TicketShopQueryComputeArgs = {
  inviteeToken?: InputMaybe<Scalars['String']['input']>
  promoCode?: InputMaybe<Scalars['String']['input']>
  ticketCategories: Array<TicketCategoryInput>
}

export type TicketShopQueryTicketCategoriesArgs = {
  eventSlug: Scalars['String']['input']
  inviteeToken?: InputMaybe<Scalars['String']['input']>
  promoCode?: InputMaybe<Scalars['String']['input']>
}

export type TicketShopQueryTicketsArgs = {
  afterPaymentToken: Scalars['String']['input']
  orderBy?: InputMaybe<TicketOrderAttributesEnum>
  orderDirection?: InputMaybe<OrderDirectionEnum>
}

export type TicketShopTicketCategory = {
  __typename?: 'TicketShopTicketCategory'
  bulkDiscount?: Maybe<Scalars['CentsAmount']['output']>
  bulkDiscountActivationLimit?: Maybe<Scalars['Int']['output']>
  currentPromoCode?: Maybe<Scalars['String']['output']>
  currentPromoCodeTimesUsable?: Maybe<Scalars['Int']['output']>
  description: Scalars['String']['output']
  descriptionDe: Scalars['String']['output']
  descriptionEn: Scalars['String']['output']
  discountAmount?: Maybe<Scalars['CentsAmount']['output']>
  discountPercentage?: Maybe<Scalars['Int']['output']>
  discountedPriceFromGross: Scalars['CentsAmount']['output']
  endTime: Scalars['ISO8601DateTime']['output']
  event: PublishedEvent
  expired: Scalars['Boolean']['output']
  fields: Array<Field>
  grossDiscount: Scalars['CentsAmount']['output']
  grossPrice: Scalars['CentsAmount']['output']
  id: Scalars['ID']['output']
  inMultiplesOf: Scalars['Int']['output']
  location: Scalars['String']['output']
  maxPurchaseable: Scalars['Int']['output']
  offerEndTime?: Maybe<Scalars['ISO8601DateTime']['output']>
  period: Scalars['String']['output']
  price: Scalars['CentsAmount']['output']
  public: Scalars['Boolean']['output']
  startTime: Scalars['ISO8601DateTime']['output']
  /** The regular price of the ticket outside any promotion period. */
  strikeThroughPrice?: Maybe<Scalars['CentsAmount']['output']>
  title: Scalars['String']['output']
  titleDe: Scalars['String']['output']
  titleEn: Scalars['String']['output']
}

export enum TicketSourceEnum {
  Airtable = 'airtable',
  Default = 'default',
  DmcSpeakerTicket = 'dmc_speaker_ticket',
  OnDemand = 'on_demand',
  RequestForm = 'request_form',
}

/** Attributes for updating a ticket */
export type TicketUpdateAttributes = {
  companyDepartment?: InputMaybe<CompanyDepartmentEnum>
  companyFieldId?: InputMaybe<Scalars['Int']['input']>
  companyIndustryId?: InputMaybe<Scalars['Int']['input']>
  companyName?: InputMaybe<Scalars['String']['input']>
  companySize?: InputMaybe<CompanySizeEnum>
  companyType?: InputMaybe<CompanyTypeEnum>
  country?: InputMaybe<Scalars['String']['input']>
  email?: InputMaybe<Scalars['String']['input']>
  ffwdConsent?: InputMaybe<Scalars['Boolean']['input']>
  firstName?: InputMaybe<Scalars['String']['input']>
  jobLevel?: InputMaybe<JobLevelEnum>
  lastName?: InputMaybe<Scalars['String']['input']>
  omrMatchConsent?: InputMaybe<Scalars['Boolean']['input']>
  ownership?: InputMaybe<TicketOwnershipTypeEnum>
  partnerName?: InputMaybe<Scalars['String']['input']>
  partnerRole?: InputMaybe<Scalars['String']['input']>
  phone?: InputMaybe<Scalars['String']['input']>
  position?: InputMaybe<Scalars['String']['input']>
  salutation?: InputMaybe<SalutationTypeEnum>
  title?: InputMaybe<TitleTypeEnum>
}

export type Timetable = {
  __typename?: 'Timetable'
  events: Array<PublishedEvent>
}

export enum TitleTypeEnum {
  DiplIng = 'dipl_ing',
  Dr = 'dr',
  Ing = 'ing',
  Mag = 'mag',
  Prof = 'prof',
  ProfDr = 'prof_dr',
}

export enum TransactionTypeEnum {
  Credit = 'credit',
  Debit = 'debit',
}

/** Autogenerated input type of UpdateAcknowledgement */
export type UpdateAcknowledgementInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>
  docAcknowledgementId: Scalars['ID']['input']
  document?: InputMaybe<Scalars['Upload']['input']>
}

/** Autogenerated return type of UpdateAcknowledgement. */
export type UpdateAcknowledgementPayload = {
  __typename?: 'UpdateAcknowledgementPayload'
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>
  docAcknowledgement?: Maybe<DocAcknowledgement>
  errors: Array<Scalars['String']['output']>
}

/** Autogenerated input type of UpdateAttendeesStateFromCsv */
export type UpdateAttendeesStateFromCsvInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>
  csvFile: Scalars['Upload']['input']
  eventId: Scalars['ID']['input']
}

/** Autogenerated return type of UpdateAttendeesStateFromCsv. */
export type UpdateAttendeesStateFromCsvPayload = {
  __typename?: 'UpdateAttendeesStateFromCsvPayload'
  accepted?: Maybe<Scalars['Int']['output']>
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>
  denied?: Maybe<Scalars['Int']['output']>
  errors: Array<Scalars['String']['output']>
  failed?: Maybe<Scalars['Int']['output']>
  notChanged?: Maybe<Scalars['Int']['output']>
}

/** Autogenerated input type of UpdateBillingAddress */
export type UpdateBillingAddressInput = {
  attributes: BillingAddressAttributes
  billingAddressId: Scalars['ID']['input']
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>
}

/** Autogenerated return type of UpdateBillingAddress. */
export type UpdateBillingAddressPayload = {
  __typename?: 'UpdateBillingAddressPayload'
  billingAddress?: Maybe<BillingAddress>
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>
  errors: Array<Scalars['String']['output']>
}

/** Autogenerated input type of UpdateBooth */
export type UpdateBoothInput = {
  attributes: BoothAttributes
  boothId: Scalars['ID']['input']
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>
}

/** Autogenerated return type of UpdateBooth. */
export type UpdateBoothPayload = {
  __typename?: 'UpdateBoothPayload'
  booth?: Maybe<Booth>
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>
  errors: Array<Scalars['String']['output']>
}

/** Autogenerated input type of UpdateContact */
export type UpdateContactInput = {
  attributes: ContactAttributes
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>
  contactId: Scalars['ID']['input']
}

/** Autogenerated return type of UpdateContact. */
export type UpdateContactPayload = {
  __typename?: 'UpdateContactPayload'
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>
  contact?: Maybe<Contact>
  errors: Array<Scalars['String']['output']>
}

/** Autogenerated input type of UpdateDirectTicket */
export type UpdateDirectTicketInput = {
  attributes: DirectTicketAttributes
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>
  /** Events to be triggered on ticket after update */
  events?: InputMaybe<Array<TicketEvent>>
  ticketId: Scalars['ID']['input']
}

/** Autogenerated return type of UpdateDirectTicket. */
export type UpdateDirectTicketPayload = {
  __typename?: 'UpdateDirectTicketPayload'
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>
  errors: Array<Scalars['String']['output']>
  ticket?: Maybe<Ticket>
}

/** Autogenerated input type of UpdateEvent */
export type UpdateEventInput = {
  attributes: EventAttributes
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>
  eventId: Scalars['ID']['input']
}

/** Autogenerated return type of UpdateEvent. */
export type UpdateEventPayload = {
  __typename?: 'UpdateEventPayload'
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>
  errors: Array<Scalars['String']['output']>
  event?: Maybe<Event>
}

/** Autogenerated input type of UpdateEventStarrings */
export type UpdateEventStarringsInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>
  updates: Array<EventStarringsUpdate>
}

/** Autogenerated return type of UpdateEventStarrings. */
export type UpdateEventStarringsPayload = {
  __typename?: 'UpdateEventStarringsPayload'
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>
  errors?: Maybe<Array<Scalars['String']['output']>>
  success: Scalars['Boolean']['output']
}

/** Autogenerated input type of UpdateExtras */
export type UpdateExtrasInput = {
  boothId: Scalars['ID']['input']
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>
  extras: Array<ExtraAttributes>
}

/** Autogenerated return type of UpdateExtras. */
export type UpdateExtrasPayload = {
  __typename?: 'UpdateExtrasPayload'
  booth?: Maybe<Booth>
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>
  errors: Array<Scalars['String']['output']>
}

/** Autogenerated input type of UpdateInterests */
export type UpdateInterestsInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>
  updates: Array<InterestsUpdate>
}

/** Autogenerated return type of UpdateInterests. */
export type UpdateInterestsPayload = {
  __typename?: 'UpdateInterestsPayload'
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>
  errors?: Maybe<Array<Scalars['String']['output']>>
  me?: Maybe<User>
  success: Scalars['Boolean']['output']
}

/** Autogenerated input type of UpdateLead */
export type UpdateLeadInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>
  note?: InputMaybe<Scalars['String']['input']>
  scannedTicket: Scalars['String']['input']
  tags?: InputMaybe<Scalars['String']['input']>
}

/** Autogenerated return type of UpdateLead. */
export type UpdateLeadPayload = {
  __typename?: 'UpdateLeadPayload'
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>
  errors: Array<Scalars['String']['output']>
  lead?: Maybe<Lead>
}

/** Autogenerated input type of UpdateMe */
export type UpdateMeInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>
  locale?: InputMaybe<LocaleEnum>
  reviewsGuidelinesAccepted?: InputMaybe<Scalars['Boolean']['input']>
}

/** Autogenerated return type of UpdateMe. */
export type UpdateMePayload = {
  __typename?: 'UpdateMePayload'
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>
  errors: Array<Scalars['String']['output']>
  user?: Maybe<User>
}

/** Attributes for updating orders */
export type UpdateOrderAttributes = {
  additionalAddress?: InputMaybe<Scalars['String']['input']>
  business?: InputMaybe<Scalars['Boolean']['input']>
  city?: InputMaybe<Scalars['String']['input']>
  company?: InputMaybe<Scalars['String']['input']>
  country?: InputMaybe<Scalars['String']['input']>
  customerReference?: InputMaybe<Scalars['String']['input']>
  email?: InputMaybe<Scalars['String']['input']>
  firstName?: InputMaybe<Scalars['String']['input']>
  lastName?: InputMaybe<Scalars['String']['input']>
  number?: InputMaybe<Scalars['String']['input']>
  salutation?: InputMaybe<Scalars['String']['input']>
  street?: InputMaybe<Scalars['String']['input']>
  title?: InputMaybe<Scalars['String']['input']>
  vatNumber?: InputMaybe<Scalars['String']['input']>
  zip?: InputMaybe<Scalars['String']['input']>
}

/** Autogenerated input type of UpdateOrder */
export type UpdateOrderInput = {
  afterPaymentToken: Scalars['String']['input']
  attributes: UpdateOrderAttributes
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>
}

/** Autogenerated return type of UpdateOrder. */
export type UpdateOrderPayload = {
  __typename?: 'UpdateOrderPayload'
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>
  errors: Array<Scalars['String']['output']>
  order?: Maybe<CheckoutOrder>
}

/** Autogenerated input type of UpdatePartnerCompany */
export type UpdatePartnerCompanyInput = {
  attributes: PartnerCompanyAttributes
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>
  partnerCompanyId: Scalars['ID']['input']
}

/** Autogenerated return type of UpdatePartnerCompany. */
export type UpdatePartnerCompanyPayload = {
  __typename?: 'UpdatePartnerCompanyPayload'
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>
  errors: Array<Scalars['String']['output']>
  partnerCompany?: Maybe<PartnerCompany>
}

/** Autogenerated input type of UpdateProspectList */
export type UpdateProspectListInput = {
  attributes: ProspectListAttributes
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>
  prospectListId: Scalars['ID']['input']
}

/** Autogenerated return type of UpdateProspectList. */
export type UpdateProspectListPayload = {
  __typename?: 'UpdateProspectListPayload'
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>
  errors: Array<Scalars['String']['output']>
  prospectList?: Maybe<ProspectList>
}

/** Autogenerated input type of UpdateSpeaker */
export type UpdateSpeakerInput = {
  attributes: SpeakerAttributes
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>
  speakerId: Scalars['ID']['input']
}

/** Autogenerated return type of UpdateSpeaker. */
export type UpdateSpeakerPayload = {
  __typename?: 'UpdateSpeakerPayload'
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>
  errors: Array<Scalars['String']['output']>
  speaker?: Maybe<Speaker>
}

/** Autogenerated input type of UpdateTicket */
export type UpdateTicketInput = {
  attributes: TicketUpdateAttributes
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>
  ticketId: Scalars['ID']['input']
}

/** Autogenerated return type of UpdateTicket. */
export type UpdateTicketPayload = {
  __typename?: 'UpdateTicketPayload'
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>
  errors: Array<Scalars['String']['output']>
  ticket?: Maybe<Ticket>
}

/** Autogenerated input type of UpdateUser */
export type UpdateUserInput = {
  attributes: UserAttributes
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>
  userId: Scalars['ID']['input']
}

/** Autogenerated return type of UpdateUser. */
export type UpdateUserPayload = {
  __typename?: 'UpdateUserPayload'
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>
  errors: Array<Scalars['String']['output']>
  user?: Maybe<User>
}

/** Autogenerated input type of UpdateUserRoles */
export type UpdateUserRolesInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>
  /** Role names */
  roleNames?: InputMaybe<Array<RoleNameEnum>>
  userId: Scalars['ID']['input']
}

/** Autogenerated return type of UpdateUserRoles. */
export type UpdateUserRolesPayload = {
  __typename?: 'UpdateUserRolesPayload'
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>
  errors: Array<Scalars['String']['output']>
  user?: Maybe<User>
}

/** Autogenerated input type of UploadInvitees */
export type UploadInviteesInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>
  csvFile: Scalars['Upload']['input']
  invitationQuotaId: Scalars['ID']['input']
}

/** Autogenerated return type of UploadInvitees. */
export type UploadInviteesPayload = {
  __typename?: 'UploadInviteesPayload'
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>
  csvPreviewData?: Maybe<Array<CsvPreviewData>>
  csvUploadId?: Maybe<Scalars['ID']['output']>
  errors: Array<Scalars['String']['output']>
}

/** Autogenerated input type of UpsertLeads */
export type UpsertLeadsInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>
  leadAttributes: Array<LeadAttributes>
}

/** Autogenerated return type of UpsertLeads. */
export type UpsertLeadsPayload = {
  __typename?: 'UpsertLeadsPayload'
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>
  errors: Array<Scalars['String']['output']>
  leads?: Maybe<Array<Lead>>
}

/** Autogenerated input type of UpsertVolunteer */
export type UpsertVolunteerInput = {
  attributes: VolunteerAttributes
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>
}

export type User = Node & {
  __typename?: 'User'
  attendingEvents: Array<PublishedEvent>
  birthdate?: Maybe<Scalars['String']['output']>
  companyAdditionalAddress?: Maybe<Scalars['String']['output']>
  companyCity?: Maybe<Scalars['String']['output']>
  companyCountry?: Maybe<Scalars['String']['output']>
  companyDepartment?: Maybe<CompanyDepartmentEnum>
  companyEmail?: Maybe<Scalars['String']['output']>
  companyFieldId?: Maybe<Scalars['ID']['output']>
  companyFieldName?: Maybe<Scalars['String']['output']>
  companyHomepage?: Maybe<Scalars['String']['output']>
  companyIndustryId?: Maybe<Scalars['ID']['output']>
  companyIndustryName?: Maybe<Scalars['String']['output']>
  companyName?: Maybe<Scalars['String']['output']>
  companyNumber?: Maybe<Scalars['String']['output']>
  companyPhone?: Maybe<Scalars['String']['output']>
  companySize?: Maybe<CompanySizeEnum>
  companyStreet?: Maybe<Scalars['String']['output']>
  companyZip?: Maybe<Scalars['String']['output']>
  createdAt: Scalars['ISO8601DateTime']['output']
  currentTransactions?: Maybe<Array<BadgeTransaction>>
  digitalMasterclasses: Array<DigitalMasterclass>
  email: Scalars['String']['output']
  eventApplications: Array<EventApplication>
  eventStarrings: Array<Scalars['ID']['output']>
  firstName?: Maybe<Scalars['String']['output']>
  id: Scalars['ID']['output']
  interests: Array<Interest>
  jobLevel?: Maybe<JobLevelEnum>
  lastName?: Maybe<Scalars['String']['output']>
  locale: Scalars['String']['output']
  /** @deprecated masterclasses is deprecated, use attending_events */
  masterclasses: Array<PublishedEvent>
  name?: Maybe<Scalars['String']['output']>
  networkingAccepted: Scalars['Boolean']['output']
  newsTags?: Maybe<Array<Scalars['String']['output']>>
  oauthProviders: Array<OauthProviderEnum>
  omrMatchConsent: Scalars['Boolean']['output']
  omrMatchConsentDate?: Maybe<Scalars['ISO8601DateTime']['output']>
  partnerCompanies?: Maybe<Array<PartnerCompany>>
  partnerCompany?: Maybe<PartnerCompany>
  phone?: Maybe<Scalars['String']['output']>
  position?: Maybe<Scalars['String']['output']>
  profileImage: Scalars['String']['output']
  profileScopes: Array<ProfileScopeEnum>
  reviewsGuidelinesAccepted?: Maybe<Scalars['Boolean']['output']>
  roles: Array<Role>
  salutation?: Maybe<SalutationTypeEnum>
  spentAmount?: Maybe<Scalars['Float']['output']>
  tickets: Array<Ticket>
  unconfirmedEmail?: Maybe<Scalars['String']['output']>
}

export type UserAttendingEventsArgs = {
  eventSlug: Scalars['String']['input']
}

export type UserCurrentTransactionsArgs = {
  eventSlug: Scalars['String']['input']
}

export type UserDigitalMasterclassesArgs = {
  eventSlug: Scalars['String']['input']
}

export type UserEventApplicationsArgs = {
  ticketId: Scalars['ID']['input']
}

export type UserEventStarringsArgs = {
  eventSlug: Scalars['String']['input']
}

export type UserMasterclassesArgs = {
  eventSlug: Scalars['String']['input']
}

export type UserSpentAmountArgs = {
  eventSlug: Scalars['String']['input']
}

export type UserTicketsArgs = {
  aasmState?: InputMaybe<Array<TicketAasmStateEnum>>
  eventSlug: Scalars['String']['input']
}

/** Attributes for updating a user */
export type UserAttributes = {
  birthdate?: InputMaybe<Scalars['String']['input']>
  companyAdditionalAddress?: InputMaybe<Scalars['String']['input']>
  companyCity?: InputMaybe<Scalars['String']['input']>
  companyCountry?: InputMaybe<Scalars['String']['input']>
  companyDepartment?: InputMaybe<CompanyDepartmentEnum>
  companyEmail?: InputMaybe<Scalars['String']['input']>
  companyFieldId?: InputMaybe<Scalars['ID']['input']>
  companyHomepage?: InputMaybe<Scalars['String']['input']>
  companyIndustryId?: InputMaybe<Scalars['ID']['input']>
  companyName?: InputMaybe<Scalars['String']['input']>
  companyNumber?: InputMaybe<Scalars['String']['input']>
  companyPhone?: InputMaybe<Scalars['String']['input']>
  companySize?: InputMaybe<CompanySizeEnum>
  companyStreet?: InputMaybe<Scalars['String']['input']>
  companyType?: InputMaybe<CompanyTypeEnum>
  companyZip?: InputMaybe<Scalars['String']['input']>
  email?: InputMaybe<Scalars['String']['input']>
  firstName?: InputMaybe<Scalars['String']['input']>
  jobLevel?: InputMaybe<JobLevelEnum>
  lastName?: InputMaybe<Scalars['String']['input']>
  networkingAccepted?: InputMaybe<Scalars['Boolean']['input']>
  omrMatchConsent?: InputMaybe<Scalars['Boolean']['input']>
  phone?: InputMaybe<Scalars['String']['input']>
  position?: InputMaybe<Scalars['String']['input']>
  profileImage?: InputMaybe<Scalars['Upload']['input']>
  salutation?: InputMaybe<SalutationTypeEnum>
}

/** The connection type for User. */
export type UserConnection = {
  __typename?: 'UserConnection'
  /** A list of edges. */
  edges: Array<UserEdge>
  /** A list of nodes. */
  nodes: Array<User>
  /** Information to aid in pagination. */
  pageInfo: PageInfo
  totalCount: Scalars['Int']['output']
}

/** An edge in a connection. */
export type UserEdge = {
  __typename?: 'UserEdge'
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output']
  /** The item at the end of the edge. */
  node?: Maybe<User>
}

export type UserPropertiesPreferences = {
  __typename?: 'UserPropertiesPreferences'
  companyDepartments: Array<CompanyDepartments>
  companyFields: Array<CompanyFields>
  companyFieldsByIndustry: Array<CompanyFields>
  companyIndustries: Array<CompanyIndustries>
  companySizes: Array<CompanySizes>
  companyTypes: Array<CompanyTypes>
  countries: Array<Country>
  id: Scalars['ID']['output']
  jobLevels: Array<JobLevels>
  salutations: Array<Salutations>
}

export type UserPropertiesPreferencesCompanyFieldsByIndustryArgs = {
  id: Scalars['ID']['input']
}

export type Variant = {
  __typename?: 'Variant'
  currency: Scalars['String']['output']
  id: Scalars['ID']['output']
  name: Scalars['String']['output']
  price: Scalars['Float']['output']
  product: Product
}

export type VisitedEvent = {
  __typename?: 'VisitedEvent'
  name: Scalars['String']['output']
  slug: Scalars['ID']['output']
}

export type Volunteer = {
  __typename?: 'Volunteer'
  /** Bewerbungsstatus */
  applicationState?: Maybe<VolunteerApplicationStateEnum>
  /** Ausbilding in der Profession (Crew Professional) */
  apprenticeship?: Maybe<Scalars['Boolean']['output']>
  /** Deine Verfügbarkeiten */
  availability?: Maybe<Array<VolunteerAvailabilityEnum>>
  /** Awareness Work Experience */
  awarenessWorkExperience?: Maybe<Scalars['String']['output']>
  /** Awareness Work Most Important */
  awarenessWorkMostImportant?: Maybe<Scalars['String']['output']>
  /** Bar experience */
  barExperience?: Maybe<VolunteerBarExperienceEnum>
  /** BIC */
  bic?: Maybe<Scalars['String']['output']>
  /** Billing Data */
  billingDataComplete?: Maybe<Scalars['Boolean']['output']>
  /** Billing Form */
  billingForm?: Maybe<VolunteerBillingFormEnum>
  /** Text Abrechnungsdaten sind eingegangen (myOMR) (from Workforce) */
  billingInfoReceivedMessage?: Maybe<Scalars['String']['output']>
  /** Text Es gibt Probleme bei deiner Abrechnung (myOMR) (from Workforce) */
  billingProblemMessage?: Maybe<Scalars['String']['output']>
  /** Billing State */
  billingState?: Maybe<VolunteerBillingStateEnum>
  /** Geburtsland */
  birthCountry?: Maybe<Scalars['String']['output']>
  /** Geburtsort */
  birthPlace?: Maybe<Scalars['String']['output']>
  /** Geburtsdatum */
  birthdate?: Maybe<Scalars['ISO8601Date']['output']>
  /** Rufname */
  callname?: Maybe<Scalars['String']['output']>
  /** Wie bist du auf uns gekommen? */
  cameFrom?: Maybe<Array<VolunteerCameFromEnum>>
  /** If OMR */
  cameFromOmr?: Maybe<Array<VolunteerFromOmrEnum>>
  /** If Sonstige */
  cameFromOther?: Maybe<Scalars['String']['output']>
  /** If Social Media */
  cameFromSocialMedia?: Maybe<Array<VolunteerFromSocialMediaEnum>>
  /** If Universität / Hochschule */
  cameFromUni?: Maybe<Scalars['String']['output']>
  /** Kinderfreibetrag */
  childrenFreeAmount?: Maybe<Scalars['String']['output']>
  /** Wohnort */
  city?: Maybe<Scalars['String']['output']>
  /** Codewort */
  codeword?: Maybe<Scalars['String']['output']>
  /** Was ist dir wichtig während deines Einsatzes? */
  commitments?: Maybe<Scalars['String']['output']>
  /** Zusatzinformation */
  complement?: Maybe<Scalars['String']['output']>
  /** Konfektionsgröße */
  confectionSize?: Maybe<VolunteerClotheSizesEnum>
  /** Bestätigung AGB Freelancer (Crew Professional) */
  consentFreelanceTerms?: Maybe<Scalars['Boolean']['output']>
  /** Bestätigung Gewerbeschein / Fähigkeiten (Crew Professional) */
  consentTradeLicense?: Maybe<Scalars['Boolean']['output']>
  /** Land */
  country?: Maybe<Scalars['String']['output']>
  /** aktueller Studiengang / Ausbildungsbereich */
  currentStudies?: Maybe<VolunteerCurrentStudiesEnum>
  /** Sonstige Fähigkeiten */
  customSkills?: Maybe<Scalars['String']['output']>
  /** Lebenslauf */
  cvFiles?: Maybe<Array<AirtableAttachment>>
  /** Datenschutzhinweise */
  dataPrivacy?: Maybe<Scalars['String']['output']>
  /** Konfession */
  denomination?: Maybe<VolunteerDenominationEnum>
  /** Führerschein */
  driverLicenses?: Maybe<Array<VolunteerDriversLicenseEnum>>
  /** Führerschein */
  driverLicensesFiles?: Maybe<Array<AirtableAttachment>>
  /** E-Mail-Adresse */
  email?: Maybe<Scalars['String']['output']>
  /** Email Consent */
  emailConsent?: Maybe<Scalars['String']['output']>
  /** Notfallkontakt Name */
  emergencyContactName?: Maybe<Scalars['String']['output']>
  /** Notfallkontakt Nummer */
  emergencyContactPhone?: Maybe<Scalars['String']['output']>
  /** Notfallkontakt Beziehung */
  emergencyContactRelation?: Maybe<Scalars['String']['output']>
  /** Voraussichtlicher Abschluss am */
  expectedGraduationDate?: Maybe<Scalars['ISO8601Date']['output']>
  /** External hire */
  externalHire?: Maybe<Scalars['Boolean']['output']>
  /** Vorname */
  firstName?: Maybe<Scalars['String']['output']>
  /** Angebot Freelancer (Crew Professional) */
  freelanceOfferFiles?: Maybe<Array<AirtableAttachment>>
  /** Geschlecht */
  gender?: Maybe<Scalars['String']['output']>
  /** Behindertenausweis */
  handicapped?: Maybe<Scalars['Boolean']['output']>
  /** Gesundheitszeugnis */
  healthCertificate?: Maybe<Scalars['Boolean']['output']>
  /** Gesundheitszeugnis */
  healthCertificateFiles?: Maybe<Array<AirtableAttachment>>
  /** Krankenkasse */
  healthInsurance?: Maybe<Scalars['String']['output']>
  /** Krankenkasse sonstige */
  healthInsuranceOther?: Maybe<Scalars['String']['output']>
  /** Versicherungstyp */
  healthInsuranceType?: Maybe<VolunteerHealthInsuranceTypeEnum>
  /** IBAN */
  iban?: Maybe<Scalars['String']['output']>
  /** Identifikationsnummer */
  id: Scalars['ID']['output']
  /** Personalausweis / Reisepass */
  idCardPassportFiles?: Maybe<Array<AirtableAttachment>>
  /** Flurförderschein */
  industrialTransportPermitFiles?: Maybe<Array<AirtableAttachment>>
  /** Einwilligung Jobangebote */
  jobOfferConsent?: Maybe<Scalars['String']['output']>
  /** Nachweis Arbeitnehmer */
  jobTitleEmployer?: Maybe<Scalars['String']['output']>
  /** Jobs Crew Professional */
  jobsCrewProfessional?: Maybe<JobsCrewProfessionalEnum>
  /** Fremdsprachen */
  languages?: Maybe<Scalars['String']['output']>
  /** Letzte gesetzliche Krankenkasse */
  lastHealthInsurance?: Maybe<Scalars['String']['output']>
  /** Nachname */
  lastName?: Maybe<Scalars['String']['output']>
  /** Steuerklassenverifikation 1-5 */
  mainJob?: Maybe<Scalars['Boolean']['output']>
  /** Familienstand */
  maritalStatus?: Maybe<VolunteerMaritalStatusEnum>
  /** Marketing Skills */
  marketingSkills?: Maybe<Array<VolunteerMarketingSkillsEnum>>
  /** Staatsangehörigkeit */
  nationality?: Maybe<Scalars['String']['output']>
  /** Einwilligung Emails */
  news?: Maybe<Scalars['String']['output']>
  /** Not Available at */
  notAvailable?: Maybe<Scalars['String']['output']>
  /** Hausnummer */
  number?: Maybe<Scalars['String']['output']>
  otherDocumentsFiles?: Maybe<Array<AirtableAttachment>>
  /** Personenbeförderungsschein */
  passengerTransportLicenseFiles?: Maybe<Array<AirtableAttachment>>
  /** Handynummer */
  phone?: Maybe<Scalars['String']['output']>
  /** Photo Video Skills */
  photoVideoSkills?: Maybe<Array<VolunteerPhotoVideoSkillsEnum>>
  /** Post Wohnort */
  postalCity?: Maybe<Scalars['String']['output']>
  /** Post Zusatzinformation */
  postalComplement?: Maybe<Scalars['String']['output']>
  /** Post Land */
  postalCountry?: Maybe<Scalars['String']['output']>
  /** Post Hausnummer */
  postalNumber?: Maybe<Scalars['String']['output']>
  /** Post Straße */
  postalStreet?: Maybe<Scalars['String']['output']>
  /** Post Postleitzahl */
  postalZip?: Maybe<Scalars['String']['output']>
  /** Bild */
  profilePicture?: Maybe<Array<AirtableAttachment>>
  /** Nachweis Arbeitnehmer (Anstellungsart) */
  proofEmployerJobTitle?: Maybe<Scalars['String']['output']>
  /** Nachweis Arbeitnehmer (Name Arbeitgeber) */
  proofEmployerName?: Maybe<Scalars['String']['output']>
  /** Nachweis Selbstständig */
  proofSelfEmployed?: Maybe<Scalars['String']['output']>
  /** Nachweis Schüler */
  pupilProofFiles?: Maybe<Array<AirtableAttachment>>
  /** Referenzen (Crew Professional) */
  references?: Maybe<Scalars['String']['output']>
  /** Tätigkeiten im Gewerbeschein (Crew Professional) */
  registeredActivitiesInTradeLicense?: Maybe<Scalars['String']['output']>
  /** Lohnvorstellung (Crew Professional) */
  salaryExpectations?: Maybe<Scalars['String']['output']>
  /** Selbstbeschreibung */
  selfDescription?: Maybe<Scalars['String']['output']>
  /** Schuhgröße */
  shoeSize?: Maybe<VolunteerShoeSizesEnum>
  /** Steuerklassenverifikation 6 */
  sideJob?: Maybe<Scalars['Boolean']['output']>
  /** Fähigkeiten und Bescheinigungen */
  skills?: Maybe<Array<VolunteerSkillsEnum>>
  /** Social Media Profil */
  socialMedia?: Maybe<Scalars['String']['output']>
  /** Sozialversicherungsnummer */
  socialSecurityNumber?: Maybe<Scalars['String']['output']>
  /** Straße */
  street?: Maybe<Scalars['String']['output']>
  /** Nachweis Student */
  studentProofFiles?: Maybe<Array<AirtableAttachment>>
  /** Steuerklasse */
  taxClass?: Maybe<VolunteerTaxClassEnum>
  /** Steueridentifikationsnummer */
  taxId?: Maybe<Scalars['String']['output']>
  /** Steuernummer (Crew Professional) */
  taxNumber?: Maybe<Scalars['String']['output']>
  /** Teamleaderbewerbung */
  teamLeader?: Maybe<Scalars['Boolean']['output']>
  /** Teamleaderbewerbung Qualifikation */
  teamLeaderQualification?: Maybe<Scalars['String']['output']>
  /** Testkonto */
  testAccount?: Maybe<Scalars['Boolean']['output']>
  /** Was ist dir wichtig während deines Einsatzes? */
  whatIsImportantForYou?: Maybe<Array<VolunteerWhatIsImportantEnum>>
  /** Arbeitserlaubnis */
  workPermitFiles?: Maybe<Array<AirtableAttachment>>
  /** Berufsstatus bei Beginn der Beschäftigung */
  workStatus?: Maybe<VolunteerWorkStatusEnum>
  /** Sonstiger Berufsstatus */
  workStatusOther?: Maybe<Scalars['String']['output']>
  /** Zusammenarbeit mit */
  workWith?: Maybe<Scalars['String']['output']>
  /** Bereits bei OMR gearbeitet (Crew Professional) */
  workedBefore?: Maybe<Scalars['String']['output']>
  /** Deine Angebot */
  yourOfferFiles?: Maybe<Scalars['String']['output']>
  /** Postleitzahl */
  zip?: Maybe<Scalars['String']['output']>
}

export enum VolunteerApplicationStateEnum {
  Accepted = 'accepted',
  Applied = 'applied',
  Denied = 'denied',
  Draft = 'draft',
  MissingDocuments = 'missing_documents',
  Timeout = 'timeout',
  Waitlist = 'waitlist',
  Withdrawn = 'withdrawn',
}

/** Attributes for creating new crew volunteer in airtable */
export type VolunteerAttributes = {
  /** Ausbilding in der Profession (Crew Professional) */
  apprenticeship?: InputMaybe<Scalars['Boolean']['input']>
  /** Deine Verfügbarkeiten */
  availability?: InputMaybe<Array<VolunteerAvailabilityEnum>>
  /** Awareness Work Experience */
  awarenessWorkExperience?: InputMaybe<Scalars['String']['input']>
  /** Awareness Work Most Important */
  awarenessWorkMostImportant?: InputMaybe<Scalars['String']['input']>
  /** Bar experience */
  barExperience?: InputMaybe<VolunteerBarExperienceEnum>
  /** BIC */
  bic?: InputMaybe<Scalars['String']['input']>
  /** Billing Data */
  billingDataComplete?: InputMaybe<Scalars['Boolean']['input']>
  /** Billing Form */
  billingForm?: InputMaybe<VolunteerBillingFormEnum>
  /** Geburtsland */
  birthCountry?: InputMaybe<Scalars['String']['input']>
  /** Geburtsort */
  birthPlace?: InputMaybe<Scalars['String']['input']>
  /** Rufname */
  callname?: InputMaybe<Scalars['String']['input']>
  /** Wie bist du auf uns gekommen? */
  cameFrom?: InputMaybe<Array<VolunteerCameFromEnum>>
  /** If OMR */
  cameFromOmr?: InputMaybe<Array<VolunteerFromOmrEnum>>
  /** If Sonstige */
  cameFromOther?: InputMaybe<Scalars['String']['input']>
  /** If Social Media */
  cameFromSocialMedia?: InputMaybe<Array<VolunteerFromSocialMediaEnum>>
  /** If Universität / Hochschule */
  cameFromUni?: InputMaybe<Scalars['String']['input']>
  /** Kinderfreibetrag */
  childrenFreeAmount?: InputMaybe<Scalars['String']['input']>
  /** Wohnort */
  city?: InputMaybe<Scalars['String']['input']>
  /** Codewort */
  codeword?: InputMaybe<Scalars['String']['input']>
  /** Was ist dir wichtig während deines Einsatzes? */
  commitments?: InputMaybe<Scalars['String']['input']>
  /** Zusatzinformation */
  complement?: InputMaybe<Scalars['String']['input']>
  /** Konfektionsgröße */
  confectionSize?: InputMaybe<VolunteerClotheSizesEnum>
  /** Bestätigung AGB Freelancer (Crew Professional) */
  consentFreelanceTerms?: InputMaybe<Scalars['Boolean']['input']>
  /** Bestätigung Gewerbeschein / Fähigkeiten (Crew Professional) */
  consentTradeLicense?: InputMaybe<Scalars['Boolean']['input']>
  /** Land */
  country?: InputMaybe<Scalars['String']['input']>
  /** aktueller Studiengang / Ausbildungsbereich */
  currentStudies?: InputMaybe<VolunteerCurrentStudiesEnum>
  /** Sonstige Fähigkeiten */
  customSkills?: InputMaybe<Scalars['String']['input']>
  /** Lebenslauf */
  cvFiles?: InputMaybe<AirtableAttachmentAttributes>
  /** Datenschutzhinweise */
  dataPrivacy?: InputMaybe<Scalars['String']['input']>
  /** Konfession */
  denomination?: InputMaybe<VolunteerDenominationEnum>
  /** Führerschein */
  driverLicenses?: InputMaybe<Array<VolunteerDriversLicenseEnum>>
  /** Führerschein */
  driverLicensesFiles?: InputMaybe<AirtableAttachmentAttributes>
  /** Email Consent */
  emailConsent?: InputMaybe<Scalars['String']['input']>
  /** Notfallkontakt Name */
  emergencyContactName?: InputMaybe<Scalars['String']['input']>
  /** Notfallkontakt Nummer */
  emergencyContactPhone?: InputMaybe<Scalars['String']['input']>
  /** Notfallkontakt Beziehung */
  emergencyContactRelation?: InputMaybe<Scalars['String']['input']>
  /** Voraussichtlicher Abschluss am */
  expectedGraduationDate?: InputMaybe<Scalars['ISO8601DateTime']['input']>
  /** Angebot Freelancer (Crew Professional) */
  freelanceOfferFiles?: InputMaybe<AirtableAttachmentAttributes>
  /** Behindertenausweis */
  handicapped?: InputMaybe<Scalars['Boolean']['input']>
  /** Gesundheitszeugnis */
  healthCertificate?: InputMaybe<Scalars['Boolean']['input']>
  /** Gesundheitszeugnis */
  healthCertificateFiles?: InputMaybe<AirtableAttachmentAttributes>
  /** Krankenkasse */
  healthInsurance?: InputMaybe<Scalars['String']['input']>
  /** Krankenkasse sonstige */
  healthInsuranceOther?: InputMaybe<Scalars['String']['input']>
  /** Versicherungstyp */
  healthInsuranceType?: InputMaybe<VolunteerHealthInsuranceTypeEnum>
  /** IBAN */
  iban?: InputMaybe<Scalars['String']['input']>
  /** Personalausweis / Reisepass */
  idCardPassportFiles?: InputMaybe<AirtableAttachmentAttributes>
  /** Flurförderschein */
  industrialTransportPermitFiles?: InputMaybe<AirtableAttachmentAttributes>
  /** Einwilligung Jobangebote */
  jobOfferConsent?: InputMaybe<Scalars['String']['input']>
  /** Nachweis Arbeitnehmer */
  jobTitleEmployer?: InputMaybe<Scalars['String']['input']>
  /** Jobs Crew Professional */
  jobsCrewProfessional?: InputMaybe<JobsCrewProfessionalEnum>
  /** Fremdsprachen */
  languages?: InputMaybe<Scalars['String']['input']>
  /** Letzte gesetzliche Krankenkasse */
  lastHealthInsurance?: InputMaybe<Scalars['String']['input']>
  /** Steuerklassenverifikation 1-5 */
  mainJob?: InputMaybe<Scalars['Boolean']['input']>
  /** Familienstand */
  maritalStatus?: InputMaybe<VolunteerMaritalStatusEnum>
  /** Marketing Skills */
  marketingSkills?: InputMaybe<Array<VolunteerMarketingSkillsEnum>>
  /** Staatsangehörigkeit */
  nationality?: InputMaybe<Scalars['String']['input']>
  /** Einwilligung Emails */
  news?: InputMaybe<Scalars['String']['input']>
  /** Not Available at */
  notAvailable?: InputMaybe<Scalars['String']['input']>
  /** Hausnummer */
  number?: InputMaybe<Scalars['String']['input']>
  otherDocumentsFiles?: InputMaybe<AirtableAttachmentAttributes>
  /** Personenbeförderungsschein */
  passengerTransportLicenseFiles?: InputMaybe<AirtableAttachmentAttributes>
  /** Handynummer */
  phone?: InputMaybe<Scalars['String']['input']>
  /** Photo Video Skills */
  photoVideoSkills?: InputMaybe<Array<VolunteerPhotoVideoSkillsEnum>>
  /** Post Wohnort */
  postalCity?: InputMaybe<Scalars['String']['input']>
  /** Post Zusatzinformation */
  postalComplement?: InputMaybe<Scalars['String']['input']>
  /** Post Land */
  postalCountry?: InputMaybe<Scalars['String']['input']>
  /** Post Hausnummer */
  postalNumber?: InputMaybe<Scalars['String']['input']>
  /** Post Straße */
  postalStreet?: InputMaybe<Scalars['String']['input']>
  /** Post Postleitzahl */
  postalZip?: InputMaybe<Scalars['String']['input']>
  /** Bild */
  profilePicture?: InputMaybe<AirtableAttachmentAttributes>
  /** Nachweis Arbeitnehmer (Anstellungsart) */
  proofEmployerJobTitle?: InputMaybe<Scalars['String']['input']>
  /** Nachweis Arbeitnehmer (Name Arbeitgeber) */
  proofEmployerName?: InputMaybe<Scalars['String']['input']>
  /** Nachweis Selbstständig */
  proofSelfEmployed?: InputMaybe<Scalars['String']['input']>
  /** Nachweis Schüler */
  pupilProofFiles?: InputMaybe<AirtableAttachmentAttributes>
  /** Referenzen (Creew Professional) */
  references?: InputMaybe<Scalars['String']['input']>
  /** Tätigkeiten im Gewerbeschein (Crew Professional) */
  registeredActivitiesInTradeLicense?: InputMaybe<Scalars['String']['input']>
  /** Lohnvorstellung (Creew Professional) */
  salaryExpectations?: InputMaybe<Scalars['String']['input']>
  /** Selbstbeschreibung */
  selfDescription?: InputMaybe<Scalars['String']['input']>
  /** Schuhgröße */
  shoeSize?: InputMaybe<VolunteerShoeSizesEnum>
  /** Steuerklassenverifikation 6 */
  sideJob?: InputMaybe<Scalars['Boolean']['input']>
  /** Fähigkeiten und Bescheinigungen */
  skills?: InputMaybe<Array<VolunteerSkillsEnum>>
  /** Social Media Profil */
  socialMedia?: InputMaybe<Scalars['String']['input']>
  /** Sozialversicherungsnummer */
  socialSecurityNumber?: InputMaybe<Scalars['String']['input']>
  /** Straße */
  street?: InputMaybe<Scalars['String']['input']>
  /** Nachweis Student */
  studentProofFiles?: InputMaybe<AirtableAttachmentAttributes>
  /** Steuerklasse */
  taxClass?: InputMaybe<VolunteerTaxClassEnum>
  /** Steueridentifikationsnummer */
  taxId?: InputMaybe<Scalars['String']['input']>
  /** Steuernummer (Crew Professional) */
  taxNumber?: InputMaybe<Scalars['String']['input']>
  /** Teamleaderbewerbung */
  teamLeader?: InputMaybe<Scalars['Boolean']['input']>
  /** Teamleaderbewerbung Qualifikation */
  teamLeaderQualification?: InputMaybe<Scalars['String']['input']>
  /** Testkonto */
  testAccount?: InputMaybe<Scalars['Boolean']['input']>
  /** Was ist dir wichtig während deines Einsatzes? */
  whatIsImportantForYou?: InputMaybe<Array<VolunteerWhatIsImportantEnum>>
  /** Arbeitserlaubnis */
  workPermitFiles?: InputMaybe<AirtableAttachmentAttributes>
  /** Berufsstatus bei Beginn der Beschäftigung */
  workStatus?: InputMaybe<VolunteerWorkStatusEnum>
  /** Sonstiger Berufsstatus */
  workStatusOther?: InputMaybe<Scalars['String']['input']>
  /** Zusammenarbeit mit */
  workWith?: InputMaybe<Scalars['String']['input']>
  /** Bereits bei OMR gearbeitet (Crew Professional) */
  workedBefore?: InputMaybe<Scalars['String']['input']>
  /** Nachweis Selbstständig */
  yearlyRevenues?: InputMaybe<Scalars['String']['input']>
  /** Deine Angebot */
  yourOfferFiles?: InputMaybe<AirtableAttachmentAttributes>
  /** Postleitzahl */
  zip?: InputMaybe<Scalars['String']['input']>
}

export enum VolunteerAvailabilityEnum {
  AlwaysTime = 'always_time',
  EarlyShifts = 'early_shifts',
  FollowupPhase = 'followup_phase',
  LateShifts = 'late_shifts',
  NightShifts = 'night_shifts',
  PreparationPhase = 'preparation_phase',
}

export enum VolunteerBarExperienceEnum {
  LessThanAYear = 'less_than_a_year',
  OneToTwoYears = 'one_to_two_years',
  OverFiveYears = 'over_five_years',
  TwoToFiveYears = 'two_to_five_years',
}

export enum VolunteerBillingFormEnum {
  Employee = 'employee',
  Freelance = 'freelance',
}

export enum VolunteerBillingStateEnum {
  BasedataWrong = 'basedata_wrong',
  BillingdataReady = 'billingdata_ready',
  DataMissing = 'data_missing',
  DataReadyForCheck = 'data_ready_for_check',
  MovementdataWrong = 'movementdata_wrong',
  NoBilling = 'no_billing',
  PaidOut = 'paid_out',
  PayoutGranted = 'payout_granted',
}

export enum VolunteerCameFromEnum {
  FriendsAndFamily = 'friends_and_family',
  OmrChannels = 'omr_channels',
  Other = 'other',
  PrintAdvertising = 'print_advertising',
  Socialmedia = 'socialmedia',
  University = 'university',
}

export enum VolunteerClotheSizesEnum {
  Size_2Xl = 'size_2XL',
  Size_3Xl = 'size_3XL',
  Size_4Xl = 'size_4XL',
  SizeL = 'size_L',
  SizeM = 'size_M',
  SizeS = 'size_S',
  SizeXl = 'size_XL',
  SizeXs = 'size_XS',
}

export enum VolunteerCurrentStudiesEnum {
  ComputerScience = 'computer_science',
  DesignCreation = 'design_creation',
  Engineering = 'engineering',
  EventTourismHospitality = 'event_tourism_hospitality',
  Healthcare = 'healthcare',
  HumanResources = 'human_resources',
  LawEconomics = 'law_economics',
  LinguisticsCulturalStudies = 'linguistics_cultural_studies',
  LogisticsTransportation = 'logistics_transportation',
  MarketingCommunication = 'marketing_communication',
  NaturalSciencesResearch = 'natural_sciences_research',
  Other = 'other',
  SocietySocialSciences = 'society_social_sciences',
}

export enum VolunteerDenominationEnum {
  /** Freie Religionsgemeinschaft Alzey */
  FreeReligiousCommunityAlzey = 'free_religious_community_alzey',
  /** Freireligiöse Gemeinde Mainz */
  FreeReligiousCommunityMainz = 'free_religious_community_mainz',
  /** Freireligiöse Gemeinde Offenbach/Mainz */
  FreeReligiousCommunityOffenbachMainz = 'free_religious_community_offenbach_mainz',
  /** Freireligiöse Landesgemeinde Pfalz */
  FreeReligiousRegionalCommunityOfThePalatinate = 'free_religious_regional_community_of_the_palatinate',
  /** Freireligiöse Landesgemeinde Baden */
  FreeReligiousStateCommunityOfBaden = 'free_religious_state_community_of_baden',
  /** Israelitische Kultussteuer der kultusberechtigten Gemeinden */
  IsraeliteCulturalTaxOfTheCommunitiesEntitledToWorship = 'israelite_cultural_tax_of_the_communities_entitled_to_worship',
  /** Israelitische / Jüdische Kultussteuer */
  IsraeliteJewishCulturalTax = 'israelite_jewish_cultural_tax',
  /** Israelitische Religionsgemeinschaft Baden */
  IsraeliteReligiousCommunityOfBaden = 'israelite_religious_community_of_baden',
  /** Israelitische Religionsgemeinschaft Württembergs */
  IsraeliteReligiousCommunityOfWuerttemberg = 'israelite_religious_community_of_wuerttemberg',
  /** Jüdische Kultussteuer */
  JewishCulturalTax = 'jewish_cultural_tax',
  /** keine Konfession / keine steuerlich relevante Konfession */
  NoDenominationNoTaxRelevantDenomination = 'no_denomination_no_tax_relevant_denomination',
  /** Altkatholisch */
  OldCatholic = 'old_catholic',
  /** Evangelisch */
  Protestant = 'protestant',
  /** Römisch-Katholisch */
  RomanCatholic = 'roman_catholic',
}

export enum VolunteerDriversLicenseEnum {
  B = 'b',
  Be = 'be',
  C = 'c',
  Ce = 'ce',
  No = 'no',
  PassengerTransport = 'passenger_transport',
  Trucking = 'trucking',
}

export enum VolunteerFromOmrEnum {
  Festival = 'festival',
  Newsletter = 'newsletter',
  Podcast = 'podcast',
  Website = 'website',
}

export enum VolunteerFromSocialMediaEnum {
  Facebook = 'facebook',
  Instagram = 'instagram',
  Linkedin = 'linkedin',
  Tiktok = 'tiktok',
  Twitch = 'twitch',
}

export enum VolunteerHealthInsuranceTypeEnum {
  Private = 'private',
  Statutory = 'statutory',
  VoluntaryStatutory = 'voluntary_statutory',
}

export enum VolunteerMaritalStatusEnum {
  /** geschieden */
  Divorced = 'divorced',
  /** verheiratet */
  Married = 'married',
  /** dauernd getrennt lebend */
  PermanentlySeparated = 'permanently_separated',
  /** ledig */
  Single = 'single',
  /** verwitwet */
  Widowed = 'widowed',
}

export enum VolunteerMarketingSkillsEnum {
  BrandManagement = 'brand_management',
  Consulting = 'consulting',
  ContentMarketing = 'content_marketing',
  CustomerRelationManagement = 'customer_relation_management',
  DataAnalytics = 'data_analytics',
  ECommerce = 'e_commerce',
  OnlineMarketing = 'online_marketing',
  Other = 'other',
  PublicRelation = 'public_relation',
}

export enum VolunteerPhotoVideoSkillsEnum {
  Cut = 'cut',
  ImageEditing = 'image_editing',
  Other = 'other',
  Photo = 'photo',
  PhotoManagement = 'photo_management',
  Video = 'video',
}

export enum VolunteerShoeSizesEnum {
  Size_34 = 'size_34',
  Size_35 = 'size_35',
  Size_36 = 'size_36',
  Size_37 = 'size_37',
  Size_38 = 'size_38',
  Size_39 = 'size_39',
  Size_40 = 'size_40',
  Size_41 = 'size_41',
  Size_42 = 'size_42',
  Size_43 = 'size_43',
  Size_44 = 'size_44',
  Size_45 = 'size_45',
  Size_46 = 'size_46',
  Size_47 = 'size_47',
  Size_48 = 'size_48',
  Size_49 = 'size_49',
  Size_50 = 'size_50',
  Size_51 = 'size_51',
  Size_52 = 'size_52',
  Size_53 = 'size_53',
}

export enum VolunteerSkillsEnum {
  AwarenessWork = 'awareness_work',
  BarExperience = 'bar_experience',
  Barista = 'barista',
  Catering = 'catering',
  CustomerSupport = 'customer_support',
  Education = 'education',
  EventManagement = 'event_management',
  EventTechnology = 'event_technology',
  HumanResources = 'human_resources',
  Kitchen = 'kitchen',
  Logistics = 'logistics',
  Marketing = 'marketing',
  Other = 'other',
  Phd = 'phd',
  PhotoVideo = 'photo_video',
  ProjectManagement = 'project_management',
  Sales = 'sales',
  SocialMedia = 'social_media',
  Technical = 'technical',
}

export enum VolunteerTaxClassEnum {
  /** Ledig, getrennt, verwitwet. OMR ist dein Hauptarbeitgeber */
  TaxClass_1 = 'tax_class_1',
  /** getrennt lebend oder alleinerziehend */
  TaxClass_2 = 'tax_class_2',
  /** Verheiratet in Kombination mit Steuerklasse 5 */
  TaxClass_3 = 'tax_class_3',
  /** Verheiratet bei gleich hohem Einkommen */
  TaxClass_4 = 'tax_class_4',
  /** Verheiratet in Kombination mit Steuerklasse 3 */
  TaxClass_5 = 'tax_class_5',
  /** Zweit und Nebenjob. OMR ist dein Nebenarbeitgeber */
  TaxClass_6 = 'tax_class_6',
}

export enum VolunteerWhatIsImportantEnum {
  CommunicateWithGuests = 'communicate_with_guests',
  MoveALittle = 'move_a_little',
  PhysicallyActive = 'physically_active',
  RequireBuddySystem = 'require_buddy_system',
  SeeAsMuchAsPossible = 'see_as_much_as_possible',
  SupportBuddySystem = 'support_buddy_system',
  TakeOnLightTasks = 'take_on_light_tasks',
  TakeOnResponsibility = 'take_on_responsibility',
  WorkAsManyHoursAsPossible = 'work_as_many_hours_as_possible',
  WorkLessHours = 'work_less_hours',
}

export enum VolunteerWorkStatusEnum {
  Civilianservice = 'civilianservice',
  Employee = 'employee',
  Homemaker = 'homemaker',
  Minijob = 'minijob',
  Other = 'other',
  ParentalLeave = 'parental_leave',
  PublicServant = 'public_servant',
  Pupil = 'pupil',
  Retiree = 'retiree',
  SchoolDropout = 'school_dropout',
  SchoolEducation = 'school_education',
  SelfEmployed = 'self_employed',
  StudentApplicant = 'student_applicant',
  StudentBachelor = 'student_bachelor',
  StudentMaster = 'student_master',
  Welfare = 'welfare',
}

export type BoothFragment = {
  __typename?: 'Booth'
  backCoverDesignUrl?: string | null
  boothNumber?: string | null
  counterBrandingFrontUrl?: string | null
  counterBrandingSideAUrl?: string | null
  counterBrandingSideBUrl?: string | null
  descriptionDe?: string | null
  descriptionEn?: string | null
  foodTypes?: Array<BoothFoodTypeEnum> | null
  id: string
  titleDe?: string | null
  titleEn?: string | null
  typeDisplayName: string
  type?: BoothTypeEnum | null
  extrasDueDate?: string | null
  oscPortalUrl?: string | null
  event: { __typename?: 'Event'; id: string }
  products: Array<{
    __typename?: 'Product'
    id: string
    name: string
    description: string
    attachmentUrl?: string | null
    maximum?: number | null
    deliveryFee?: number | null
    fileUploadRequired: boolean
    commentRequired: boolean
    variants: Array<{ __typename?: 'Variant'; id: string; price: number; name: string; currency: string }>
    productCategory?: {
      __typename?: 'ProductCategory'
      id: string
      name: string
      position: number
      cancellationDate?: string | null
      expiresAt?: string | null
    } | null
  }>
  extras?: Array<{
    __typename?: 'Extra'
    productId: string
    selectedVariantId: string
    count: number
    price: number
    deliveryFee?: number | null
    fileName?: string | null
    fileUrl?: string | null
    comment?: string | null
  }> | null
}

export type ContactFragment = {
  __typename?: 'Contact'
  email?: string | null
  firstname?: string | null
  id: string
  name?: string | null
  phone?: string | null
  salutation?: string | null
  title?: string | null
  type: ContactTypeEnum
}

export type DueDateItemFragment = {
  __typename?: 'DueDateItem'
  id: string
  dueAt?: string | null
  resourceType: string
  resourceId?: string | null
  titleDe: string
  titleEn: string
  completed: boolean
  valid: boolean
}

export type EventFragment = {
  __typename?: 'Event'
  agendaFormatted?: string | null
  attendeeCount: number
  attendeeSelectionClosed: boolean
  attendeeSelectionPaused: boolean
  companyEmail?: string | null
  companyName?: string | null
  companyWebsite?: string | null
  ctaButtonLink?: string | null
  ctaButtonText?: string | null
  descriptionDe?: string | null
  descriptionEn?: string | null
  duration?: string | null
  enableCheckin: boolean
  endsAt?: string | null
  eventFormat?: string | null
  featured: boolean
  id: string
  invitationQuotasCount: number
  language?: LanguageEnum | null
  leadCount: number
  livestreamUrlWithToken?: string | null
  locationAddress?: string | null
  locationName?: string | null
  maxAttendees?: number | null
  minAttendees?: number | null
  name?: string | null
  nameDe?: string | null
  nameEn?: string | null
  presentationFormatted?: string | null
  presentationRaw?: string | null
  prospectSelectionUntil?: string | null
  reservedSeats: number
  shuttleService?: string | null
  slug?: string | null
  startsAt?: string | null
  streamingKey?: string | null
  subtitleDe?: string | null
  subtitleEn?: string | null
  ticketShop: boolean
  type?: EventTypeEnum | null
  videoPresentationUrl?: string | null
  videoRecordingUrl?: string | null
  websiteBackgroundUrl?: string | null
  interests: Array<{ __typename?: 'Interest'; id: string; slug: string; text: string }>
  invitationQuotas?: Array<{
    __typename: 'InvitationQuota'
    discount: number
    id: string
    kind: InvitationQuotaTypeEnum
    maxUsable: number
    title?: string | null
    descriptionEn?: string | null
    descriptionDe?: string | null
    fields: Array<{ __typename?: 'Field'; id: string; name?: string | null; descriptionEn?: string | null; descriptionDe?: string | null }>
    inviteeTracking?: {
      __typename?: 'InviteeTracking'
      acceptedCount: number
      declinedCount: number
      openedCount: number
      purchasedCount: number
      sentCount: number
      totalCount: number
    } | null
    ticketCategory?: { __typename?: 'TicketCategory'; id: string; public: boolean; titleDe?: string | null; titleEn?: string | null } | null
  }> | null
  location?: { __typename?: 'Location'; id: string; name?: string | null } | null
}

export type EventOverviewFragment = {
  __typename?: 'Event'
  descriptionDe?: string | null
  descriptionEn?: string | null
  endsAt?: string | null
  id: string
  invitationQuotasCount: number
  language?: LanguageEnum | null
  leadCount: number
  livestreamUrlWithToken?: string | null
  name?: string | null
  nameDe?: string | null
  nameEn?: string | null
  slug?: string | null
  startsAt?: string | null
  type?: EventTypeEnum | null
  location?: { __typename?: 'Location'; id: string; name?: string | null } | null
}

export type PageInfoFragment = {
  __typename?: 'PageInfo'
  startCursor?: string | null
  endCursor?: string | null
  hasNextPage: boolean
  hasPreviousPage: boolean
}

export type PartnerCompanyFragment = {
  __typename?: 'PartnerCompany'
  city?: string | null
  countryCode?: string | null
  descriptionDe?: string | null
  descriptionEn?: string | null
  displayName?: string | null
  facebook?: string | null
  foodTypes: Array<BoothFoodTypeEnum>
  hasFoodBooths: boolean
  hasChildEvents: boolean
  hasQuotas: boolean
  id: string
  instagram?: string | null
  webLogoUrl?: string | null
  logoUrl?: string | null
  name?: string | null
  publicEmail?: string | null
  slug?: string | null
  street?: string | null
  twitter?: string | null
  website?: string | null
  youtube?: string | null
  zipcode?: string | null
  linkedinUrl?: string | null
  tagList?: Array<TagListTypeEnum> | null
  contacts: Array<{ __typename?: 'Contact' } & ContactFragment>
  interests?: Array<{ __typename?: 'Interest'; id: string; slug: string; text: string }> | null
  leadGenLimitConfigs: Array<{
    __typename?: 'LeadGenLimitConfig'
    id: string
    limit?: number | null
    event: { __typename?: 'Event'; slug?: string | null }
  }>
}

export type ProspectFragment = {
  __typename?: 'Prospect'
  id: string
  available: boolean
  highlight?: { __typename?: 'SearchResultHighlight'; companyName?: string | null; companyPosition?: string | null } | null
  companies: Array<{
    __typename?: 'ProspectCompany'
    department?: CompanyDepartmentEnum | null
    eventSlug: string
    field?: string | null
    industry?: string | null
    jobLevel?: JobLevelEnum | null
    name?: string | null
    position?: string | null
    size?: string | null
    country?: string | null
  }>
  visitedEvents: Array<{ __typename?: 'VisitedEvent'; name: string; slug: string }>
}

export type RoleFragment = {
  __typename?: 'Role'
  id: string
  name: RoleNameEnum
  resourceId?: string | null
  resourceType?: ResourceTypeEnum | null
}

export type UserFragment = {
  __typename?: 'User'
  email: string
  firstName?: string | null
  id: string
  lastName?: string | null
  locale: string
  omrMatchConsent: boolean
}

export type AcceptAttendeeMutationVariables = Exact<{
  attendeeId: Scalars['ID']['input']
}>

export type AcceptAttendeeMutation = {
  __typename?: 'Mutation'
  acceptAttendee?: {
    __typename?: 'AcceptPayload'
    errors: Array<string>
    attendee?: { __typename?: 'Attendee'; aasmState: string; id: string } | null
  } | null
}

export type AddAttendeesByTicketIdentifierMutationVariables = Exact<{
  ticketIdentifiers: Array<Scalars['ID']['input']> | Scalars['ID']['input']
  eventId: Scalars['ID']['input']
}>

export type AddAttendeesByTicketIdentifierMutation = {
  __typename?: 'Mutation'
  addAttendeesByTicketIdentifier?: {
    __typename?: 'AddAttendeesByTicketIdentifierPayload'
    clientMutationId?: string | null
    errors: Array<string>
    results?: Array<{
      __typename?: 'AttendeeByTicketIdentifierResult'
      companyName?: string | null
      errorMessage?: string | null
      position?: string | null
      success: boolean
      ticketIdentifier: string
    }> | null
  } | null
}

export type CloseAttendeeSelectionMutationVariables = Exact<{
  eventId: Scalars['ID']['input']
}>

export type CloseAttendeeSelectionMutation = {
  __typename?: 'Mutation'
  closeAttendeeSelection?: {
    __typename?: 'CloseAttendeeSelectionPayload'
    errors: Array<string>
    event?: { __typename?: 'Event'; id: string; attendeeSelectionClosed: boolean } | null
  } | null
}

export type DenyAttendeeMutationVariables = Exact<{
  attendeeId: Scalars['ID']['input']
}>

export type DenyAttendeeMutation = {
  __typename?: 'Mutation'
  denyAttendee?: {
    __typename?: 'DenyPayload'
    errors: Array<string>
    attendee?: { __typename?: 'Attendee'; aasmState: string; id: string } | null
  } | null
}

export type UpdateAttendeesStateFromCsvMutationVariables = Exact<{
  eventId: Scalars['ID']['input']
  csvFile: Scalars['Upload']['input']
}>

export type UpdateAttendeesStateFromCsvMutation = {
  __typename?: 'Mutation'
  updateAttendeesStateFromCsv?: {
    __typename?: 'UpdateAttendeesStateFromCsvPayload'
    accepted?: number | null
    denied?: number | null
    notChanged?: number | null
    failed?: number | null
    errors: Array<string>
  } | null
}

export type UpdateBoothExtrasMutationVariables = Exact<{
  boothId: Scalars['ID']['input']
  extras: Array<ExtraAttributes> | ExtraAttributes
}>

export type UpdateBoothExtrasMutation = {
  __typename?: 'Mutation'
  updateBoothExtras?: {
    __typename?: 'UpdateExtrasPayload'
    errors: Array<string>
    booth?: ({ __typename?: 'Booth' } & BoothFragment) | null
  } | null
}

export type UpdateBoothMutationVariables = Exact<{
  backCoverDesign?: InputMaybe<Scalars['Upload']['input']>
  boothId: Scalars['ID']['input']
  counterBrandingFront?: InputMaybe<Scalars['Upload']['input']>
  counterBrandingSideA?: InputMaybe<Scalars['Upload']['input']>
  counterBrandingSideB?: InputMaybe<Scalars['Upload']['input']>
  descriptionDe?: InputMaybe<Scalars['String']['input']>
  descriptionEn?: InputMaybe<Scalars['String']['input']>
  foodTypes?: InputMaybe<Array<BoothFoodTypeEnum> | BoothFoodTypeEnum>
  titleDe?: InputMaybe<Scalars['String']['input']>
  titleEn?: InputMaybe<Scalars['String']['input']>
}>

export type UpdateBoothMutation = {
  __typename?: 'Mutation'
  updateBooth?: {
    __typename?: 'UpdateBoothPayload'
    errors: Array<string>
    booth?: ({ __typename?: 'Booth' } & BoothFragment) | null
  } | null
}

export type CreateContactMutationVariables = Exact<{
  partnerCompanyId: Scalars['ID']['input']
  email?: InputMaybe<Scalars['String']['input']>
  firstname?: InputMaybe<Scalars['String']['input']>
  name?: InputMaybe<Scalars['String']['input']>
  phone?: InputMaybe<Scalars['String']['input']>
  title?: InputMaybe<Scalars['String']['input']>
  type?: InputMaybe<Scalars['String']['input']>
}>

export type CreateContactMutation = {
  __typename?: 'Mutation'
  createContact?: { __typename?: 'CreateContactPayload'; contact?: ({ __typename?: 'Contact' } & ContactFragment) | null } | null
}

export type UpdateContactMutationVariables = Exact<{
  id: Scalars['ID']['input']
  email?: InputMaybe<Scalars['String']['input']>
  firstname?: InputMaybe<Scalars['String']['input']>
  name?: InputMaybe<Scalars['String']['input']>
  phone?: InputMaybe<Scalars['String']['input']>
  title?: InputMaybe<Scalars['String']['input']>
}>

export type UpdateContactMutation = {
  __typename?: 'Mutation'
  updateContact?: { __typename?: 'UpdateContactPayload'; contact?: ({ __typename?: 'Contact' } & ContactFragment) | null } | null
}

export type AcceptTicketRequestMutationVariables = Exact<{
  ticketId: Scalars['ID']['input']
}>

export type AcceptTicketRequestMutation = {
  __typename?: 'Mutation'
  acceptTicketRequest?: {
    __typename?: 'AcceptTicketRequestPayload'
    errors: Array<string>
    ticket?: { __typename?: 'Ticket'; id: string } | null
  } | null
}

export type ActivateTicketMutationVariables = Exact<{
  ticketId: Scalars['ID']['input']
}>

export type ActivateTicketMutation = {
  __typename?: 'Mutation'
  activateTicket?: {
    __typename?: 'ActivateTicketPayload'
    errors: Array<string>
    ticket?: { __typename?: 'Ticket'; id: string } | null
  } | null
}

export type CreateTicketMutationVariables = Exact<{
  invitationQuotaId: Scalars['ID']['input']
  ticketCreateAttributes: DirectTicketAttributes
}>

export type CreateTicketMutation = {
  __typename?: 'Mutation'
  createDirectTicket?: {
    __typename?: 'CreateDirectTicketPayload'
    errors: Array<string>
    ticket?: { __typename?: 'Ticket'; id: string } | null
  } | null
}

export type DeactivateTicketMutationVariables = Exact<{
  ticketId: Scalars['ID']['input']
}>

export type DeactivateTicketMutation = {
  __typename?: 'Mutation'
  deactivateTicket?: {
    __typename?: 'DeactivateTicketPayload'
    errors: Array<string>
    ticket?: { __typename?: 'Ticket'; id: string } | null
  } | null
}

export type DeleteTicketMutationVariables = Exact<{
  ticketId: Scalars['ID']['input']
}>

export type DeleteTicketMutation = {
  __typename?: 'Mutation'
  deleteTicket?: { __typename?: 'DeleteTicketPayload'; errors: Array<string> } | null
}

export type DenyTicketRequestMutationVariables = Exact<{
  ticketId: Scalars['ID']['input']
}>

export type DenyTicketRequestMutation = {
  __typename?: 'Mutation'
  denyTicketRequest?: { __typename?: 'DenyTicketRequestPayload'; errors: Array<string> } | null
}

export type ResendTicketMailMutationVariables = Exact<{
  ticketId: Scalars['ID']['input']
}>

export type ResendTicketMailMutation = {
  __typename?: 'Mutation'
  resendTicketMail?: { __typename?: 'ResendTicketMailPayload'; success: boolean; errors: Array<string> } | null
}

export type UpdateTicketMutationVariables = Exact<{
  ticketId: Scalars['ID']['input']
  ticketCreateAttributes: DirectTicketAttributes
  events?: InputMaybe<Array<TicketEvent> | TicketEvent>
}>

export type UpdateTicketMutation = {
  __typename?: 'Mutation'
  updateDirectTicket?: {
    __typename?: 'UpdateDirectTicketPayload'
    errors: Array<string>
    ticket?: { __typename?: 'Ticket'; id: string } | null
  } | null
}

export type CreateAcknowledgementMutationVariables = Exact<{
  documentId: Scalars['ID']['input']
  resourceId: Scalars['ID']['input']
}>

export type CreateAcknowledgementMutation = {
  __typename?: 'Mutation'
  createAcknowledgement?: {
    __typename?: 'CreateAcknowledgementPayload'
    errors?: Array<string> | null
    docAcknowledgement?: {
      __typename?: 'DocAcknowledgement'
      id: string
      document: {
        __typename?: 'Document'
        id: string
        docAcknowledgements?: Array<{
          __typename?: 'DocAcknowledgement'
          id: string
          signableId: string
          signableType: string
          uploadsUrls: Array<string>
        }> | null
      }
    } | null
  } | null
}

export type DeleteAcknowledgementUploadMutationVariables = Exact<{
  docAcknowledgementId: Scalars['ID']['input']
  uploadUrl: Scalars['String']['input']
}>

export type DeleteAcknowledgementUploadMutation = {
  __typename?: 'Mutation'
  deleteAcknowledgementUpload?: {
    __typename?: 'DeleteAcknowledgementUploadPayload'
    clientMutationId?: string | null
    errors: Array<string>
    docAcknowledgement?: {
      __typename?: 'DocAcknowledgement'
      acknowledgedAt?: string | null
      acknowledgedBy?: string | null
      id: string
      signableId: string
      signableType: string
      uploadedAt?: string | null
      uploadedBy?: string | null
      uploadsUrls: Array<string>
      document: { __typename?: 'Document'; id: string }
    } | null
  } | null
}

export type UpdateAcknowledgementMutationVariables = Exact<{
  docAcknowledgementId: Scalars['ID']['input']
  document?: InputMaybe<Scalars['Upload']['input']>
}>

export type UpdateAcknowledgementMutation = {
  __typename?: 'Mutation'
  updateAcknowledgement?: {
    __typename?: 'UpdateAcknowledgementPayload'
    errors: Array<string>
    docAcknowledgement?: {
      __typename?: 'DocAcknowledgement'
      id: string
      document: {
        __typename?: 'Document'
        id: string
        docAcknowledgements?: Array<{ __typename?: 'DocAcknowledgement'; id: string; uploadsUrls: Array<string> }> | null
      }
    } | null
  } | null
}

export type CreateDueDateCompletionMutationVariables = Exact<{
  dueDateItemId: Scalars['ID']['input']
}>

export type CreateDueDateCompletionMutation = {
  __typename?: 'Mutation'
  createDueDateCompletion?: {
    __typename?: 'CreatePayload'
    success: boolean
    errors?: Array<string> | null
    dueDateItems: Array<{ __typename?: 'DueDateItem' } & DueDateItemFragment>
  } | null
}

export type DeleteDueDateCompletionMutationVariables = Exact<{
  dueDateItemId: Scalars['ID']['input']
}>

export type DeleteDueDateCompletionMutation = {
  __typename?: 'Mutation'
  deleteDueDateCompletion?: {
    __typename?: 'DeletePayload'
    success: boolean
    errors?: Array<string> | null
    dueDateItems: Array<{ __typename?: 'DueDateItem' } & DueDateItemFragment>
  } | null
}

export type CreateIndividualInviteesMutationVariables = Exact<{
  invitationQuotaId: Scalars['ID']['input']
  invitees: Array<InviteeInput> | InviteeInput
}>

export type CreateIndividualInviteesMutation = {
  __typename?: 'Mutation'
  createIndividualInvitees?: { __typename?: 'CreateIndividualInviteesPayload'; errors?: Array<string> | null } | null
}

export type DeleteIndividualInviteesMutationVariables = Exact<{
  emails: Array<Scalars['String']['input']> | Scalars['String']['input']
  invitationQuotaId: Scalars['ID']['input']
}>

export type DeleteIndividualInviteesMutation = {
  __typename?: 'Mutation'
  deleteIndividualInvitees?: {
    __typename?: 'DeleteIndividualInviteesPayload'
    clientMutationId?: string | null
    errors?: Array<string> | null
    success: boolean
  } | null
}

export type DeleteUnsentInviteesMutationVariables = Exact<{
  id: Scalars['ID']['input']
}>

export type DeleteUnsentInviteesMutation = {
  __typename?: 'Mutation'
  deleteUnsentInvitees?: { __typename?: 'DeleteUnsentInviteesPayload'; success: boolean; errors: Array<string> } | null
}

export type EditEmailTemplateMutationVariables = Exact<{
  invitationQuotaId: Scalars['ID']['input']
  subject: Scalars['String']['input']
  body: Scalars['String']['input']
  replyTo: Scalars['String']['input']
  emailCc?: InputMaybe<Scalars['String']['input']>
}>

export type EditEmailTemplateMutation = {
  __typename?: 'Mutation'
  editEmailTemplate?: {
    __typename?: 'EditEmailTemplatePayload'
    errors: Array<string>
    invitationQuota?: {
      __typename: 'InvitationQuota'
      id: string
      discount: number
      kind: InvitationQuotaTypeEnum
      messageSubject?: string | null
      messageBody?: string | null
      replyTo?: string | null
      cc?: string | null
    } | null
  } | null
}

export type SendIndividualInvitationsMutationVariables = Exact<{
  invitationQuotaId: Scalars['ID']['input']
  emails: Array<Scalars['String']['input']> | Scalars['String']['input']
  cc?: InputMaybe<Scalars['String']['input']>
  messageSubject: Scalars['String']['input']
  messageBody: Scalars['String']['input']
  replyTo: Scalars['String']['input']
}>

export type SendIndividualInvitationsMutation = {
  __typename?: 'Mutation'
  sendIndividualInvitations?: { __typename?: 'SendIndividualInvitationsPayload'; success: boolean; errors: Array<string> } | null
}

export type SendInvitationQuotaEmailsMutationVariables = Exact<{
  id: Scalars['ID']['input']
}>

export type SendInvitationQuotaEmailsMutation = {
  __typename?: 'Mutation'
  sendInvitations?: { __typename?: 'SendInvitationsPayload'; success: boolean; errors: Array<string> } | null
}

export type SendTestEmailMutationVariables = Exact<{
  invitationQuotaId: Scalars['ID']['input']
}>

export type SendTestEmailMutation = {
  __typename?: 'Mutation'
  sendTestEmail?: { __typename?: 'SendTestEmailPayload'; success: boolean; errors: Array<string> } | null
}

export type UpdateEventMutationVariables = Exact<{
  nameDe?: InputMaybe<Scalars['String']['input']>
  nameEn?: InputMaybe<Scalars['String']['input']>
  descriptionDe?: InputMaybe<Scalars['String']['input']>
  descriptionEn?: InputMaybe<Scalars['String']['input']>
  locationName?: InputMaybe<Scalars['String']['input']>
  locationAddress?: InputMaybe<Scalars['String']['input']>
  companyName?: InputMaybe<Scalars['String']['input']>
  companyWebsite?: InputMaybe<Scalars['String']['input']>
  ctaButtonLink?: InputMaybe<Scalars['String']['input']>
  ctaButtonText?: InputMaybe<Scalars['String']['input']>
  companyEmail?: InputMaybe<Scalars['String']['input']>
  eventFormat?: InputMaybe<Scalars['String']['input']>
  shuttleService?: InputMaybe<Scalars['Boolean']['input']>
  maxAttendees?: InputMaybe<Scalars['Int']['input']>
  duration?: InputMaybe<Scalars['String']['input']>
  interests?: InputMaybe<Array<Scalars['ID']['input']> | Scalars['ID']['input']>
  eventId: Scalars['ID']['input']
  language?: InputMaybe<Scalars['String']['input']>
  agendaRaw?: InputMaybe<Scalars['String']['input']>
  presentationRaw?: InputMaybe<Scalars['String']['input']>
  agendaFormatted?: InputMaybe<Scalars['String']['input']>
  presentationFormatted?: InputMaybe<Scalars['String']['input']>
  videoPresentation?: InputMaybe<Scalars['Upload']['input']>
  videoRecording?: InputMaybe<Scalars['Upload']['input']>
  startsAt?: InputMaybe<Scalars['ISO8601DateTime']['input']>
  endsAt?: InputMaybe<Scalars['ISO8601DateTime']['input']>
  streamingKey?: InputMaybe<Scalars['String']['input']>
}>

export type UpdateEventMutation = {
  __typename?: 'Mutation'
  updateEvent?: { __typename?: 'UpdateEventPayload'; errors: Array<string> } | null
}

export type ImportInviteesMutationVariables = Exact<{
  invitationQuotaId: Scalars['ID']['input']
  csvUploadId: Scalars['ID']['input']
  hasHeaders: Scalars['Boolean']['input']
  mapping: CsvMappingAttributes
}>

export type ImportInviteesMutation = {
  __typename?: 'Mutation'
  importInvitees?: { __typename?: 'ImportInviteesPayload'; success: boolean; errors: Array<string> } | null
}

export type UploadInviteesMutationVariables = Exact<{
  invitationQuotaId: Scalars['ID']['input']
  csvFile: Scalars['Upload']['input']
}>

export type UploadInviteesMutation = {
  __typename?: 'Mutation'
  uploadInvitees?: { __typename?: 'UploadInviteesPayload'; csvUploadId?: string | null; errors: Array<string> } | null
}

export type ManuallyMarkAsSentMutationVariables = Exact<{
  promoCodeId: Scalars['ID']['input']
}>

export type ManuallyMarkAsSentMutation = {
  __typename?: 'Mutation'
  manuallyMarkAsSent?: {
    __typename?: 'ManuallyMarkAsSentPayload'
    errors: Array<string>
    promoCode?: { __typename?: 'PromoCode'; id: string; manuallyMarkedAsSentAt?: string | null } | null
  } | null
}

export type ManuallyMarkAsUnsentMutationVariables = Exact<{
  promoCodeId: Scalars['ID']['input']
}>

export type ManuallyMarkAsUnsentMutation = {
  __typename?: 'Mutation'
  manuallyMarkAsUnsent?: {
    __typename?: 'ManuallyMarkAsUnsentPayload'
    errors: Array<string>
    promoCode?: { __typename?: 'PromoCode'; id: string; manuallyMarkedAsSentAt?: string | null } | null
  } | null
}

export type CreateProspectItemMutationVariables = Exact<{
  prospectListId: Scalars['ID']['input']
  token: Scalars['ID']['input']
}>

export type CreateProspectItemMutation = {
  __typename?: 'Mutation'
  createProspectItem?: { __typename?: 'CreateProspectItemPayload'; errors: Array<string>; success: boolean } | null
}

export type CreateProspectListMutationVariables = Exact<{
  eventId: Scalars['ID']['input']
  partnerCompanyId: Scalars['ID']['input']
  contactEmail: Scalars['String']['input']
  contactName: Scalars['String']['input']
  contactReason?: InputMaybe<Scalars['String']['input']>
  title: Scalars['String']['input']
}>

export type CreateProspectListMutation = {
  __typename?: 'Mutation'
  createProspectList?: {
    __typename?: 'CreateProspectListPayload'
    errors: Array<string>
    prospectList?: {
      __typename?: 'ProspectList'
      id: string
      title: string
      contactName?: string | null
      contactEmail?: string | null
      contactReason?: string | null
      partnerCompany: { __typename?: 'PartnerCompany'; id: string; name?: string | null }
      event: { __typename?: 'Event'; id: string; name?: string | null }
    } | null
  } | null
}

export type DeleteProspectItemMutationVariables = Exact<{
  prospectListId: Scalars['ID']['input']
  token: Scalars['ID']['input']
}>

export type DeleteProspectItemMutation = {
  __typename?: 'Mutation'
  deleteProspectItem?: { __typename?: 'DeleteProspectItemPayload'; errors: Array<string>; success: boolean } | null
}

export type DeleteProspectListMutationVariables = Exact<{
  prospectListId: Scalars['ID']['input']
}>

export type DeleteProspectListMutation = {
  __typename?: 'Mutation'
  deleteProspectList?: { __typename?: 'DeleteProspectListPayload'; errors: Array<string> } | null
}

export type SendProspectListInvitationsMutationVariables = Exact<{
  prospectListId: Scalars['ID']['input']
}>

export type SendProspectListInvitationsMutation = {
  __typename?: 'Mutation'
  sendProspectListInvitations?: { __typename?: 'SendProspectListInvitationsPayload'; errors: Array<string>; success: boolean } | null
}

export type SendTestProspectInvitationMutationVariables = Exact<{
  prospectListId: Scalars['ID']['input']
  email: Scalars['String']['input']
}>

export type SendTestProspectInvitationMutation = {
  __typename?: 'Mutation'
  sendTestProspectInvitation?: { __typename?: 'SendTestInvitationPayload'; success: boolean; errors: Array<string> } | null
}

export type SubmitProspectListMutationVariables = Exact<{
  prospectListId: Scalars['ID']['input']
}>

export type SubmitProspectListMutation = {
  __typename?: 'Mutation'
  submitProspectList?: {
    __typename?: 'SubmitProspectListPayload'
    errors: Array<string>
    prospectList?: { __typename?: 'ProspectList'; id: string; submittedAt?: string | null } | null
  } | null
}

export type UpdateProspectListMutationVariables = Exact<{
  prospectListId: Scalars['ID']['input']
  contactEmail: Scalars['String']['input']
  contactName: Scalars['String']['input']
  contactReason: Scalars['String']['input']
  title: Scalars['String']['input']
}>

export type UpdateProspectListMutation = {
  __typename?: 'Mutation'
  updateProspectList?: { __typename?: 'UpdateProspectListPayload'; errors: Array<string> } | null
}

export type CreateEventSpeakerMutationVariables = Exact<{
  eventId: Scalars['ID']['input']
  speakerId: Scalars['ID']['input']
}>

export type CreateEventSpeakerMutation = {
  __typename?: 'Mutation'
  createEventSpeaker?: {
    __typename?: 'CreateEventSpeakerPayload'
    errors: Array<string>
    eventSpeaker?: {
      __typename?: 'EventSpeaker'
      id: string
      event: { __typename?: 'Event'; id: string }
      speaker: { __typename?: 'Speaker'; id: string }
    } | null
  } | null
}

export type CreateSpeakerMutationVariables = Exact<{
  photo?: InputMaybe<Scalars['Upload']['input']>
  partnerCompanyId: Scalars['ID']['input']
  name?: InputMaybe<Scalars['String']['input']>
  firstname?: InputMaybe<Scalars['String']['input']>
  lastname?: InputMaybe<Scalars['String']['input']>
  email?: InputMaybe<Scalars['String']['input']>
  descriptionEn?: InputMaybe<Scalars['String']['input']>
  descriptionDe?: InputMaybe<Scalars['String']['input']>
  partnerCompanyName?: InputMaybe<Scalars['String']['input']>
  title?: InputMaybe<Scalars['String']['input']>
  twitterUrl?: InputMaybe<Scalars['String']['input']>
  facebookUrl?: InputMaybe<Scalars['String']['input']>
  instagramUrl?: InputMaybe<Scalars['String']['input']>
  youtubeUrl?: InputMaybe<Scalars['String']['input']>
  websiteUrl?: InputMaybe<Scalars['String']['input']>
  linkedinUrl?: InputMaybe<Scalars['String']['input']>
  interests?: InputMaybe<Array<Scalars['ID']['input']> | Scalars['ID']['input']>
}>

export type CreateSpeakerMutation = {
  __typename?: 'Mutation'
  createSpeaker?: {
    __typename?: 'CreateSpeakerPayload'
    errors: Array<string>
    speaker?: {
      __typename?: 'Speaker'
      id: string
      name?: string | null
      firstname?: string | null
      lastname?: string | null
      descriptionEn?: string | null
      descriptionDe?: string | null
      partnerCompanyName?: string | null
      title?: string | null
      photoUrl?: string | null
      twitterUrl?: string | null
      facebookUrl?: string | null
      instagramUrl?: string | null
      youtubeUrl?: string | null
      websiteUrl?: string | null
      linkedinUrl?: string | null
    } | null
  } | null
}

export type DeleteEventSpeakerMutationVariables = Exact<{
  eventSpeakerId: Scalars['ID']['input']
}>

export type DeleteEventSpeakerMutation = {
  __typename?: 'Mutation'
  deleteEventSpeaker?: { __typename?: 'DeleteEventSpeakerPayload'; success: boolean; errors?: Array<string> | null } | null
}

export type UpdateSpeakerMutationVariables = Exact<{
  speakerId: Scalars['ID']['input']
  photo?: InputMaybe<Scalars['Upload']['input']>
  name?: InputMaybe<Scalars['String']['input']>
  firstname?: InputMaybe<Scalars['String']['input']>
  lastname?: InputMaybe<Scalars['String']['input']>
  descriptionEn?: InputMaybe<Scalars['String']['input']>
  descriptionDe?: InputMaybe<Scalars['String']['input']>
  partnerCompanyName?: InputMaybe<Scalars['String']['input']>
  title?: InputMaybe<Scalars['String']['input']>
  email?: InputMaybe<Scalars['String']['input']>
  twitterUrl?: InputMaybe<Scalars['String']['input']>
  facebookUrl?: InputMaybe<Scalars['String']['input']>
  instagramUrl?: InputMaybe<Scalars['String']['input']>
  youtubeUrl?: InputMaybe<Scalars['String']['input']>
  websiteUrl?: InputMaybe<Scalars['String']['input']>
  linkedinUrl?: InputMaybe<Scalars['String']['input']>
  interests?: InputMaybe<Array<Scalars['ID']['input']> | Scalars['ID']['input']>
}>

export type UpdateSpeakerMutation = {
  __typename?: 'Mutation'
  updateSpeaker?: {
    __typename?: 'UpdateSpeakerPayload'
    errors: Array<string>
    speaker?: {
      __typename?: 'Speaker'
      name?: string | null
      firstname?: string | null
      lastname?: string | null
      descriptionEn?: string | null
      descriptionDe?: string | null
      partnerCompanyName?: string | null
      title?: string | null
      email?: string | null
      photoUrl?: string | null
      twitterUrl?: string | null
      facebookUrl?: string | null
      instagramUrl?: string | null
      youtubeUrl?: string | null
      websiteUrl?: string | null
      linkedinUrl?: string | null
    } | null
  } | null
}

export type UpdatePartnerCompanyMutationVariables = Exact<{
  descriptionDe?: InputMaybe<Scalars['String']['input']>
  descriptionEn?: InputMaybe<Scalars['String']['input']>
  facebook?: InputMaybe<Scalars['String']['input']>
  instagram?: InputMaybe<Scalars['String']['input']>
  linkedinUrl?: InputMaybe<Scalars['String']['input']>
  interests?: InputMaybe<Array<Scalars['ID']['input']> | Scalars['ID']['input']>
  logo?: InputMaybe<Scalars['Upload']['input']>
  webLogo?: InputMaybe<Scalars['Upload']['input']>
  name?: InputMaybe<Scalars['String']['input']>
  displayName?: InputMaybe<Scalars['String']['input']>
  partnerCompanyId: Scalars['ID']['input']
  twitter?: InputMaybe<Scalars['String']['input']>
  website?: InputMaybe<Scalars['String']['input']>
  youtube?: InputMaybe<Scalars['String']['input']>
}>

export type UpdatePartnerCompanyMutation = {
  __typename?: 'Mutation'
  updatePartnerCompany?: {
    __typename?: 'UpdatePartnerCompanyPayload'
    errors: Array<string>
    partnerCompany?: ({ __typename?: 'PartnerCompany' } & PartnerCompanyFragment) | null
  } | null
}

export type CreateUserMutationVariables = Exact<{
  email: Scalars['String']['input']
  personalMessage?: InputMaybe<Scalars['String']['input']>
  roleNames?: InputMaybe<Array<RoleNameEnum> | RoleNameEnum>
}>

export type CreateUserMutation = {
  __typename?: 'Mutation'
  createUser?: {
    __typename?: 'CreateUserPayload'
    errors: Array<string>
    user?: ({ __typename?: 'User'; roles: Array<{ __typename?: 'Role' } & RoleFragment> } & UserFragment) | null
  } | null
}

export type UpdateUserRolesMutationVariables = Exact<{
  userId: Scalars['ID']['input']
  roleNames?: InputMaybe<Array<RoleNameEnum> | RoleNameEnum>
}>

export type UpdateUserRolesMutation = {
  __typename?: 'Mutation'
  updateUserRoles?: {
    __typename?: 'UpdateUserRolesPayload'
    errors: Array<string>
    user?: ({ __typename?: 'User'; roles: Array<{ __typename?: 'Role' } & RoleFragment> } & UserFragment) | null
  } | null
}

export type AttendeesQueryVariables = Exact<{
  eventId: Scalars['ID']['input']
  state?: InputMaybe<Scalars['String']['input']>
  orderBy?: InputMaybe<AttendeeOrderAttributesEnum>
  orderDirection?: InputMaybe<OrderDirectionEnum>
}>

export type AttendeesQuery = {
  __typename?: 'Query'
  attendees: {
    __typename: 'AttendeeConnection'
    acceptedCount: number
    acceptedSuccessorCount: number
    deniedCount: number
    requestedCount: number
    totalCount: number
    edges: Array<{ __typename?: 'AttendeeEdge'; cursor: string }>
    nodes: Array<{
      __typename: 'Attendee'
      id: string
      aasmState: string
      addedViaManage: boolean
      forcedAllocation?: boolean | null
      ticket?: {
        __typename: 'Ticket'
        companyName?: string | null
        firstName?: string | null
        identifier: string
        lastName?: string | null
        position?: string | null
      } | null
    }>
    pageInfo: {
      __typename: 'PageInfo'
      endCursor?: string | null
      hasNextPage: boolean
      hasPreviousPage: boolean
      startCursor?: string | null
    }
  }
}

export type BoothQueryVariables = Exact<{
  boothId: Scalars['ID']['input']
}>

export type BoothQuery = {
  __typename?: 'Query'
  node?:
    | { __typename: 'ActsAsTaggableOn'; id: string }
    | { __typename: 'Attendee'; id: string }
    | { __typename: 'BadgeTransaction'; id: string }
    | { __typename: 'BadgeTransactionItem'; id: string }
    | ({
        __typename: 'Booth'
        id: string
        partnerCompany?: {
          __typename?: 'PartnerCompany'
          id: string
          name?: string | null
          displayName?: string | null
          contacts: Array<{ __typename?: 'Contact' } & ContactFragment>
        } | null
      } & BoothFragment)
    | { __typename: 'ContentStream'; id: string }
    | { __typename: 'DigitalMasterclass'; id: string }
    | { __typename: 'Document'; id: string }
    | { __typename: 'DueDateItem'; id: string }
    | { __typename: 'Event'; id: string }
    | { __typename: 'EventSpeaker'; id: string }
    | { __typename: 'Interest'; id: string }
    | { __typename: 'InvitationQuota'; id: string }
    | { __typename: 'LeadGenLimitConfig'; id: string }
    | { __typename: 'Location'; id: string }
    | { __typename: 'Order'; id: string }
    | { __typename: 'PartnerCompany'; id: string }
    | { __typename: 'PromoCode'; id: string }
    | { __typename: 'ProspectList'; id: string }
    | { __typename: 'Speaker'; id: string }
    | { __typename: 'Stage'; id: string }
    | { __typename: 'User'; id: string }
    | null
}

export type BoothsQueryVariables = Exact<{
  cursor?: InputMaybe<Scalars['String']['input']>
  eventPeriod?: InputMaybe<EventPeriodEnum>
  type?: InputMaybe<BoothTypeEnum>
}>

export type BoothsQuery = {
  __typename?: 'Query'
  booths: {
    __typename?: 'BoothConnection'
    nodes: Array<{
      __typename?: 'Booth'
      boothNumber?: string | null
      foodTypes?: Array<BoothFoodTypeEnum> | null
      id: string
      titleDe?: string | null
      titleEn?: string | null
      type?: BoothTypeEnum | null
      typeDisplayName: string
      event: {
        __typename?: 'Event'
        endsAt?: string | null
        id: string
        name?: string | null
        startsAt?: string | null
        nameDe?: string | null
        nameEn?: string | null
        location?: { __typename?: 'Location'; id: string; name?: string | null } | null
      }
      partnerCompany?: { __typename?: 'PartnerCompany'; id: string; name?: string | null; displayName?: string | null } | null
    }>
    pageInfo: { __typename?: 'PageInfo' } & PageInfoFragment
  }
}

export type CompanyFieldsByIndustryQueryVariables = Exact<{
  id: Scalars['ID']['input']
}>

export type CompanyFieldsByIndustryQuery = {
  __typename?: 'Query'
  userPropertiesPreferences: {
    __typename?: 'UserPropertiesPreferences'
    companyFieldsByIndustry: Array<{ __typename?: 'CompanyFields'; id: number; companyIndustryId: number; translation: string }>
  }
}

export type DocumentsQueryVariables = Exact<{
  eventId?: InputMaybe<Scalars['ID']['input']>
  tags: Array<Scalars['String']['input']> | Scalars['String']['input']
  signable?: InputMaybe<Scalars['ID']['input']>
}>

export type DocumentsQuery = {
  __typename?: 'Query'
  documents: {
    __typename?: 'DocumentConnection'
    nodes: Array<{
      __typename?: 'Document'
      eventId: string
      id: string
      titleDe?: string | null
      titleEn?: string | null
      descriptionDe?: string | null
      descriptionEn?: string | null
      documentUrl?: string | null
      requireAcknowledge: boolean
      requireUpload: boolean
      docAcknowledgements?: Array<{
        __typename?: 'DocAcknowledgement'
        id: string
        uploadsUrls: Array<string>
        signableId: string
        signableType: string
      }> | null
    }>
  }
}

export type DueDateItemsQueryVariables = Exact<{ [key: string]: never }>

export type DueDateItemsQuery = { __typename?: 'Query'; dueDateItems: Array<{ __typename?: 'DueDateItem' } & DueDateItemFragment> }

export type EventBySlugQueryVariables = Exact<{
  slug: Scalars['String']['input']
}>

export type EventBySlugQuery = { __typename?: 'Query'; event: { __typename: 'Event' } & EventOverviewFragment }

export type EventQueryVariables = Exact<{
  id: Scalars['ID']['input']
}>

export type EventQuery = { __typename?: 'Query'; eventById: { __typename: 'Event' } & EventFragment }

export type EventsQueryVariables = Exact<{
  cursor?: InputMaybe<Scalars['String']['input']>
  period?: InputMaybe<EventPeriodEnum>
  noParent?: InputMaybe<Scalars['Boolean']['input']>
  parentEventsFirst?: InputMaybe<Scalars['Boolean']['input']>
  type?: InputMaybe<Array<EventTypeEnum> | EventTypeEnum>
}>

export type EventsQuery = {
  __typename?: 'Query'
  events: {
    __typename?: 'EventConnectionWithTotalCount'
    nodes?: Array<({ __typename?: 'Event' } & EventOverviewFragment) | null> | null
    pageInfo: { __typename?: 'PageInfo' } & PageInfoFragment
  }
}

export type InterestsQueryVariables = Exact<{ [key: string]: never }>

export type InterestsQuery = {
  __typename?: 'Query'
  interests: { __typename?: 'InterestConnection'; nodes: Array<{ __typename?: 'Interest'; id: string; name: string; slug: string }> }
}

export type CsvPreviewDataQueryVariables = Exact<{
  invitationQuotaId: Scalars['ID']['input']
  csvUploadId: Scalars['ID']['input']
}>

export type CsvPreviewDataQuery = {
  __typename?: 'Query'
  csvPreviewData?: Array<{ __typename?: 'CsvPreviewData'; name: string; index: number; sample: Array<string> }> | null
}

export type InvitationQuotaQueryVariables = Exact<{
  id: Scalars['ID']['input']
}>

export type InvitationQuotaQuery = {
  __typename?: 'Query'
  invitationQuota: {
    __typename?: 'InvitationQuota'
    id: string
    activatedTicketsCount?: number | null
    usableTicketsCount?: number | null
    allowUsageOfSameAddress: boolean
    cc?: string | null
    createdAt: string
    descriptionDe?: string | null
    descriptionEn?: string | null
    discount: number
    doNotSendOutTicketEmail: boolean
    expirationDate?: string | null
    kind: InvitationQuotaTypeEnum
    maxUsable: number
    messageBody?: string | null
    messageSubject?: string | null
    replyTo?: string | null
    showFormLink: boolean
    ticketsCount?: number | null
    title?: string | null
    event?: {
      __typename: 'Event'
      id: string
      slug?: string | null
      nameEn?: string | null
      nameDe?: string | null
      ticketCreationDeadlineHint?: string | null
    } | null
    fields: Array<{ __typename?: 'Field'; id: string; name?: string | null; descriptionDe?: string | null; descriptionEn?: string | null }>
    inviteeTracking?: {
      __typename?: 'InviteeTracking'
      acceptedCount: number
      declinedCount: number
      openedCount: number
      purchasedCount: number
      sentCount: number
      totalCount: number
    } | null
    partnerCompany?: { __typename: 'PartnerCompany'; id: string } | null
    promoCodes?: Array<{
      __typename?: 'PromoCode'
      code: string
      companyName?: string | null
      id: string
      manuallyMarkedAsSentAt?: string | null
      ticketCount: number
      timesUsableRemaining: number
      usable: boolean
      userName?: string | null
    }> | null
    ticketCategory?: { __typename: 'TicketCategory'; id: string; titleDe?: string | null; titleEn?: string | null } | null
  }
}

export type InviteesQueryVariables = Exact<{
  invitationQuotaId: Scalars['ID']['input']
  status?: InputMaybe<InviteeStatusEnum>
  activated?: InputMaybe<Scalars['Boolean']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  limit?: InputMaybe<Scalars['Int']['input']>
}>

export type InviteesQuery = {
  __typename?: 'Query'
  invitees: {
    __typename?: 'InviteePaginatedCollection'
    nodes: Array<{
      __typename?: 'Invitee'
      token: string
      email: string
      firstName?: string | null
      lastName?: string | null
      company?: string | null
      gender?: GenderEnum | null
      status: InviteeStatusEnum
      sentAt?: string | null
      openedAt?: string | null
      acceptedAt?: string | null
      createdAt: string
      declinedAt?: string | null
      sentCount: number
      externalId?: string | null
      title?: string | null
      activated: boolean
    }>
    pageInfo: {
      __typename?: 'OffsetPaginationInfo'
      hasNextPage: boolean
      hasPreviousPage: boolean
      limit: number
      offset: number
      pagesCount: number
      totalCount: number
    }
  }
}

export type LeadCountsByEventQueryVariables = Exact<{
  cursor?: InputMaybe<Scalars['String']['input']>
  rootEvent?: InputMaybe<Scalars['String']['input']>
  type?: InputMaybe<Array<EventTypeEnum> | EventTypeEnum>
}>

export type LeadCountsByEventQuery = {
  __typename?: 'Query'
  events: {
    __typename?: 'EventConnectionWithTotalCount'
    totalCount: number
    nodes?: Array<{
      __typename?: 'Event'
      endsAt?: string | null
      id: string
      leadCount: number
      name?: string | null
      nameDe?: string | null
      nameEn?: string | null
      slug?: string | null
      startsAt?: string | null
      type?: EventTypeEnum | null
    } | null> | null
    pageInfo: { __typename?: 'PageInfo' } & PageInfoFragment
  }
}

export type PartnerCompanyLeadCountQueryVariables = Exact<{
  cursor?: InputMaybe<Scalars['String']['input']>
  mainEventId: Scalars['ID']['input']
  partnerCompanyId: Scalars['ID']['input']
}>

export type PartnerCompanyLeadCountQuery = { __typename?: 'Query'; leads: { __typename?: 'LeadConnection'; totalCount: number } }

export type MeQueryVariables = Exact<{ [key: string]: never }>

export type MeQuery = {
  __typename?: 'Query'
  me?:
    | ({
        __typename?: 'User'
        profileImage: string
        profileScopes: Array<ProfileScopeEnum>
        newsTags?: Array<string> | null
        partnerCompany?: ({ __typename?: 'PartnerCompany' } & PartnerCompanyFragment) | null
        roles: Array<{ __typename?: 'Role' } & RoleFragment>
      } & UserFragment)
    | null
  booths: {
    __typename?: 'BoothConnection'
    nodes: Array<{ __typename?: 'Booth'; id: string; premium: boolean; createdAt?: string | null }>
  }
}

export type NewsQueryVariables = Exact<{ [key: string]: never }>

export type NewsQuery = {
  __typename?: 'Query'
  news: {
    __typename?: 'NewsConnection'
    nodes: Array<{
      __typename?: 'News'
      id: string
      titleDe: string
      titleEn: string
      bodyDe?: string | null
      bodyEn?: string | null
      bodyHtmlDe?: string | null
      bodyHtmlEn?: string | null
      ctaButtonTextDe?: string | null
      ctaButtonTextEn?: string | null
      ctaButtonLink?: string | null
      imageUrl?: string | null
      force: boolean
    }>
  }
}

export type PartnerCompaniesQueryVariables = Exact<{
  partnerCompanyIds: Array<Scalars['ID']['input']> | Scalars['ID']['input']
}>

export type PartnerCompaniesQuery = {
  __typename?: 'Query'
  nodes: Array<
    | { __typename: 'ActsAsTaggableOn' }
    | { __typename: 'Attendee' }
    | { __typename: 'BadgeTransaction' }
    | { __typename: 'BadgeTransactionItem' }
    | { __typename: 'Booth' }
    | { __typename: 'ContentStream' }
    | { __typename: 'DigitalMasterclass' }
    | { __typename: 'Document' }
    | { __typename: 'DueDateItem' }
    | { __typename: 'Event' }
    | { __typename: 'EventSpeaker' }
    | { __typename: 'Interest' }
    | { __typename: 'InvitationQuota' }
    | { __typename: 'LeadGenLimitConfig' }
    | { __typename: 'Location' }
    | { __typename: 'Order' }
    | { __typename: 'PartnerCompany'; id: string; name?: string | null; displayName?: string | null }
    | { __typename: 'PromoCode' }
    | { __typename: 'ProspectList' }
    | { __typename: 'Speaker' }
    | { __typename: 'Stage' }
    | { __typename: 'User' }
    | null
  >
}

export type ProspectListsQueryVariables = Exact<{
  partnerCompanyId: Scalars['ID']['input']
  period?: InputMaybe<EventPeriodEnum>
}>

export type ProspectListsQuery = {
  __typename?: 'Query'
  prospectLists?: {
    __typename?: 'ProspectListConnection'
    nodes: Array<{
      __typename?: 'ProspectList'
      id: string
      title: string
      contactEmail?: string | null
      contactName?: string | null
      contactReason?: string | null
      submittedAt?: string | null
      event: {
        __typename?: 'Event'
        endsAt?: string | null
        id: string
        language?: LanguageEnum | null
        name?: string | null
        nameDe?: string | null
        nameEn?: string | null
        prospectSelectionUntil?: string | null
        slug?: string | null
        startsAt?: string | null
        location?: { __typename?: 'Location'; id: string; name?: string | null } | null
      }
      prospects: Array<{ __typename?: 'Prospect' } & ProspectFragment>
    }>
  } | null
}

export type ProspectsQueryVariables = Exact<{
  page?: InputMaybe<Scalars['Int']['input']>
  perPage?: InputMaybe<Scalars['Int']['input']>
  companyName?: InputMaybe<Scalars['String']['input']>
  position?: InputMaybe<Scalars['String']['input']>
  visitedEvents?: InputMaybe<Array<Scalars['ID']['input']> | Scalars['ID']['input']>
  companySizes?: InputMaybe<Array<Scalars['ID']['input']> | Scalars['ID']['input']>
  companyFields?: InputMaybe<Array<Scalars['ID']['input']> | Scalars['ID']['input']>
  companyIndustries?: InputMaybe<Array<Scalars['ID']['input']> | Scalars['ID']['input']>
  companyDepartments?: InputMaybe<Array<Scalars['ID']['input']> | Scalars['ID']['input']>
  companyJobLevels?: InputMaybe<Array<Scalars['ID']['input']> | Scalars['ID']['input']>
  companyCountries?: InputMaybe<Array<Scalars['ID']['input']> | Scalars['ID']['input']>
  available?: InputMaybe<Scalars['Boolean']['input']>
  eventSlug: Scalars['String']['input']
}>

export type ProspectsQuery = {
  __typename?: 'Query'
  prospects?: {
    __typename?: 'ProspectsSearchResult'
    prospectsCount: { __typename?: 'ProspectsCount'; relation: string; total: number }
    prospects: Array<{ __typename?: 'Prospect' } & ProspectFragment>
    visitedEventsFilter: Array<{ __typename?: 'ProspectsFilter'; id: string; name: string }>
    companySizeFacets: Array<{ __typename?: 'Facet'; id: string; name: string }>
    companyFieldFacets: Array<{ __typename?: 'Facet'; id: string; name: string }>
    companyCountryFacets: Array<{ __typename?: 'Facet'; id: string; name: string }>
    companyIndustryFacets: Array<{ __typename?: 'Facet'; id: string; name: string }>
    companyDepartmentFacets: Array<{ __typename?: 'Facet'; id: string; name: string }>
    companyJobLevelFacets: Array<{ __typename?: 'Facet'; id: string; name: string }>
  } | null
}

export type SpeakerQueryVariables = Exact<{
  speakerId: Scalars['ID']['input']
}>

export type SpeakerQuery = {
  __typename?: 'Query'
  node?:
    | { __typename: 'ActsAsTaggableOn'; id: string }
    | { __typename: 'Attendee'; id: string }
    | { __typename: 'BadgeTransaction'; id: string }
    | { __typename: 'BadgeTransactionItem'; id: string }
    | { __typename: 'Booth'; id: string }
    | { __typename: 'ContentStream'; id: string }
    | { __typename: 'DigitalMasterclass'; id: string }
    | { __typename: 'Document'; id: string }
    | { __typename: 'DueDateItem'; id: string }
    | { __typename: 'Event'; id: string }
    | { __typename: 'EventSpeaker'; id: string }
    | { __typename: 'Interest'; id: string }
    | { __typename: 'InvitationQuota'; id: string }
    | { __typename: 'LeadGenLimitConfig'; id: string }
    | { __typename: 'Location'; id: string }
    | { __typename: 'Order'; id: string }
    | { __typename: 'PartnerCompany'; id: string }
    | { __typename: 'PromoCode'; id: string }
    | { __typename: 'ProspectList'; id: string }
    | {
        __typename: 'Speaker'
        id: string
        name?: string | null
        firstname?: string | null
        lastname?: string | null
        descriptionEn?: string | null
        descriptionDe?: string | null
        title?: string | null
        email?: string | null
        editorialFlag?: boolean | null
        partnerCompanyName?: string | null
        createdAt: string
        photoUrl?: string | null
        twitterUrl?: string | null
        facebookUrl?: string | null
        instagramUrl?: string | null
        linkedinUrl?: string | null
        youtubeUrl?: string | null
        websiteUrl?: string | null
        partnerCompany?: { __typename?: 'PartnerCompany'; id: string } | null
        interests?: Array<{ __typename?: 'Interest'; id: string; slug: string; text: string }> | null
        eventSpeakers?: Array<{ __typename?: 'EventSpeaker'; id: string; event: { __typename?: 'Event' } & EventFragment }> | null
      }
    | { __typename: 'Stage'; id: string }
    | { __typename: 'User'; id: string }
    | null
}

export type SpeakersQueryVariables = Exact<{
  cursor?: InputMaybe<Scalars['String']['input']>
  searchTerm?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  forCurrentPartnerCompany?: InputMaybe<Scalars['Boolean']['input']>
}>

export type SpeakersQuery = {
  __typename?: 'Query'
  speakers: {
    __typename?: 'SpeakerConnection'
    nodes: Array<{
      __typename?: 'Speaker'
      id: string
      name?: string | null
      firstname?: string | null
      lastname?: string | null
      descriptionEn?: string | null
      descriptionDe?: string | null
      createdAt: string
      editorialFlag?: boolean | null
      partnerCompanyName?: string | null
      title?: string | null
      photoUrl?: string | null
      twitterUrl?: string | null
      facebookUrl?: string | null
      instagramUrl?: string | null
      linkedinUrl?: string | null
      youtubeUrl?: string | null
      websiteUrl?: string | null
      email?: string | null
      partnerCompany?: { __typename?: 'PartnerCompany'; id: string } | null
      interests?: Array<{ __typename?: 'Interest'; id: string; slug: string; text: string }> | null
      eventSpeakers?: Array<{ __typename?: 'EventSpeaker'; id: string; event: { __typename?: 'Event' } & EventFragment }> | null
    }>
    pageInfo: { __typename?: 'PageInfo' } & PageInfoFragment
  }
}

export type TicketCountsQueryVariables = Exact<{
  aasmState?: InputMaybe<Array<TicketAasmStateEnum> | TicketAasmStateEnum>
  activated?: InputMaybe<Scalars['Boolean']['input']>
  invitationQuotaId?: InputMaybe<Scalars['ID']['input']>
  source?: InputMaybe<Array<TicketSourceEnum> | TicketSourceEnum>
  swapped?: InputMaybe<Scalars['Boolean']['input']>
}>

export type TicketCountsQuery = { __typename?: 'Query'; tickets: { __typename?: 'TicketConnection'; totalCount: number } }

export type TicketQueryVariables = Exact<{
  identifier: Scalars['String']['input']
}>

export type TicketQuery = {
  __typename?: 'Query'
  ticket?: {
    __typename?: 'Ticket'
    aasmState: TicketAasmStateEnum
    birthdate?: any | null
    companyDepartment?: CompanyDepartmentEnum | null
    companyFieldId?: number | null
    companyIndustryId?: number | null
    companyName?: string | null
    companyType?: CompanyTypeEnum | null
    companySize?: CompanySizeEnum | null
    country?: string | null
    createdAt: string
    deactivatedAt?: string | null
    email?: string | null
    firstName?: string | null
    id: string
    identifier: string
    jobLevel?: JobLevelEnum | null
    lastName?: string | null
    partnerName?: string | null
    partnerRole?: string | null
    phone?: string | null
    position?: string | null
    qrCodePayload: string
    salutation?: SalutationTypeEnum | null
    source: TicketSourceEnum
    swapped: boolean
    title?: string | null
    updatedAt: string
    fields?: Array<{
      __typename?: 'Field'
      id: string
      descriptionDe?: string | null
      descriptionEn?: string | null
      key?: string | null
      name?: string | null
    }> | null
  } | null
}

export type TicketsQueryVariables = Exact<{
  aasmState?: InputMaybe<Array<TicketAasmStateEnum> | TicketAasmStateEnum>
  activated?: InputMaybe<Scalars['Boolean']['input']>
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  invitationQuotaId?: InputMaybe<Scalars['ID']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  orderBy?: InputMaybe<TicketOrderAttributesEnum>
  orderDirection?: InputMaybe<OrderDirectionEnum>
  search?: InputMaybe<Scalars['String']['input']>
  source?: InputMaybe<Array<TicketSourceEnum> | TicketSourceEnum>
  swapped?: InputMaybe<Scalars['Boolean']['input']>
}>

export type TicketsQuery = {
  __typename?: 'Query'
  tickets: {
    __typename?: 'TicketConnection'
    totalCount: number
    lockedCount: number
    unlockedCount: number
    nodes: Array<{
      __typename?: 'Ticket'
      aasmState: TicketAasmStateEnum
      companyName?: string | null
      companyType?: CompanyTypeEnum | null
      createdAt: string
      deactivatedAt?: string | null
      email?: string | null
      firstName?: string | null
      id: string
      identifier: string
      lastName?: string | null
      phone?: string | null
      position?: string | null
      salutation?: SalutationTypeEnum | null
      source: TicketSourceEnum
      swapped: boolean
      title?: string | null
    }>
    edges: Array<{ __typename?: 'TicketEdge'; cursor: string }>
    pageInfo: {
      __typename?: 'PageInfo'
      hasNextPage: boolean
      hasPreviousPage: boolean
      startCursor?: string | null
      endCursor?: string | null
    }
  }
}

export type UserPropertiesPreferencesQueryVariables = Exact<{ [key: string]: never }>

export type UserPropertiesPreferencesQuery = {
  __typename?: 'Query'
  userPropertiesPreferences: {
    __typename?: 'UserPropertiesPreferences'
    companyDepartments: Array<{ __typename?: 'CompanyDepartments'; enum: CompanyDepartmentEnum; name: string }>
    companyFields: Array<{ __typename?: 'CompanyFields'; id: number; companyIndustryId: number; translation: string }>
    companyIndustries: Array<{ __typename?: 'CompanyIndustries'; id: number; translation: string }>
    companySizes: Array<{ __typename?: 'CompanySizes'; enum: CompanySizeEnum; name: string }>
    countries: Array<{ __typename?: 'Country'; code: string; translations: { __typename?: 'CountryTranslations'; en: string; de: string } }>
    companyTypes: Array<{ __typename?: 'CompanyTypes'; enum: CompanyTypeEnum; name: string }>
    jobLevels: Array<{ __typename?: 'JobLevels'; enum: JobLevelEnum; name: string }>
    salutations: Array<{ __typename?: 'Salutations'; enum: SalutationTypeEnum; name: string }>
  }
}

export type UserQueryVariables = Exact<{
  userId: Scalars['String']['input']
}>

export type UserQuery = {
  __typename?: 'Query'
  user?: ({ __typename: 'User'; roles: Array<{ __typename?: 'Role' } & RoleFragment> } & UserFragment) | null
}

export type UsersQueryVariables = Exact<{ [key: string]: never }>

export type UsersQuery = {
  __typename?: 'Query'
  users: {
    __typename?: 'UserConnection'
    nodes: Array<{ __typename?: 'User'; roles: Array<{ __typename?: 'Role' } & RoleFragment> } & UserFragment>
    pageInfo: { __typename?: 'PageInfo' } & PageInfoFragment
  }
}

export const BoothFragmentDoc = gql`
  fragment booth on Booth {
    backCoverDesignUrl
    boothNumber
    counterBrandingFrontUrl
    counterBrandingSideAUrl
    counterBrandingSideBUrl
    descriptionDe
    descriptionEn
    foodTypes
    id
    titleDe
    titleEn
    typeDisplayName
    type
    event {
      id
    }
    products {
      id
      name
      description
      attachmentUrl
      maximum
      deliveryFee
      fileUploadRequired
      commentRequired
      variants {
        id
        price
        name
        currency
      }
      productCategory {
        id
        name
        position
        cancellationDate
        expiresAt
      }
    }
    extras {
      productId
      selectedVariantId
      count
      price
      deliveryFee
      fileName
      fileUrl
      comment
    }
    extrasDueDate
    oscPortalUrl
  }
`
export const DueDateItemFragmentDoc = gql`
  fragment dueDateItem on DueDateItem {
    id
    dueAt
    resourceType
    resourceId
    titleDe
    titleEn
    completed
    valid
  }
`
export const EventFragmentDoc = gql`
  fragment event on Event {
    agendaFormatted
    attendeeCount
    attendeeSelectionClosed
    attendeeSelectionPaused
    companyEmail
    companyName
    companyWebsite
    ctaButtonLink
    ctaButtonText
    descriptionDe
    descriptionEn
    duration
    enableCheckin
    endsAt
    eventFormat
    featured
    id
    interests {
      id
      slug
      text
    }
    invitationQuotas {
      __typename
      discount
      id
      fields {
        id
        name
        descriptionEn
        descriptionDe
      }
      inviteeTracking {
        acceptedCount
        declinedCount
        openedCount
        purchasedCount
        sentCount
        totalCount
      }
      kind
      maxUsable
      ticketCategory {
        id
        public
        titleDe
        titleEn
      }
      title
      descriptionEn
      descriptionDe
    }
    invitationQuotasCount
    language
    leadCount
    livestreamUrlWithToken
    location {
      id
      name
    }
    locationAddress
    locationName
    maxAttendees
    minAttendees
    name
    nameDe
    nameEn
    presentationFormatted
    presentationRaw
    prospectSelectionUntil
    reservedSeats
    shuttleService
    slug
    startsAt
    streamingKey
    subtitleDe
    subtitleEn
    ticketShop
    type
    videoPresentationUrl
    videoRecordingUrl
    websiteBackgroundUrl
  }
`
export const EventOverviewFragmentDoc = gql`
  fragment eventOverview on Event {
    descriptionDe
    descriptionEn
    endsAt
    id
    invitationQuotasCount
    language
    leadCount
    livestreamUrlWithToken
    location {
      id
      name
    }
    name
    nameDe
    nameEn
    slug
    startsAt
    type
  }
`
export const PageInfoFragmentDoc = gql`
  fragment pageInfo on PageInfo {
    startCursor
    endCursor
    hasNextPage
    hasPreviousPage
  }
`
export const ContactFragmentDoc = gql`
  fragment contact on Contact {
    email
    firstname
    id
    name
    phone
    salutation
    title
    type
  }
`
export const PartnerCompanyFragmentDoc = gql`
  fragment partnerCompany on PartnerCompany {
    city
    contacts {
      ...contact
    }
    countryCode
    descriptionDe
    descriptionEn
    displayName
    facebook
    foodTypes
    hasFoodBooths
    hasChildEvents
    hasQuotas
    id
    instagram
    interests {
      id
      slug
      text
    }
    webLogoUrl
    logoUrl
    name
    displayName
    publicEmail
    slug
    street
    twitter
    website
    youtube
    zipcode
    linkedinUrl
    tagList
    leadGenLimitConfigs {
      id
      limit
      event {
        slug
      }
    }
  }
  ${ContactFragmentDoc}
`
export const ProspectFragmentDoc = gql`
  fragment prospect on Prospect {
    id
    available
    highlight {
      companyName
      companyPosition
    }
    companies {
      department
      eventSlug
      field
      industry
      jobLevel
      name
      position
      size
      country
    }
    visitedEvents {
      name
      slug
    }
  }
`
export const RoleFragmentDoc = gql`
  fragment role on Role {
    id
    name
    resourceId
    resourceType
  }
`
export const UserFragmentDoc = gql`
  fragment user on User {
    email
    firstName
    id
    lastName
    locale
    omrMatchConsent
  }
`
export const AcceptAttendeeDocument = gql`
  mutation AcceptAttendee($attendeeId: ID!) {
    acceptAttendee(input: { attendeeId: $attendeeId }) {
      attendee {
        aasmState
        id
      }
      errors
    }
  }
`

/**
 * __useAcceptAttendeeMutation__
 *
 * To run a mutation, you first call `useAcceptAttendeeMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useAcceptAttendeeMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useAcceptAttendeeMutation({
 *   variables: {
 *     attendeeId: // value for 'attendeeId'
 *   },
 * });
 */
export function useAcceptAttendeeMutation(
  options:
    | VueApolloComposable.UseMutationOptions<AcceptAttendeeMutation, AcceptAttendeeMutationVariables>
    | ReactiveFunction<VueApolloComposable.UseMutationOptions<AcceptAttendeeMutation, AcceptAttendeeMutationVariables>> = {},
) {
  return VueApolloComposable.useMutation<AcceptAttendeeMutation, AcceptAttendeeMutationVariables>(AcceptAttendeeDocument, options)
}
export type AcceptAttendeeMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<
  AcceptAttendeeMutation,
  AcceptAttendeeMutationVariables
>
export const AddAttendeesByTicketIdentifierDocument = gql`
  mutation AddAttendeesByTicketIdentifier($ticketIdentifiers: [ID!]!, $eventId: ID!) {
    addAttendeesByTicketIdentifier(input: { ticketIdentifiers: $ticketIdentifiers, eventId: $eventId }) {
      clientMutationId
      errors
      results {
        companyName
        errorMessage
        position
        success
        ticketIdentifier
      }
    }
  }
`

/**
 * __useAddAttendeesByTicketIdentifierMutation__
 *
 * To run a mutation, you first call `useAddAttendeesByTicketIdentifierMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useAddAttendeesByTicketIdentifierMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useAddAttendeesByTicketIdentifierMutation({
 *   variables: {
 *     ticketIdentifiers: // value for 'ticketIdentifiers'
 *     eventId: // value for 'eventId'
 *   },
 * });
 */
export function useAddAttendeesByTicketIdentifierMutation(
  options:
    | VueApolloComposable.UseMutationOptions<AddAttendeesByTicketIdentifierMutation, AddAttendeesByTicketIdentifierMutationVariables>
    | ReactiveFunction<
        VueApolloComposable.UseMutationOptions<AddAttendeesByTicketIdentifierMutation, AddAttendeesByTicketIdentifierMutationVariables>
      > = {},
) {
  return VueApolloComposable.useMutation<AddAttendeesByTicketIdentifierMutation, AddAttendeesByTicketIdentifierMutationVariables>(
    AddAttendeesByTicketIdentifierDocument,
    options,
  )
}
export type AddAttendeesByTicketIdentifierMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<
  AddAttendeesByTicketIdentifierMutation,
  AddAttendeesByTicketIdentifierMutationVariables
>
export const CloseAttendeeSelectionDocument = gql`
  mutation CloseAttendeeSelection($eventId: ID!) {
    closeAttendeeSelection(input: { eventId: $eventId }) {
      event {
        id
        attendeeSelectionClosed
      }
      errors
    }
  }
`

/**
 * __useCloseAttendeeSelectionMutation__
 *
 * To run a mutation, you first call `useCloseAttendeeSelectionMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useCloseAttendeeSelectionMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useCloseAttendeeSelectionMutation({
 *   variables: {
 *     eventId: // value for 'eventId'
 *   },
 * });
 */
export function useCloseAttendeeSelectionMutation(
  options:
    | VueApolloComposable.UseMutationOptions<CloseAttendeeSelectionMutation, CloseAttendeeSelectionMutationVariables>
    | ReactiveFunction<
        VueApolloComposable.UseMutationOptions<CloseAttendeeSelectionMutation, CloseAttendeeSelectionMutationVariables>
      > = {},
) {
  return VueApolloComposable.useMutation<CloseAttendeeSelectionMutation, CloseAttendeeSelectionMutationVariables>(
    CloseAttendeeSelectionDocument,
    options,
  )
}
export type CloseAttendeeSelectionMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<
  CloseAttendeeSelectionMutation,
  CloseAttendeeSelectionMutationVariables
>
export const DenyAttendeeDocument = gql`
  mutation denyAttendee($attendeeId: ID!) {
    denyAttendee(input: { attendeeId: $attendeeId }) {
      attendee {
        aasmState
        id
      }
      errors
    }
  }
`

/**
 * __useDenyAttendeeMutation__
 *
 * To run a mutation, you first call `useDenyAttendeeMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useDenyAttendeeMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useDenyAttendeeMutation({
 *   variables: {
 *     attendeeId: // value for 'attendeeId'
 *   },
 * });
 */
export function useDenyAttendeeMutation(
  options:
    | VueApolloComposable.UseMutationOptions<DenyAttendeeMutation, DenyAttendeeMutationVariables>
    | ReactiveFunction<VueApolloComposable.UseMutationOptions<DenyAttendeeMutation, DenyAttendeeMutationVariables>> = {},
) {
  return VueApolloComposable.useMutation<DenyAttendeeMutation, DenyAttendeeMutationVariables>(DenyAttendeeDocument, options)
}
export type DenyAttendeeMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<
  DenyAttendeeMutation,
  DenyAttendeeMutationVariables
>
export const UpdateAttendeesStateFromCsvDocument = gql`
  mutation UpdateAttendeesStateFromCsv($eventId: ID!, $csvFile: Upload!) {
    updateAttendeesStateFromCsv(input: { eventId: $eventId, csvFile: $csvFile }) {
      accepted
      denied
      notChanged
      failed
      errors
    }
  }
`

/**
 * __useUpdateAttendeesStateFromCsvMutation__
 *
 * To run a mutation, you first call `useUpdateAttendeesStateFromCsvMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAttendeesStateFromCsvMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useUpdateAttendeesStateFromCsvMutation({
 *   variables: {
 *     eventId: // value for 'eventId'
 *     csvFile: // value for 'csvFile'
 *   },
 * });
 */
export function useUpdateAttendeesStateFromCsvMutation(
  options:
    | VueApolloComposable.UseMutationOptions<UpdateAttendeesStateFromCsvMutation, UpdateAttendeesStateFromCsvMutationVariables>
    | ReactiveFunction<
        VueApolloComposable.UseMutationOptions<UpdateAttendeesStateFromCsvMutation, UpdateAttendeesStateFromCsvMutationVariables>
      > = {},
) {
  return VueApolloComposable.useMutation<UpdateAttendeesStateFromCsvMutation, UpdateAttendeesStateFromCsvMutationVariables>(
    UpdateAttendeesStateFromCsvDocument,
    options,
  )
}
export type UpdateAttendeesStateFromCsvMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<
  UpdateAttendeesStateFromCsvMutation,
  UpdateAttendeesStateFromCsvMutationVariables
>
export const UpdateBoothExtrasDocument = gql`
  mutation UpdateBoothExtras($boothId: ID!, $extras: [ExtraAttributes!]!) {
    updateBoothExtras(input: { extras: $extras, boothId: $boothId }) {
      booth {
        ...booth
      }
      errors
    }
  }
  ${BoothFragmentDoc}
`

/**
 * __useUpdateBoothExtrasMutation__
 *
 * To run a mutation, you first call `useUpdateBoothExtrasMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useUpdateBoothExtrasMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useUpdateBoothExtrasMutation({
 *   variables: {
 *     boothId: // value for 'boothId'
 *     extras: // value for 'extras'
 *   },
 * });
 */
export function useUpdateBoothExtrasMutation(
  options:
    | VueApolloComposable.UseMutationOptions<UpdateBoothExtrasMutation, UpdateBoothExtrasMutationVariables>
    | ReactiveFunction<VueApolloComposable.UseMutationOptions<UpdateBoothExtrasMutation, UpdateBoothExtrasMutationVariables>> = {},
) {
  return VueApolloComposable.useMutation<UpdateBoothExtrasMutation, UpdateBoothExtrasMutationVariables>(UpdateBoothExtrasDocument, options)
}
export type UpdateBoothExtrasMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<
  UpdateBoothExtrasMutation,
  UpdateBoothExtrasMutationVariables
>
export const UpdateBoothDocument = gql`
  mutation UpdateBooth(
    $backCoverDesign: Upload
    $boothId: ID!
    $counterBrandingFront: Upload
    $counterBrandingSideA: Upload
    $counterBrandingSideB: Upload
    $descriptionDe: String
    $descriptionEn: String
    $foodTypes: [BoothFoodTypeEnum!]
    $titleDe: String
    $titleEn: String
  ) {
    updateBooth(
      input: {
        attributes: {
          backCoverDesign: $backCoverDesign
          counterBrandingFront: $counterBrandingFront
          counterBrandingSideA: $counterBrandingSideA
          counterBrandingSideB: $counterBrandingSideB
          descriptionDe: $descriptionDe
          descriptionEn: $descriptionEn
          foodTypes: $foodTypes
          titleDe: $titleDe
          titleEn: $titleEn
        }
        boothId: $boothId
      }
    ) {
      booth {
        ...booth
      }
      errors
    }
  }
  ${BoothFragmentDoc}
`

/**
 * __useUpdateBoothMutation__
 *
 * To run a mutation, you first call `useUpdateBoothMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useUpdateBoothMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useUpdateBoothMutation({
 *   variables: {
 *     backCoverDesign: // value for 'backCoverDesign'
 *     boothId: // value for 'boothId'
 *     counterBrandingFront: // value for 'counterBrandingFront'
 *     counterBrandingSideA: // value for 'counterBrandingSideA'
 *     counterBrandingSideB: // value for 'counterBrandingSideB'
 *     descriptionDe: // value for 'descriptionDe'
 *     descriptionEn: // value for 'descriptionEn'
 *     foodTypes: // value for 'foodTypes'
 *     titleDe: // value for 'titleDe'
 *     titleEn: // value for 'titleEn'
 *   },
 * });
 */
export function useUpdateBoothMutation(
  options:
    | VueApolloComposable.UseMutationOptions<UpdateBoothMutation, UpdateBoothMutationVariables>
    | ReactiveFunction<VueApolloComposable.UseMutationOptions<UpdateBoothMutation, UpdateBoothMutationVariables>> = {},
) {
  return VueApolloComposable.useMutation<UpdateBoothMutation, UpdateBoothMutationVariables>(UpdateBoothDocument, options)
}
export type UpdateBoothMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<
  UpdateBoothMutation,
  UpdateBoothMutationVariables
>
export const CreateContactDocument = gql`
  mutation CreateContact(
    $partnerCompanyId: ID!
    $email: String
    $firstname: String
    $name: String
    $phone: String
    $title: String
    $type: String
  ) {
    createContact(
      input: {
        partnerCompanyId: $partnerCompanyId
        attributes: { email: $email, firstname: $firstname, name: $name, phone: $phone, title: $title, type: $type }
      }
    ) {
      contact {
        ...contact
      }
    }
  }
  ${ContactFragmentDoc}
`

/**
 * __useCreateContactMutation__
 *
 * To run a mutation, you first call `useCreateContactMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useCreateContactMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useCreateContactMutation({
 *   variables: {
 *     partnerCompanyId: // value for 'partnerCompanyId'
 *     email: // value for 'email'
 *     firstname: // value for 'firstname'
 *     name: // value for 'name'
 *     phone: // value for 'phone'
 *     title: // value for 'title'
 *     type: // value for 'type'
 *   },
 * });
 */
export function useCreateContactMutation(
  options:
    | VueApolloComposable.UseMutationOptions<CreateContactMutation, CreateContactMutationVariables>
    | ReactiveFunction<VueApolloComposable.UseMutationOptions<CreateContactMutation, CreateContactMutationVariables>> = {},
) {
  return VueApolloComposable.useMutation<CreateContactMutation, CreateContactMutationVariables>(CreateContactDocument, options)
}
export type CreateContactMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<
  CreateContactMutation,
  CreateContactMutationVariables
>
export const UpdateContactDocument = gql`
  mutation UpdateContact($id: ID!, $email: String, $firstname: String, $name: String, $phone: String, $title: String) {
    updateContact(
      input: { contactId: $id, attributes: { email: $email, firstname: $firstname, name: $name, phone: $phone, title: $title } }
    ) {
      contact {
        ...contact
      }
    }
  }
  ${ContactFragmentDoc}
`

/**
 * __useUpdateContactMutation__
 *
 * To run a mutation, you first call `useUpdateContactMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useUpdateContactMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useUpdateContactMutation({
 *   variables: {
 *     id: // value for 'id'
 *     email: // value for 'email'
 *     firstname: // value for 'firstname'
 *     name: // value for 'name'
 *     phone: // value for 'phone'
 *     title: // value for 'title'
 *   },
 * });
 */
export function useUpdateContactMutation(
  options:
    | VueApolloComposable.UseMutationOptions<UpdateContactMutation, UpdateContactMutationVariables>
    | ReactiveFunction<VueApolloComposable.UseMutationOptions<UpdateContactMutation, UpdateContactMutationVariables>> = {},
) {
  return VueApolloComposable.useMutation<UpdateContactMutation, UpdateContactMutationVariables>(UpdateContactDocument, options)
}
export type UpdateContactMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<
  UpdateContactMutation,
  UpdateContactMutationVariables
>
export const AcceptTicketRequestDocument = gql`
  mutation AcceptTicketRequest($ticketId: ID!) {
    acceptTicketRequest(input: { ticketId: $ticketId }) {
      ticket {
        id
      }
      errors
    }
  }
`

/**
 * __useAcceptTicketRequestMutation__
 *
 * To run a mutation, you first call `useAcceptTicketRequestMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useAcceptTicketRequestMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useAcceptTicketRequestMutation({
 *   variables: {
 *     ticketId: // value for 'ticketId'
 *   },
 * });
 */
export function useAcceptTicketRequestMutation(
  options:
    | VueApolloComposable.UseMutationOptions<AcceptTicketRequestMutation, AcceptTicketRequestMutationVariables>
    | ReactiveFunction<VueApolloComposable.UseMutationOptions<AcceptTicketRequestMutation, AcceptTicketRequestMutationVariables>> = {},
) {
  return VueApolloComposable.useMutation<AcceptTicketRequestMutation, AcceptTicketRequestMutationVariables>(
    AcceptTicketRequestDocument,
    options,
  )
}
export type AcceptTicketRequestMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<
  AcceptTicketRequestMutation,
  AcceptTicketRequestMutationVariables
>
export const ActivateTicketDocument = gql`
  mutation ActivateTicket($ticketId: ID!) {
    activateTicket(input: { ticketId: $ticketId }) {
      ticket {
        id
      }
      errors
    }
  }
`

/**
 * __useActivateTicketMutation__
 *
 * To run a mutation, you first call `useActivateTicketMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useActivateTicketMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useActivateTicketMutation({
 *   variables: {
 *     ticketId: // value for 'ticketId'
 *   },
 * });
 */
export function useActivateTicketMutation(
  options:
    | VueApolloComposable.UseMutationOptions<ActivateTicketMutation, ActivateTicketMutationVariables>
    | ReactiveFunction<VueApolloComposable.UseMutationOptions<ActivateTicketMutation, ActivateTicketMutationVariables>> = {},
) {
  return VueApolloComposable.useMutation<ActivateTicketMutation, ActivateTicketMutationVariables>(ActivateTicketDocument, options)
}
export type ActivateTicketMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<
  ActivateTicketMutation,
  ActivateTicketMutationVariables
>
export const CreateTicketDocument = gql`
  mutation CreateTicket($invitationQuotaId: ID!, $ticketCreateAttributes: DirectTicketAttributes!) {
    createDirectTicket(input: { attributes: $ticketCreateAttributes, invitationQuotaId: $invitationQuotaId }) {
      ticket {
        id
      }
      errors
    }
  }
`

/**
 * __useCreateTicketMutation__
 *
 * To run a mutation, you first call `useCreateTicketMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useCreateTicketMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useCreateTicketMutation({
 *   variables: {
 *     invitationQuotaId: // value for 'invitationQuotaId'
 *     ticketCreateAttributes: // value for 'ticketCreateAttributes'
 *   },
 * });
 */
export function useCreateTicketMutation(
  options:
    | VueApolloComposable.UseMutationOptions<CreateTicketMutation, CreateTicketMutationVariables>
    | ReactiveFunction<VueApolloComposable.UseMutationOptions<CreateTicketMutation, CreateTicketMutationVariables>> = {},
) {
  return VueApolloComposable.useMutation<CreateTicketMutation, CreateTicketMutationVariables>(CreateTicketDocument, options)
}
export type CreateTicketMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<
  CreateTicketMutation,
  CreateTicketMutationVariables
>
export const DeactivateTicketDocument = gql`
  mutation DeactivateTicket($ticketId: ID!) {
    deactivateTicket(input: { ticketId: $ticketId }) {
      ticket {
        id
      }
      errors
    }
  }
`

/**
 * __useDeactivateTicketMutation__
 *
 * To run a mutation, you first call `useDeactivateTicketMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useDeactivateTicketMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useDeactivateTicketMutation({
 *   variables: {
 *     ticketId: // value for 'ticketId'
 *   },
 * });
 */
export function useDeactivateTicketMutation(
  options:
    | VueApolloComposable.UseMutationOptions<DeactivateTicketMutation, DeactivateTicketMutationVariables>
    | ReactiveFunction<VueApolloComposable.UseMutationOptions<DeactivateTicketMutation, DeactivateTicketMutationVariables>> = {},
) {
  return VueApolloComposable.useMutation<DeactivateTicketMutation, DeactivateTicketMutationVariables>(DeactivateTicketDocument, options)
}
export type DeactivateTicketMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<
  DeactivateTicketMutation,
  DeactivateTicketMutationVariables
>
export const DeleteTicketDocument = gql`
  mutation DeleteTicket($ticketId: ID!) {
    deleteTicket(input: { ticketId: $ticketId }) {
      errors
    }
  }
`

/**
 * __useDeleteTicketMutation__
 *
 * To run a mutation, you first call `useDeleteTicketMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useDeleteTicketMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useDeleteTicketMutation({
 *   variables: {
 *     ticketId: // value for 'ticketId'
 *   },
 * });
 */
export function useDeleteTicketMutation(
  options:
    | VueApolloComposable.UseMutationOptions<DeleteTicketMutation, DeleteTicketMutationVariables>
    | ReactiveFunction<VueApolloComposable.UseMutationOptions<DeleteTicketMutation, DeleteTicketMutationVariables>> = {},
) {
  return VueApolloComposable.useMutation<DeleteTicketMutation, DeleteTicketMutationVariables>(DeleteTicketDocument, options)
}
export type DeleteTicketMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<
  DeleteTicketMutation,
  DeleteTicketMutationVariables
>
export const DenyTicketRequestDocument = gql`
  mutation DenyTicketRequest($ticketId: ID!) {
    denyTicketRequest(input: { ticketId: $ticketId }) {
      errors
    }
  }
`

/**
 * __useDenyTicketRequestMutation__
 *
 * To run a mutation, you first call `useDenyTicketRequestMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useDenyTicketRequestMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useDenyTicketRequestMutation({
 *   variables: {
 *     ticketId: // value for 'ticketId'
 *   },
 * });
 */
export function useDenyTicketRequestMutation(
  options:
    | VueApolloComposable.UseMutationOptions<DenyTicketRequestMutation, DenyTicketRequestMutationVariables>
    | ReactiveFunction<VueApolloComposable.UseMutationOptions<DenyTicketRequestMutation, DenyTicketRequestMutationVariables>> = {},
) {
  return VueApolloComposable.useMutation<DenyTicketRequestMutation, DenyTicketRequestMutationVariables>(DenyTicketRequestDocument, options)
}
export type DenyTicketRequestMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<
  DenyTicketRequestMutation,
  DenyTicketRequestMutationVariables
>
export const ResendTicketMailDocument = gql`
  mutation ResendTicketMail($ticketId: ID!) {
    resendTicketMail(input: { ticketId: $ticketId }) {
      success
      errors
    }
  }
`

/**
 * __useResendTicketMailMutation__
 *
 * To run a mutation, you first call `useResendTicketMailMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useResendTicketMailMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useResendTicketMailMutation({
 *   variables: {
 *     ticketId: // value for 'ticketId'
 *   },
 * });
 */
export function useResendTicketMailMutation(
  options:
    | VueApolloComposable.UseMutationOptions<ResendTicketMailMutation, ResendTicketMailMutationVariables>
    | ReactiveFunction<VueApolloComposable.UseMutationOptions<ResendTicketMailMutation, ResendTicketMailMutationVariables>> = {},
) {
  return VueApolloComposable.useMutation<ResendTicketMailMutation, ResendTicketMailMutationVariables>(ResendTicketMailDocument, options)
}
export type ResendTicketMailMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<
  ResendTicketMailMutation,
  ResendTicketMailMutationVariables
>
export const UpdateTicketDocument = gql`
  mutation updateTicket($ticketId: ID!, $ticketCreateAttributes: DirectTicketAttributes!, $events: [TicketEvent!]) {
    updateDirectTicket(input: { ticketId: $ticketId, attributes: $ticketCreateAttributes, events: $events }) {
      ticket {
        id
      }
      errors
    }
  }
`

/**
 * __useUpdateTicketMutation__
 *
 * To run a mutation, you first call `useUpdateTicketMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTicketMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useUpdateTicketMutation({
 *   variables: {
 *     ticketId: // value for 'ticketId'
 *     ticketCreateAttributes: // value for 'ticketCreateAttributes'
 *     events: // value for 'events'
 *   },
 * });
 */
export function useUpdateTicketMutation(
  options:
    | VueApolloComposable.UseMutationOptions<UpdateTicketMutation, UpdateTicketMutationVariables>
    | ReactiveFunction<VueApolloComposable.UseMutationOptions<UpdateTicketMutation, UpdateTicketMutationVariables>> = {},
) {
  return VueApolloComposable.useMutation<UpdateTicketMutation, UpdateTicketMutationVariables>(UpdateTicketDocument, options)
}
export type UpdateTicketMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<
  UpdateTicketMutation,
  UpdateTicketMutationVariables
>
export const CreateAcknowledgementDocument = gql`
  mutation CreateAcknowledgement($documentId: ID!, $resourceId: ID!) {
    createAcknowledgement(input: { documentId: $documentId, resourceId: $resourceId }) {
      docAcknowledgement {
        id
        document {
          id
          docAcknowledgements {
            id
            signableId
            signableType
            uploadsUrls
          }
        }
      }
      errors
    }
  }
`

/**
 * __useCreateAcknowledgementMutation__
 *
 * To run a mutation, you first call `useCreateAcknowledgementMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useCreateAcknowledgementMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useCreateAcknowledgementMutation({
 *   variables: {
 *     documentId: // value for 'documentId'
 *     resourceId: // value for 'resourceId'
 *   },
 * });
 */
export function useCreateAcknowledgementMutation(
  options:
    | VueApolloComposable.UseMutationOptions<CreateAcknowledgementMutation, CreateAcknowledgementMutationVariables>
    | ReactiveFunction<VueApolloComposable.UseMutationOptions<CreateAcknowledgementMutation, CreateAcknowledgementMutationVariables>> = {},
) {
  return VueApolloComposable.useMutation<CreateAcknowledgementMutation, CreateAcknowledgementMutationVariables>(
    CreateAcknowledgementDocument,
    options,
  )
}
export type CreateAcknowledgementMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<
  CreateAcknowledgementMutation,
  CreateAcknowledgementMutationVariables
>
export const DeleteAcknowledgementUploadDocument = gql`
  mutation DeleteAcknowledgementUpload($docAcknowledgementId: ID!, $uploadUrl: String!) {
    deleteAcknowledgementUpload(input: { uploadUrl: $uploadUrl, docAcknowledgementId: $docAcknowledgementId }) {
      clientMutationId
      docAcknowledgement {
        acknowledgedAt
        acknowledgedBy
        document {
          id
        }
        id
        signableId
        signableType
        uploadedAt
        uploadedBy
        uploadsUrls
      }
      errors
    }
  }
`

/**
 * __useDeleteAcknowledgementUploadMutation__
 *
 * To run a mutation, you first call `useDeleteAcknowledgementUploadMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useDeleteAcknowledgementUploadMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useDeleteAcknowledgementUploadMutation({
 *   variables: {
 *     docAcknowledgementId: // value for 'docAcknowledgementId'
 *     uploadUrl: // value for 'uploadUrl'
 *   },
 * });
 */
export function useDeleteAcknowledgementUploadMutation(
  options:
    | VueApolloComposable.UseMutationOptions<DeleteAcknowledgementUploadMutation, DeleteAcknowledgementUploadMutationVariables>
    | ReactiveFunction<
        VueApolloComposable.UseMutationOptions<DeleteAcknowledgementUploadMutation, DeleteAcknowledgementUploadMutationVariables>
      > = {},
) {
  return VueApolloComposable.useMutation<DeleteAcknowledgementUploadMutation, DeleteAcknowledgementUploadMutationVariables>(
    DeleteAcknowledgementUploadDocument,
    options,
  )
}
export type DeleteAcknowledgementUploadMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<
  DeleteAcknowledgementUploadMutation,
  DeleteAcknowledgementUploadMutationVariables
>
export const UpdateAcknowledgementDocument = gql`
  mutation UpdateAcknowledgement($docAcknowledgementId: ID!, $document: Upload) {
    updateAcknowledgement(input: { docAcknowledgementId: $docAcknowledgementId, document: $document }) {
      docAcknowledgement {
        id
        document {
          id
          docAcknowledgements {
            id
            uploadsUrls
          }
        }
      }
      errors
    }
  }
`

/**
 * __useUpdateAcknowledgementMutation__
 *
 * To run a mutation, you first call `useUpdateAcknowledgementMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAcknowledgementMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useUpdateAcknowledgementMutation({
 *   variables: {
 *     docAcknowledgementId: // value for 'docAcknowledgementId'
 *     document: // value for 'document'
 *   },
 * });
 */
export function useUpdateAcknowledgementMutation(
  options:
    | VueApolloComposable.UseMutationOptions<UpdateAcknowledgementMutation, UpdateAcknowledgementMutationVariables>
    | ReactiveFunction<VueApolloComposable.UseMutationOptions<UpdateAcknowledgementMutation, UpdateAcknowledgementMutationVariables>> = {},
) {
  return VueApolloComposable.useMutation<UpdateAcknowledgementMutation, UpdateAcknowledgementMutationVariables>(
    UpdateAcknowledgementDocument,
    options,
  )
}
export type UpdateAcknowledgementMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<
  UpdateAcknowledgementMutation,
  UpdateAcknowledgementMutationVariables
>
export const CreateDueDateCompletionDocument = gql`
  mutation CreateDueDateCompletion($dueDateItemId: ID!) {
    createDueDateCompletion(input: { dueDateItemId: $dueDateItemId }) {
      success
      errors
      dueDateItems {
        ...dueDateItem
      }
    }
  }
  ${DueDateItemFragmentDoc}
`

/**
 * __useCreateDueDateCompletionMutation__
 *
 * To run a mutation, you first call `useCreateDueDateCompletionMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useCreateDueDateCompletionMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useCreateDueDateCompletionMutation({
 *   variables: {
 *     dueDateItemId: // value for 'dueDateItemId'
 *   },
 * });
 */
export function useCreateDueDateCompletionMutation(
  options:
    | VueApolloComposable.UseMutationOptions<CreateDueDateCompletionMutation, CreateDueDateCompletionMutationVariables>
    | ReactiveFunction<
        VueApolloComposable.UseMutationOptions<CreateDueDateCompletionMutation, CreateDueDateCompletionMutationVariables>
      > = {},
) {
  return VueApolloComposable.useMutation<CreateDueDateCompletionMutation, CreateDueDateCompletionMutationVariables>(
    CreateDueDateCompletionDocument,
    options,
  )
}
export type CreateDueDateCompletionMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<
  CreateDueDateCompletionMutation,
  CreateDueDateCompletionMutationVariables
>
export const DeleteDueDateCompletionDocument = gql`
  mutation DeleteDueDateCompletion($dueDateItemId: ID!) {
    deleteDueDateCompletion(input: { dueDateItemId: $dueDateItemId }) {
      success
      errors
      dueDateItems {
        ...dueDateItem
      }
    }
  }
  ${DueDateItemFragmentDoc}
`

/**
 * __useDeleteDueDateCompletionMutation__
 *
 * To run a mutation, you first call `useDeleteDueDateCompletionMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useDeleteDueDateCompletionMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useDeleteDueDateCompletionMutation({
 *   variables: {
 *     dueDateItemId: // value for 'dueDateItemId'
 *   },
 * });
 */
export function useDeleteDueDateCompletionMutation(
  options:
    | VueApolloComposable.UseMutationOptions<DeleteDueDateCompletionMutation, DeleteDueDateCompletionMutationVariables>
    | ReactiveFunction<
        VueApolloComposable.UseMutationOptions<DeleteDueDateCompletionMutation, DeleteDueDateCompletionMutationVariables>
      > = {},
) {
  return VueApolloComposable.useMutation<DeleteDueDateCompletionMutation, DeleteDueDateCompletionMutationVariables>(
    DeleteDueDateCompletionDocument,
    options,
  )
}
export type DeleteDueDateCompletionMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<
  DeleteDueDateCompletionMutation,
  DeleteDueDateCompletionMutationVariables
>
export const CreateIndividualInviteesDocument = gql`
  mutation createIndividualInvitees($invitationQuotaId: ID!, $invitees: [InviteeInput!]!) {
    createIndividualInvitees(input: { invitees: $invitees, invitationQuotaId: $invitationQuotaId }) {
      errors
    }
  }
`

/**
 * __useCreateIndividualInviteesMutation__
 *
 * To run a mutation, you first call `useCreateIndividualInviteesMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useCreateIndividualInviteesMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useCreateIndividualInviteesMutation({
 *   variables: {
 *     invitationQuotaId: // value for 'invitationQuotaId'
 *     invitees: // value for 'invitees'
 *   },
 * });
 */
export function useCreateIndividualInviteesMutation(
  options:
    | VueApolloComposable.UseMutationOptions<CreateIndividualInviteesMutation, CreateIndividualInviteesMutationVariables>
    | ReactiveFunction<
        VueApolloComposable.UseMutationOptions<CreateIndividualInviteesMutation, CreateIndividualInviteesMutationVariables>
      > = {},
) {
  return VueApolloComposable.useMutation<CreateIndividualInviteesMutation, CreateIndividualInviteesMutationVariables>(
    CreateIndividualInviteesDocument,
    options,
  )
}
export type CreateIndividualInviteesMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<
  CreateIndividualInviteesMutation,
  CreateIndividualInviteesMutationVariables
>
export const DeleteIndividualInviteesDocument = gql`
  mutation DeleteIndividualInvitees($emails: [String!]!, $invitationQuotaId: ID!) {
    deleteIndividualInvitees(input: { emails: $emails, invitationQuotaId: $invitationQuotaId }) {
      clientMutationId
      errors
      success
    }
  }
`

/**
 * __useDeleteIndividualInviteesMutation__
 *
 * To run a mutation, you first call `useDeleteIndividualInviteesMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useDeleteIndividualInviteesMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useDeleteIndividualInviteesMutation({
 *   variables: {
 *     emails: // value for 'emails'
 *     invitationQuotaId: // value for 'invitationQuotaId'
 *   },
 * });
 */
export function useDeleteIndividualInviteesMutation(
  options:
    | VueApolloComposable.UseMutationOptions<DeleteIndividualInviteesMutation, DeleteIndividualInviteesMutationVariables>
    | ReactiveFunction<
        VueApolloComposable.UseMutationOptions<DeleteIndividualInviteesMutation, DeleteIndividualInviteesMutationVariables>
      > = {},
) {
  return VueApolloComposable.useMutation<DeleteIndividualInviteesMutation, DeleteIndividualInviteesMutationVariables>(
    DeleteIndividualInviteesDocument,
    options,
  )
}
export type DeleteIndividualInviteesMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<
  DeleteIndividualInviteesMutation,
  DeleteIndividualInviteesMutationVariables
>
export const DeleteUnsentInviteesDocument = gql`
  mutation DeleteUnsentInvitees($id: ID!) {
    deleteUnsentInvitees(input: { invitationQuotaId: $id }) {
      success
      errors
    }
  }
`

/**
 * __useDeleteUnsentInviteesMutation__
 *
 * To run a mutation, you first call `useDeleteUnsentInviteesMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useDeleteUnsentInviteesMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useDeleteUnsentInviteesMutation({
 *   variables: {
 *     id: // value for 'id'
 *   },
 * });
 */
export function useDeleteUnsentInviteesMutation(
  options:
    | VueApolloComposable.UseMutationOptions<DeleteUnsentInviteesMutation, DeleteUnsentInviteesMutationVariables>
    | ReactiveFunction<VueApolloComposable.UseMutationOptions<DeleteUnsentInviteesMutation, DeleteUnsentInviteesMutationVariables>> = {},
) {
  return VueApolloComposable.useMutation<DeleteUnsentInviteesMutation, DeleteUnsentInviteesMutationVariables>(
    DeleteUnsentInviteesDocument,
    options,
  )
}
export type DeleteUnsentInviteesMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<
  DeleteUnsentInviteesMutation,
  DeleteUnsentInviteesMutationVariables
>
export const EditEmailTemplateDocument = gql`
  mutation EditEmailTemplate($invitationQuotaId: ID!, $subject: String!, $body: String!, $replyTo: String!, $emailCc: String) {
    editEmailTemplate(
      input: { invitationQuotaId: $invitationQuotaId, subject: $subject, body: $body, replyTo: $replyTo, emailCc: $emailCc }
    ) {
      errors
      invitationQuota {
        id
        __typename
        discount
        kind
        messageSubject
        messageBody
        replyTo
        cc
      }
    }
  }
`

/**
 * __useEditEmailTemplateMutation__
 *
 * To run a mutation, you first call `useEditEmailTemplateMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useEditEmailTemplateMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useEditEmailTemplateMutation({
 *   variables: {
 *     invitationQuotaId: // value for 'invitationQuotaId'
 *     subject: // value for 'subject'
 *     body: // value for 'body'
 *     replyTo: // value for 'replyTo'
 *     emailCc: // value for 'emailCc'
 *   },
 * });
 */
export function useEditEmailTemplateMutation(
  options:
    | VueApolloComposable.UseMutationOptions<EditEmailTemplateMutation, EditEmailTemplateMutationVariables>
    | ReactiveFunction<VueApolloComposable.UseMutationOptions<EditEmailTemplateMutation, EditEmailTemplateMutationVariables>> = {},
) {
  return VueApolloComposable.useMutation<EditEmailTemplateMutation, EditEmailTemplateMutationVariables>(EditEmailTemplateDocument, options)
}
export type EditEmailTemplateMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<
  EditEmailTemplateMutation,
  EditEmailTemplateMutationVariables
>
export const SendIndividualInvitationsDocument = gql`
  mutation sendIndividualInvitations(
    $invitationQuotaId: ID!
    $emails: [String!]!
    $cc: String
    $messageSubject: String!
    $messageBody: String!
    $replyTo: String!
  ) {
    sendIndividualInvitations(
      input: {
        invitationQuotaId: $invitationQuotaId
        emails: $emails
        cc: $cc
        messageSubject: $messageSubject
        messageBody: $messageBody
        replyTo: $replyTo
      }
    ) {
      success
      errors
    }
  }
`

/**
 * __useSendIndividualInvitationsMutation__
 *
 * To run a mutation, you first call `useSendIndividualInvitationsMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useSendIndividualInvitationsMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useSendIndividualInvitationsMutation({
 *   variables: {
 *     invitationQuotaId: // value for 'invitationQuotaId'
 *     emails: // value for 'emails'
 *     cc: // value for 'cc'
 *     messageSubject: // value for 'messageSubject'
 *     messageBody: // value for 'messageBody'
 *     replyTo: // value for 'replyTo'
 *   },
 * });
 */
export function useSendIndividualInvitationsMutation(
  options:
    | VueApolloComposable.UseMutationOptions<SendIndividualInvitationsMutation, SendIndividualInvitationsMutationVariables>
    | ReactiveFunction<
        VueApolloComposable.UseMutationOptions<SendIndividualInvitationsMutation, SendIndividualInvitationsMutationVariables>
      > = {},
) {
  return VueApolloComposable.useMutation<SendIndividualInvitationsMutation, SendIndividualInvitationsMutationVariables>(
    SendIndividualInvitationsDocument,
    options,
  )
}
export type SendIndividualInvitationsMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<
  SendIndividualInvitationsMutation,
  SendIndividualInvitationsMutationVariables
>
export const SendInvitationQuotaEmailsDocument = gql`
  mutation SendInvitationQuotaEmails($id: ID!) {
    sendInvitations(input: { invitationQuotaId: $id }) {
      success
      errors
    }
  }
`

/**
 * __useSendInvitationQuotaEmailsMutation__
 *
 * To run a mutation, you first call `useSendInvitationQuotaEmailsMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useSendInvitationQuotaEmailsMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useSendInvitationQuotaEmailsMutation({
 *   variables: {
 *     id: // value for 'id'
 *   },
 * });
 */
export function useSendInvitationQuotaEmailsMutation(
  options:
    | VueApolloComposable.UseMutationOptions<SendInvitationQuotaEmailsMutation, SendInvitationQuotaEmailsMutationVariables>
    | ReactiveFunction<
        VueApolloComposable.UseMutationOptions<SendInvitationQuotaEmailsMutation, SendInvitationQuotaEmailsMutationVariables>
      > = {},
) {
  return VueApolloComposable.useMutation<SendInvitationQuotaEmailsMutation, SendInvitationQuotaEmailsMutationVariables>(
    SendInvitationQuotaEmailsDocument,
    options,
  )
}
export type SendInvitationQuotaEmailsMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<
  SendInvitationQuotaEmailsMutation,
  SendInvitationQuotaEmailsMutationVariables
>
export const SendTestEmailDocument = gql`
  mutation SendTestEmail($invitationQuotaId: ID!) {
    sendTestEmail(input: { invitationQuotaId: $invitationQuotaId }) {
      success
      errors
    }
  }
`

/**
 * __useSendTestEmailMutation__
 *
 * To run a mutation, you first call `useSendTestEmailMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useSendTestEmailMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useSendTestEmailMutation({
 *   variables: {
 *     invitationQuotaId: // value for 'invitationQuotaId'
 *   },
 * });
 */
export function useSendTestEmailMutation(
  options:
    | VueApolloComposable.UseMutationOptions<SendTestEmailMutation, SendTestEmailMutationVariables>
    | ReactiveFunction<VueApolloComposable.UseMutationOptions<SendTestEmailMutation, SendTestEmailMutationVariables>> = {},
) {
  return VueApolloComposable.useMutation<SendTestEmailMutation, SendTestEmailMutationVariables>(SendTestEmailDocument, options)
}
export type SendTestEmailMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<
  SendTestEmailMutation,
  SendTestEmailMutationVariables
>
export const UpdateEventDocument = gql`
  mutation UpdateEvent(
    $nameDe: String
    $nameEn: String
    $descriptionDe: String
    $descriptionEn: String
    $locationName: String
    $locationAddress: String
    $companyName: String
    $companyWebsite: String
    $ctaButtonLink: String
    $ctaButtonText: String
    $companyEmail: String
    $eventFormat: String
    $shuttleService: Boolean
    $maxAttendees: Int
    $duration: String
    $interests: [ID!]
    $eventId: ID!
    $language: String
    $agendaRaw: String
    $presentationRaw: String
    $agendaFormatted: String
    $presentationFormatted: String
    $videoPresentation: Upload
    $videoRecording: Upload
    $startsAt: ISO8601DateTime
    $endsAt: ISO8601DateTime
    $streamingKey: String
  ) {
    updateEvent(
      input: {
        attributes: {
          descriptionDe: $descriptionDe
          descriptionEn: $descriptionEn
          interests: $interests
          duration: $duration
          nameDe: $nameDe
          nameEn: $nameEn
          language: $language
          companyName: $companyName
          companyWebsite: $companyWebsite
          companyEmail: $companyEmail
          ctaButtonLink: $ctaButtonLink
          ctaButtonText: $ctaButtonText
          eventFormat: $eventFormat
          shuttleService: $shuttleService
          maxAttendees: $maxAttendees
          locationName: $locationName
          locationAddress: $locationAddress
          agendaRaw: $agendaRaw
          presentationRaw: $presentationRaw
          agendaFormatted: $agendaFormatted
          presentationFormatted: $presentationFormatted
          videoPresentation: $videoPresentation
          videoRecording: $videoRecording
          startsAt: $startsAt
          endsAt: $endsAt
          streamingKey: $streamingKey
        }
        eventId: $eventId
      }
    ) {
      errors
    }
  }
`

/**
 * __useUpdateEventMutation__
 *
 * To run a mutation, you first call `useUpdateEventMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useUpdateEventMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useUpdateEventMutation({
 *   variables: {
 *     nameDe: // value for 'nameDe'
 *     nameEn: // value for 'nameEn'
 *     descriptionDe: // value for 'descriptionDe'
 *     descriptionEn: // value for 'descriptionEn'
 *     locationName: // value for 'locationName'
 *     locationAddress: // value for 'locationAddress'
 *     companyName: // value for 'companyName'
 *     companyWebsite: // value for 'companyWebsite'
 *     ctaButtonLink: // value for 'ctaButtonLink'
 *     ctaButtonText: // value for 'ctaButtonText'
 *     companyEmail: // value for 'companyEmail'
 *     eventFormat: // value for 'eventFormat'
 *     shuttleService: // value for 'shuttleService'
 *     maxAttendees: // value for 'maxAttendees'
 *     duration: // value for 'duration'
 *     interests: // value for 'interests'
 *     eventId: // value for 'eventId'
 *     language: // value for 'language'
 *     agendaRaw: // value for 'agendaRaw'
 *     presentationRaw: // value for 'presentationRaw'
 *     agendaFormatted: // value for 'agendaFormatted'
 *     presentationFormatted: // value for 'presentationFormatted'
 *     videoPresentation: // value for 'videoPresentation'
 *     videoRecording: // value for 'videoRecording'
 *     startsAt: // value for 'startsAt'
 *     endsAt: // value for 'endsAt'
 *     streamingKey: // value for 'streamingKey'
 *   },
 * });
 */
export function useUpdateEventMutation(
  options:
    | VueApolloComposable.UseMutationOptions<UpdateEventMutation, UpdateEventMutationVariables>
    | ReactiveFunction<VueApolloComposable.UseMutationOptions<UpdateEventMutation, UpdateEventMutationVariables>> = {},
) {
  return VueApolloComposable.useMutation<UpdateEventMutation, UpdateEventMutationVariables>(UpdateEventDocument, options)
}
export type UpdateEventMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<
  UpdateEventMutation,
  UpdateEventMutationVariables
>
export const ImportInviteesDocument = gql`
  mutation ImportInvitees($invitationQuotaId: ID!, $csvUploadId: ID!, $hasHeaders: Boolean!, $mapping: CsvMappingAttributes!) {
    importInvitees(
      input: { invitationQuotaId: $invitationQuotaId, csvUploadId: $csvUploadId, hasHeaders: $hasHeaders, mapping: $mapping }
    ) {
      success
      errors
    }
  }
`

/**
 * __useImportInviteesMutation__
 *
 * To run a mutation, you first call `useImportInviteesMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useImportInviteesMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useImportInviteesMutation({
 *   variables: {
 *     invitationQuotaId: // value for 'invitationQuotaId'
 *     csvUploadId: // value for 'csvUploadId'
 *     hasHeaders: // value for 'hasHeaders'
 *     mapping: // value for 'mapping'
 *   },
 * });
 */
export function useImportInviteesMutation(
  options:
    | VueApolloComposable.UseMutationOptions<ImportInviteesMutation, ImportInviteesMutationVariables>
    | ReactiveFunction<VueApolloComposable.UseMutationOptions<ImportInviteesMutation, ImportInviteesMutationVariables>> = {},
) {
  return VueApolloComposable.useMutation<ImportInviteesMutation, ImportInviteesMutationVariables>(ImportInviteesDocument, options)
}
export type ImportInviteesMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<
  ImportInviteesMutation,
  ImportInviteesMutationVariables
>
export const UploadInviteesDocument = gql`
  mutation UploadInvitees($invitationQuotaId: ID!, $csvFile: Upload!) {
    uploadInvitees(input: { invitationQuotaId: $invitationQuotaId, csvFile: $csvFile }) {
      csvUploadId
      errors
    }
  }
`

/**
 * __useUploadInviteesMutation__
 *
 * To run a mutation, you first call `useUploadInviteesMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useUploadInviteesMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useUploadInviteesMutation({
 *   variables: {
 *     invitationQuotaId: // value for 'invitationQuotaId'
 *     csvFile: // value for 'csvFile'
 *   },
 * });
 */
export function useUploadInviteesMutation(
  options:
    | VueApolloComposable.UseMutationOptions<UploadInviteesMutation, UploadInviteesMutationVariables>
    | ReactiveFunction<VueApolloComposable.UseMutationOptions<UploadInviteesMutation, UploadInviteesMutationVariables>> = {},
) {
  return VueApolloComposable.useMutation<UploadInviteesMutation, UploadInviteesMutationVariables>(UploadInviteesDocument, options)
}
export type UploadInviteesMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<
  UploadInviteesMutation,
  UploadInviteesMutationVariables
>
export const ManuallyMarkAsSentDocument = gql`
  mutation manuallyMarkAsSent($promoCodeId: ID!) {
    manuallyMarkAsSent(input: { promoCodeId: $promoCodeId }) {
      promoCode {
        id
        manuallyMarkedAsSentAt
      }
      errors
    }
  }
`

/**
 * __useManuallyMarkAsSentMutation__
 *
 * To run a mutation, you first call `useManuallyMarkAsSentMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useManuallyMarkAsSentMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useManuallyMarkAsSentMutation({
 *   variables: {
 *     promoCodeId: // value for 'promoCodeId'
 *   },
 * });
 */
export function useManuallyMarkAsSentMutation(
  options:
    | VueApolloComposable.UseMutationOptions<ManuallyMarkAsSentMutation, ManuallyMarkAsSentMutationVariables>
    | ReactiveFunction<VueApolloComposable.UseMutationOptions<ManuallyMarkAsSentMutation, ManuallyMarkAsSentMutationVariables>> = {},
) {
  return VueApolloComposable.useMutation<ManuallyMarkAsSentMutation, ManuallyMarkAsSentMutationVariables>(
    ManuallyMarkAsSentDocument,
    options,
  )
}
export type ManuallyMarkAsSentMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<
  ManuallyMarkAsSentMutation,
  ManuallyMarkAsSentMutationVariables
>
export const ManuallyMarkAsUnsentDocument = gql`
  mutation manuallyMarkAsUnsent($promoCodeId: ID!) {
    manuallyMarkAsUnsent(input: { promoCodeId: $promoCodeId }) {
      promoCode {
        id
        manuallyMarkedAsSentAt
      }
      errors
    }
  }
`

/**
 * __useManuallyMarkAsUnsentMutation__
 *
 * To run a mutation, you first call `useManuallyMarkAsUnsentMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useManuallyMarkAsUnsentMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useManuallyMarkAsUnsentMutation({
 *   variables: {
 *     promoCodeId: // value for 'promoCodeId'
 *   },
 * });
 */
export function useManuallyMarkAsUnsentMutation(
  options:
    | VueApolloComposable.UseMutationOptions<ManuallyMarkAsUnsentMutation, ManuallyMarkAsUnsentMutationVariables>
    | ReactiveFunction<VueApolloComposable.UseMutationOptions<ManuallyMarkAsUnsentMutation, ManuallyMarkAsUnsentMutationVariables>> = {},
) {
  return VueApolloComposable.useMutation<ManuallyMarkAsUnsentMutation, ManuallyMarkAsUnsentMutationVariables>(
    ManuallyMarkAsUnsentDocument,
    options,
  )
}
export type ManuallyMarkAsUnsentMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<
  ManuallyMarkAsUnsentMutation,
  ManuallyMarkAsUnsentMutationVariables
>
export const CreateProspectItemDocument = gql`
  mutation CreateProspectItem($prospectListId: ID!, $token: ID!) {
    createProspectItem(input: { prospectListId: $prospectListId, token: $token }) {
      errors
      success
    }
  }
`

/**
 * __useCreateProspectItemMutation__
 *
 * To run a mutation, you first call `useCreateProspectItemMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useCreateProspectItemMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useCreateProspectItemMutation({
 *   variables: {
 *     prospectListId: // value for 'prospectListId'
 *     token: // value for 'token'
 *   },
 * });
 */
export function useCreateProspectItemMutation(
  options:
    | VueApolloComposable.UseMutationOptions<CreateProspectItemMutation, CreateProspectItemMutationVariables>
    | ReactiveFunction<VueApolloComposable.UseMutationOptions<CreateProspectItemMutation, CreateProspectItemMutationVariables>> = {},
) {
  return VueApolloComposable.useMutation<CreateProspectItemMutation, CreateProspectItemMutationVariables>(
    CreateProspectItemDocument,
    options,
  )
}
export type CreateProspectItemMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<
  CreateProspectItemMutation,
  CreateProspectItemMutationVariables
>
export const CreateProspectListDocument = gql`
  mutation CreateProspectList(
    $eventId: ID!
    $partnerCompanyId: ID!
    $contactEmail: String!
    $contactName: String!
    $contactReason: String
    $title: String!
  ) {
    createProspectList(
      input: {
        eventId: $eventId
        partnerCompanyId: $partnerCompanyId
        attributes: { contactEmail: $contactEmail, contactName: $contactName, contactReason: $contactReason, title: $title }
      }
    ) {
      prospectList {
        id
        title
        contactName
        contactEmail
        contactReason
        partnerCompany {
          id
          name
        }
        event {
          id
          name
        }
      }
      errors
    }
  }
`

/**
 * __useCreateProspectListMutation__
 *
 * To run a mutation, you first call `useCreateProspectListMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useCreateProspectListMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useCreateProspectListMutation({
 *   variables: {
 *     eventId: // value for 'eventId'
 *     partnerCompanyId: // value for 'partnerCompanyId'
 *     contactEmail: // value for 'contactEmail'
 *     contactName: // value for 'contactName'
 *     contactReason: // value for 'contactReason'
 *     title: // value for 'title'
 *   },
 * });
 */
export function useCreateProspectListMutation(
  options:
    | VueApolloComposable.UseMutationOptions<CreateProspectListMutation, CreateProspectListMutationVariables>
    | ReactiveFunction<VueApolloComposable.UseMutationOptions<CreateProspectListMutation, CreateProspectListMutationVariables>> = {},
) {
  return VueApolloComposable.useMutation<CreateProspectListMutation, CreateProspectListMutationVariables>(
    CreateProspectListDocument,
    options,
  )
}
export type CreateProspectListMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<
  CreateProspectListMutation,
  CreateProspectListMutationVariables
>
export const DeleteProspectItemDocument = gql`
  mutation DeleteProspectItem($prospectListId: ID!, $token: ID!) {
    deleteProspectItem(input: { prospectListId: $prospectListId, token: $token }) {
      errors
      success
    }
  }
`

/**
 * __useDeleteProspectItemMutation__
 *
 * To run a mutation, you first call `useDeleteProspectItemMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useDeleteProspectItemMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useDeleteProspectItemMutation({
 *   variables: {
 *     prospectListId: // value for 'prospectListId'
 *     token: // value for 'token'
 *   },
 * });
 */
export function useDeleteProspectItemMutation(
  options:
    | VueApolloComposable.UseMutationOptions<DeleteProspectItemMutation, DeleteProspectItemMutationVariables>
    | ReactiveFunction<VueApolloComposable.UseMutationOptions<DeleteProspectItemMutation, DeleteProspectItemMutationVariables>> = {},
) {
  return VueApolloComposable.useMutation<DeleteProspectItemMutation, DeleteProspectItemMutationVariables>(
    DeleteProspectItemDocument,
    options,
  )
}
export type DeleteProspectItemMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<
  DeleteProspectItemMutation,
  DeleteProspectItemMutationVariables
>
export const DeleteProspectListDocument = gql`
  mutation DeleteProspectList($prospectListId: ID!) {
    deleteProspectList(input: { prospectListId: $prospectListId }) {
      errors
    }
  }
`

/**
 * __useDeleteProspectListMutation__
 *
 * To run a mutation, you first call `useDeleteProspectListMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useDeleteProspectListMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useDeleteProspectListMutation({
 *   variables: {
 *     prospectListId: // value for 'prospectListId'
 *   },
 * });
 */
export function useDeleteProspectListMutation(
  options:
    | VueApolloComposable.UseMutationOptions<DeleteProspectListMutation, DeleteProspectListMutationVariables>
    | ReactiveFunction<VueApolloComposable.UseMutationOptions<DeleteProspectListMutation, DeleteProspectListMutationVariables>> = {},
) {
  return VueApolloComposable.useMutation<DeleteProspectListMutation, DeleteProspectListMutationVariables>(
    DeleteProspectListDocument,
    options,
  )
}
export type DeleteProspectListMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<
  DeleteProspectListMutation,
  DeleteProspectListMutationVariables
>
export const SendProspectListInvitationsDocument = gql`
  mutation SendProspectListInvitations($prospectListId: ID!) {
    sendProspectListInvitations(input: { prospectListId: $prospectListId }) {
      errors
      success
    }
  }
`

/**
 * __useSendProspectListInvitationsMutation__
 *
 * To run a mutation, you first call `useSendProspectListInvitationsMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useSendProspectListInvitationsMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useSendProspectListInvitationsMutation({
 *   variables: {
 *     prospectListId: // value for 'prospectListId'
 *   },
 * });
 */
export function useSendProspectListInvitationsMutation(
  options:
    | VueApolloComposable.UseMutationOptions<SendProspectListInvitationsMutation, SendProspectListInvitationsMutationVariables>
    | ReactiveFunction<
        VueApolloComposable.UseMutationOptions<SendProspectListInvitationsMutation, SendProspectListInvitationsMutationVariables>
      > = {},
) {
  return VueApolloComposable.useMutation<SendProspectListInvitationsMutation, SendProspectListInvitationsMutationVariables>(
    SendProspectListInvitationsDocument,
    options,
  )
}
export type SendProspectListInvitationsMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<
  SendProspectListInvitationsMutation,
  SendProspectListInvitationsMutationVariables
>
export const SendTestProspectInvitationDocument = gql`
  mutation SendTestProspectInvitation($prospectListId: ID!, $email: String!) {
    sendTestProspectInvitation(input: { prospectListId: $prospectListId, email: $email }) {
      success
      errors
    }
  }
`

/**
 * __useSendTestProspectInvitationMutation__
 *
 * To run a mutation, you first call `useSendTestProspectInvitationMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useSendTestProspectInvitationMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useSendTestProspectInvitationMutation({
 *   variables: {
 *     prospectListId: // value for 'prospectListId'
 *     email: // value for 'email'
 *   },
 * });
 */
export function useSendTestProspectInvitationMutation(
  options:
    | VueApolloComposable.UseMutationOptions<SendTestProspectInvitationMutation, SendTestProspectInvitationMutationVariables>
    | ReactiveFunction<
        VueApolloComposable.UseMutationOptions<SendTestProspectInvitationMutation, SendTestProspectInvitationMutationVariables>
      > = {},
) {
  return VueApolloComposable.useMutation<SendTestProspectInvitationMutation, SendTestProspectInvitationMutationVariables>(
    SendTestProspectInvitationDocument,
    options,
  )
}
export type SendTestProspectInvitationMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<
  SendTestProspectInvitationMutation,
  SendTestProspectInvitationMutationVariables
>
export const SubmitProspectListDocument = gql`
  mutation SubmitProspectList($prospectListId: ID!) {
    submitProspectList(input: { prospectListId: $prospectListId }) {
      prospectList {
        id
        submittedAt
      }
      errors
    }
  }
`

/**
 * __useSubmitProspectListMutation__
 *
 * To run a mutation, you first call `useSubmitProspectListMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useSubmitProspectListMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useSubmitProspectListMutation({
 *   variables: {
 *     prospectListId: // value for 'prospectListId'
 *   },
 * });
 */
export function useSubmitProspectListMutation(
  options:
    | VueApolloComposable.UseMutationOptions<SubmitProspectListMutation, SubmitProspectListMutationVariables>
    | ReactiveFunction<VueApolloComposable.UseMutationOptions<SubmitProspectListMutation, SubmitProspectListMutationVariables>> = {},
) {
  return VueApolloComposable.useMutation<SubmitProspectListMutation, SubmitProspectListMutationVariables>(
    SubmitProspectListDocument,
    options,
  )
}
export type SubmitProspectListMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<
  SubmitProspectListMutation,
  SubmitProspectListMutationVariables
>
export const UpdateProspectListDocument = gql`
  mutation UpdateProspectList(
    $prospectListId: ID!
    $contactEmail: String!
    $contactName: String!
    $contactReason: String!
    $title: String!
  ) {
    updateProspectList(
      input: {
        prospectListId: $prospectListId
        attributes: { contactEmail: $contactEmail, contactName: $contactName, contactReason: $contactReason, title: $title }
      }
    ) {
      errors
    }
  }
`

/**
 * __useUpdateProspectListMutation__
 *
 * To run a mutation, you first call `useUpdateProspectListMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useUpdateProspectListMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useUpdateProspectListMutation({
 *   variables: {
 *     prospectListId: // value for 'prospectListId'
 *     contactEmail: // value for 'contactEmail'
 *     contactName: // value for 'contactName'
 *     contactReason: // value for 'contactReason'
 *     title: // value for 'title'
 *   },
 * });
 */
export function useUpdateProspectListMutation(
  options:
    | VueApolloComposable.UseMutationOptions<UpdateProspectListMutation, UpdateProspectListMutationVariables>
    | ReactiveFunction<VueApolloComposable.UseMutationOptions<UpdateProspectListMutation, UpdateProspectListMutationVariables>> = {},
) {
  return VueApolloComposable.useMutation<UpdateProspectListMutation, UpdateProspectListMutationVariables>(
    UpdateProspectListDocument,
    options,
  )
}
export type UpdateProspectListMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<
  UpdateProspectListMutation,
  UpdateProspectListMutationVariables
>
export const CreateEventSpeakerDocument = gql`
  mutation CreateEventSpeaker($eventId: ID!, $speakerId: ID!) {
    createEventSpeaker(input: { eventId: $eventId, speakerId: $speakerId }) {
      eventSpeaker {
        id
        event {
          id
        }
        speaker {
          id
        }
      }
      errors
    }
  }
`

/**
 * __useCreateEventSpeakerMutation__
 *
 * To run a mutation, you first call `useCreateEventSpeakerMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useCreateEventSpeakerMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useCreateEventSpeakerMutation({
 *   variables: {
 *     eventId: // value for 'eventId'
 *     speakerId: // value for 'speakerId'
 *   },
 * });
 */
export function useCreateEventSpeakerMutation(
  options:
    | VueApolloComposable.UseMutationOptions<CreateEventSpeakerMutation, CreateEventSpeakerMutationVariables>
    | ReactiveFunction<VueApolloComposable.UseMutationOptions<CreateEventSpeakerMutation, CreateEventSpeakerMutationVariables>> = {},
) {
  return VueApolloComposable.useMutation<CreateEventSpeakerMutation, CreateEventSpeakerMutationVariables>(
    CreateEventSpeakerDocument,
    options,
  )
}
export type CreateEventSpeakerMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<
  CreateEventSpeakerMutation,
  CreateEventSpeakerMutationVariables
>
export const CreateSpeakerDocument = gql`
  mutation createSpeaker(
    $photo: Upload
    $partnerCompanyId: ID!
    $name: String
    $firstname: String
    $lastname: String
    $email: String
    $descriptionEn: String
    $descriptionDe: String
    $partnerCompanyName: String
    $title: String
    $twitterUrl: String
    $facebookUrl: String
    $instagramUrl: String
    $youtubeUrl: String
    $websiteUrl: String
    $linkedinUrl: String
    $interests: [ID!]
  ) {
    createSpeaker(
      input: {
        partnerCompanyId: $partnerCompanyId
        attributes: {
          photo: $photo
          name: $name
          firstname: $firstname
          lastname: $lastname
          email: $email
          descriptionEn: $descriptionEn
          descriptionDe: $descriptionDe
          partnerCompanyName: $partnerCompanyName
          title: $title
          twitterUrl: $twitterUrl
          facebookUrl: $facebookUrl
          instagramUrl: $instagramUrl
          youtubeUrl: $youtubeUrl
          websiteUrl: $websiteUrl
          linkedinUrl: $linkedinUrl
          interests: $interests
        }
      }
    ) {
      speaker {
        id
        name
        firstname
        lastname
        descriptionEn
        descriptionDe
        partnerCompanyName
        title
        photoUrl
        twitterUrl
        facebookUrl
        instagramUrl
        youtubeUrl
        websiteUrl
        linkedinUrl
      }
      errors
    }
  }
`

/**
 * __useCreateSpeakerMutation__
 *
 * To run a mutation, you first call `useCreateSpeakerMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useCreateSpeakerMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useCreateSpeakerMutation({
 *   variables: {
 *     photo: // value for 'photo'
 *     partnerCompanyId: // value for 'partnerCompanyId'
 *     name: // value for 'name'
 *     firstname: // value for 'firstname'
 *     lastname: // value for 'lastname'
 *     email: // value for 'email'
 *     descriptionEn: // value for 'descriptionEn'
 *     descriptionDe: // value for 'descriptionDe'
 *     partnerCompanyName: // value for 'partnerCompanyName'
 *     title: // value for 'title'
 *     twitterUrl: // value for 'twitterUrl'
 *     facebookUrl: // value for 'facebookUrl'
 *     instagramUrl: // value for 'instagramUrl'
 *     youtubeUrl: // value for 'youtubeUrl'
 *     websiteUrl: // value for 'websiteUrl'
 *     linkedinUrl: // value for 'linkedinUrl'
 *     interests: // value for 'interests'
 *   },
 * });
 */
export function useCreateSpeakerMutation(
  options:
    | VueApolloComposable.UseMutationOptions<CreateSpeakerMutation, CreateSpeakerMutationVariables>
    | ReactiveFunction<VueApolloComposable.UseMutationOptions<CreateSpeakerMutation, CreateSpeakerMutationVariables>> = {},
) {
  return VueApolloComposable.useMutation<CreateSpeakerMutation, CreateSpeakerMutationVariables>(CreateSpeakerDocument, options)
}
export type CreateSpeakerMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<
  CreateSpeakerMutation,
  CreateSpeakerMutationVariables
>
export const DeleteEventSpeakerDocument = gql`
  mutation DeleteEventSpeaker($eventSpeakerId: ID!) {
    deleteEventSpeaker(input: { eventSpeakerId: $eventSpeakerId }) {
      success
      errors
    }
  }
`

/**
 * __useDeleteEventSpeakerMutation__
 *
 * To run a mutation, you first call `useDeleteEventSpeakerMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useDeleteEventSpeakerMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useDeleteEventSpeakerMutation({
 *   variables: {
 *     eventSpeakerId: // value for 'eventSpeakerId'
 *   },
 * });
 */
export function useDeleteEventSpeakerMutation(
  options:
    | VueApolloComposable.UseMutationOptions<DeleteEventSpeakerMutation, DeleteEventSpeakerMutationVariables>
    | ReactiveFunction<VueApolloComposable.UseMutationOptions<DeleteEventSpeakerMutation, DeleteEventSpeakerMutationVariables>> = {},
) {
  return VueApolloComposable.useMutation<DeleteEventSpeakerMutation, DeleteEventSpeakerMutationVariables>(
    DeleteEventSpeakerDocument,
    options,
  )
}
export type DeleteEventSpeakerMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<
  DeleteEventSpeakerMutation,
  DeleteEventSpeakerMutationVariables
>
export const UpdateSpeakerDocument = gql`
  mutation updateSpeaker(
    $speakerId: ID!
    $photo: Upload
    $name: String
    $firstname: String
    $lastname: String
    $descriptionEn: String
    $descriptionDe: String
    $partnerCompanyName: String
    $title: String
    $email: String
    $twitterUrl: String
    $facebookUrl: String
    $instagramUrl: String
    $youtubeUrl: String
    $websiteUrl: String
    $linkedinUrl: String
    $interests: [ID!]
  ) {
    updateSpeaker(
      input: {
        speakerId: $speakerId
        attributes: {
          name: $name
          photo: $photo
          firstname: $firstname
          lastname: $lastname
          descriptionEn: $descriptionEn
          descriptionDe: $descriptionDe
          partnerCompanyName: $partnerCompanyName
          title: $title
          email: $email
          twitterUrl: $twitterUrl
          facebookUrl: $facebookUrl
          instagramUrl: $instagramUrl
          youtubeUrl: $youtubeUrl
          websiteUrl: $websiteUrl
          linkedinUrl: $linkedinUrl
          interests: $interests
        }
      }
    ) {
      speaker {
        name
        firstname
        lastname
        descriptionEn
        descriptionDe
        partnerCompanyName
        title
        email
        photoUrl
        twitterUrl
        facebookUrl
        instagramUrl
        youtubeUrl
        websiteUrl
        linkedinUrl
      }
      errors
    }
  }
`

/**
 * __useUpdateSpeakerMutation__
 *
 * To run a mutation, you first call `useUpdateSpeakerMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSpeakerMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useUpdateSpeakerMutation({
 *   variables: {
 *     speakerId: // value for 'speakerId'
 *     photo: // value for 'photo'
 *     name: // value for 'name'
 *     firstname: // value for 'firstname'
 *     lastname: // value for 'lastname'
 *     descriptionEn: // value for 'descriptionEn'
 *     descriptionDe: // value for 'descriptionDe'
 *     partnerCompanyName: // value for 'partnerCompanyName'
 *     title: // value for 'title'
 *     email: // value for 'email'
 *     twitterUrl: // value for 'twitterUrl'
 *     facebookUrl: // value for 'facebookUrl'
 *     instagramUrl: // value for 'instagramUrl'
 *     youtubeUrl: // value for 'youtubeUrl'
 *     websiteUrl: // value for 'websiteUrl'
 *     linkedinUrl: // value for 'linkedinUrl'
 *     interests: // value for 'interests'
 *   },
 * });
 */
export function useUpdateSpeakerMutation(
  options:
    | VueApolloComposable.UseMutationOptions<UpdateSpeakerMutation, UpdateSpeakerMutationVariables>
    | ReactiveFunction<VueApolloComposable.UseMutationOptions<UpdateSpeakerMutation, UpdateSpeakerMutationVariables>> = {},
) {
  return VueApolloComposable.useMutation<UpdateSpeakerMutation, UpdateSpeakerMutationVariables>(UpdateSpeakerDocument, options)
}
export type UpdateSpeakerMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<
  UpdateSpeakerMutation,
  UpdateSpeakerMutationVariables
>
export const UpdatePartnerCompanyDocument = gql`
  mutation UpdatePartnerCompany(
    $descriptionDe: String
    $descriptionEn: String
    $facebook: String
    $instagram: String
    $linkedinUrl: String
    $interests: [ID!]
    $logo: Upload
    $webLogo: Upload
    $name: String
    $displayName: String
    $partnerCompanyId: ID!
    $twitter: String
    $website: String
    $youtube: String
  ) {
    updatePartnerCompany(
      input: {
        attributes: {
          descriptionDe: $descriptionDe
          descriptionEn: $descriptionEn
          facebook: $facebook
          instagram: $instagram
          interests: $interests
          logo: $logo
          webLogo: $webLogo
          name: $name
          displayName: $displayName
          twitter: $twitter
          website: $website
          youtube: $youtube
          linkedinUrl: $linkedinUrl
        }
        partnerCompanyId: $partnerCompanyId
      }
    ) {
      partnerCompany {
        ...partnerCompany
      }
      errors
    }
  }
  ${PartnerCompanyFragmentDoc}
`

/**
 * __useUpdatePartnerCompanyMutation__
 *
 * To run a mutation, you first call `useUpdatePartnerCompanyMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePartnerCompanyMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useUpdatePartnerCompanyMutation({
 *   variables: {
 *     descriptionDe: // value for 'descriptionDe'
 *     descriptionEn: // value for 'descriptionEn'
 *     facebook: // value for 'facebook'
 *     instagram: // value for 'instagram'
 *     linkedinUrl: // value for 'linkedinUrl'
 *     interests: // value for 'interests'
 *     logo: // value for 'logo'
 *     webLogo: // value for 'webLogo'
 *     name: // value for 'name'
 *     displayName: // value for 'displayName'
 *     partnerCompanyId: // value for 'partnerCompanyId'
 *     twitter: // value for 'twitter'
 *     website: // value for 'website'
 *     youtube: // value for 'youtube'
 *   },
 * });
 */
export function useUpdatePartnerCompanyMutation(
  options:
    | VueApolloComposable.UseMutationOptions<UpdatePartnerCompanyMutation, UpdatePartnerCompanyMutationVariables>
    | ReactiveFunction<VueApolloComposable.UseMutationOptions<UpdatePartnerCompanyMutation, UpdatePartnerCompanyMutationVariables>> = {},
) {
  return VueApolloComposable.useMutation<UpdatePartnerCompanyMutation, UpdatePartnerCompanyMutationVariables>(
    UpdatePartnerCompanyDocument,
    options,
  )
}
export type UpdatePartnerCompanyMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<
  UpdatePartnerCompanyMutation,
  UpdatePartnerCompanyMutationVariables
>
export const CreateUserDocument = gql`
  mutation CreateUser($email: String!, $personalMessage: String, $roleNames: [RoleNameEnum!]) {
    createUser(input: { email: $email, personalMessage: $personalMessage, roleNames: $roleNames }) {
      user {
        ...user
        roles {
          ...role
        }
      }
      errors
    }
  }
  ${UserFragmentDoc}
  ${RoleFragmentDoc}
`

/**
 * __useCreateUserMutation__
 *
 * To run a mutation, you first call `useCreateUserMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useCreateUserMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useCreateUserMutation({
 *   variables: {
 *     email: // value for 'email'
 *     personalMessage: // value for 'personalMessage'
 *     roleNames: // value for 'roleNames'
 *   },
 * });
 */
export function useCreateUserMutation(
  options:
    | VueApolloComposable.UseMutationOptions<CreateUserMutation, CreateUserMutationVariables>
    | ReactiveFunction<VueApolloComposable.UseMutationOptions<CreateUserMutation, CreateUserMutationVariables>> = {},
) {
  return VueApolloComposable.useMutation<CreateUserMutation, CreateUserMutationVariables>(CreateUserDocument, options)
}
export type CreateUserMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<
  CreateUserMutation,
  CreateUserMutationVariables
>
export const UpdateUserRolesDocument = gql`
  mutation UpdateUserRoles($userId: ID!, $roleNames: [RoleNameEnum!]) {
    updateUserRoles(input: { userId: $userId, roleNames: $roleNames }) {
      user {
        ...user
        roles {
          ...role
        }
      }
      errors
    }
  }
  ${UserFragmentDoc}
  ${RoleFragmentDoc}
`

/**
 * __useUpdateUserRolesMutation__
 *
 * To run a mutation, you first call `useUpdateUserRolesMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserRolesMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useUpdateUserRolesMutation({
 *   variables: {
 *     userId: // value for 'userId'
 *     roleNames: // value for 'roleNames'
 *   },
 * });
 */
export function useUpdateUserRolesMutation(
  options:
    | VueApolloComposable.UseMutationOptions<UpdateUserRolesMutation, UpdateUserRolesMutationVariables>
    | ReactiveFunction<VueApolloComposable.UseMutationOptions<UpdateUserRolesMutation, UpdateUserRolesMutationVariables>> = {},
) {
  return VueApolloComposable.useMutation<UpdateUserRolesMutation, UpdateUserRolesMutationVariables>(UpdateUserRolesDocument, options)
}
export type UpdateUserRolesMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<
  UpdateUserRolesMutation,
  UpdateUserRolesMutationVariables
>
export const AttendeesDocument = gql`
  query Attendees($eventId: ID!, $state: String, $orderBy: AttendeeOrderAttributesEnum, $orderDirection: OrderDirectionEnum) {
    attendees(eventId: $eventId, state: $state, orderBy: $orderBy, orderDirection: $orderDirection) {
      acceptedCount
      acceptedSuccessorCount
      deniedCount
      requestedCount
      totalCount
      edges {
        cursor
      }
      nodes {
        id
        aasmState
        addedViaManage
        forcedAllocation
        ticket {
          companyName
          firstName
          identifier
          lastName
          position
          __typename
        }
        __typename
      }
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        startCursor
        __typename
      }
      __typename
    }
  }
`

/**
 * __useAttendeesQuery__
 *
 * To run a query within a Vue component, call `useAttendeesQuery` and pass it any options that fit your needs.
 * When your component renders, `useAttendeesQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useAttendeesQuery({
 *   eventId: // value for 'eventId'
 *   state: // value for 'state'
 *   orderBy: // value for 'orderBy'
 *   orderDirection: // value for 'orderDirection'
 * });
 */
export function useAttendeesQuery(
  variables: AttendeesQueryVariables | VueCompositionApi.Ref<AttendeesQueryVariables> | ReactiveFunction<AttendeesQueryVariables>,
  options:
    | VueApolloComposable.UseQueryOptions<AttendeesQuery, AttendeesQueryVariables>
    | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<AttendeesQuery, AttendeesQueryVariables>>
    | ReactiveFunction<VueApolloComposable.UseQueryOptions<AttendeesQuery, AttendeesQueryVariables>> = {},
) {
  return VueApolloComposable.useQuery<AttendeesQuery, AttendeesQueryVariables>(AttendeesDocument, variables, options)
}
export function useAttendeesLazyQuery(
  variables?: AttendeesQueryVariables | VueCompositionApi.Ref<AttendeesQueryVariables> | ReactiveFunction<AttendeesQueryVariables>,
  options:
    | VueApolloComposable.UseQueryOptions<AttendeesQuery, AttendeesQueryVariables>
    | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<AttendeesQuery, AttendeesQueryVariables>>
    | ReactiveFunction<VueApolloComposable.UseQueryOptions<AttendeesQuery, AttendeesQueryVariables>> = {},
) {
  return VueApolloComposable.useLazyQuery<AttendeesQuery, AttendeesQueryVariables>(AttendeesDocument, variables, options)
}
export type AttendeesQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<AttendeesQuery, AttendeesQueryVariables>
export const BoothDocument = gql`
  query Booth($boothId: ID!) {
    node(id: $boothId) {
      id
      __typename
      ... on Booth {
        ...booth
        partnerCompany {
          id
          name
          displayName
          contacts {
            ...contact
          }
        }
      }
    }
  }
  ${BoothFragmentDoc}
  ${ContactFragmentDoc}
`

/**
 * __useBoothQuery__
 *
 * To run a query within a Vue component, call `useBoothQuery` and pass it any options that fit your needs.
 * When your component renders, `useBoothQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useBoothQuery({
 *   boothId: // value for 'boothId'
 * });
 */
export function useBoothQuery(
  variables: BoothQueryVariables | VueCompositionApi.Ref<BoothQueryVariables> | ReactiveFunction<BoothQueryVariables>,
  options:
    | VueApolloComposable.UseQueryOptions<BoothQuery, BoothQueryVariables>
    | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<BoothQuery, BoothQueryVariables>>
    | ReactiveFunction<VueApolloComposable.UseQueryOptions<BoothQuery, BoothQueryVariables>> = {},
) {
  return VueApolloComposable.useQuery<BoothQuery, BoothQueryVariables>(BoothDocument, variables, options)
}
export function useBoothLazyQuery(
  variables?: BoothQueryVariables | VueCompositionApi.Ref<BoothQueryVariables> | ReactiveFunction<BoothQueryVariables>,
  options:
    | VueApolloComposable.UseQueryOptions<BoothQuery, BoothQueryVariables>
    | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<BoothQuery, BoothQueryVariables>>
    | ReactiveFunction<VueApolloComposable.UseQueryOptions<BoothQuery, BoothQueryVariables>> = {},
) {
  return VueApolloComposable.useLazyQuery<BoothQuery, BoothQueryVariables>(BoothDocument, variables, options)
}
export type BoothQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<BoothQuery, BoothQueryVariables>
export const BoothsDocument = gql`
  query Booths($cursor: String, $eventPeriod: EventPeriodEnum, $type: BoothTypeEnum) {
    booths(first: 100, after: $cursor, eventPeriod: $eventPeriod, type: $type) {
      nodes {
        boothNumber
        foodTypes
        id
        titleDe
        titleEn
        type
        typeDisplayName
        event {
          endsAt
          id
          name
          startsAt
          nameDe
          nameEn
          location {
            id
            name
          }
        }
        partnerCompany {
          id
          name
          displayName
        }
      }
      pageInfo {
        ...pageInfo
      }
    }
  }
  ${PageInfoFragmentDoc}
`

/**
 * __useBoothsQuery__
 *
 * To run a query within a Vue component, call `useBoothsQuery` and pass it any options that fit your needs.
 * When your component renders, `useBoothsQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useBoothsQuery({
 *   cursor: // value for 'cursor'
 *   eventPeriod: // value for 'eventPeriod'
 *   type: // value for 'type'
 * });
 */
export function useBoothsQuery(
  variables: BoothsQueryVariables | VueCompositionApi.Ref<BoothsQueryVariables> | ReactiveFunction<BoothsQueryVariables> = {},
  options:
    | VueApolloComposable.UseQueryOptions<BoothsQuery, BoothsQueryVariables>
    | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<BoothsQuery, BoothsQueryVariables>>
    | ReactiveFunction<VueApolloComposable.UseQueryOptions<BoothsQuery, BoothsQueryVariables>> = {},
) {
  return VueApolloComposable.useQuery<BoothsQuery, BoothsQueryVariables>(BoothsDocument, variables, options)
}
export function useBoothsLazyQuery(
  variables: BoothsQueryVariables | VueCompositionApi.Ref<BoothsQueryVariables> | ReactiveFunction<BoothsQueryVariables> = {},
  options:
    | VueApolloComposable.UseQueryOptions<BoothsQuery, BoothsQueryVariables>
    | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<BoothsQuery, BoothsQueryVariables>>
    | ReactiveFunction<VueApolloComposable.UseQueryOptions<BoothsQuery, BoothsQueryVariables>> = {},
) {
  return VueApolloComposable.useLazyQuery<BoothsQuery, BoothsQueryVariables>(BoothsDocument, variables, options)
}
export type BoothsQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<BoothsQuery, BoothsQueryVariables>
export const CompanyFieldsByIndustryDocument = gql`
  query companyFieldsByIndustry($id: ID!) {
    userPropertiesPreferences {
      companyFieldsByIndustry(id: $id) {
        id
        companyIndustryId
        translation
      }
    }
  }
`

/**
 * __useCompanyFieldsByIndustryQuery__
 *
 * To run a query within a Vue component, call `useCompanyFieldsByIndustryQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompanyFieldsByIndustryQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useCompanyFieldsByIndustryQuery({
 *   id: // value for 'id'
 * });
 */
export function useCompanyFieldsByIndustryQuery(
  variables:
    | CompanyFieldsByIndustryQueryVariables
    | VueCompositionApi.Ref<CompanyFieldsByIndustryQueryVariables>
    | ReactiveFunction<CompanyFieldsByIndustryQueryVariables>,
  options:
    | VueApolloComposable.UseQueryOptions<CompanyFieldsByIndustryQuery, CompanyFieldsByIndustryQueryVariables>
    | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<CompanyFieldsByIndustryQuery, CompanyFieldsByIndustryQueryVariables>>
    | ReactiveFunction<VueApolloComposable.UseQueryOptions<CompanyFieldsByIndustryQuery, CompanyFieldsByIndustryQueryVariables>> = {},
) {
  return VueApolloComposable.useQuery<CompanyFieldsByIndustryQuery, CompanyFieldsByIndustryQueryVariables>(
    CompanyFieldsByIndustryDocument,
    variables,
    options,
  )
}
export function useCompanyFieldsByIndustryLazyQuery(
  variables?:
    | CompanyFieldsByIndustryQueryVariables
    | VueCompositionApi.Ref<CompanyFieldsByIndustryQueryVariables>
    | ReactiveFunction<CompanyFieldsByIndustryQueryVariables>,
  options:
    | VueApolloComposable.UseQueryOptions<CompanyFieldsByIndustryQuery, CompanyFieldsByIndustryQueryVariables>
    | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<CompanyFieldsByIndustryQuery, CompanyFieldsByIndustryQueryVariables>>
    | ReactiveFunction<VueApolloComposable.UseQueryOptions<CompanyFieldsByIndustryQuery, CompanyFieldsByIndustryQueryVariables>> = {},
) {
  return VueApolloComposable.useLazyQuery<CompanyFieldsByIndustryQuery, CompanyFieldsByIndustryQueryVariables>(
    CompanyFieldsByIndustryDocument,
    variables,
    options,
  )
}
export type CompanyFieldsByIndustryQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<
  CompanyFieldsByIndustryQuery,
  CompanyFieldsByIndustryQueryVariables
>
export const DocumentsDocument = gql`
  query Documents($eventId: ID, $tags: [String!]!, $signable: ID) {
    documents(eventId: $eventId, tags: $tags, signable: $signable) {
      nodes {
        eventId
        id
        titleDe
        titleEn
        descriptionDe
        descriptionEn
        documentUrl
        requireAcknowledge
        requireUpload
        docAcknowledgements {
          id
          uploadsUrls
          signableId
          signableType
        }
      }
    }
  }
`

/**
 * __useDocumentsQuery__
 *
 * To run a query within a Vue component, call `useDocumentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useDocumentsQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useDocumentsQuery({
 *   eventId: // value for 'eventId'
 *   tags: // value for 'tags'
 *   signable: // value for 'signable'
 * });
 */
export function useDocumentsQuery(
  variables: DocumentsQueryVariables | VueCompositionApi.Ref<DocumentsQueryVariables> | ReactiveFunction<DocumentsQueryVariables>,
  options:
    | VueApolloComposable.UseQueryOptions<DocumentsQuery, DocumentsQueryVariables>
    | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<DocumentsQuery, DocumentsQueryVariables>>
    | ReactiveFunction<VueApolloComposable.UseQueryOptions<DocumentsQuery, DocumentsQueryVariables>> = {},
) {
  return VueApolloComposable.useQuery<DocumentsQuery, DocumentsQueryVariables>(DocumentsDocument, variables, options)
}
export function useDocumentsLazyQuery(
  variables?: DocumentsQueryVariables | VueCompositionApi.Ref<DocumentsQueryVariables> | ReactiveFunction<DocumentsQueryVariables>,
  options:
    | VueApolloComposable.UseQueryOptions<DocumentsQuery, DocumentsQueryVariables>
    | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<DocumentsQuery, DocumentsQueryVariables>>
    | ReactiveFunction<VueApolloComposable.UseQueryOptions<DocumentsQuery, DocumentsQueryVariables>> = {},
) {
  return VueApolloComposable.useLazyQuery<DocumentsQuery, DocumentsQueryVariables>(DocumentsDocument, variables, options)
}
export type DocumentsQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<DocumentsQuery, DocumentsQueryVariables>
export const DueDateItemsDocument = gql`
  query DueDateItems {
    dueDateItems {
      ...dueDateItem
    }
  }
  ${DueDateItemFragmentDoc}
`

/**
 * __useDueDateItemsQuery__
 *
 * To run a query within a Vue component, call `useDueDateItemsQuery` and pass it any options that fit your needs.
 * When your component renders, `useDueDateItemsQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useDueDateItemsQuery();
 */
export function useDueDateItemsQuery(
  options:
    | VueApolloComposable.UseQueryOptions<DueDateItemsQuery, DueDateItemsQueryVariables>
    | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<DueDateItemsQuery, DueDateItemsQueryVariables>>
    | ReactiveFunction<VueApolloComposable.UseQueryOptions<DueDateItemsQuery, DueDateItemsQueryVariables>> = {},
) {
  return VueApolloComposable.useQuery<DueDateItemsQuery, DueDateItemsQueryVariables>(DueDateItemsDocument, {}, options)
}
export function useDueDateItemsLazyQuery(
  options:
    | VueApolloComposable.UseQueryOptions<DueDateItemsQuery, DueDateItemsQueryVariables>
    | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<DueDateItemsQuery, DueDateItemsQueryVariables>>
    | ReactiveFunction<VueApolloComposable.UseQueryOptions<DueDateItemsQuery, DueDateItemsQueryVariables>> = {},
) {
  return VueApolloComposable.useLazyQuery<DueDateItemsQuery, DueDateItemsQueryVariables>(DueDateItemsDocument, {}, options)
}
export type DueDateItemsQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<DueDateItemsQuery, DueDateItemsQueryVariables>
export const EventBySlugDocument = gql`
  query EventBySlug($slug: String!) {
    event(slug: $slug) {
      __typename
      ...eventOverview
    }
  }
  ${EventOverviewFragmentDoc}
`

/**
 * __useEventBySlugQuery__
 *
 * To run a query within a Vue component, call `useEventBySlugQuery` and pass it any options that fit your needs.
 * When your component renders, `useEventBySlugQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useEventBySlugQuery({
 *   slug: // value for 'slug'
 * });
 */
export function useEventBySlugQuery(
  variables: EventBySlugQueryVariables | VueCompositionApi.Ref<EventBySlugQueryVariables> | ReactiveFunction<EventBySlugQueryVariables>,
  options:
    | VueApolloComposable.UseQueryOptions<EventBySlugQuery, EventBySlugQueryVariables>
    | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<EventBySlugQuery, EventBySlugQueryVariables>>
    | ReactiveFunction<VueApolloComposable.UseQueryOptions<EventBySlugQuery, EventBySlugQueryVariables>> = {},
) {
  return VueApolloComposable.useQuery<EventBySlugQuery, EventBySlugQueryVariables>(EventBySlugDocument, variables, options)
}
export function useEventBySlugLazyQuery(
  variables?: EventBySlugQueryVariables | VueCompositionApi.Ref<EventBySlugQueryVariables> | ReactiveFunction<EventBySlugQueryVariables>,
  options:
    | VueApolloComposable.UseQueryOptions<EventBySlugQuery, EventBySlugQueryVariables>
    | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<EventBySlugQuery, EventBySlugQueryVariables>>
    | ReactiveFunction<VueApolloComposable.UseQueryOptions<EventBySlugQuery, EventBySlugQueryVariables>> = {},
) {
  return VueApolloComposable.useLazyQuery<EventBySlugQuery, EventBySlugQueryVariables>(EventBySlugDocument, variables, options)
}
export type EventBySlugQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<EventBySlugQuery, EventBySlugQueryVariables>
export const EventDocument = gql`
  query Event($id: ID!) {
    eventById(id: $id) {
      __typename
      ...event
    }
  }
  ${EventFragmentDoc}
`

/**
 * __useEventQuery__
 *
 * To run a query within a Vue component, call `useEventQuery` and pass it any options that fit your needs.
 * When your component renders, `useEventQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useEventQuery({
 *   id: // value for 'id'
 * });
 */
export function useEventQuery(
  variables: EventQueryVariables | VueCompositionApi.Ref<EventQueryVariables> | ReactiveFunction<EventQueryVariables>,
  options:
    | VueApolloComposable.UseQueryOptions<EventQuery, EventQueryVariables>
    | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<EventQuery, EventQueryVariables>>
    | ReactiveFunction<VueApolloComposable.UseQueryOptions<EventQuery, EventQueryVariables>> = {},
) {
  return VueApolloComposable.useQuery<EventQuery, EventQueryVariables>(EventDocument, variables, options)
}
export function useEventLazyQuery(
  variables?: EventQueryVariables | VueCompositionApi.Ref<EventQueryVariables> | ReactiveFunction<EventQueryVariables>,
  options:
    | VueApolloComposable.UseQueryOptions<EventQuery, EventQueryVariables>
    | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<EventQuery, EventQueryVariables>>
    | ReactiveFunction<VueApolloComposable.UseQueryOptions<EventQuery, EventQueryVariables>> = {},
) {
  return VueApolloComposable.useLazyQuery<EventQuery, EventQueryVariables>(EventDocument, variables, options)
}
export type EventQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<EventQuery, EventQueryVariables>
export const EventsDocument = gql`
  query Events($cursor: String, $period: EventPeriodEnum, $noParent: Boolean, $parentEventsFirst: Boolean = true, $type: [EventTypeEnum!]) {
    events(first: 100, after: $cursor, period: $period, noParent: $noParent, parentEventsFirst: $parentEventsFirst, type: $type) {
      nodes {
        ...eventOverview
      }
      pageInfo {
        ...pageInfo
      }
    }
  }
  ${EventOverviewFragmentDoc}
  ${PageInfoFragmentDoc}
`

/**
 * __useEventsQuery__
 *
 * To run a query within a Vue component, call `useEventsQuery` and pass it any options that fit your needs.
 * When your component renders, `useEventsQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useEventsQuery({
 *   cursor: // value for 'cursor'
 *   period: // value for 'period'
 *   noParent: // value for 'noParent'
 *   parentEventsFirst: // value for 'parentEventsFirst'
 *   type: // value for 'type'
 * });
 */
export function useEventsQuery(
  variables: EventsQueryVariables | VueCompositionApi.Ref<EventsQueryVariables> | ReactiveFunction<EventsQueryVariables> = {},
  options:
    | VueApolloComposable.UseQueryOptions<EventsQuery, EventsQueryVariables>
    | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<EventsQuery, EventsQueryVariables>>
    | ReactiveFunction<VueApolloComposable.UseQueryOptions<EventsQuery, EventsQueryVariables>> = {},
) {
  return VueApolloComposable.useQuery<EventsQuery, EventsQueryVariables>(EventsDocument, variables, options)
}
export function useEventsLazyQuery(
  variables: EventsQueryVariables | VueCompositionApi.Ref<EventsQueryVariables> | ReactiveFunction<EventsQueryVariables> = {},
  options:
    | VueApolloComposable.UseQueryOptions<EventsQuery, EventsQueryVariables>
    | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<EventsQuery, EventsQueryVariables>>
    | ReactiveFunction<VueApolloComposable.UseQueryOptions<EventsQuery, EventsQueryVariables>> = {},
) {
  return VueApolloComposable.useLazyQuery<EventsQuery, EventsQueryVariables>(EventsDocument, variables, options)
}
export type EventsQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<EventsQuery, EventsQueryVariables>
export const InterestsDocument = gql`
  query Interests {
    interests {
      nodes {
        id
        name
        slug
      }
    }
  }
`

/**
 * __useInterestsQuery__
 *
 * To run a query within a Vue component, call `useInterestsQuery` and pass it any options that fit your needs.
 * When your component renders, `useInterestsQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useInterestsQuery();
 */
export function useInterestsQuery(
  options:
    | VueApolloComposable.UseQueryOptions<InterestsQuery, InterestsQueryVariables>
    | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<InterestsQuery, InterestsQueryVariables>>
    | ReactiveFunction<VueApolloComposable.UseQueryOptions<InterestsQuery, InterestsQueryVariables>> = {},
) {
  return VueApolloComposable.useQuery<InterestsQuery, InterestsQueryVariables>(InterestsDocument, {}, options)
}
export function useInterestsLazyQuery(
  options:
    | VueApolloComposable.UseQueryOptions<InterestsQuery, InterestsQueryVariables>
    | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<InterestsQuery, InterestsQueryVariables>>
    | ReactiveFunction<VueApolloComposable.UseQueryOptions<InterestsQuery, InterestsQueryVariables>> = {},
) {
  return VueApolloComposable.useLazyQuery<InterestsQuery, InterestsQueryVariables>(InterestsDocument, {}, options)
}
export type InterestsQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<InterestsQuery, InterestsQueryVariables>
export const CsvPreviewDataDocument = gql`
  query CsvPreviewData($invitationQuotaId: ID!, $csvUploadId: ID!) {
    csvPreviewData(csvUploadId: $csvUploadId, invitationQuotaId: $invitationQuotaId) {
      name
      index
      sample
    }
  }
`

/**
 * __useCsvPreviewDataQuery__
 *
 * To run a query within a Vue component, call `useCsvPreviewDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useCsvPreviewDataQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useCsvPreviewDataQuery({
 *   invitationQuotaId: // value for 'invitationQuotaId'
 *   csvUploadId: // value for 'csvUploadId'
 * });
 */
export function useCsvPreviewDataQuery(
  variables:
    | CsvPreviewDataQueryVariables
    | VueCompositionApi.Ref<CsvPreviewDataQueryVariables>
    | ReactiveFunction<CsvPreviewDataQueryVariables>,
  options:
    | VueApolloComposable.UseQueryOptions<CsvPreviewDataQuery, CsvPreviewDataQueryVariables>
    | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<CsvPreviewDataQuery, CsvPreviewDataQueryVariables>>
    | ReactiveFunction<VueApolloComposable.UseQueryOptions<CsvPreviewDataQuery, CsvPreviewDataQueryVariables>> = {},
) {
  return VueApolloComposable.useQuery<CsvPreviewDataQuery, CsvPreviewDataQueryVariables>(CsvPreviewDataDocument, variables, options)
}
export function useCsvPreviewDataLazyQuery(
  variables?:
    | CsvPreviewDataQueryVariables
    | VueCompositionApi.Ref<CsvPreviewDataQueryVariables>
    | ReactiveFunction<CsvPreviewDataQueryVariables>,
  options:
    | VueApolloComposable.UseQueryOptions<CsvPreviewDataQuery, CsvPreviewDataQueryVariables>
    | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<CsvPreviewDataQuery, CsvPreviewDataQueryVariables>>
    | ReactiveFunction<VueApolloComposable.UseQueryOptions<CsvPreviewDataQuery, CsvPreviewDataQueryVariables>> = {},
) {
  return VueApolloComposable.useLazyQuery<CsvPreviewDataQuery, CsvPreviewDataQueryVariables>(CsvPreviewDataDocument, variables, options)
}
export type CsvPreviewDataQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<
  CsvPreviewDataQuery,
  CsvPreviewDataQueryVariables
>
export const InvitationQuotaDocument = gql`
  query InvitationQuota($id: ID!) {
    invitationQuota(id: $id) {
      id
      activatedTicketsCount
      usableTicketsCount
      allowUsageOfSameAddress
      cc
      createdAt
      descriptionDe
      descriptionEn
      discount
      doNotSendOutTicketEmail
      expirationDate
      kind
      maxUsable
      messageBody
      messageSubject
      replyTo
      showFormLink
      ticketsCount
      title
      event {
        id
        slug
        __typename
        nameEn
        nameDe
        ticketCreationDeadlineHint
      }
      fields {
        id
        name
        descriptionDe
        descriptionEn
      }
      inviteeTracking {
        acceptedCount
        declinedCount
        openedCount
        purchasedCount
        sentCount
        totalCount
      }
      partnerCompany {
        id
        __typename
      }
      promoCodes {
        code
        companyName
        id
        manuallyMarkedAsSentAt
        ticketCount
        timesUsableRemaining
        usable
        userName
      }
      ticketCategory {
        id
        titleDe
        titleEn
        __typename
      }
    }
  }
`

/**
 * __useInvitationQuotaQuery__
 *
 * To run a query within a Vue component, call `useInvitationQuotaQuery` and pass it any options that fit your needs.
 * When your component renders, `useInvitationQuotaQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useInvitationQuotaQuery({
 *   id: // value for 'id'
 * });
 */
export function useInvitationQuotaQuery(
  variables:
    | InvitationQuotaQueryVariables
    | VueCompositionApi.Ref<InvitationQuotaQueryVariables>
    | ReactiveFunction<InvitationQuotaQueryVariables>,
  options:
    | VueApolloComposable.UseQueryOptions<InvitationQuotaQuery, InvitationQuotaQueryVariables>
    | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<InvitationQuotaQuery, InvitationQuotaQueryVariables>>
    | ReactiveFunction<VueApolloComposable.UseQueryOptions<InvitationQuotaQuery, InvitationQuotaQueryVariables>> = {},
) {
  return VueApolloComposable.useQuery<InvitationQuotaQuery, InvitationQuotaQueryVariables>(InvitationQuotaDocument, variables, options)
}
export function useInvitationQuotaLazyQuery(
  variables?:
    | InvitationQuotaQueryVariables
    | VueCompositionApi.Ref<InvitationQuotaQueryVariables>
    | ReactiveFunction<InvitationQuotaQueryVariables>,
  options:
    | VueApolloComposable.UseQueryOptions<InvitationQuotaQuery, InvitationQuotaQueryVariables>
    | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<InvitationQuotaQuery, InvitationQuotaQueryVariables>>
    | ReactiveFunction<VueApolloComposable.UseQueryOptions<InvitationQuotaQuery, InvitationQuotaQueryVariables>> = {},
) {
  return VueApolloComposable.useLazyQuery<InvitationQuotaQuery, InvitationQuotaQueryVariables>(InvitationQuotaDocument, variables, options)
}
export type InvitationQuotaQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<
  InvitationQuotaQuery,
  InvitationQuotaQueryVariables
>
export const InviteesDocument = gql`
  query Invitees($invitationQuotaId: ID!, $status: InviteeStatusEnum, $activated: Boolean, $offset: Int, $limit: Int) {
    invitees(invitationQuotaId: $invitationQuotaId, status: $status, activated: $activated, offset: $offset, limit: $limit) {
      nodes {
        token
        email
        firstName
        lastName
        company
        gender
        status
        sentAt
        openedAt
        acceptedAt
        createdAt
        declinedAt
        sentCount
        externalId
        title
        activated
      }
      pageInfo {
        hasNextPage
        hasPreviousPage
        limit
        offset
        pagesCount
        totalCount
      }
    }
  }
`

/**
 * __useInviteesQuery__
 *
 * To run a query within a Vue component, call `useInviteesQuery` and pass it any options that fit your needs.
 * When your component renders, `useInviteesQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useInviteesQuery({
 *   invitationQuotaId: // value for 'invitationQuotaId'
 *   status: // value for 'status'
 *   activated: // value for 'activated'
 *   offset: // value for 'offset'
 *   limit: // value for 'limit'
 * });
 */
export function useInviteesQuery(
  variables: InviteesQueryVariables | VueCompositionApi.Ref<InviteesQueryVariables> | ReactiveFunction<InviteesQueryVariables>,
  options:
    | VueApolloComposable.UseQueryOptions<InviteesQuery, InviteesQueryVariables>
    | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<InviteesQuery, InviteesQueryVariables>>
    | ReactiveFunction<VueApolloComposable.UseQueryOptions<InviteesQuery, InviteesQueryVariables>> = {},
) {
  return VueApolloComposable.useQuery<InviteesQuery, InviteesQueryVariables>(InviteesDocument, variables, options)
}
export function useInviteesLazyQuery(
  variables?: InviteesQueryVariables | VueCompositionApi.Ref<InviteesQueryVariables> | ReactiveFunction<InviteesQueryVariables>,
  options:
    | VueApolloComposable.UseQueryOptions<InviteesQuery, InviteesQueryVariables>
    | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<InviteesQuery, InviteesQueryVariables>>
    | ReactiveFunction<VueApolloComposable.UseQueryOptions<InviteesQuery, InviteesQueryVariables>> = {},
) {
  return VueApolloComposable.useLazyQuery<InviteesQuery, InviteesQueryVariables>(InviteesDocument, variables, options)
}
export type InviteesQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<InviteesQuery, InviteesQueryVariables>
export const LeadCountsByEventDocument = gql`
  query LeadCountsByEvent($cursor: String, $rootEvent: String, $type: [EventTypeEnum!]) {
    events(first: 100, after: $cursor, rootEvent: $rootEvent, type: $type) {
      nodes {
        endsAt
        id
        leadCount
        name
        nameDe
        nameEn
        slug
        startsAt
        type
      }
      totalCount
      pageInfo {
        ...pageInfo
      }
    }
  }
  ${PageInfoFragmentDoc}
`

/**
 * __useLeadCountsByEventQuery__
 *
 * To run a query within a Vue component, call `useLeadCountsByEventQuery` and pass it any options that fit your needs.
 * When your component renders, `useLeadCountsByEventQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useLeadCountsByEventQuery({
 *   cursor: // value for 'cursor'
 *   rootEvent: // value for 'rootEvent'
 *   type: // value for 'type'
 * });
 */
export function useLeadCountsByEventQuery(
  variables:
    | LeadCountsByEventQueryVariables
    | VueCompositionApi.Ref<LeadCountsByEventQueryVariables>
    | ReactiveFunction<LeadCountsByEventQueryVariables> = {},
  options:
    | VueApolloComposable.UseQueryOptions<LeadCountsByEventQuery, LeadCountsByEventQueryVariables>
    | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<LeadCountsByEventQuery, LeadCountsByEventQueryVariables>>
    | ReactiveFunction<VueApolloComposable.UseQueryOptions<LeadCountsByEventQuery, LeadCountsByEventQueryVariables>> = {},
) {
  return VueApolloComposable.useQuery<LeadCountsByEventQuery, LeadCountsByEventQueryVariables>(
    LeadCountsByEventDocument,
    variables,
    options,
  )
}
export function useLeadCountsByEventLazyQuery(
  variables:
    | LeadCountsByEventQueryVariables
    | VueCompositionApi.Ref<LeadCountsByEventQueryVariables>
    | ReactiveFunction<LeadCountsByEventQueryVariables> = {},
  options:
    | VueApolloComposable.UseQueryOptions<LeadCountsByEventQuery, LeadCountsByEventQueryVariables>
    | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<LeadCountsByEventQuery, LeadCountsByEventQueryVariables>>
    | ReactiveFunction<VueApolloComposable.UseQueryOptions<LeadCountsByEventQuery, LeadCountsByEventQueryVariables>> = {},
) {
  return VueApolloComposable.useLazyQuery<LeadCountsByEventQuery, LeadCountsByEventQueryVariables>(
    LeadCountsByEventDocument,
    variables,
    options,
  )
}
export type LeadCountsByEventQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<
  LeadCountsByEventQuery,
  LeadCountsByEventQueryVariables
>
export const PartnerCompanyLeadCountDocument = gql`
  query PartnerCompanyLeadCount($cursor: String, $mainEventId: ID!, $partnerCompanyId: ID!) {
    leads(mainEventId: $mainEventId, first: 100, after: $cursor, partnerCompanyId: $partnerCompanyId) {
      totalCount
    }
  }
`

/**
 * __usePartnerCompanyLeadCountQuery__
 *
 * To run a query within a Vue component, call `usePartnerCompanyLeadCountQuery` and pass it any options that fit your needs.
 * When your component renders, `usePartnerCompanyLeadCountQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = usePartnerCompanyLeadCountQuery({
 *   cursor: // value for 'cursor'
 *   mainEventId: // value for 'mainEventId'
 *   partnerCompanyId: // value for 'partnerCompanyId'
 * });
 */
export function usePartnerCompanyLeadCountQuery(
  variables:
    | PartnerCompanyLeadCountQueryVariables
    | VueCompositionApi.Ref<PartnerCompanyLeadCountQueryVariables>
    | ReactiveFunction<PartnerCompanyLeadCountQueryVariables>,
  options:
    | VueApolloComposable.UseQueryOptions<PartnerCompanyLeadCountQuery, PartnerCompanyLeadCountQueryVariables>
    | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<PartnerCompanyLeadCountQuery, PartnerCompanyLeadCountQueryVariables>>
    | ReactiveFunction<VueApolloComposable.UseQueryOptions<PartnerCompanyLeadCountQuery, PartnerCompanyLeadCountQueryVariables>> = {},
) {
  return VueApolloComposable.useQuery<PartnerCompanyLeadCountQuery, PartnerCompanyLeadCountQueryVariables>(
    PartnerCompanyLeadCountDocument,
    variables,
    options,
  )
}
export function usePartnerCompanyLeadCountLazyQuery(
  variables?:
    | PartnerCompanyLeadCountQueryVariables
    | VueCompositionApi.Ref<PartnerCompanyLeadCountQueryVariables>
    | ReactiveFunction<PartnerCompanyLeadCountQueryVariables>,
  options:
    | VueApolloComposable.UseQueryOptions<PartnerCompanyLeadCountQuery, PartnerCompanyLeadCountQueryVariables>
    | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<PartnerCompanyLeadCountQuery, PartnerCompanyLeadCountQueryVariables>>
    | ReactiveFunction<VueApolloComposable.UseQueryOptions<PartnerCompanyLeadCountQuery, PartnerCompanyLeadCountQueryVariables>> = {},
) {
  return VueApolloComposable.useLazyQuery<PartnerCompanyLeadCountQuery, PartnerCompanyLeadCountQueryVariables>(
    PartnerCompanyLeadCountDocument,
    variables,
    options,
  )
}
export type PartnerCompanyLeadCountQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<
  PartnerCompanyLeadCountQuery,
  PartnerCompanyLeadCountQueryVariables
>
export const MeDocument = gql`
  query Me {
    me {
      ...user
      profileImage
      profileScopes
      partnerCompany {
        ...partnerCompany
      }
      roles {
        ...role
      }
      newsTags
    }
    booths {
      nodes {
        id
        premium
        createdAt
      }
    }
  }
  ${UserFragmentDoc}
  ${PartnerCompanyFragmentDoc}
  ${RoleFragmentDoc}
`

/**
 * __useMeQuery__
 *
 * To run a query within a Vue component, call `useMeQuery` and pass it any options that fit your needs.
 * When your component renders, `useMeQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useMeQuery();
 */
export function useMeQuery(
  options:
    | VueApolloComposable.UseQueryOptions<MeQuery, MeQueryVariables>
    | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<MeQuery, MeQueryVariables>>
    | ReactiveFunction<VueApolloComposable.UseQueryOptions<MeQuery, MeQueryVariables>> = {},
) {
  return VueApolloComposable.useQuery<MeQuery, MeQueryVariables>(MeDocument, {}, options)
}
export function useMeLazyQuery(
  options:
    | VueApolloComposable.UseQueryOptions<MeQuery, MeQueryVariables>
    | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<MeQuery, MeQueryVariables>>
    | ReactiveFunction<VueApolloComposable.UseQueryOptions<MeQuery, MeQueryVariables>> = {},
) {
  return VueApolloComposable.useLazyQuery<MeQuery, MeQueryVariables>(MeDocument, {}, options)
}
export type MeQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<MeQuery, MeQueryVariables>
export const NewsDocument = gql`
  query News {
    news {
      nodes {
        id
        titleDe
        titleEn
        bodyDe
        bodyEn
        bodyHtmlDe
        bodyHtmlEn
        ctaButtonTextDe
        ctaButtonTextEn
        ctaButtonLink
        imageUrl
        force
      }
    }
  }
`

/**
 * __useNewsQuery__
 *
 * To run a query within a Vue component, call `useNewsQuery` and pass it any options that fit your needs.
 * When your component renders, `useNewsQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useNewsQuery();
 */
export function useNewsQuery(
  options:
    | VueApolloComposable.UseQueryOptions<NewsQuery, NewsQueryVariables>
    | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<NewsQuery, NewsQueryVariables>>
    | ReactiveFunction<VueApolloComposable.UseQueryOptions<NewsQuery, NewsQueryVariables>> = {},
) {
  return VueApolloComposable.useQuery<NewsQuery, NewsQueryVariables>(NewsDocument, {}, options)
}
export function useNewsLazyQuery(
  options:
    | VueApolloComposable.UseQueryOptions<NewsQuery, NewsQueryVariables>
    | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<NewsQuery, NewsQueryVariables>>
    | ReactiveFunction<VueApolloComposable.UseQueryOptions<NewsQuery, NewsQueryVariables>> = {},
) {
  return VueApolloComposable.useLazyQuery<NewsQuery, NewsQueryVariables>(NewsDocument, {}, options)
}
export type NewsQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<NewsQuery, NewsQueryVariables>
export const PartnerCompaniesDocument = gql`
  query PartnerCompanies($partnerCompanyIds: [ID!]!) {
    nodes(ids: $partnerCompanyIds) {
      __typename
      ... on PartnerCompany {
        id
        name
        displayName
      }
    }
  }
`

/**
 * __usePartnerCompaniesQuery__
 *
 * To run a query within a Vue component, call `usePartnerCompaniesQuery` and pass it any options that fit your needs.
 * When your component renders, `usePartnerCompaniesQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = usePartnerCompaniesQuery({
 *   partnerCompanyIds: // value for 'partnerCompanyIds'
 * });
 */
export function usePartnerCompaniesQuery(
  variables:
    | PartnerCompaniesQueryVariables
    | VueCompositionApi.Ref<PartnerCompaniesQueryVariables>
    | ReactiveFunction<PartnerCompaniesQueryVariables>,
  options:
    | VueApolloComposable.UseQueryOptions<PartnerCompaniesQuery, PartnerCompaniesQueryVariables>
    | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<PartnerCompaniesQuery, PartnerCompaniesQueryVariables>>
    | ReactiveFunction<VueApolloComposable.UseQueryOptions<PartnerCompaniesQuery, PartnerCompaniesQueryVariables>> = {},
) {
  return VueApolloComposable.useQuery<PartnerCompaniesQuery, PartnerCompaniesQueryVariables>(PartnerCompaniesDocument, variables, options)
}
export function usePartnerCompaniesLazyQuery(
  variables?:
    | PartnerCompaniesQueryVariables
    | VueCompositionApi.Ref<PartnerCompaniesQueryVariables>
    | ReactiveFunction<PartnerCompaniesQueryVariables>,
  options:
    | VueApolloComposable.UseQueryOptions<PartnerCompaniesQuery, PartnerCompaniesQueryVariables>
    | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<PartnerCompaniesQuery, PartnerCompaniesQueryVariables>>
    | ReactiveFunction<VueApolloComposable.UseQueryOptions<PartnerCompaniesQuery, PartnerCompaniesQueryVariables>> = {},
) {
  return VueApolloComposable.useLazyQuery<PartnerCompaniesQuery, PartnerCompaniesQueryVariables>(
    PartnerCompaniesDocument,
    variables,
    options,
  )
}
export type PartnerCompaniesQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<
  PartnerCompaniesQuery,
  PartnerCompaniesQueryVariables
>
export const ProspectListsDocument = gql`
  query ProspectLists($partnerCompanyId: ID!, $period: EventPeriodEnum) {
    prospectLists(partnerCompanyId: $partnerCompanyId, period: $period) {
      nodes {
        id
        title
        contactEmail
        contactName
        contactReason
        event {
          endsAt
          id
          language
          location {
            id
            name
          }
          name
          nameDe
          nameEn
          prospectSelectionUntil
          slug
          startsAt
        }
        prospects {
          ...prospect
        }
        submittedAt
      }
    }
  }
  ${ProspectFragmentDoc}
`

/**
 * __useProspectListsQuery__
 *
 * To run a query within a Vue component, call `useProspectListsQuery` and pass it any options that fit your needs.
 * When your component renders, `useProspectListsQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useProspectListsQuery({
 *   partnerCompanyId: // value for 'partnerCompanyId'
 *   period: // value for 'period'
 * });
 */
export function useProspectListsQuery(
  variables:
    | ProspectListsQueryVariables
    | VueCompositionApi.Ref<ProspectListsQueryVariables>
    | ReactiveFunction<ProspectListsQueryVariables>,
  options:
    | VueApolloComposable.UseQueryOptions<ProspectListsQuery, ProspectListsQueryVariables>
    | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<ProspectListsQuery, ProspectListsQueryVariables>>
    | ReactiveFunction<VueApolloComposable.UseQueryOptions<ProspectListsQuery, ProspectListsQueryVariables>> = {},
) {
  return VueApolloComposable.useQuery<ProspectListsQuery, ProspectListsQueryVariables>(ProspectListsDocument, variables, options)
}
export function useProspectListsLazyQuery(
  variables?:
    | ProspectListsQueryVariables
    | VueCompositionApi.Ref<ProspectListsQueryVariables>
    | ReactiveFunction<ProspectListsQueryVariables>,
  options:
    | VueApolloComposable.UseQueryOptions<ProspectListsQuery, ProspectListsQueryVariables>
    | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<ProspectListsQuery, ProspectListsQueryVariables>>
    | ReactiveFunction<VueApolloComposable.UseQueryOptions<ProspectListsQuery, ProspectListsQueryVariables>> = {},
) {
  return VueApolloComposable.useLazyQuery<ProspectListsQuery, ProspectListsQueryVariables>(ProspectListsDocument, variables, options)
}
export type ProspectListsQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<
  ProspectListsQuery,
  ProspectListsQueryVariables
>
export const ProspectsDocument = gql`
  query Prospects(
    $page: Int
    $perPage: Int
    $companyName: String
    $position: String
    $visitedEvents: [ID!]
    $companySizes: [ID!]
    $companyFields: [ID!]
    $companyIndustries: [ID!]
    $companyDepartments: [ID!]
    $companyJobLevels: [ID!]
    $companyCountries: [ID!]
    $available: Boolean
    $eventSlug: String!
  ) {
    prospects(
      page: $page
      perPage: $perPage
      companyName: $companyName
      position: $position
      visitedEvents: $visitedEvents
      companySizes: $companySizes
      companyFields: $companyFields
      companyIndustries: $companyIndustries
      companyDepartments: $companyDepartments
      companyJobLevels: $companyJobLevels
      companyCountries: $companyCountries
      available: $available
      eventSlug: $eventSlug
    ) {
      prospectsCount {
        relation
        total
      }
      prospects {
        ...prospect
      }
      visitedEventsFilter {
        id
        name
      }
      companySizeFacets {
        id
        name
      }
      companyFieldFacets {
        id
        name
      }
      companyCountryFacets {
        id
        name
      }
      companyIndustryFacets {
        id
        name
      }
      companyDepartmentFacets {
        id
        name
      }
      companyJobLevelFacets {
        id
        name
      }
    }
  }
  ${ProspectFragmentDoc}
`

/**
 * __useProspectsQuery__
 *
 * To run a query within a Vue component, call `useProspectsQuery` and pass it any options that fit your needs.
 * When your component renders, `useProspectsQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useProspectsQuery({
 *   page: // value for 'page'
 *   perPage: // value for 'perPage'
 *   companyName: // value for 'companyName'
 *   position: // value for 'position'
 *   visitedEvents: // value for 'visitedEvents'
 *   companySizes: // value for 'companySizes'
 *   companyFields: // value for 'companyFields'
 *   companyIndustries: // value for 'companyIndustries'
 *   companyDepartments: // value for 'companyDepartments'
 *   companyJobLevels: // value for 'companyJobLevels'
 *   companyCountries: // value for 'companyCountries'
 *   available: // value for 'available'
 *   eventSlug: // value for 'eventSlug'
 * });
 */
export function useProspectsQuery(
  variables: ProspectsQueryVariables | VueCompositionApi.Ref<ProspectsQueryVariables> | ReactiveFunction<ProspectsQueryVariables>,
  options:
    | VueApolloComposable.UseQueryOptions<ProspectsQuery, ProspectsQueryVariables>
    | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<ProspectsQuery, ProspectsQueryVariables>>
    | ReactiveFunction<VueApolloComposable.UseQueryOptions<ProspectsQuery, ProspectsQueryVariables>> = {},
) {
  return VueApolloComposable.useQuery<ProspectsQuery, ProspectsQueryVariables>(ProspectsDocument, variables, options)
}
export function useProspectsLazyQuery(
  variables?: ProspectsQueryVariables | VueCompositionApi.Ref<ProspectsQueryVariables> | ReactiveFunction<ProspectsQueryVariables>,
  options:
    | VueApolloComposable.UseQueryOptions<ProspectsQuery, ProspectsQueryVariables>
    | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<ProspectsQuery, ProspectsQueryVariables>>
    | ReactiveFunction<VueApolloComposable.UseQueryOptions<ProspectsQuery, ProspectsQueryVariables>> = {},
) {
  return VueApolloComposable.useLazyQuery<ProspectsQuery, ProspectsQueryVariables>(ProspectsDocument, variables, options)
}
export type ProspectsQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<ProspectsQuery, ProspectsQueryVariables>
export const SpeakerDocument = gql`
  query Speaker($speakerId: ID!) {
    node(id: $speakerId) {
      id
      __typename
      ... on Speaker {
        id
        name
        firstname
        lastname
        descriptionEn
        descriptionDe
        title
        email
        editorialFlag
        partnerCompanyName
        createdAt
        partnerCompany {
          id
        }
        interests {
          id
          slug
          text
        }
        photoUrl
        twitterUrl
        facebookUrl
        instagramUrl
        linkedinUrl
        youtubeUrl
        websiteUrl
        eventSpeakers {
          id
          event {
            ...event
          }
        }
      }
    }
  }
  ${EventFragmentDoc}
`

/**
 * __useSpeakerQuery__
 *
 * To run a query within a Vue component, call `useSpeakerQuery` and pass it any options that fit your needs.
 * When your component renders, `useSpeakerQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useSpeakerQuery({
 *   speakerId: // value for 'speakerId'
 * });
 */
export function useSpeakerQuery(
  variables: SpeakerQueryVariables | VueCompositionApi.Ref<SpeakerQueryVariables> | ReactiveFunction<SpeakerQueryVariables>,
  options:
    | VueApolloComposable.UseQueryOptions<SpeakerQuery, SpeakerQueryVariables>
    | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<SpeakerQuery, SpeakerQueryVariables>>
    | ReactiveFunction<VueApolloComposable.UseQueryOptions<SpeakerQuery, SpeakerQueryVariables>> = {},
) {
  return VueApolloComposable.useQuery<SpeakerQuery, SpeakerQueryVariables>(SpeakerDocument, variables, options)
}
export function useSpeakerLazyQuery(
  variables?: SpeakerQueryVariables | VueCompositionApi.Ref<SpeakerQueryVariables> | ReactiveFunction<SpeakerQueryVariables>,
  options:
    | VueApolloComposable.UseQueryOptions<SpeakerQuery, SpeakerQueryVariables>
    | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<SpeakerQuery, SpeakerQueryVariables>>
    | ReactiveFunction<VueApolloComposable.UseQueryOptions<SpeakerQuery, SpeakerQueryVariables>> = {},
) {
  return VueApolloComposable.useLazyQuery<SpeakerQuery, SpeakerQueryVariables>(SpeakerDocument, variables, options)
}
export type SpeakerQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<SpeakerQuery, SpeakerQueryVariables>
export const SpeakersDocument = gql`
  query Speakers($cursor: String, $searchTerm: String, $first: Int = 300, $forCurrentPartnerCompany: Boolean = true) {
    speakers(first: $first, after: $cursor, searchTerm: $searchTerm, forCurrentPartnerCompany: $forCurrentPartnerCompany) {
      nodes {
        id
        name
        firstname
        lastname
        descriptionEn
        descriptionDe
        createdAt
        editorialFlag
        partnerCompany {
          id
        }
        partnerCompanyName
        title
        interests {
          id
          slug
          text
        }
        photoUrl
        twitterUrl
        facebookUrl
        instagramUrl
        linkedinUrl
        youtubeUrl
        websiteUrl
        email
        eventSpeakers {
          id
          event {
            ...event
          }
        }
      }
      pageInfo {
        ...pageInfo
      }
    }
  }
  ${EventFragmentDoc}
  ${PageInfoFragmentDoc}
`

/**
 * __useSpeakersQuery__
 *
 * To run a query within a Vue component, call `useSpeakersQuery` and pass it any options that fit your needs.
 * When your component renders, `useSpeakersQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useSpeakersQuery({
 *   cursor: // value for 'cursor'
 *   searchTerm: // value for 'searchTerm'
 *   first: // value for 'first'
 *   forCurrentPartnerCompany: // value for 'forCurrentPartnerCompany'
 * });
 */
export function useSpeakersQuery(
  variables: SpeakersQueryVariables | VueCompositionApi.Ref<SpeakersQueryVariables> | ReactiveFunction<SpeakersQueryVariables> = {},
  options:
    | VueApolloComposable.UseQueryOptions<SpeakersQuery, SpeakersQueryVariables>
    | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<SpeakersQuery, SpeakersQueryVariables>>
    | ReactiveFunction<VueApolloComposable.UseQueryOptions<SpeakersQuery, SpeakersQueryVariables>> = {},
) {
  return VueApolloComposable.useQuery<SpeakersQuery, SpeakersQueryVariables>(SpeakersDocument, variables, options)
}
export function useSpeakersLazyQuery(
  variables: SpeakersQueryVariables | VueCompositionApi.Ref<SpeakersQueryVariables> | ReactiveFunction<SpeakersQueryVariables> = {},
  options:
    | VueApolloComposable.UseQueryOptions<SpeakersQuery, SpeakersQueryVariables>
    | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<SpeakersQuery, SpeakersQueryVariables>>
    | ReactiveFunction<VueApolloComposable.UseQueryOptions<SpeakersQuery, SpeakersQueryVariables>> = {},
) {
  return VueApolloComposable.useLazyQuery<SpeakersQuery, SpeakersQueryVariables>(SpeakersDocument, variables, options)
}
export type SpeakersQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<SpeakersQuery, SpeakersQueryVariables>
export const TicketCountsDocument = gql`
  query TicketCounts(
    $aasmState: [TicketAasmStateEnum!]
    $activated: Boolean
    $invitationQuotaId: ID
    $source: [TicketSourceEnum!]
    $swapped: Boolean
  ) {
    tickets(aasmState: $aasmState, activated: $activated, invitationQuotaId: $invitationQuotaId, source: $source, swapped: $swapped) {
      totalCount
    }
  }
`

/**
 * __useTicketCountsQuery__
 *
 * To run a query within a Vue component, call `useTicketCountsQuery` and pass it any options that fit your needs.
 * When your component renders, `useTicketCountsQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useTicketCountsQuery({
 *   aasmState: // value for 'aasmState'
 *   activated: // value for 'activated'
 *   invitationQuotaId: // value for 'invitationQuotaId'
 *   source: // value for 'source'
 *   swapped: // value for 'swapped'
 * });
 */
export function useTicketCountsQuery(
  variables:
    | TicketCountsQueryVariables
    | VueCompositionApi.Ref<TicketCountsQueryVariables>
    | ReactiveFunction<TicketCountsQueryVariables> = {},
  options:
    | VueApolloComposable.UseQueryOptions<TicketCountsQuery, TicketCountsQueryVariables>
    | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<TicketCountsQuery, TicketCountsQueryVariables>>
    | ReactiveFunction<VueApolloComposable.UseQueryOptions<TicketCountsQuery, TicketCountsQueryVariables>> = {},
) {
  return VueApolloComposable.useQuery<TicketCountsQuery, TicketCountsQueryVariables>(TicketCountsDocument, variables, options)
}
export function useTicketCountsLazyQuery(
  variables:
    | TicketCountsQueryVariables
    | VueCompositionApi.Ref<TicketCountsQueryVariables>
    | ReactiveFunction<TicketCountsQueryVariables> = {},
  options:
    | VueApolloComposable.UseQueryOptions<TicketCountsQuery, TicketCountsQueryVariables>
    | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<TicketCountsQuery, TicketCountsQueryVariables>>
    | ReactiveFunction<VueApolloComposable.UseQueryOptions<TicketCountsQuery, TicketCountsQueryVariables>> = {},
) {
  return VueApolloComposable.useLazyQuery<TicketCountsQuery, TicketCountsQueryVariables>(TicketCountsDocument, variables, options)
}
export type TicketCountsQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<TicketCountsQuery, TicketCountsQueryVariables>
export const TicketDocument = gql`
  query Ticket($identifier: String!) {
    ticket(identifier: $identifier) {
      aasmState
      birthdate
      companyDepartment
      companyFieldId
      companyIndustryId
      companyName
      companyType
      companySize
      country
      createdAt
      deactivatedAt
      email
      firstName
      id
      identifier
      jobLevel
      lastName
      partnerName
      partnerRole
      phone
      position
      qrCodePayload
      salutation
      source
      swapped
      title
      updatedAt
      fields {
        id
        descriptionDe
        descriptionEn
        key
        name
      }
    }
  }
`

/**
 * __useTicketQuery__
 *
 * To run a query within a Vue component, call `useTicketQuery` and pass it any options that fit your needs.
 * When your component renders, `useTicketQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useTicketQuery({
 *   identifier: // value for 'identifier'
 * });
 */
export function useTicketQuery(
  variables: TicketQueryVariables | VueCompositionApi.Ref<TicketQueryVariables> | ReactiveFunction<TicketQueryVariables>,
  options:
    | VueApolloComposable.UseQueryOptions<TicketQuery, TicketQueryVariables>
    | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<TicketQuery, TicketQueryVariables>>
    | ReactiveFunction<VueApolloComposable.UseQueryOptions<TicketQuery, TicketQueryVariables>> = {},
) {
  return VueApolloComposable.useQuery<TicketQuery, TicketQueryVariables>(TicketDocument, variables, options)
}
export function useTicketLazyQuery(
  variables?: TicketQueryVariables | VueCompositionApi.Ref<TicketQueryVariables> | ReactiveFunction<TicketQueryVariables>,
  options:
    | VueApolloComposable.UseQueryOptions<TicketQuery, TicketQueryVariables>
    | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<TicketQuery, TicketQueryVariables>>
    | ReactiveFunction<VueApolloComposable.UseQueryOptions<TicketQuery, TicketQueryVariables>> = {},
) {
  return VueApolloComposable.useLazyQuery<TicketQuery, TicketQueryVariables>(TicketDocument, variables, options)
}
export type TicketQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<TicketQuery, TicketQueryVariables>
export const TicketsDocument = gql`
  query Tickets(
    $aasmState: [TicketAasmStateEnum!]
    $activated: Boolean
    $after: String
    $before: String
    $first: Int
    $invitationQuotaId: ID
    $last: Int
    $orderBy: TicketOrderAttributesEnum
    $orderDirection: OrderDirectionEnum
    $search: String
    $source: [TicketSourceEnum!]
    $swapped: Boolean
  ) {
    tickets(
      aasmState: $aasmState
      activated: $activated
      after: $after
      before: $before
      first: $first
      invitationQuotaId: $invitationQuotaId
      last: $last
      orderBy: $orderBy
      orderDirection: $orderDirection
      search: $search
      source: $source
      swapped: $swapped
    ) {
      nodes {
        aasmState
        companyName
        companyType
        createdAt
        deactivatedAt
        email
        firstName
        id
        identifier
        lastName
        phone
        position
        salutation
        source
        swapped
        title
      }
      edges {
        cursor
      }
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      totalCount
      lockedCount
      unlockedCount
    }
  }
`

/**
 * __useTicketsQuery__
 *
 * To run a query within a Vue component, call `useTicketsQuery` and pass it any options that fit your needs.
 * When your component renders, `useTicketsQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useTicketsQuery({
 *   aasmState: // value for 'aasmState'
 *   activated: // value for 'activated'
 *   after: // value for 'after'
 *   before: // value for 'before'
 *   first: // value for 'first'
 *   invitationQuotaId: // value for 'invitationQuotaId'
 *   last: // value for 'last'
 *   orderBy: // value for 'orderBy'
 *   orderDirection: // value for 'orderDirection'
 *   search: // value for 'search'
 *   source: // value for 'source'
 *   swapped: // value for 'swapped'
 * });
 */
export function useTicketsQuery(
  variables: TicketsQueryVariables | VueCompositionApi.Ref<TicketsQueryVariables> | ReactiveFunction<TicketsQueryVariables> = {},
  options:
    | VueApolloComposable.UseQueryOptions<TicketsQuery, TicketsQueryVariables>
    | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<TicketsQuery, TicketsQueryVariables>>
    | ReactiveFunction<VueApolloComposable.UseQueryOptions<TicketsQuery, TicketsQueryVariables>> = {},
) {
  return VueApolloComposable.useQuery<TicketsQuery, TicketsQueryVariables>(TicketsDocument, variables, options)
}
export function useTicketsLazyQuery(
  variables: TicketsQueryVariables | VueCompositionApi.Ref<TicketsQueryVariables> | ReactiveFunction<TicketsQueryVariables> = {},
  options:
    | VueApolloComposable.UseQueryOptions<TicketsQuery, TicketsQueryVariables>
    | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<TicketsQuery, TicketsQueryVariables>>
    | ReactiveFunction<VueApolloComposable.UseQueryOptions<TicketsQuery, TicketsQueryVariables>> = {},
) {
  return VueApolloComposable.useLazyQuery<TicketsQuery, TicketsQueryVariables>(TicketsDocument, variables, options)
}
export type TicketsQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<TicketsQuery, TicketsQueryVariables>
export const UserPropertiesPreferencesDocument = gql`
  query UserPropertiesPreferences {
    userPropertiesPreferences {
      companyDepartments {
        enum
        name
      }
      companyFields {
        id
        companyIndustryId
        translation
      }
      companyIndustries {
        id
        translation
      }
      companySizes {
        enum
        name
      }
      countries {
        code
        translations {
          en
          de
        }
      }
      companyTypes {
        enum
        name
      }
      jobLevels {
        enum
        name
      }
      salutations {
        enum
        name
      }
    }
  }
`

/**
 * __useUserPropertiesPreferencesQuery__
 *
 * To run a query within a Vue component, call `useUserPropertiesPreferencesQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserPropertiesPreferencesQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useUserPropertiesPreferencesQuery();
 */
export function useUserPropertiesPreferencesQuery(
  options:
    | VueApolloComposable.UseQueryOptions<UserPropertiesPreferencesQuery, UserPropertiesPreferencesQueryVariables>
    | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<UserPropertiesPreferencesQuery, UserPropertiesPreferencesQueryVariables>>
    | ReactiveFunction<VueApolloComposable.UseQueryOptions<UserPropertiesPreferencesQuery, UserPropertiesPreferencesQueryVariables>> = {},
) {
  return VueApolloComposable.useQuery<UserPropertiesPreferencesQuery, UserPropertiesPreferencesQueryVariables>(
    UserPropertiesPreferencesDocument,
    {},
    options,
  )
}
export function useUserPropertiesPreferencesLazyQuery(
  options:
    | VueApolloComposable.UseQueryOptions<UserPropertiesPreferencesQuery, UserPropertiesPreferencesQueryVariables>
    | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<UserPropertiesPreferencesQuery, UserPropertiesPreferencesQueryVariables>>
    | ReactiveFunction<VueApolloComposable.UseQueryOptions<UserPropertiesPreferencesQuery, UserPropertiesPreferencesQueryVariables>> = {},
) {
  return VueApolloComposable.useLazyQuery<UserPropertiesPreferencesQuery, UserPropertiesPreferencesQueryVariables>(
    UserPropertiesPreferencesDocument,
    {},
    options,
  )
}
export type UserPropertiesPreferencesQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<
  UserPropertiesPreferencesQuery,
  UserPropertiesPreferencesQueryVariables
>
export const UserDocument = gql`
  query User($userId: String!) {
    user(id: $userId) {
      __typename
      ... on User {
        ...user
        roles {
          ...role
        }
      }
    }
  }
  ${UserFragmentDoc}
  ${RoleFragmentDoc}
`

/**
 * __useUserQuery__
 *
 * To run a query within a Vue component, call `useUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useUserQuery({
 *   userId: // value for 'userId'
 * });
 */
export function useUserQuery(
  variables: UserQueryVariables | VueCompositionApi.Ref<UserQueryVariables> | ReactiveFunction<UserQueryVariables>,
  options:
    | VueApolloComposable.UseQueryOptions<UserQuery, UserQueryVariables>
    | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<UserQuery, UserQueryVariables>>
    | ReactiveFunction<VueApolloComposable.UseQueryOptions<UserQuery, UserQueryVariables>> = {},
) {
  return VueApolloComposable.useQuery<UserQuery, UserQueryVariables>(UserDocument, variables, options)
}
export function useUserLazyQuery(
  variables?: UserQueryVariables | VueCompositionApi.Ref<UserQueryVariables> | ReactiveFunction<UserQueryVariables>,
  options:
    | VueApolloComposable.UseQueryOptions<UserQuery, UserQueryVariables>
    | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<UserQuery, UserQueryVariables>>
    | ReactiveFunction<VueApolloComposable.UseQueryOptions<UserQuery, UserQueryVariables>> = {},
) {
  return VueApolloComposable.useLazyQuery<UserQuery, UserQueryVariables>(UserDocument, variables, options)
}
export type UserQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<UserQuery, UserQueryVariables>
export const UsersDocument = gql`
  query Users {
    users {
      nodes {
        ...user
        roles {
          ...role
        }
      }
      pageInfo {
        ...pageInfo
      }
    }
  }
  ${UserFragmentDoc}
  ${RoleFragmentDoc}
  ${PageInfoFragmentDoc}
`

/**
 * __useUsersQuery__
 *
 * To run a query within a Vue component, call `useUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useUsersQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useUsersQuery();
 */
export function useUsersQuery(
  options:
    | VueApolloComposable.UseQueryOptions<UsersQuery, UsersQueryVariables>
    | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<UsersQuery, UsersQueryVariables>>
    | ReactiveFunction<VueApolloComposable.UseQueryOptions<UsersQuery, UsersQueryVariables>> = {},
) {
  return VueApolloComposable.useQuery<UsersQuery, UsersQueryVariables>(UsersDocument, {}, options)
}
export function useUsersLazyQuery(
  options:
    | VueApolloComposable.UseQueryOptions<UsersQuery, UsersQueryVariables>
    | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<UsersQuery, UsersQueryVariables>>
    | ReactiveFunction<VueApolloComposable.UseQueryOptions<UsersQuery, UsersQueryVariables>> = {},
) {
  return VueApolloComposable.useLazyQuery<UsersQuery, UsersQueryVariables>(UsersDocument, {}, options)
}
export type UsersQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<UsersQuery, UsersQueryVariables>
