<template>
  <div>
    <h1 class="mt-2">{{ t('Applicants.applicantsTitle') }}</h1>

    <BackLink class="mt-4" :to="{ name: 'events' }">{{ t('backToOverview') }}</BackLink>

    <ContentCard v-if="!loading" class="mt-4">
      <EventItem ref="eventItem" :event="event" :show-main-button="false" :show-chip="false" :show-dropdown="false">
        <template #additionalEventProperties>
          <div class="flex items-center flex-start content-baseline">
            <SvgIcon :path="mdiAccountCheck" :size="16" class="mr-2" />
            <span class="text-xs">{{ attendeeCounts.accepted + attendeeCounts.acceptedSuccessor }}</span
            >&nbsp;<span v-if="event.maxAttendees" class="text-xs"> / {{ event.maxAttendees }}</span>
          </div>
        </template>
        <template #customButton>
          <button :disabled="!hasRemainingSlots" class="btn-secondary-purple-m" @click="showManualAttendeeModal = true">
            <SvgIcon :path="mdiPlusCircleOutline" :size="16" class="mr-2" />
            {{ t('attendee.addAttendeesButton') }}
          </button>
        </template>
      </EventItem>

      <div v-if="attendeeCsvUpdateValues">
        <AlertBox v-if="attendeeCsvUpdateValues.approved || attendeeCsvUpdateValues.rejected" class="mt-6" type="success">
          <template #header>{{
            t('Applicants.manualApplicantUpload.successfulUpdateTitle', {
              number: attendeeCsvUpdateValues.approved + attendeeCsvUpdateValues.rejected,
            })
          }}</template>
          {{
            t('Applicants.manualApplicantUpload.successfulUpdateDescription', {
              numberAccepted: attendeeCsvUpdateValues.approved,
              numberDenied: attendeeCsvUpdateValues.rejected,
            })
          }}
        </AlertBox>
        <AlertBox v-if="attendeeCsvUpdateValues.failed" class="mt-6" :icon-path="mdiAlertOctagon" type="danger">
          <template #header>{{
            t('Applicants.manualApplicantUpload.failedUpdateTitle', {
              number: attendeeCsvUpdateValues.failed,
            })
          }}</template>
          {{ t('Applicants.manualApplicantUpload.failedUpdateDescription') }}
        </AlertBox>
      </div>

      <div v-if="attendeesLoading">
        <ProgressBar />
      </div>

      <div v-if="!attendeesLoading && attendeeCounts.total == 0" class="text-center pt-12 pb-12">
        <h3>{{ t('Applicants.noApplicantsTitle') }}</h3>
        <p v-html="t('Applicants.noApplicantsText')"></p>
      </div>

      <AlertBox v-if="isSelectionClosed" type="success" class="mt-4">
        <template #header>
          {{ t('Applicants.attendeeSectionClosedAlertHeadline') }}
        </template>
        <div>
          {{ t('Applicants.attendeeSectionClosedAlertBody') }}
        </div>
      </AlertBox>

      <div v-if="attendeeCounts.total != 0" class="mt-6 flex flex-row gap-2 items-center">
        <FilterDropDown :states="aasmStates" :filter-status="filterStatus" @change="filterStatus = $event" />
        <FilterDropDown
          :title="$t('attendee.sorting.title')"
          :show-counts="false"
          :states="sortingStates"
          :filter-status="sortStatus"
          @change="sortStatus = $event"
        />
        <button v-if="filtersAreApplied" class="btn-text-purple-m" @click="resetFilters">{{ $t('attendee.sorting.resetFilters') }}</button>

        <div class="grow"></div>

        <span
          v-if="selectedAttendeeIds.length"
          :class="{ 'text-amaranth': selectLimitReached }"
          v-html="
            t('Applicants.applicantsSelectedText', {
              selected: selectedAttendeeIds.length,
              total: attendeeCounts.total,
            })
          "
        ></span>

        <button
          v-if="!isSelectionClosed"
          :disabled="!selectedAttendeeIds.length || allSelectedAttendeesAreAccepted"
          class="btn-secondary-mint-m"
          @click="bulkAcceptAttendees"
        >
          <SvgIcon :path="mdiCheck" :size="16" class="mr-2" />
          {{ t('attendee.accept') }}
        </button>

        <ConfirmModal
          v-if="selectLimitReachedWarningVisible"
          :show-cancel="false"
          :approve="t('ok')"
          @close-dialog="selectLimitReachedWarningVisible = false"
        >
          <template #title>
            <div class="flex justify-between">
              <div class="p-6 flex justify-start text-[#FA0000]">
                <SvgIcon :path="mdiAlertCircle" :size="32" class="mr-2" />
                <p class="text-2xl mb-0">
                  {{ t('Applicants.AttendeeLimitReachedModal.title') }}
                </p>
              </div>
              <div class="text-grey-800 cursor-pointer" @click="selectLimitReachedWarningVisible = false">
                <SvgIcon :path="mdiClose" :size="20" class="mr-6 mt-6" />
              </div>
            </div>
          </template>

          <template #description>
            <div class="px-6 text-base">
              {{ t('Applicants.AttendeeLimitReachedModal.body') }}
            </div>
          </template>
        </ConfirmModal>

        <button
          v-if="!isSelectionClosed"
          :disabled="!selectedAttendeeIds.length || allSelectedAttendeesAreDenied"
          class="btn-secondary-error-m px-3"
          @click="bulkDenyAttendees"
        >
          <SvgIcon :path="mdiClose" :size="16" class="mr-2" />
          {{ t('attendee.deny') }}
        </button>
      </div>
      <TableLight v-if="attendeeCounts.total != 0" class="mt-6 mb-6 w-full">
        <thead>
          <tr class="text-black uppercase">
            <th v-if="!isSelectionClosed" class="text-start">
              <div class="flex items-center pl-2">
                <CheckBox :model-value="allSelected" class="text-grey-800" @update:model-value="updateSelection" />
              </div>
            </th>
            <th class="text-start">{{ t('Applicants.company') }}</th>
            <th class="text-start">{{ t('Applicants.ticketNumber') }}</th>
            <th class="text-start">{{ t('Applicants.position') }}</th>
            <th class="text-start">
              <div class="px-2 flex flex-start">
                <span>{{ t('Applicants.status') }}</span>
                <SvgIcon class="ml-1 cursor-pointer" :path="mdiInformationOutline" @click="showHintModal = true" />
              </div>
            </th>
            <th></th>
          </tr>
          <AttendeeStatusHintModal v-if="showHintModal" @close-dialog="showHintModal = false" />
        </thead>
        <tbody>
          <EventApplicationTableRow
            v-for="attendee in paginagedAttendees"
            :key="`attendee-${attendee.id}`"
            :attendee="attendee"
            :selected-attendee-ids="selectedAttendeeIds"
            :is-selection-closed="isSelectionClosed"
            @select="selectedAttendeeIds.push($event)"
            @unselect="selectedAttendeeIds = selectedAttendeeIds.filter((item) => item !== $event)"
          />
        </tbody>
      </TableLight>

      <div class="mx-auto mt-6 mb-4">
        <PaginationControl
          :total="displayedAttendees.length"
          :page="page"
          :per-page="perPage"
          @update:page="page = $event"
          @update:per-page="perPage = $event"
        />
      </div>

      <div v-if="attendeeCounts.total != 0" class="mt-4 mb-6 flex flex-row gap-2 items-center">
        <div class="grow">
          <p>
            {{ t('Applicants.manualApplicantUpload.text') }}
            <RouterLink :to="{ name: 'AttendeeUpload' }" class="text-black underline"
              >{{ t('Applicants.manualApplicantUpload.cta') }}.</RouterLink
            >
          </p>
        </div>

        <DropDown wrapper-class="w-full">
          <template #activator="{ open }">
            <button class="btn-secondary-purple-m uppercase" @click.prevent="open">
              {{ t('exportCta') }}
            </button>
          </template>
          <template #default>
            <ul class="list-none p-0 m-0 divide-solid divide-y divide-grey-300">
              <li v-for="format in exportFormats" :key="`format-${format}`" class="text-left py-2 px-2 cursor-pointer hover:bg-grey-100">
                <a :href="eventApplicantsDownloadUrl(plainEventId, locale, format)" class="text-black visited:text-black flex">
                  {{ t(`exportFormats.${format}`) }}
                </a>
              </li>
            </ul>
          </template>
        </DropDown>

        <button
          v-if="!isSelectionClosed"
          :disabled="!canCloseAttendeeSelection"
          class="btn-primary-purple-m"
          @click="eventCloseConfirmationVisible = true"
        >
          {{ t('Applicants.closeAttendeeSectionCTA') }}
        </button>
        <ConfirmModal
          v-if="eventCloseConfirmationVisible"
          :title="t('EventCloseConfirmation.headline')"
          :description="t('EventCloseConfirmation.body')"
          @close-dialog="$event ? closeAttendeeSelection() : (eventCloseConfirmationVisible = false)"
        />
      </div>
    </ContentCard>
    <ManualEventApplicationModal
      v-if="showManualAttendeeModal"
      :max-attendees="remainingSlots"
      @close-dialog="showManualAttendeeModal = false"
      @confirm-dialog="handleAttendeesByTicketId"
    />
    <AddedAttendeeListModal
      v-if="showAddedAttendeeListModal"
      :attendees-added="manualAddedTickets"
      @close-dialog="handleCloseAddedAttendeeList"
    />
  </div>
</template>

<script setup lang="ts">
import { computed, ref, watch } from 'vue'
import type { AddAttendeesByTicketIdentifierMutation, EventQuery } from '@/gql/myomr'
import { toGlobalId, toTypeAndId } from '@/helpers/globalIdHelper'
import { EventItem } from '@/components/EventsInvites'
import { showNotification } from '@/helpers/notificationHelper'
import { eventApplicantsDownloadUrl, exportFormats } from '@/services/ApiService'
import { mdiAccountCheck, mdiAlertCircle, mdiCheck, mdiClose, mdiInformationOutline, mdiPlusCircleOutline, mdiAlertOctagon } from '@mdi/js'
import { useRoute } from 'vue-router'
import { useI18n } from 'vue-i18n'
import {
  AttendeeOrderAttributesEnum,
  OrderDirectionEnum,
  useAcceptAttendeeMutation,
  useAddAttendeesByTicketIdentifierMutation,
  useAttendeesQuery,
  useCloseAttendeeSelectionMutation,
  useDenyAttendeeMutation,
} from '@/gql/myomr'
import AttendeeStatusHintModal from './AttendeeStatusHintModal.vue'
import { TableLight } from '@/ui/TableLight'
import type { UpdateCsvAttendeeValue } from './AttendeeUpload.vue'
import { DropDown } from '@ramp106/omrjs-core-ui'

const { locale, t } = useI18n()
const route = useRoute()

const props = defineProps<{
  event: EventQuery['eventById']
  loading: boolean
  attendeeCsvUpdateValues: UpdateCsvAttendeeValue | null
}>()

const {
  result: attendeesQueryResult,
  loading: attendeesLoading,
  refetch: attendeesRefetch,
} = useAttendeesQuery(
  {
    eventId: toGlobalId('Event', route.params.eventId as string),
    orderBy: AttendeeOrderAttributesEnum.CreatedAt,
    orderDirection: OrderDirectionEnum.Desc,
  },
  { fetchPolicy: 'no-cache' },
)
const attendees = computed(() => attendeesQueryResult.value?.attendees?.nodes || [])

const showManualAttendeeModal = ref(false)

const { mutate: acceptAttendeeMutation } = useAcceptAttendeeMutation()
const { mutate: denyAttendeeMutation } = useDenyAttendeeMutation()
const { mutate: closeAttendeeSelectionMutation } = useCloseAttendeeSelectionMutation()
const { mutate: addAttendeesByTicketId } = useAddAttendeesByTicketIdentifierMutation()

const displayedAttendees = computed(() => {
  if (filterStatus.value) {
    if (filterStatus.value == 'acceptedSuccessor') {
      return attendees.value.filter((attendee) => attendee.aasmState == 'accepted' && attendee.forcedAllocation)
    } else if (filterStatus.value == 'accepted') {
      return attendees.value.filter((attendee) => attendee.aasmState == 'accepted' && attendee.forcedAllocation == false)
    } else {
      return attendees.value.filter((attendee) => attendee.aasmState == filterStatus.value)
    }
  } else {
    return attendees.value
  }
})
const paginagedAttendees = computed(() => displayedAttendees.value.slice(offset.value, offset.value + perPage.value))

const page = ref(1)
const perPage = ref(10)
const offset = computed(() => (page.value - 1) * perPage.value)

const acceptedAttendees = computed(() => attendees.value.filter((attendee) => attendee.aasmState == 'accepted'))
const acceptedAttendeeIds = computed(() => acceptedAttendees.value.map((attendee) => attendee.id))
const deniedAttendees = computed(() => attendees.value.filter((attendee) => attendee.aasmState == 'denied'))
const deniedAttendeeIds = computed(() => deniedAttendees.value.map((attendee) => attendee.id))

const filtersAreApplied = computed(() => {
  return filterStatus.value != '' || sortStatus.value != AttendeeOrderAttributesEnum.CreatedAt
})

function resetFilters() {
  filterStatus.value = ''
  sortStatus.value = AttendeeOrderAttributesEnum.CreatedAt
}

const sortStatus = ref<AttendeeOrderAttributesEnum>(AttendeeOrderAttributesEnum.CreatedAt)
watch([sortStatus], () => {
  page.value = 1
  attendeesRefetch({
    eventId: toGlobalId('Event', route.params.eventId as string),
    orderBy: sortStatus.value,
    orderDirection: sortStatus.value == AttendeeOrderAttributesEnum.CreatedAt ? OrderDirectionEnum.Desc : OrderDirectionEnum.Asc,
  })
})

const filterStatus = ref('')
watch([filterStatus, perPage], () => {
  page.value = 1
})
watch([page], () => {
  allSelected.value = false
  selectedAttendeeIds.value = []
})
const selectedAttendeeIds = ref<Array<string>>([])
const allSelectedAttendeesAreAccepted = computed(() => selectedAttendeeIds.value.every((id) => acceptedAttendeeIds.value.includes(id)))
const allSelectedAttendeesAreDenied = computed(() => selectedAttendeeIds.value.every((id) => deniedAttendeeIds.value.includes(id)))

const attendeeCounts = computed(() => {
  const attendeeData = attendeesQueryResult.value?.attendees

  return {
    accepted: attendeeData?.acceptedCount || 0,
    denied: attendeeData?.deniedCount || 0,
    requested: attendeeData?.requestedCount || 0,
    acceptedSuccessor: attendeeData?.acceptedSuccessorCount || 0,
    total: attendeeData?.totalCount || 0,
  }
})

type AddedTicketType = NonNullable<NonNullable<AddAttendeesByTicketIdentifierMutation['addAttendeesByTicketIdentifier']>['results']>

const manualAddedTickets = ref<AddedTicketType>()
const showAddedAttendeeListModal = ref(false)

async function handleAttendeesByTicketId(ticketsIds: Array<string>) {
  if (!ticketsIds.length) return (showManualAttendeeModal.value = false)
  try {
    const result = await addAttendeesByTicketId({ eventId: props.event.id, ticketIdentifiers: ticketsIds })
    if (result?.data?.addAttendeesByTicketIdentifier?.results) manualAddedTickets.value = result.data.addAttendeesByTicketIdentifier.results
    showAddedAttendeeListModal.value = true
  } catch (e) {
    console.log(e)
  }
  showManualAttendeeModal.value = false
  attendeesRefetch()
}

function handleCloseAddedAttendeeList(addMore: boolean) {
  showAddedAttendeeListModal.value = false
  if (addMore) showManualAttendeeModal.value = true
}

async function bulkAcceptAttendees() {
  if (selectLimitReached.value) {
    selectLimitReachedWarningVisible.value = true
    return
  }
  await Promise.allSettled(
    selectedAttendeeIds.value
      .filter((id) => !acceptedAttendeeIds.value.includes(id))
      .map((attendeeId) => {
        return acceptAttendeeMutation({
          attendeeId: attendeeId,
        })
      }),
  )

  afterBulkMutation()
}
async function bulkDenyAttendees() {
  await Promise.allSettled(
    selectedAttendeeIds.value
      .filter((id) => !deniedAttendeeIds.value.includes(id))
      .map((attendeeId) => {
        return denyAttendeeMutation({
          attendeeId: attendeeId,
        })
      }),
  )

  afterBulkMutation()
}

async function afterBulkMutation() {
  attendeesRefetch()
  showNotification(t('EventApplications.statusSavedSuccessfully'), 'success', 3000)
  selectedAttendeeIds.value = []
  allSelected.value = false
}

const eventCloseConfirmationVisible = ref(false)
const canCloseAttendeeSelection = computed(() => {
  return !isSelectionClosed.value && !acceptLimitReached.value
})
async function closeAttendeeSelection() {
  await closeAttendeeSelectionMutation({
    eventId: toGlobalId('Event', route.params.eventId as string),
  })
  eventCloseConfirmationVisible.value = false
  showNotification(t('EventCloseConfirmation.successMessage'), 'success', 3000)
}

const selectLimitReachedWarningVisible = ref(false)

const aasmStates = computed(() => [
  { label: t('attendee.requested'), count: attendeeCounts.value.requested, value: 'requested' },
  { label: t('attendee.denied'), count: attendeeCounts.value.denied, value: 'denied' },
  { label: t('attendee.accepted'), count: attendeeCounts.value.accepted, value: 'accepted' },
  { label: t('attendee.acceptedSuccessor'), count: attendeeCounts.value.acceptedSuccessor, value: 'acceptedSuccessor' },
  { label: t('attendee.all'), count: attendeeCounts.value.total, value: '' },
])

const sortingStates = computed(() => [
  { label: t('attendee.sorting.company_name'), value: AttendeeOrderAttributesEnum.CompanyName },
  { label: t('attendee.sorting.position'), value: AttendeeOrderAttributesEnum.CompanyPosition },
  { label: t('attendee.sorting.createdAt'), value: AttendeeOrderAttributesEnum.CreatedAt },
])

const remainingSlots = computed(() => {
  if (props.event.maxAttendees === null || props.event.maxAttendees === undefined) return 10000
  return props.event.maxAttendees - (attendeeCounts.value.accepted + attendeeCounts.value.acceptedSuccessor)
})

const hasRemainingSlots = computed(() => {
  return remainingSlots.value > 0
})

const plainEventId = computed(() => {
  return (props.event && toTypeAndId(props.event.id)['id']) || ''
})

const isSelectionClosed = computed(() => {
  return props.event && props.event.attendeeSelectionClosed
})

const selectLimitReached = computed(() => {
  if (!props.event.maxAttendees) {
    return false
  }

  const acceptedAttendeeIds = acceptedAttendees.value.map((attendee) => attendee.id)
  const unacceptedSelectedAttendeeIds = selectedAttendeeIds.value.filter((attendeeId) => !acceptedAttendeeIds.includes(attendeeId))
  return (
    unacceptedSelectedAttendeeIds.length != 0 &&
    unacceptedSelectedAttendeeIds.length > props.event.maxAttendees - acceptedAttendees.value.length
  )
})

const acceptLimitReached = computed(() => {
  if (!props.event.maxAttendees) {
    return false
  }

  return acceptedAttendees.value.length > props.event.maxAttendees
})

const showHintModal = ref(false)

const allSelected = ref(false)
function updateSelection(value: boolean) {
  allSelected.value = value
  if (value) {
    selectedAttendeeIds.value = paginagedAttendees.value.map((attendee) => attendee.id)
  } else {
    selectedAttendeeIds.value = []
  }
}
</script>
