<template>
  <div class="w-7 h-7 relative">
    <div>
      <svg xmlns="http://www.w3.org/2000/svg" width="29" height="29" viewBox="0 0 29 29" fill="none">
        <circle cx="14.5" cy="14.9092" r="13.5" fill="white" stroke="#ECEDEE" />
      </svg>
    </div>
    <div class="absolute inset-0 flex justify-center items-center">
<svg xmlns="http://www.w3.org/2000/svg" width="16" height="15" viewBox="0 0 16 15" fill="none">
  <path
    d="M1.83334 15.5758V3.57577C1.83334 3.2091 1.9639 2.89521 2.22501 2.6341C2.48612 2.37299 2.80001 2.24243 3.16668 2.24243H13.8333C14.2 2.24243 14.5139 2.37299 14.775 2.6341C15.0361 2.89521 15.1667 3.2091 15.1667 3.57577V11.5758C15.1667 11.9424 15.0361 12.2563 14.775 12.5174C14.5139 12.7785 14.2 12.9091 13.8333 12.9091H4.50001L1.83334 15.5758ZM3.93334 11.5758H13.8333V3.57577H3.16668V12.3258L3.93334 11.5758Z"
    fill="#D4D6D9"
  />
</svg>
    </div>
  </div>
</template>
