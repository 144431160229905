import { useMeStore } from '@/stores'
import type { Role } from '@/gql/myomr'

export abstract class ApplicationPolicy {
  public static roles() {
    const meStore = useMeStore()
    return meStore.currentRoles as Role[]
  }

  public static currentPartnerCompanyId() {
    const meStore = useMeStore()
    return meStore.currentPartnerCompanyId
  }

  public static isUser() {
    return !!this.currentPartnerCompanyId() && this.roles() && this.roles().length > 0
  }

  public static hasManageRole(roleName: string) {
    const hasRole = this.roles().find((role) => {
      return role.name === roleName && role.resourceType === 'PartnerCompany' && role.resourceId === this.currentPartnerCompanyId()
    })

    return !!hasRole
  }

  // helper methods
  public static hasDashboardAccess() {
    return this.hasManageRole('manage__dashboard')
  }

  public static hasContentPagesAccess() {
    return this.hasManageRole('manage__content_pages')
  }

  public static hasUserManagementAccess() {
    return this.hasManageRole('manage__user_management')
  }

  public static hasReviewsManagementAccess() {
    return this.hasManageRole('manage__reviews_management')
  }

  public static hasSpeakersAccess() {
    return this.hasManageRole('manage__speakers')
  }

  public static hasEventsOrQuotaAccess() {
    const quotaUserRole = this.roles().find((role) => {
      return role.name === 'quota_user'
    })

    return this.hasEventsAccess() || !!quotaUserRole
  }

  public static hasEventsAccess() {
    return this.hasManageRole('manage__events')
  }

  public static hasTicketsAccess() {
    const ticketsRole = this.hasManageRole('manage__tickets')
    const quotaUserRole = this.roles().find((role) => role.name === 'quota_user')

    return ticketsRole || quotaUserRole
  }

  public static hasCompanyProfileAccess() {
    return this.hasManageRole('manage__company_profile')
  }

  public static hasBoothsAccess() {
    return this.hasManageRole('manage__booths')
  }

  public static hasMyLeadsAccess() {
    return this.hasManageRole('manage__my_leads')
  }

  public static hasLeadGenerationRole() {
    return this.hasManageRole('manage__lead_generation')
  }

  public static hasLeadScannerRole() {
    return this.hasManageRole('manage__lead_scanner')
  }
}
