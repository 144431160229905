<template>
  <PageBreadcrumbs :items="breadcrumbs" />
  <ContentCard>
    <template #subheader>
      <ReviewsProductReviewDetailsSubheader v-if="surveyResponse" :survey-response="surveyResponse" @send-reply="openReplyDialog" />
    </template>
    <div v-if="surveyResponse">
      <ReviewsProductReviewDetails
        :product="product"
        :survey-response="surveyResponse"
        :reminder-disabled="surveyResponse.reminderDisabled"
        @send-reminder="openReminderDialog"
        @edit-reply="openReplyDialog"
        @delete-reply="handleDeleteReviewReply"
      />
      <SendReminderDialog ref="sendReminderModal" :product="product" :survey-response="surveyResponse" @closed="refetch" />
      <ReviewReplyDialog
        v-if="surveyResponse.review?.id"
        ref="reviewReplyModal"
        :review-id="surveyResponse.review.id"
        :review-reply="surveyResponse.review.reviewReply"
        :product-slug="product.slug"
        :product-title="product.title"
        :reviewer-name="reviewerName"
        @closed="refetch"
      />
      <ReviewReplyConfirmModal
        :show-confirmation-modal="showDeleteConfirmationModal"
        @canceled="onDeleteReviewReply(false)"
        @confirmed="onDeleteReviewReply(true)"
      />
    </div>
  </ContentCard>
</template>
<script setup lang="ts">
import { computed, ref } from 'vue'
import { useRoute } from 'vue-router'
import { useI18n } from 'vue-i18n'
import { type ProductById, useDeleteReviewsProductReviewReplyMutation, useReviewsProductSurveyResponseDetailsQuery } from '@/gql/reviews'
import ContentCard from '@/ui/ContentCard.vue'
import ReviewsProductReviewDetailsSubheader from './ReviewsProductReviewDetailsSubheader.vue'
import ReviewsProductReviewDetails from './ReviewsProductReviewDetails.vue'
import SendReminderDialog from '@/components/ReviewsManagement/Reviews/SendReminderDialog.vue'
import ReviewReplyDialog from '@/components/ReviewsManagement/Reviews/ReviewReplyDialog.vue'
import ReviewReplyConfirmModal from './ReviewReplyConfirmModal.vue'
import { showNotification } from '@/helpers/notificationHelper'
import PageBreadcrumbs from '@/components/PageBreadcrumbs.vue'
import { useReviewsRootBreadcrumb } from '@/composables/breadcrumbs/useReviewsRootBreadcrumb.ts'
import { useReviewsProductReviewsBreadcrumb } from '@/composables/breadcrumbs/useReviewsProductReviewsBreadcrumb.ts'

const props = defineProps<{
  product: ProductById
}>()

const route = useRoute()
const { t } = useI18n()

const sendReminderModal = ref<InstanceType<typeof SendReminderDialog> | null>(null)
const reviewReplyModal = ref<InstanceType<typeof SendReminderDialog> | null>(null)

const showDeleteConfirmationModal = ref(false)

const variables = computed(() => ({ id: route.params.surveyResponseId as string }))
const { result, refetch } = useReviewsProductSurveyResponseDetailsQuery(variables, { clientId: 'reviews' })

const { mutate: mutateDeleteReviewReply } = useDeleteReviewsProductReviewReplyMutation({ clientId: 'reviews' })

const surveyResponse = computed(() => result.value?.manage?.surveyResponse)

const reviewerName = computed(() => surveyResponse.value?.reviewerName || undefined)

const reviewsRoot = useReviewsRootBreadcrumb()
const reviewsProductViewReviews = useReviewsProductReviewsBreadcrumb(props.product)

const breadcrumbs = computed(() => {
  return [
    reviewsRoot.value,
    reviewsProductViewReviews.value,
    {
      text: t('breadcrumbs.reviews.product.view.reviewDetails', { authorName: reviewerName.value || 'Anonym' }),
    },
  ]
})

function openReminderDialog() {
  sendReminderModal.value?.open()
}

function openReplyDialog() {
  reviewReplyModal.value?.open()
}

function handleDeleteReviewReply() {
  showDeleteConfirmationModal.value = true
}

async function onDeleteReviewReply(confirmed: boolean) {
  if (surveyResponse.value?.review?.reviewReply?.id && confirmed) {
    try {
      await mutateDeleteReviewReply({ id: surveyResponse.value.review.reviewReply.id })
      refetch()
      showNotification(t('Reviews.reviewReply.delete.success'), 'success', 10000)
    } catch (e) {
      showNotification(t('Reviews.reviewReply.delete.error'), 'success', 10000)
    }
  }
  showDeleteConfirmationModal.value = false
}
</script>
