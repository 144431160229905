import { computed, type Ref } from 'vue'
import { type RouteLocationAsRelativeGeneric, useRoute } from 'vue-router'
import type { BreadcrumbItem } from '@/components/PageBreadcrumbs.vue'

export const useBreadcrumb = (to: RouteLocationAsRelativeGeneric, text: string): Ref<BreadcrumbItem> => {
  const route = useRoute()

  return computed(() => ({
    to: route.name === to.name ? undefined : to,
    text,
  }))
}
