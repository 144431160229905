<template>
  <th
    class="py-4 text-start"
    :class="{
      'cursor-pointer': !!sortParams,
    }"
    @click="toggleSort"
  >
    <div class="flex">
      {{ label }}
      <MdiSvg
        v-if="sortIcon"
        :path="sortIcon"
        size="16"
        class="ml-1"
        :class="{
          'text-grey-300': !active,
        }"
      />
    </div>
  </th>
</template>

<script setup lang="ts" generic="SortKey extends string, SortDir extends SortDirection">
import { computed } from 'vue'
import { MdiSvg } from '@ramp106/omrjs-core-ui'
import { mdiMenuUp, mdiMenuDown } from '@mdi/js'
import type { SortParams, SortDirection } from './types.ts'

const props = defineProps<{
  label: string
  sortParams?: SortParams<SortKey, SortDir>
}>()

const emit = defineEmits<{
  (e: 'sort', params: SortParams<SortKey, SortDir>): void
}>()

const sortIcon = computed(() => {
  if (!props.sortParams) {
    return null
  }
  return props.sortParams.sortDirection === 'asc' ? mdiMenuUp : mdiMenuDown
})

const active = computed(() => !!props.sortParams?.sortDirection)

function toggleSort() {
  if (!props.sortParams) {
    return
  }

  const newDirection = (props.sortParams.sortDirection === 'asc' ? 'desc' : 'asc') as SortDir
  emit('sort', { sortBy: props.sortParams.sortBy, sortDirection: newDirection })
}
</script>
