import { computed, type Ref } from 'vue'
import { useRoute } from 'vue-router'
import { useI18n } from 'vue-i18n'
import { ReviewsRouteName } from '@/router/types.ts'
import type { BreadcrumbItem } from '@/components/PageBreadcrumbs.vue'
import type { ProductById } from '@/gql/reviews'
import { useReviewsRootBreadcrumb } from './useReviewsRootBreadcrumb.ts'
import { useReviewsProductReviewsBreadcrumb } from './useReviewsProductReviewsBreadcrumb.ts'
import { useBreadcrumb } from './useBreadcrumb.ts'

export const useReviewsProductBreadcrumbs = (product: Pick<ProductById, 'id' | 'title'>): Ref<BreadcrumbItem[]> => {
  const route = useRoute()
  const { t } = useI18n()

  const params = { productId: product.id }
  const productName = product.title

  const reviewsRoot = useReviewsRootBreadcrumb()
  const reviewsProductViewReviews = useReviewsProductReviewsBreadcrumb(product)

  const reviewsProductViewDetails = useBreadcrumb(
    { name: ReviewsRouteName.ReviewsProductViewDetails, params },
    t('breadcrumbs.reviews.product.view.details', { productName }),
  )

  const reviewsProductEditDetails = useBreadcrumb(
    { name: ReviewsRouteName.ReviewsProductEditDetails, params },
    t('breadcrumbs.reviews.product.edit.details', { productName }),
  )

  const reviewsProductViewGlobalFeatures = useBreadcrumb(
    { name: ReviewsRouteName.ReviewsProductViewGlobalFeatures, params },
    t('breadcrumbs.reviews.product.view.globalFeatures', { productName }),
  )

  const reviewsProductEditGlobalFeatures = useBreadcrumb(
    { name: ReviewsRouteName.ReviewsProductEditGlobalFeatures, params },
    t('breadcrumbs.reviews.product.edit.globalFeatures', { productName }),
  )

  const reviewsProductViewPricing = useBreadcrumb(
    { name: ReviewsRouteName.ReviewsProductViewPricing, params },
    t('breadcrumbs.reviews.product.view.pricing', { productName }),
  )

  const reviewsProductEditPricing = useBreadcrumb(
    { name: ReviewsRouteName.ReviewsProductEditPricing, params },
    t('breadcrumbs.reviews.product.edit.pricing', { productName }),
  )

  const reviewsProductViewAssets = useBreadcrumb(
    { name: ReviewsRouteName.ReviewsProductViewAssets, params },
    t('breadcrumbs.reviews.product.view.assets', { productName }),
  )

  const reviewsProductEditAssets = useBreadcrumb(
    { name: ReviewsRouteName.ReviewsProductEditAssets, params },
    t('breadcrumbs.reviews.product.edit.assets', { productName }),
  )

  return computed(() => {
    const breadcrumbsMap: Partial<Record<ReviewsRouteName, BreadcrumbItem[]>> = {
      [ReviewsRouteName.ReviewsProductView]: [],
      [ReviewsRouteName.ReviewsProductEdit]: [],
      [ReviewsRouteName.ReviewsProducts]: [reviewsRoot.value],
      [ReviewsRouteName.ReviewsProductViewDetails]: [reviewsRoot.value, reviewsProductViewDetails.value],
      [ReviewsRouteName.ReviewsProductEditDetails]: [reviewsRoot.value, reviewsProductViewDetails.value, reviewsProductEditDetails.value],
      [ReviewsRouteName.ReviewsProductViewGlobalFeatures]: [reviewsRoot.value, reviewsProductViewGlobalFeatures.value],
      [ReviewsRouteName.ReviewsProductEditGlobalFeatures]: [
        reviewsRoot.value,

        reviewsProductViewGlobalFeatures.value,
        reviewsProductEditGlobalFeatures.value,
      ],
      [ReviewsRouteName.ReviewsProductViewPricing]: [reviewsRoot.value, reviewsProductViewPricing.value],
      [ReviewsRouteName.ReviewsProductEditPricing]: [reviewsRoot.value, reviewsProductViewPricing.value, reviewsProductEditPricing.value],
      [ReviewsRouteName.ReviewsProductViewAssets]: [reviewsRoot.value, reviewsProductViewAssets.value],
      [ReviewsRouteName.ReviewsProductEditAssets]: [reviewsRoot.value, reviewsProductViewAssets.value, reviewsProductEditAssets.value],
      [ReviewsRouteName.ReviewsProductViewReviews]: [reviewsRoot.value, reviewsProductViewReviews.value],
    }

    return breadcrumbsMap[route.name as ReviewsRouteName] || []
  })
}
