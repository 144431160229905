<template>
  <PageBreadcrumbs :items="breadcrumbs" />
  <ContentCard>
    <template #header>
      <ReviewsProductTabs />
    </template>
    <template #subheader>
      <RouterView :product="product" name="subheader" />
    </template>
    <RouterView :product="product" />
  </ContentCard>
</template>
<script setup lang="ts">
import type { ProductById } from '@/gql/reviews'
import ContentCard from '@/ui/ContentCard.vue'
import ReviewsProductTabs from './ReviewsProductTabs.vue'
import PageBreadcrumbs from '@/components/PageBreadcrumbs.vue'
import { useReviewsProductBreadcrumbs } from '@/composables/breadcrumbs/useReviewsProductBreadcrumbs.ts'

const props = defineProps<{
  product: ProductById
}>()

const breadcrumbs = useReviewsProductBreadcrumbs(props.product)
</script>
