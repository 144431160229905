<template>
  <div class="py-6">
    <ReviewsTable :loading="loading" :product-id="product.id" :survey-responses="result?.manage?.surveyResponses?.results || []" />
    <PaginationControl
      :page="pagination.page"
      :per-page="pagination.perPage"
      :total="pagination.total"
      show-range
      show-total
      show-pagination="always"
      @update:page="updatePage"
      @update:per-page="updatePerPage"
    />
    <WorkInProgressHint context="reviews" />
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import { type ProductData, SurveyResponsesSortByEnum, SortDirectionEnum, useReviewsProductSurveyResponsesQuery } from '@/gql/reviews'
import { useRouterQueryPagination } from '@/composables/useRouterQueryPagination'
import WorkInProgressHint from '@/components/ReviewsManagement/WorkInProgressHint.vue'
import { useSortQuery } from '@/ui/TableLight'
import ReviewsTable from './ReviewsTable.vue'
import PaginationControl from '../../PaginationControl.vue'

const props = defineProps<{
  product: ProductData
}>()

const { page, perPage, updatePage, updatePerPage } = useRouterQueryPagination()
const { sortBy, sortDirection } = useSortQuery<SurveyResponsesSortByEnum, SortDirectionEnum>()

const variables = computed(() => ({
  slug: props.product.slug,
  page: page.value,
  perPage: perPage.value,
  sortBy: sortBy.value,
  sortDirection: sortDirection.value,
}))

const { loading, result } = useReviewsProductSurveyResponsesQuery(variables, { clientId: 'reviews' })

const pagination = computed(() => ({
  page: result.value?.manage?.surveyResponses?.pagination.currentPage ?? page.value,
  perPage: result.value?.manage?.surveyResponses?.pagination.perPage ?? perPage.value,
  total: result.value?.manage?.surveyResponses?.pagination.totalCount ?? result.value?.manage?.surveyResponses?.results.length ?? 0,
}))
</script>
