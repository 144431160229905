<template>
  <div class="w-7 h-7 relative">
    <div>
      <svg xmlns="http://www.w3.org/2000/svg" width="29" height="29" viewBox="0 0 29 29" fill="none">
        <circle cx="14.5" cy="14.7273" r="13.5" fill="#F3F4F6" stroke="#D4D6D9" />
      </svg>
    </div>
    <div class="absolute inset-0 flex justify-center items-center">
      <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 17 17" fill="none">
        <path
          d="M10.5987 4.0582C10.1689 3.76498 9.68389 3.54696 9.16306 3.42306V2.96973C9.16306 2.70451 9.05776 2.45016 8.87033 2.26262C8.68289 2.07508 8.42868 1.96973 8.1636 1.96973C7.89853 1.96973 7.64431 2.07508 7.45688 2.26262C7.26944 2.45016 7.16414 2.70451 7.16414 2.96973V3.42306C5.25185 3.87639 3.83261 5.58973 3.83261 7.63639V11.3031L2.5 12.6364V13.3031H13.8272V12.6364L12.4946 11.3031V9.9618L11.162 11.2951V11.9697H5.16523V7.63639C5.16523 5.96973 6.49784 4.63639 8.1636 4.63639C8.70103 4.63639 9.20377 4.77518 9.63827 5.01916L10.5987 4.0582Z"
          fill="#6E737D"
        />
        <path
          d="M15.0926 4.53397L14.0281 3.4689L8.67217 8.82773L7.10367 7.25838L6.03821 8.32441L8.67121 10.9588L15.0926 4.53397Z"
          fill="#6E737D"
        />
        <path
          d="M8.1636 15.3031C8.51704 15.3031 8.85599 15.1626 9.1059 14.9125C9.35582 14.6625 9.49622 14.3233 9.49622 13.9697H6.83099C6.83099 14.3233 6.97139 14.6625 7.22131 14.9125C7.47122 15.1626 7.81017 15.3031 8.1636 15.3031Z"
          fill="#6E737D"
        />
      </svg>
    </div>
  </div>
</template>
