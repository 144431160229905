<template>
  <ContentCardSubheader :back-to="{ name: ReviewsRouteName.ReviewsProducts }">
    <div class="flex items-end">
      {{ t('Reviews.product.view.reviewsTitle', { productName: product.title }) }}
      <span class="text-body-m ml-4">{{ t('Reviews.product.view.reviewsTitleHint', { count }) }}</span>
    </div>
  </ContentCardSubheader>
</template>
<script setup lang="ts">
import { type ProductData, useReviewsProductSurveyResponsesCountQuery } from '@/gql/reviews'
import { useI18n } from 'vue-i18n'
import { computed } from 'vue'
import { ReviewsRouteName } from '@/router/types.ts'
import ContentCardSubheader from '@/ui/ContentCardSubheader.vue'

const props = defineProps<{
  product: Pick<ProductData, 'id' | 'slug' | 'title'>
}>()

const { t } = useI18n()

const variables = computed(() => ({ slug: props.product.slug }))
const { result } = useReviewsProductSurveyResponsesCountQuery(variables, { clientId: 'reviews' })
const count = computed(() => result.value?.manage?.surveyResponses?.pagination.totalCount)
</script>
