export enum ReviewsRouteTabs {
  Details = 'details',
  GlobalFeatures = 'global-features',
  Pricing = 'pricing',
  Assets = 'assets',
  Reviews = 'reviews',
}

export enum ReviewsRouteName {
  ReviewsProducts = 'reviewsProducts',
  ReviewsProductView = 'reviewsProductView',
  ReviewsProductViewDetails = `reviewsProductView${ReviewsRouteTabs.Details}`,
  ReviewsProductViewAssets = `reviewsProductView${ReviewsRouteTabs.Assets}`,
  ReviewsProductViewGlobalFeatures = `reviewsProductView${ReviewsRouteTabs.GlobalFeatures}`,
  ReviewsProductViewPricing = `reviewsProductView${ReviewsRouteTabs.Pricing}`,
  ReviewsProductViewReviews = `reviewsProductView${ReviewsRouteTabs.Reviews}`,
  ReviewsProductViewReviewDetails = `reviewsProductView${ReviewsRouteTabs.Reviews}Details`,
  ReviewsProductEdit = 'reviewsProductEdit',
  ReviewsProductEditDetails = `reviewsProductEdit${ReviewsRouteTabs.Details}`,
  ReviewsProductEditAssets = `reviewsProductEdit${ReviewsRouteTabs.Assets}`,
  ReviewsProductEditGlobalFeatures = `reviewsProductEdit${ReviewsRouteTabs.GlobalFeatures}`,
  ReviewsProductEditPricing = `reviewsProductEdit${ReviewsRouteTabs.Pricing}`,
}
